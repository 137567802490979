import { createRef, useEffect } from "react"
import { Loader } from "../Loader";
import { motion } from 'framer-motion'
import { useStateMany } from "../customHooks/useStateMany";
import { ErrorAlert } from "../ErrorAlert";
import { API } from "../API";
import { Modal } from "../modal";
import { Fragment, useState } from 'react'
import { Listbox, Transition } from '@headlessui/react'
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid'
import { userType } from "../typos";

interface ProfilesTypes {
  user: userType
}
export function Profiles({ user }: ProfilesTypes) {
  const [state, setState] = useStateMany({
    data: [],
    reload: false,
    loading: false,
    loaded: false,
    showCreateProfile: false,
    error: false,
    errorMessage: '',
    errorTitle: '',
  })
  const [citysData, setCitysData]: any = useState([
    { name: "Seleccion una ciudad", id: 0 },
  ])
  const [documentTypeData, setDocumentTypeData]: any = useState([
    { name: "Seleccion un tipo de documento", id: 0, abbrev: '' },
  ])
  // falta traer esta del backend
  const [clientPayMethodData, setClientsPayMethod] = useState([
    { name: "Seleccione un tipo", id: 0 },
  ])
  const [userTypesData, setUserTypesData] = useState([
    { name: "", id: 0, allow_created_by: '', color: '', level: '' },
  ])
  const [customerTypesData, setCustomerTypeData]: any = useState([
    { name: "", id: 0 },
  ])
  useEffect(() => {
    try {
      let dataToSend = {
        API_KEY: process.env.REACT_APP_API_KEY,
        id: user.id,
        permissions: user.permissions,
      }
      setState({ loading: true });

      API.post('/?profiles', {
        ...dataToSend
      }).then((response) => {
        // console.log(response, 'profiles')
        if (response.status === 200) {
          setState({ data: response.data.profiles, loading: false, loaded: true });
          setState({ loading: false });
        } else {
          setState({ loading: false, loaded: true, error: true, errorTitle: 'Error al consultar la informacion de los usuarios', errorMessage: 'No se pudo obtener los datos de los usuarios, por favor comunique este error al equipo de desarrollo' })
        }
      }).catch((error) => {
        console.log("Ha ocurrido un error", error)
        setState({ loading: false, loaded: true, error: true, errorTitle: 'Error al consultar la informacion de los usuarios', errorMessage: 'No se pudo obtener los datos de los usuarios, por favor comunique este error al equipo de desarrollo' })
      });
    } catch (error) {
      console.log(error)
    }
    let dataToSend = {
      API_KEY: process.env.REACT_APP_API_KEY
    }
    API.post('/?lens', {
      ...dataToSend
    }).then((response) => {
      // console.log('response de lens', response)
      if (response.status === 200 && response.data) {
        let newCitysData = [
          { name: "Seleccion una ciudad", id: 0 },
          ...response.data.citys
        ]
        let newDocumentTypeData = [
          ...response.data.documentType
        ]
        let newCustomerTypesData = [
          ...response.data.customerType
        ]
        let newUserTypesData = [
          ...response.data.userType
        ]
        let newClientsPayMethod = [
          ...response.data.clientPayMethods
        ]
        setCitysData(newCitysData)
        setDocumentTypeData(newDocumentTypeData)
        setCustomerTypeData(newCustomerTypesData)
        setUserTypesData(newUserTypesData)
        setClientsPayMethod(newClientsPayMethod)
      } else {
        setState({ waitingForResponse: false, error: true, errorTitle: 'Ocurrio un error al intentar consultar informacion', errorMessage: 'No se pudo conseguir la informacion necesaria para continuar.' })
      }
    }).catch((error) => {
      console.log(error)
    })
  }, [state.reload])
  return (
    <>
      {state.error &&
        <ErrorAlert ErrorElement={state.ErrorElement}
          animate={{ x: 0, opacity: 1, zIndex: 50, left: "1rem", bottom: "1rem", position: "fixed" }}
          transition={{ duration: 0.3 }}
          initial={{ x: -50, opacity: 0.1, zIndex: 50, left: "1rem", bottom: "1rem", position: "fixed" }}
          message={state.errorMessage}
          title={state.errorTitle} />
      }
      <div className="max-w-full">
        <div className="flex items-center justify-center flex-col h-full w-full">
          <h4 className="text-4xl font-bold mb-2 text-center">
            Perfiles
          </h4>
          <p className="mb-10 text-gray-600 text-center max-w-[500px]">En esta seccion podras administrar los perfiles/cuentas de los usuarios o clientes</p>
          <div className="flex justify-center items-center gap-4">
            <button onClick={() => { setState({ showCreateProfile: true }) }}
              className="inline-block rounded bg-green-500 px-8 py-3 text-sm font-medium text-white transition hover:bg-transparent border-[1px] hover:border-green-500  hover:text-green-500 focus:outline-none focus:ring active:bg-green-400 active:text-white">
              Crear Perfil
            </button>
          </div>
        </div>
        {state.loading &&
          <Loader className="mt-16" />
        }
        <ProfilesList
          citysData={citysData}
          documentTypeData={documentTypeData}
          clientPayMethodData={clientPayMethodData}
          userTypesData={userTypesData}
          customerTypesData={customerTypesData}
          loading={state.loading}
          data={state.data}
          loaded={state.loaded}
          user={user}
          reload={state.reload}
          updateProfiles={setState}
        />
        <CreateProfile
          citysData={citysData}
          documentTypeData={documentTypeData}
          clientPayMethodData={clientPayMethodData}
          userTypesData={userTypesData}
          customerTypesData={customerTypesData}
          user={user}
          showCreateProfile={state.showCreateProfile}
          reload={state.reload}
          setShowCreateProfile={setState}
        />
      </div>
    </>
  )
}
interface CreateProfileTypes {
  showCreateProfile: boolean;
  setShowCreateProfile: (value: boolean | {}) => void;
  user: userType;
  reload: boolean;
  citysData: any;
  documentTypeData: any;
  clientPayMethodData: any;
  userTypesData: any;
  customerTypesData: any;
}

const CreateProfile = ({ showCreateProfile, setShowCreateProfile, user, reload, citysData, documentTypeData, clientPayMethodData, userTypesData, customerTypesData }: CreateProfileTypes) => {
  const [city, setCity] = useState(citysData[0])
  const [clientPayMethod, setClientPayMethod] = useState(clientPayMethodData[0])
  // con esto hago que al actualizarse los datos, se cambie y seleccione por defecto a el primero y no se quede en uno vacio
  const [documentType, setDocumentType] = useState(documentTypeData.length > 0 ? documentTypeData[0] : documentTypeData[0])
  const [state, setState] = useStateMany({
    successAlert: false,
    waitingForResponse: false,
    userName: '',
    name: '',
    lastName: '',
    email: '',
    phone: '',
    level: '0',
    address: '',
    documentNumber: '',
    clientType: 1,
    professionalCard: '',
    eyeActive: false,
    error: false,
    errorMessage: '',
    errorTitle: '',
    ErrorElement: null,
  })
  // TODO: modificar el actualizar para que carge la informacion
  const userNameRef = createRef<HTMLInputElement>()
  const emailRef = createRef<HTMLInputElement>()
  const phoneRef = createRef<HTMLInputElement>()
  const addressRef = createRef<HTMLInputElement>()
  const cityRef = createRef<HTMLInputElement>()
  const professionalCardRef = createRef<HTMLInputElement>()
  const documentNumberRef = createRef<HTMLInputElement>()
  const clientTypeRef = createRef<HTMLInputElement>()
  const nameRef = createRef<HTMLInputElement>()
  const lastNameRef = createRef<HTMLInputElement>()
  const errorStyles = ["outline-3", "outline", "outline-red-400"]

  function setInputLevel(event: any) {
    setState({ level: event.target.value });
  }
  function setClientType(event: any) {
    setState({ clientType: event.target.value });
  }
  function updateUserName(event: React.ChangeEvent<HTMLInputElement>) {
    userNameRef.current?.classList.remove(...errorStyles)
    setState({ userName: event.target.value });
  }
  function updateEmail(event: React.ChangeEvent<HTMLInputElement>) {
    emailRef.current?.classList.remove(...errorStyles)
    setState({ email: event.target.value });
  }
  function updatePhone(event: React.ChangeEvent<HTMLInputElement>) {
    phoneRef.current?.classList.remove(...errorStyles)
    setState({ phone: event.target.value });
  }
  function updateName(event: React.ChangeEvent<HTMLInputElement>) {
    nameRef.current?.classList.remove(...errorStyles)
    setState({ name: event.target.value });
  }
  function updateLastName(event: React.ChangeEvent<HTMLInputElement>) {
    lastNameRef.current?.classList.remove(...errorStyles)
    setState({ lastName: event.target.value });
  }
  function updateAddress(event: React.ChangeEvent<HTMLInputElement>) {
    addressRef.current?.classList.remove(...errorStyles)
    setState({ address: event.target.value });
  }
  function updateCity() {
    cityRef.current?.classList.remove(...errorStyles)
  }
  function updateProfessionalCard(event: React.ChangeEvent<HTMLInputElement>) {
    professionalCardRef.current?.classList.remove(...errorStyles)
    setState({ professionalCard: event.target.value });
  }
  function updateDocumentNumber(event: React.ChangeEvent<HTMLInputElement>) {
    documentNumberRef.current?.classList.remove(...errorStyles)
    setState({ documentNumber: event.target.value });
  }
  function updateClientType() {
    clientTypeRef.current?.classList.remove(...errorStyles)
  }

  function sendCreateProfile(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    event.preventDefault();
    if (state.userName && state.name && state.lastName && state.email && state.phone && state.level) {
      let dataToSend = {}
      if (state.level === '1') {
        if (state.address && city.id !== 0 && state.documentNumber && clientPayMethod.id !== 0) {
          dataToSend = {
            API_KEY: process.env.REACT_APP_API_KEY,
            id_profile_creator: parseInt(user.id),
            username: state.userName.trim(),
            name: state.name.trim(),
            lastName: state.lastName.trim(),
            email: state.email.trim(),
            phone: state.phone,
            permissions: parseInt(state.level),
            city: parseInt(city.id),
            address: state.address,
            clientPayMethod: clientPayMethod.id,
            professionalCard: state.professionalCard,
            documentNumber: state.documentNumber,
            documentType: parseInt(documentType.id),
            customerType: parseInt(state.clientType)
          }
        } else {
          throwEmptyDataError()
        }
      } else {
        dataToSend = {
          API_KEY: process.env.REACT_APP_API_KEY,
          id_profile_creator: parseInt(user.id),
          username: state.userName,
          name: state.name,
          lastName: state.lastName,
          email: state.email,
          phone: state.phone,
          permissions: parseInt(state.level),
        }
      }

      try {
        // console.log("se envio:", dataToSend)
        setState({ waitingForResponse: true });
        API.post("/?insert", {
          ...dataToSend
        }).then((response) => {
          // console.log(response, 'respuesta de insert')
          if (response.status === 200 && response.data.success === 1) {
            // cambiar la variable 'reload' hace que se recargue la informacion de los perfiles
            setShowCreateProfile({ showCreateProfile: false, reload: !reload });
            resetProfileState()
            setTimeout(() => {
              setState({ successAlert: false });
            }, 7000);
          } else if (response.data['success'] === 10) {
            setState({ waitingForResponse: false, error: true, errorTitle: 'Ya existe un perfil asociado a ese correo electronico', errorMessage: 'Ya hay un perfil con esa direccion de correo electronico, puede intentar borrar la cuenta que lo tiene o actualizarla.' })
          } else if (response.data['success'] === 11) {
            setState({ waitingForResponse: false, error: true, errorTitle: 'Ya existe un perfil asociado a ese nombre de usuario', errorMessage: 'Ya hay un perfil con ese nombre de usuario, puede intentar borrar la cuenta que lo tiene o actualizarla.' })
          } else {
            setState({ waitingForResponse: false, error: true, errorTitle: 'Error al guardar en la informacion', errorMessage: 'Ocurrio un error al guardar la informacion en la base de datos, por favor verifique que la informacion ingresada sea la correcta e intente nuevamente' })
          }
        })
      } catch (error) {
        setState({ waitingForResponse: false, error: true, errorTitle: 'Error', errorMessage: error })
        console.error(error);
      }
    } else {
      throwEmptyDataError()
    }
  }
  function throwEmptyDataError() {
    let emptyFields = ''
    if (state.userName === '') {
      userNameRef.current?.classList.add(...errorStyles);
      emptyFields += " 'Nombre de usuario' ";
    }
    if (state.name === '') {
      nameRef.current?.classList.add(...errorStyles);
      emptyFields += " 'Nombre' ";
    }
    if (state.lastName === '') {
      lastNameRef.current?.classList.add(...errorStyles);
      emptyFields += " 'Apellido' ";
    }
    if (state.email === '') {
      emailRef.current?.classList.add(...errorStyles);
      emptyFields += " 'Correo electronico' ";
    }
    if (state.phone === '') {
      phoneRef.current?.classList.add(...errorStyles);
      emptyFields += " 'Numero de telefono' ";
    }
    if (state.level === '1') {
      if (state.address === '') {
        addressRef.current?.classList.add(...errorStyles);
        emptyFields += " 'Direccion' ";
      }
      if (city.id === 0) {
        cityRef.current?.classList.add(...errorStyles);
        emptyFields += " 'Ciudad' ";
      }
      if (state.professionalCard === '') {
        professionalCardRef.current?.classList.add(...errorStyles);
        emptyFields += " 'Tarjeta profesional' ";
      }
      if (state.documentNumber === '') {
        documentNumberRef.current?.classList.add(...errorStyles);
        emptyFields += " 'Numero de documento' ";
      }
      if (clientPayMethod.id === 0) {
        clientTypeRef.current?.classList.add(...errorStyles);
        emptyFields += " 'Tipo de cliente' ";
      }
    }
    setState({ error: true, errorTitle: 'Error: rellene todos los campos para enviar el guardar la informacion', errorMessage: `Los campos ${emptyFields} estan vacios o no son validos, por favor rellene los campos e intente de nuevo.`, ErrorElement: <p>Los campos <strong>{emptyFields}</strong> estan vacios o no son validos, por favor rellene los campos e intente de nuevo.</p> })
  }
  function resetProfileState() {
    setCity(citysData[0])
    setClientPayMethod(clientPayMethodData[0])
    setDocumentType(documentTypeData[0])
    setState({
      successAlert: false,
      waitingForResponse: false,
      userName: '',
      name: '',
      lastName: '',
      email: '',
      phone: '',
      level: '0',
      address: '',
      documentNumber: '',
      professionalCard: '',
      eyeActive: false,
      error: false,
      errorMessage: '',
      errorTitle: '',
      ErrorElement: null,
    })
  }
  if (showCreateProfile) {
    return (<>
      {state.error &&
        <ErrorAlert ErrorElement={state.ErrorElement}
          animate={{ x: 0, opacity: 1, zIndex: 50, left: "1rem", bottom: "1rem", position: "fixed" }}
          transition={{ duration: 0.3 }}
          initial={{ x: -50, opacity: 0.1, zIndex: 50, left: "1rem", bottom: "1rem", position: "fixed" }}
          message={state.errorMessage}
          title={state.errorTitle} />
      }
      {state.waitingForResponse &&
        <Loader
          animate={{ transform: "rotate(45deg) scale(0.8) translate(0px,0px)", opacity: 1, zIndex: 50, left: "2.5rem", bottom: "1.25rem", position: "fixed" }} transition={{ duration: 0.3 }} initial={{ transform: "rotate(45deg) scale(0.8) translate(-50px,50px)", opacity: 0.1, zIndex: 50, left: "2.5rem", bottom: "1.25rem", position: "fixed" }} />
      }
      {state.successAlert &&
        <motion.div initial={{ x: '-150%', opacity: 0.5 }} animate={{ x: '0%', opacity: 1 }} role="alert" className="rounded-xl border fixed  left-3 sm:left-10  bottom-10 z-50 bg-white border-gray-100 p-4 shadow-xl">
          <div className="flex items-start  gap-4">
            <span className="text-green-600">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                className="h-6 w-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                />
              </svg>
            </span>

            <div className="flex-1">
              <strong className="block font-medium text-gray-900">Cambios completados</strong>

              <p className="mt-1 text-sm text-gray-700">
                El nuevo perfil ha sido guardado.
              </p>
            </div>

            <button onClick={() => { setState({ successAlert: false }) }} className="text-gray-500 transition hover:text-gray-600">
              <span className="sr-only">Dismiss popup</span>

              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                className="h-6 w-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
          </div>
        </motion.div>
      }
      <Modal />
      <motion.section initial={{ y: 50, opacity: 0 }} animate={{ y: 0, opacity: 1 }} transition={{ duration: 0.5 }} className="fixed top-0 left-0 w-full h-full flex justify-center items-start z-40 overflow-y-auto">
        <div className="mx-auto max-w-screen-xl px-4 py-16 sm:px-6 lg:px-8">
          <div className="grid grid-cols-1 gap-x-16 gap-y-8 lg:grid-cols-5">
            <div className="lg:col-span-2 lg:py-6">
              <p className="max-w-xl text-lg text-white">
                Aquí podrás crear un nuevo perfil, el correo al que se le enviara una notificación con su nuevo Nombre de usuario y Contraseña para que pueda acceder a la aplicacion con el rol que se le asigne
              </p>
              <div>
                <p className="text-white mt-4 inline-block">Existen diferentes niveles de roles y acceso a los que la cuenta que vas a crear puede hacer. Puedes cambiar los roles de cada usuario cuando quieras.</p>
                {/* <i className="text-sm text-white mt-4 inline-block">No se puede crear un perfil con un rol igual o superior al de el usuario que lo esta creando</i> */}
                <ul className="pl-3 py-5 text-white list-disc flex items-start justify-start flex-col gap-y-5">
                  <li className="flex justify-start items-center first-letter:uppercase"><i className="mb-auto text-green-500 font-bold mr-3 text-lg">Cliente: </i><span className="w-full first-letter:uppercase">Puede crear pedidos, cajas de prueba y accesorios</span></li>
                  <li className="flex justify-start items-center first-letter:uppercase"><i className="mb-auto text-blue-500 font-bold mr-3 text-lg">Supervisor: </i><span className="w-full first-letter:uppercase">Podra cambiar el estado de los pedidos, cajas de prueba, accesorios y crear y modificar perfiles para nuevos clientes.</span></li>
                  <li className="flex justify-start items-center first-letter:uppercase"><i className="mb-auto text-yellow-500 font-bold mr-3 text-lg">Mensajero: </i><span className="w-full first-letter:uppercase">Este rol unicamente puede ver los pedidos y marcarlos como entregado.</span></li>
                  {/* <li className="flex justify-start items-center first-letter:uppercase"><i className="mb-auto text-orange-500 font-bold mr-3 text-lg">Nivel 4: </i><span className="w-full first-letter:uppercase">puede ver y editar los pedidos, permisos, perfiles y ver general</span></li> */}
                  <li className="flex justify-start items-center first-letter:uppercase"><i className="mb-auto text-red-500 font-bold mr-3 text-lg">Administrador: </i><span className="w-full first-letter:uppercase">Puede hacer todo y ver todo</span></li>
                </ul>
              </div>
            </div>
            <div className="lg:col-span-3 lg:p-12">
              <form className="rounded-lg bg-white p-8 shadow-lg space-y-4" autoComplete="nope">
                <div>
                  <label className="text-sm font-medium text-gray-700" htmlFor="username">Nombre de usuario</label>
                  <input ref={userNameRef} className="h-12 w-full rounded-lg border-gray-200 border-[1px] p-3 text-sm" type="text" id="username" value={state.userName} onChange={(event) => { updateUserName(event) }} />
                </div>
                <div className="grid grid-cols-1 gap-4 sm:grid-cols-2">
                  {/* Nombre */}
                  <div>
                    <label className="text-sm font-medium text-gray-700" htmlFor="name">Nombre</label>
                    <input ref={nameRef} className="h-12 w-full rounded-lg border-gray-200 border-[1px] p-3 text-sm" type="text" id="name" value={state.name} onChange={(event) => { updateName(event) }} />
                  </div>
                  {/* Apellido */}
                  <div>
                    <label className="text-sm font-medium text-gray-700" htmlFor="lastName">Apellido</label>
                    <input ref={lastNameRef} className="h-12 w-full rounded-lg border-gray-200 border-[1px] p-3 text-sm" type="text" id="lastName" value={state.lastName} onChange={(event) => { updateLastName(event) }} />
                  </div>
                </div>
                <div className="grid grid-cols-1 gap-4 sm:grid-cols-2">
                  <div>
                    <label className="text-sm font-medium text-gray-700" htmlFor="email">Correo Electronico</label>
                    <input ref={emailRef} className="h-12 w-full rounded-lg border-gray-200 border-[1px] p-3 text-sm" type="email" id="email" value={state.email} onChange={(event) => { updateEmail(event) }} />
                  </div>
                  <div>
                    <label className="text-sm font-medium text-gray-700" htmlFor="phone">Numero Telefono</label>
                    <input ref={phoneRef} className="h-12 w-full rounded-lg border-gray-200 border-[1px] p-3 text-sm" type="tel" id="phone" value={state.phone} onChange={(event) => { updatePhone(event) }} />
                  </div>
                </div>
                <div className={`grid grid-cols-1 gap-4 text-center md:grid-cols-${(() => {
                  // se encarga de poner la cantidad de columnas apropiadas para la cantidad de roles que puedes crear
                  let i = 0;
                  userTypesData.forEach((el: any) => `${el.allow_created_by}`.includes(`${user.permissions}`) && i++);
                  return i;
                })()}`}>
                  {/* ok al parecer tailwind requiere que los colores o alguna clases a usar esten explicitamente escritas en alguna parte de el codigo  */}
                  {userTypesData.map((userType: any) => {
                    if (`${userType.allow_created_by}`.includes(`${user.permissions}`)) {
                      return (
                        <div key={userType.id}>
                          <input className="peer sr-only" id={`level-${userType.level}`} type="radio" tabIndex={-1} name="level" value={`${userType.id}`} onClick={(event) => { setInputLevel(event) }} />
                          <label htmlFor={`level-${userType.level}`} className={`block w-full border-[2px] rounded-lg border-gray-200 p-3 hover:border-black peer-checked:border-blue-600 peer-checked:bg-${userType.color} peer-checked:text-white text-gray-700`} tabIndex={0}>
                            <span className="text-sm font-medium"> {userType.name} </span>
                          </label>
                        </div>
                      )
                    }
                  })}
                </div>
                {/* {parseInt(user.permissions) > 1 &&
                    <div>
                      <input className="peer sr-only" id="level-1" type="radio" tabIndex={-1} name="level" value="1" onClick={(event) => { setInputLevel(event) }} />
                      <label htmlFor="level-1" className="block w-full border-[2px] rounded-lg border-gray-200 p-3 hover:border-black peer-checked:border-blue-600 peer-checked:bg-green-500 peer-checked:text-white text-gray-700" tabIndex={0}>
                        <span className="text-sm font-medium"> Cliente </span>
                      </label>
                    </div>}
                  {parseInt(user.permissions) > 2 &&
                    <div>
                      <input value="2" onClick={(event) => { setInputLevel(event) }} className="peer sr-only" id="level-2" type="radio" tabIndex={-1} name="level" />
                      <label htmlFor="level-2" className="block w-full rounded-lg border-[2px] border-gray-200 p-3 hover:border-black peer-checked:border-blue-600 peer-checked:bg-blue-500 peer-checked:text-white text-gray-700" tabIndex={0}>
                        <span className="text-sm font-medium"> Supervisor </span>
                      </label>
                    </div>
                  }
                  {parseInt(user.permissions) > 3 &&
                    <div>
                      <input value="3" onClick={(event) => { setInputLevel(event) }} className="peer sr-only" id="level-3" type="radio" tabIndex={-1} name="level" />
                      <label htmlFor="level-3" className="block w-full rounded-lg border-[2px] border-gray-200 p-3 hover:border-black peer-checked:border-blue-600 peer-checked:bg-yellow-500 peer-checked:text-white text-gray-700" tabIndex={0}>
                        <span className="text-sm font-medium"> Mensajero </span>
                      </label>
                    </div>
                  } */}
                {/* {parseInt(user.permissions) > 4 &&
                    <div>
                      <input value="4" onClick={(event) => { setInputLevel(event) }} className="peer sr-only" id="level-4" type="radio" tabIndex={-1} name="level" />
                      <label htmlFor="level-4" className="block w-full rounded-lg border-[2px] border-gray-200 p-3 hover:border-black peer-checked:border-blue-600 peer-checked:bg-orange-500 peer-checked:text-white text-gray-700" tabIndex={0}>
                        <span className="text-sm font-medium"> Nivel 4 </span>
                      </label>
                    </div>
                  } */}
                {/* {parseInt(user.permissions) === 5 &&
                    <div>
                      <input value="5" onClick={(event) => { setInputLevel(event) }} className="peer sr-only" id="level-5" type="radio" tabIndex={-1} name="level" />
                      <label htmlFor="level-5" className="block w-full rounded-lg border-[2px] border-gray-200 p-3 hover:border-black peer-checked:border-blue-600 peer-checked:bg-red-500 peer-checked:text-white text-gray-700" tabIndex={0}>
                        <span className="text-sm font-medium"> Administrador </span>
                      </label>
                    </div>
                  } */}
                {state.level === '1' &&
                  <>
                    <div className="grid grid-cols-1 gap-4 sm:grid-cols-2">
                      {/* Ciudad */}
                      <div>
                        <span className="first-letter:capitalize text-sm font-medium text-gray-700">Ciudad</span>
                        <Listbox value={city} onChange={setCity}>
                          <div ref={cityRef} className="relative rounded-lg">
                            <Listbox.Button className="relative h-12 w-full mt-0 rounded-lg border-gray-200 border-[1px] p-3 text-sm text-left focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-sm">
                              <span className={`block truncate ${city.id === 0 && "text-gray-500"}`}>{city.name}</span>
                              <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                                <ChevronUpDownIcon
                                  className="h-5 w-5 text-gray-400"
                                  aria-hidden="true"
                                />
                              </span>
                            </Listbox.Button>
                            <Transition
                              as={Fragment}
                              leave="transition ease-in duration-100"
                              leaveFrom="opacity-100"
                              leaveTo="opacity-0"
                            >
                              <Listbox.Options className="z-40 absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                {citysData.map((city: { name: string, id: number }, cityIdx: number) => (
                                  <Listbox.Option
                                    key={cityIdx}
                                    onClick={((event) => { updateCity() })}
                                    className={({ active }) =>
                                      `relative cursor-default select-none py-2 pl-10 pr-4 ${city.id === 0 && 'hidden'} ${active ? 'bg-blue-600 text-white' : 'text-gray-900'
                                      }`
                                    }
                                    value={city}
                                  >
                                    {({ selected }) => (
                                      <>
                                        <span
                                          className={`block truncate ${city.id === 0 && 'text-gray-500'} ${selected ? 'font-medium' : 'font-normal'
                                            }`}
                                        >
                                          {city.name}
                                        </span>
                                        {selected ? (
                                          <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-indigo-600">
                                            <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                          </span>
                                        ) : null}
                                      </>
                                    )}
                                  </Listbox.Option>
                                ))}
                              </Listbox.Options>
                            </Transition>
                          </div>
                        </Listbox>
                      </div>
                      {/* Direccion */}
                      <div>
                        <label className="text-sm font-medium text-gray-700" htmlFor="address">Direccion</label>
                        <input ref={addressRef} className="h-12 w-full rounded-lg border-gray-200 border-[1px] p-3 text-sm" type="text" id="address" value={state.address} onChange={(event) => { updateAddress(event) }} />
                      </div>
                    </div>
                    <div className="grid grid-cols-1 gap-4 sm:grid-cols-2">
                      {/* numero de documento */}
                      <div>
                        <span className="first-letter:capitalize text-sm font-medium text-gray-700">Numero de documento</span>
                        <div className="flex justify-center items-center border-gray-200 border-[1px] rounded-lg">
                          <Listbox value={documentType} onChange={setDocumentType}>
                            <div className="relative rounded-l-lg">
                              <Listbox.Button className="relative h-12 w-16 mt-0 rounded-l-lg p-3 text-sm text-left focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-sm">
                                <span className={`block truncate`}>{documentType.abbrev}</span>
                                <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                                  <ChevronUpDownIcon
                                    className="h-5 w-5 text-gray-400"
                                    aria-hidden="true"
                                  />
                                </span>
                              </Listbox.Button>
                              <Transition
                                as={Fragment}
                                leave="transition ease-in duration-100"
                                leaveFrom="opacity-100"
                                leaveTo="opacity-0"
                              >
                                <Listbox.Options className="z-40 absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                  {documentTypeData.map((documentType: { name: string, id: number, abbrev: string }, documentIdx: number) => (
                                    <Listbox.Option
                                      key={documentIdx}
                                      onClick={((event) => { })}
                                      className={({ active }) =>
                                        `relative cursor-default select-none py-2 pl-7 pr-1 ${active ? 'bg-blue-600 text-white' : 'text-gray-900'
                                        }`
                                      }
                                      value={documentType}
                                    >
                                      {({ selected }) => (
                                        <>
                                          <span
                                            className={`block truncate ${selected ? 'font-medium' : 'font-normal'
                                              }`}
                                          >
                                            {documentType.abbrev}
                                          </span>
                                          {selected ? (
                                            <span className="absolute inset-y-0 left-0 flex items-center pl-1 text-indigo-600">
                                              <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                            </span>
                                          ) : null}
                                        </>
                                      )}
                                    </Listbox.Option>
                                  ))}
                                </Listbox.Options>
                              </Transition>
                            </div>
                          </Listbox>
                          <input ref={documentNumberRef} className="h-12 w-full rounded-r-lg border-gray-200 border-l-[1px] p-3 text-sm" type="text" id="documentNumber" value={state.documentNumber} onChange={(event) => { updateDocumentNumber(event) }} />
                        </div>
                      </div>
                      {/* Tipo de cliente */}
                      <fieldset>
                        <legend className="first-letter:capitalize text-sm font-medium text-gray-700">Tipo de cliente</legend>
                        <div className="flex h-12 justify-between items-center">
                          {
                            customerTypesData.map((item: any) => {
                              if (parseInt(item.id) !== 3) {
                                return (
                                  <div className="inline-flex items-center" key={item.id}>
                                    <label
                                      className="relative flex cursor-pointer items-center rounded-full p-3"
                                      htmlFor={item.id}
                                      data-ripple-dark="true"
                                    >
                                      <input
                                        id={item.id}
                                        name="type"
                                        type="radio"
                                        tabIndex={-1}
                                        value={item.id}
                                        defaultChecked={parseInt(item.id) === 1 ? true : false}
                                        onChange={(event) => { setClientType(event) }}
                                        className="before:content[''] peer relative h-4 w-4 cursor-pointer appearance-none rounded-full border border-gray-200 text-blue-500 transition-all before:absolute before:top-2/4 before:left-2/4 before:block before:h-9 before:w-9 before:-translate-y-2/4 before:-translate-x-2/4 before:rounded-full before:bg-blue-gray-500 before:opacity-0 before:transition-opacity checked:border-blue-500 checked:before:bg-blue-500 hover:before:opacity-10"
                                      />
                                      <div className="pointer-events-none absolute top-2/4 left-2/4 -translate-y-2/4 -translate-x-2/4 text-blue-500 opacity-0 transition-opacity peer-checked:opacity-100">
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          className="h-3 w-3"
                                          viewBox="0 0 16 16"
                                          fill="currentColor"
                                        >
                                          <circle data-name="ellipse" cx="8" cy="8" r="8"></circle>
                                        </svg>
                                      </div>
                                    </label>
                                    <label
                                      className="cursor-pointer text-sm font-normal select-none text-gray-700"
                                      htmlFor={item.id}
                                    >
                                      {item.name}
                                    </label>
                                  </div>
                                )
                              }
                            })
                          }
                        </div>
                      </fieldset>
                    </div>
                    <div className="grid grid-cols-1 gap-4 sm:grid-cols-2">
                      {/* metodo de pago del cliente */}
                      <div>
                        <span className="first-letter:capitalize text-sm font-medium text-gray-700">Metodos de pago de cliente</span>
                        <Listbox value={clientPayMethod} onChange={setClientPayMethod}>
                          <div ref={clientTypeRef} className="relative rounded-lg">
                            <Listbox.Button className="relative h-12 w-full mt-0 rounded-lg border-gray-200 border-[1px] p-3 text-sm text-left focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-sm">
                              <span className={`block truncate ${clientPayMethod.id === 0 && "text-gray-500"}`}>{clientPayMethod.name}</span>
                              <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                                <ChevronUpDownIcon
                                  className="h-5 w-5 text-gray-400"
                                  aria-hidden="true"
                                />
                              </span>
                            </Listbox.Button>
                            <Transition
                              as={Fragment}
                              leave="transition ease-in duration-100"
                              leaveFrom="opacity-100"
                              leaveTo="opacity-0"
                            >
                              <Listbox.Options className="z-40 absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                {clientPayMethodData.map((clientPayMethod: { name: string, id: number }, clientIdx: number) => (
                                  <Listbox.Option
                                    key={clientIdx}
                                    onClick={((event) => { updateClientType() })}
                                    className={({ active }) =>
                                      `relative cursor-default select-none py-2 pl-10 pr-4 ${active ? 'bg-blue-600 text-white' : 'text-gray-900'
                                      }`
                                    }
                                    value={clientPayMethod}
                                  >
                                    {({ selected }) => (
                                      <>
                                        <span
                                          className={`block truncate ${clientPayMethod.id === 0 && 'text-gray-500'} ${selected ? 'font-medium' : 'font-normal'
                                            }`}
                                        >
                                          {clientPayMethod.name}
                                        </span>
                                        {selected ? (
                                          <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-indigo-600">
                                            <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                          </span>
                                        ) : null}
                                      </>
                                    )}
                                  </Listbox.Option>
                                ))}
                              </Listbox.Options>
                            </Transition>
                          </div>
                        </Listbox>
                      </div>

                      {/* Tarjeta profesional */}
                      {parseInt(state.clientType) === 1 &&
                        <div>
                          <label className="text-sm font-medium text-gray-700" htmlFor="professionalCard">Tarjeta profesional</label>
                          <input ref={professionalCardRef} className="h-12 w-full rounded-lg border-gray-200 border-[1px] p-3 text-sm" type="text" id="professionalCard" value={state.professionalCard} onChange={(event) => { updateProfessionalCard(event) }} />
                        </div>
                      }
                    </div>
                  </>
                }
                <div className="mt-4 flex justify-center items-center gap-5">
                  <button type="submit" onClick={(event) => { (sendCreateProfile(event)) }}
                    className="inline-block rounded bg-green-500 px-8 py-3 text-sm font-medium text-white transition hover:bg-transparent border-[1px] hover:border-green-500  hover:text-green-500 focus:outline-none focus:ring active:bg-green-400 active:text-white">
                    Crear Perfil
                  </button>
                  <button type="button" onClick={() => {
                    setShowCreateProfile({ showCreateProfile: false });
                    resetProfileState()
                  }}
                    className="inline-block rounded bg-red-500 px-8 py-3 text-sm font-medium text-white transition hover:bg-transparent border-[1px] hover:border-red-500  hover:text-red-500 focus:outline-none focus:ring active:bg-red-400 active:text-white">
                    Cerrar
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </motion.section>
    </>

    )
  } else {
    return (
      <></>
    )
  }
}
interface ProfilesListTypes {
  loading: boolean;
  data: [];
  loaded: boolean;
  user: userType;
  reload: boolean;
  citysData: any;
  documentTypeData: any;
  clientPayMethodData: any;
  userTypesData: any;
  customerTypesData: any;
  updateProfiles: (value: boolean | {}) => void;
}
const ProfilesList = ({ loading, data, loaded, user, reload, updateProfiles, citysData, documentTypeData, clientPayMethodData, userTypesData, customerTypesData }: ProfilesListTypes) => {
  const userNameRef = createRef<HTMLInputElement>()
  const emailRef = createRef<HTMLInputElement>()
  const phoneRef = createRef<HTMLInputElement>()
  const addressRef = createRef<HTMLInputElement>()
  const cityRef = createRef<HTMLInputElement>()
  const professionalCardRef = createRef<HTMLInputElement>()
  const documentNumberRef = createRef<HTMLInputElement>()
  const clientTypeRef = createRef<HTMLInputElement>()
  const nameRef = createRef<HTMLInputElement>()
  const lastNameRef = createRef<HTMLInputElement>()

  const [city, setCity] = useState(citysData[0])
  const [clientPayMethod, setClientPayMethod] = useState(clientPayMethodData[0])
  const [documentType, setDocumentType] = useState(documentTypeData[0])
  const [state, setState] = useStateMany({
    deleteStage: false,
    waitingForResponse: false,
    idToDelete: null,
    nameToDelete: null,
    emailToDelete: null,
    phoneToDelete: null,
    permissionsToDelete: null,
    successAlert: false,
    error: false,
    errorMessage: '',
    errorTitle: '',
    ErrorElement: null,
    updateStage: false,
    idToUpdate: null,
    userName: '',
    name: '',
    lastName: '',
    email: '',
    phone: '',
    address: '',
    professionalCard: '',
    level: '0',
    documentNumber: '',
    clientType: 0,

  });
  function sendDelete() {
    if (state.idToDelete !== null) {
      setState({
        waitingForResponse: true,
        error: false,
        errorMessage: '',
        errorTitle: ''
      });
      API.post('/?delete', {
        API_KEY: process.env.REACT_APP_API_KEY,
        id: state.idToDelete,
      }).then((response) => {
        if (response.status === 200 && response.data.success === 1) {
          setState({
            waitingForResponse: false,
            successAlert: true,
            deleteStage: false,
            idToDelete: null,
            nameToDelete: null,
            emailToDelete: null,
            phoneToDelete: null,
            permissionsToDelete: null,
            error: false,
            errorMessage: '',
            errorTitle: ''
          });
          updateProfiles({ reload: !reload })
          setTimeout(() => {
            setState({
              successAlert: false,
            });
          }, 7000);
        } else {
          setState({
            error: true,
            errorMessage: 'No tienes permisos para borrar',
            errorTitle: 'No se pudo borrar el perfil',
            waitingForResponse: false,
            successAlert: false,
            deleteStage: false,
            idToDelete: null,
            nameToDelete: null,
            emailToDelete: null,
            phoneToDelete: null,
            permissionsToDelete: null,
          })
        }
      })
    }
  }
  function setInputLevel(event: any) {
    setState({ level: event.target.value });
  }
  function setClientType(event: any) {
    setState({ clientType: event.target.value });
  }
  function updateUserName(event: React.ChangeEvent<HTMLInputElement>) {
    userNameRef.current?.classList.remove(...errorStyles)
    setState({ userName: event.target.value });
  }
  function updateEmail(event: React.ChangeEvent<HTMLInputElement>) {
    emailRef.current?.classList.remove(...errorStyles)
    setState({ email: event.target.value });
  }
  function updatePhone(event: React.ChangeEvent<HTMLInputElement>) {
    phoneRef.current?.classList.remove(...errorStyles)
    setState({ phone: event.target.value });
  }
  function updateName(event: React.ChangeEvent<HTMLInputElement>) {
    nameRef.current?.classList.remove(...errorStyles)
    setState({ name: event.target.value });
  }
  function updateLastName(event: React.ChangeEvent<HTMLInputElement>) {
    lastNameRef.current?.classList.remove(...errorStyles)
    setState({ lastName: event.target.value });
  }
  function updateAddress(event: React.ChangeEvent<HTMLInputElement>) {
    addressRef.current?.classList.remove(...errorStyles)
    setState({ address: event.target.value });
  }
  function updateCity() {
    cityRef.current?.classList.remove(...errorStyles)
  }
  function updateProfessionalCard(event: React.ChangeEvent<HTMLInputElement>) {
    professionalCardRef.current?.classList.remove(...errorStyles)
    setState({ professionalCard: event.target.value });
  }
  function updateDocumentNumber(event: React.ChangeEvent<HTMLInputElement>) {
    documentNumberRef.current?.classList.remove(...errorStyles)
    setState({ documentNumber: event.target.value });
  }
  function updateClientType() {
    clientTypeRef.current?.classList.remove(...errorStyles)
  }

  function sendUpdateProfile(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    event.preventDefault();
    if (state.idToUpdate !== null) {
      if (state.userName && state.name && state.lastName && state.email && state.phone && state.level) {
        let dataToSend = {}
        if (state.level === '1') {
          if (state.address && city.id !== 0 && state.documentNumber && clientPayMethod.id !== 0) {
            dataToSend = {
              API_KEY: process.env.REACT_APP_API_KEY,
              idSession: parseInt(state.idToUpdate),
              username: state.userName.trim(),
              name: state.name.trim(),
              lastName: state.lastName.trim(),
              email: state.email.trim(),
              phone: state.phone.trim(),
              permissions: parseInt(state.level), // mejorar
              city: parseInt(city.id),
              address: state.address,
              clientPayMethod: parseInt(clientPayMethod.id),
              professionalCard: state.professionalCard,
              documentNumber: state.documentNumber,
              documentType: parseInt(documentType.id),
              customerType: parseInt(state.clientType)
            }
          } else {
            throwEmptyDataError()
          }
        } else {
          dataToSend = {
            API_KEY: process.env.REACT_APP_API_KEY,
            idSession: parseInt(state.idToUpdate),
            username: state.userName,
            name: state.name,
            lastName: state.lastName,
            email: state.email,
            phone: state.phone,
            permissions: parseInt(state.level),
          }
        }
        setState({
          waitingForResponse: true,
          error: false,
          errorMessage: '',
          errorTitle: ''
        });
        // console.log(dataToSend, 'actualizar perfil')
        // return '';
        API.post('/?update', {
          ...dataToSend
        }).then((response) => {
          // console.log(response, 'respyesta de actualizar')
          setState({ waitingForResponse: false })
          if (response.status === 200 && response.data.success === 1) {
            setState({
              updateStage: false,
              idToUpdate: null,
              successAlert: true,
            });
            resetProfileState()
            setTimeout(() => {
              setState({ successAlert: false });
            }, 7000);
            updateProfiles({ reload: !reload })
            setTimeout(() => {
              setState({
                successAlert: false,
              });
            }, 7000);
          } else if (response.data['success'] === 10) {
            setState({ waitingForResponse: false, error: true, errorTitle: 'Ya existe un perfil asociado a ese correo electronico', errorMessage: 'Ya hay un perfil con esa direccion de correo electronico, puede intentar borrar la cuenta que lo tiene o actualizarla.' })
          } else if (response.data['success'] === 11) {
            setState({ waitingForResponse: false, error: true, errorTitle: 'Ya existe un perfil asociado a ese nombre de usuario', errorMessage: 'Ya hay un perfil con ese nombre de usuario, puede intentar borrar la cuenta que lo tiene o actualizarla.' })
          } else {
            setState({
              error: true,
              errorMessage: 'Ocurrio un error al proceder con la actualizacion',
              errorTitle: 'No se pudo actualizar el perfil',
              waitingForResponse: false,
              successAlert: false,
              updateStage: false,
            })
          }
        }).catch((error) => {
          console.log('Ha ocurrido un error', error)
          setState({
            error: true,
            errorMessage: 'Ocurrio un error al proceder con la actualizacion',
            errorTitle: 'No se pudo actualizar el perfil',
            waitingForResponse: false,
            successAlert: false,
            updateStage: false,
          })
        })
      } else {
        throwEmptyDataError()
      }
    }
  }

  function resetProfileState() {
    setCity(citysData[0])
    setClientPayMethod(clientPayMethodData[0])
    setDocumentType(documentTypeData[0])
    setState({
      successAlert: false,
      waitingForResponse: false,
      userName: '',
      name: '',
      lastName: '',
      email: '',
      phone: '',
      level: '0',
      address: '',
      clientType: 0,
      documentNumber: '',
      professionalCard: '',
      eyeActive: false,
      error: false,
      errorMessage: '',
      errorTitle: '',
      ErrorElement: null,
    })
  }
  function throwEmptyDataError() {
    let emptyFields = ''
    if (state.userName === '') {
      userNameRef.current?.classList.add(...errorStyles);
      emptyFields += " 'Nombre de usuario' ";
    }
    if (state.name === '') {
      nameRef.current?.classList.add(...errorStyles);
      emptyFields += " 'Nombre' ";
    }
    if (state.lastName === '') {
      lastNameRef.current?.classList.add(...errorStyles);
      emptyFields += " 'Apellido' ";
    }
    if (state.email === '') {
      emailRef.current?.classList.add(...errorStyles);
      emptyFields += " 'Correo electronico' ";
    }
    if (state.phone === '') {
      phoneRef.current?.classList.add(...errorStyles);
      emptyFields += " 'Numero de telefono' ";
    }
    if (state.level === '1') {
      if (state.address === '') {
        addressRef.current?.classList.add(...errorStyles);
        emptyFields += " 'Direccion' ";
      }
      if (city.id === 0) {
        cityRef.current?.classList.add(...errorStyles);
        emptyFields += " 'Ciudad' ";
      }
      if (state.professionalCard === '') {
        professionalCardRef.current?.classList.add(...errorStyles);
        emptyFields += " 'Tarjeta profesional' ";
      }
      if (state.documentNumber === '') {
        documentNumberRef.current?.classList.add(...errorStyles);
        emptyFields += " 'Numero de documento' ";
      }
      if (clientPayMethod.id === 0) {
        clientTypeRef.current?.classList.add(...errorStyles);
        emptyFields += " 'Tipo de cliente' ";
      }
    }
    setState({ error: true, errorTitle: 'Error: rellene todos los campos para enviar el guardar la informacion', errorMessage: `Los campos ${emptyFields} estan vacios o no son validos, por favor rellene los campos e intente de nuevo.`, ErrorElement: <p>Los campos <strong>{emptyFields}</strong> estan vacios o no son validos, por favor rellene los campos e intente de nuevo.</p> })
  }
  const errorStyles = ["outline-3", "outline", "outline-red-400"]

  function loadToUpdate(profile: any) {
    // console.log(profile, 'profail')
    // se cambia el estado con la informacion de el perfil a actualizar
    setState({
      updateStage: true,
      idToUpdate: profile.id,
      userName: profile.username,
      lastName: profile.last_name,
      name: profile.name,
      phone: profile.phone,
      level: profile.user_level,
      email: profile.email,
      successAlert: false,
    })
    if (parseInt(profile.user_level) === 1) {
      // busco los indices de las ciudades de los perfiles en los datos existentes traidos de la base de datos 
      let indexCity = 0
      if (profile.city) {
        indexCity = citysData.findIndex((el: any) => el.name === profile.city)
      }
      let indexDocumentType = 0
      if (profile.document_type) {
        indexDocumentType = documentTypeData.findIndex((el: any) => el.name === profile.document_type)
      }
      let indexClientPayMethod = 0
      if (profile.client_pay_method) {
        indexClientPayMethod = clientPayMethodData.findIndex((el: any) => parseInt(el.id) === parseInt(profile.client_pay_method))
      }
      let indexClientType = 0
      if (profile.customer_type) {
        indexClientType = customerTypesData.findIndex((el: any) => parseInt(el.id) === parseInt(profile.customer_type))
      }
      setState({
        updateStage: true,
        idToUpdate: profile.id,
        userName: profile.username,
        lastName: profile.last_name,
        name: profile.name,
        phone: profile.phone,
        level: profile.user_level,
        email: profile.email,
        successAlert: false,
        documentNumber: profile.document_number ? profile.document_number : '',
        address: profile.address ? profile.address : '',
        professionalCard: parseInt(profile.customer_type) === 2 ? '' : profile.professional_card ? profile.professional_card : '',
      })
      // guardo los datos si son diferentes de 0 
      setClientPayMethod(clientPayMethodData[indexClientPayMethod])
      setCity(citysData[indexCity])
      setDocumentType(documentTypeData[indexDocumentType])
      setState({ clientType: customerTypesData[indexClientType].id });
    } else {


    }

  }
  return (
    <>
      {state.updateStage === true && state.successAlert === false &&
        <>
          <Modal />
          <motion.section initial={{ y: 50, opacity: 0 }} animate={{ y: 0, opacity: 1 }} transition={{ duration: 0.5 }} className="fixed top-0 left-0 w-full h-full flex justify-center items-start z-40 overflow-y-auto">
            {state.waitingForResponse === false && state.successAlert === false &&
              <div className="mx-auto max-w-screen-xl px-4 py-6 lg:py-16 sm:px-6 lg:px-8">
                <div className="grid grid-cols-1 gap-x-16 gap-y-8 lg:grid-cols-5">
                  <div className="lg:col-span-2 lg:py-6">
                    <p className="max-w-xl text-lg text-white font-medium">
                      Aquí podrás actualizar la informacion de los perfiles
                    </p>
                    <div>
                      <p className="text-white mt-4 inline-block">Antes de guardar los cambios por favor asegurese de que todos los campos esten completos y correctos.</p>
                    </div>
                  </div>
                  <div className="lg:col-span-3 lg:p-12">
                    <form className="rounded-lg bg-white p-8 shadow-lg space-y-4" autoComplete="nope">
                      <div>
                        <label className="text-sm font-medium text-gray-700" htmlFor="username">Nombre de usuario</label>
                        <input ref={userNameRef} className="h-12 w-full rounded-lg border-gray-200 border-[1px] p-3 text-sm" type="text" id="username" value={state.userName} onChange={(event) => { updateUserName(event) }} />
                      </div>
                      <div className="grid grid-cols-1 gap-4 sm:grid-cols-2">
                        {/* Nombre */}
                        <div>
                          <label className="text-sm font-medium text-gray-700" htmlFor="name">Nombre</label>
                          <input ref={nameRef} className="h-12 w-full rounded-lg border-gray-200 border-[1px] p-3 text-sm" type="text" id="name" value={state.name} onChange={(event) => { updateName(event) }} />
                        </div>
                        {/* Apellido */}
                        <div>
                          <label className="text-sm font-medium text-gray-700" htmlFor="lastName">Apellido</label>
                          <input ref={lastNameRef} className="h-12 w-full rounded-lg border-gray-200 border-[1px] p-3 text-sm" type="text" id="lastName" value={state.lastName} onChange={(event) => { updateLastName(event) }} />
                        </div>
                      </div>
                      <div className="grid grid-cols-1 gap-4 sm:grid-cols-2">
                        <div>
                          <label className="text-sm font-medium text-gray-700" htmlFor="email">Correo Electronico</label>
                          <input ref={emailRef} className="h-12 w-full rounded-lg border-gray-200 border-[1px] p-3 text-sm" type="email" id="email" value={state.email} onChange={(event) => { updateEmail(event) }} />
                        </div>
                        <div>
                          <label className="text-sm font-medium text-gray-700" htmlFor="phone">Numero Telefono</label>
                          <input ref={phoneRef} className="h-12 w-full rounded-lg border-gray-200 border-[1px] p-3 text-sm" type="tel" id="phone" value={state.phone} onChange={(event) => { updatePhone(event) }} />
                        </div>
                      </div>
                      <div className={`grid grid-cols-1 gap-4 text-center md:grid-cols-${(() => {
                        // se encarga de poner la cantidad de columnas apropiadas para la cantidad de roles que puedes crear
                        let i = 0;
                        userTypesData.forEach((el: any) => `${el.allow_created_by}`.includes(`${user.permissions}`) && i++)
                        return i;
                      })()}`}>
                        {userTypesData.map((item: any) => {
                          if (`${item.allow_created_by}`.includes(`${user.permissions}`)) {
                            return (
                              <div key={item.id}>
                                <input className="peer sr-only" defaultChecked={state.level === item.level ? true : false} id={`level-${item.level}`} type="radio" tabIndex={-1} name="level" value={`${item.id}`} onClick={(event) => { setInputLevel(event) }} />
                                <label htmlFor={`level-${item.level}`} className={`block w-full border-[2px] rounded-lg border-gray-200 p-3 hover:border-black peer-checked:border-blue-600 peer-checked:bg-${item.color} peer-checked:text-white text-gray-700`} tabIndex={0}>
                                  <span className="text-sm font-medium"> {item.name} </span>
                                </label>
                              </div>
                            )
                          }
                        })}
                      </div>
                      {state.level === '1' &&
                        <>
                          <div className="grid grid-cols-1 gap-4 sm:grid-cols-2">
                            {/* Ciudad */}
                            <div>
                              <span className="first-letter:capitalize text-sm font-medium text-gray-700">Ciudad</span>
                              <Listbox value={city} onChange={setCity}>
                                <div ref={cityRef} className="relative rounded-lg">
                                  <Listbox.Button className="relative h-12 w-full mt-0 rounded-lg border-gray-200 border-[1px] p-3 text-sm text-left focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-sm">
                                    <span className={`block truncate ${city.id === 0 && "text-gray-500"}`}>{city.name}</span>
                                    <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                                      <ChevronUpDownIcon
                                        className="h-5 w-5 text-gray-400"
                                        aria-hidden="true"
                                      />
                                    </span>
                                  </Listbox.Button>
                                  <Transition
                                    as={Fragment}
                                    leave="transition ease-in duration-100"
                                    leaveFrom="opacity-100"
                                    leaveTo="opacity-0"
                                  >
                                    <Listbox.Options className="z-40 absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                      {citysData.map((city: { name: string, id: number }, cityIdx: number) => (
                                        <Listbox.Option
                                          key={cityIdx}
                                          onClick={((event) => { updateCity() })}
                                          className={({ active }) =>
                                            `relative cursor-default select-none py-2 pl-10 ${city.id === 0 && 'hidden'} pr-4 ${active ? 'bg-blue-600 text-white' : 'text-gray-900'
                                            }`
                                          }
                                          value={city}
                                        >
                                          {({ selected }) => (
                                            <>
                                              <span
                                                className={`block truncate ${city.id === 0 && 'text-gray-500'} ${selected ? 'font-medium' : 'font-normal'
                                                  }`}
                                              >
                                                {city.name}
                                              </span>
                                              {selected ? (
                                                <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-indigo-600">
                                                  <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                                </span>
                                              ) : null}
                                            </>
                                          )}
                                        </Listbox.Option>
                                      ))}
                                    </Listbox.Options>
                                  </Transition>
                                </div>
                              </Listbox>
                            </div>
                            {/* Direccion */}
                            <div>
                              <label className="text-sm font-medium text-gray-700" htmlFor="address">Direccion</label>
                              <input ref={addressRef} className="h-12 w-full rounded-lg border-gray-200 border-[1px] p-3 text-sm" type="text" id="address" value={state.address} onChange={(event) => { updateAddress(event) }} />
                            </div>
                          </div>
                          <div className="grid grid-cols-1 gap-4 sm:grid-cols-2">
                            {/* numero de documento */}
                            <div>
                              <span className="first-letter:capitalize text-sm font-medium text-gray-700">Numero de documento</span>
                              <div className="flex justify-center items-center border-gray-200 border-[1px] rounded-lg">
                                <Listbox value={documentType} onChange={setDocumentType}>
                                  <div className="relative rounded-l-lg">
                                    <Listbox.Button className="relative h-12 w-16 mt-0 rounded-l-lg p-3 text-sm text-left focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-sm">
                                      <span className={`block truncate`}>{documentType.abbrev}</span>
                                      <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                                        <ChevronUpDownIcon
                                          className="h-5 w-5 text-gray-400"
                                          aria-hidden="true"
                                        />
                                      </span>
                                    </Listbox.Button>
                                    <Transition
                                      as={Fragment}
                                      leave="transition ease-in duration-100"
                                      leaveFrom="opacity-100"
                                      leaveTo="opacity-0"
                                    >
                                      <Listbox.Options className="z-40 absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                        {documentTypeData.map((documentType: { name: string, id: number, abbrev: string }, documentIdx: number) => (
                                          <Listbox.Option
                                            key={documentIdx}
                                            onClick={((event) => { })}
                                            className={({ active }) =>
                                              `relative cursor-default select-none py-2 pl-7 pr-1 ${active ? 'bg-blue-600 text-white' : 'text-gray-900'
                                              }`
                                            }
                                            value={documentType}
                                          >
                                            {({ selected }) => (
                                              <>
                                                <span
                                                  className={`block truncate ${selected ? 'font-medium' : 'font-normal'
                                                    }`}
                                                >
                                                  {documentType.abbrev}
                                                </span>
                                                {selected ? (
                                                  <span className="absolute inset-y-0 left-0 flex items-center pl-1 text-indigo-600">
                                                    <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                                  </span>
                                                ) : null}
                                              </>
                                            )}
                                          </Listbox.Option>
                                        ))}
                                      </Listbox.Options>
                                    </Transition>
                                  </div>
                                </Listbox>
                                <input ref={documentNumberRef} className="h-12 w-full rounded-r-lg border-gray-200 border-l-[1px] p-3 text-sm" type="text" id="documentNumber" value={state.documentNumber} onChange={(event) => { updateDocumentNumber(event) }} />
                              </div>
                            </div>
                            {/* Tipo de cliente */}
                            <fieldset>
                              <legend className="first-letter:capitalize text-sm font-medium text-gray-700">Tipo de cliente</legend>
                              <div className="flex h-12 justify-between items-center">
                                {
                                  customerTypesData.map((item: any) => {
                                    // filtramos la opcion con id 3 que seria optometras, ya que esta solo se puede emplear en la seccion optometras
                                    if (parseInt(item.id) !== 3) {
                                      return (
                                        <div className="inline-flex items-center" key={`${item.id}-clientType`}>
                                          <label
                                            className="relative flex cursor-pointer items-center rounded-full p-3"
                                            htmlFor={item.id}
                                            data-ripple-dark="true"
                                          >
                                            <input
                                              id={item.id}
                                              name="type"
                                              type="radio"
                                              tabIndex={-1}
                                              value={item.id}
                                              defaultChecked={parseInt(state.clientType) === parseInt(item.id) ? true : false}
                                              onClick={(event) => { setClientType(event) }}
                                              className="before:content[''] peer relative h-4 w-4 cursor-pointer appearance-none rounded-full border border-gray-200 text-blue-500 transition-all before:absolute before:top-2/4 before:left-2/4 before:block before:h-9 before:w-9 before:-translate-y-2/4 before:-translate-x-2/4 before:rounded-full before:bg-blue-gray-500 before:opacity-0 before:transition-opacity checked:border-blue-500 checked:before:bg-blue-500 hover:before:opacity-10"
                                            />
                                            <div className="pointer-events-none absolute top-2/4 left-2/4 -translate-y-2/4 -translate-x-2/4 text-blue-500 opacity-0 transition-opacity peer-checked:opacity-100">
                                              <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                className="h-3 w-3"
                                                viewBox="0 0 16 16"
                                                fill="currentColor"
                                              >
                                                <circle data-name="ellipse" cx="8" cy="8" r="8"></circle>
                                              </svg>
                                            </div>
                                          </label>
                                          <label
                                            className="cursor-pointer text-sm font-normal select-none text-gray-700"
                                            htmlFor={item.id}
                                          >
                                            {item.name}
                                          </label>
                                        </div>
                                      )
                                    }
                                  })
                                }
                              </div>
                            </fieldset>
                          </div>
                          <div className="grid grid-cols-1 gap-4 sm:grid-cols-2">
                            {/* Metodo de pago de el cliente */}
                            <div>
                              <span className="first-letter:capitalize text-sm font-medium text-gray-700">Metodos de pago de cliente</span>
                              <Listbox value={clientPayMethod} onChange={setClientPayMethod}>
                                <div ref={clientTypeRef} className="relative rounded-lg">
                                  <Listbox.Button className="relative h-12 w-full mt-0 rounded-lg border-gray-200 border-[1px] p-3 text-sm text-left focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-sm">
                                    <span className={`block truncate ${clientPayMethod.id === 0 && "text-gray-500"}`}>{clientPayMethod.name}</span>
                                    <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                                      <ChevronUpDownIcon
                                        className="h-5 w-5 text-gray-400"
                                        aria-hidden="true"
                                      />
                                    </span>
                                  </Listbox.Button>
                                  <Transition
                                    as={Fragment}
                                    leave="transition ease-in duration-100"
                                    leaveFrom="opacity-100"
                                    leaveTo="opacity-0"
                                  >
                                    <Listbox.Options className="z-40 absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                      {clientPayMethodData.map((clientPayMethod: { name: string, id: number }, clientIdx: number) => (
                                        <Listbox.Option
                                          key={clientIdx}
                                          onClick={((event) => { updateClientType() })}
                                          className={({ active }) =>
                                            `relative cursor-default select-none py-2 pl-10 pr-4 ${active ? 'bg-blue-600 text-white' : 'text-gray-900'
                                            }`
                                          }
                                          value={clientPayMethod}
                                        >
                                          {({ selected }) => (
                                            <>
                                              <span
                                                className={`block truncate ${clientPayMethod.id === 0 && 'text-gray-500'} ${selected ? 'font-medium' : 'font-normal'
                                                  }`}
                                              >
                                                {clientPayMethod.name}
                                              </span>
                                              {selected ? (
                                                <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-indigo-600">
                                                  <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                                </span>
                                              ) : null}
                                            </>
                                          )}
                                        </Listbox.Option>
                                      ))}
                                    </Listbox.Options>
                                  </Transition>
                                </div>
                              </Listbox>
                            </div>
                            {/* Tarjeta profesional */}
                            {parseInt(state.clientType) === 1 &&
                              <div>
                                <label className="text-sm font-medium text-gray-700" htmlFor="professionalCard">Tarjeta profesional</label>
                                <input ref={professionalCardRef} className="h-12 w-full rounded-lg border-gray-200 border-[1px] p-3 text-sm" type="text" id="professionalCard" value={state.professionalCard} onChange={(event) => { updateProfessionalCard(event) }} />
                              </div>
                            }
                          </div>
                        </>
                      }
                      <div className="mt-4 flex justify-center items-center gap-5">
                        <button type="submit" onClick={(event) => { sendUpdateProfile(event) }}
                          className="inline-block rounded bg-green-500 px-8 py-3 text-sm font-medium text-white transition hover:bg-transparent border-[1px] hover:border-green-500  hover:text-green-500 focus:outline-none focus:ring active:bg-green-400 active:text-white">
                          Actualizar
                        </button>
                        <button type="button" onClick={() => {
                          setState({
                            updateStage: false,
                            idToUpdate: null,
                          });
                          resetProfileState()
                        }}
                          className="inline-block rounded bg-red-500 px-8 py-3 text-sm font-medium text-white transition hover:bg-transparent border-[1px] hover:border-red-500  hover:text-red-500 focus:outline-none focus:ring active:bg-red-400 active:text-white">
                          Cerrar
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            }
            {state.waitingForResponse === true && state.successAlert === false &&
              <Loader />
            }
          </motion.section>
        </>
      }
      {state.deleteStage === true && state.idToDelete !== null &&
        <>
          <Modal />
          <motion.section initial={{ y: 50, opacity: 0 }} animate={{ y: 0, opacity: 1 }} transition={{ duration: 0.5 }} className="fixed top-0 left-0 w-full h-full flex justify-center items-center z-40">
            {state.waitingForResponse === false && state.successAlert === false &&
              <div className="rounded-lg bg-white p-8 shadow-2xl z-40">
                <p className="text-lg font-bold">¿Estas seguro de que quieres borrar este perfil?</p>
                <div className="mt-2">
                  <p className="text-sm lg:text-base text-gray-600">
                    Estas apunto de borrar el siguiente perfil:
                  </p>
                  <ul className="pl-2 mt-2 text-sm lg:text-base">
                    <li className="flex justify-start items-center gap-x-2 text-slate-800"><span className="font-bold first-letter:uppercase">Nombre de usuario:</span><span>{state.nameToDelete}</span></li>
                    <li className="flex justify-start items-center gap-x-2 text-slate-800"><span className="font-bold first-letter:uppercase">Correo electronico:</span><span>{state.emailToDelete}</span></li>
                    <li className="flex justify-start items-center gap-x-2 text-slate-800"><span className="font-bold first-letter:uppercase">Numero de telefono:</span><span>{state.phoneToDelete}</span></li>
                    <li className="flex justify-start items-center gap-x-2 text-slate-800"><span className="font-bold first-letter:uppercase">Permisos:</span><span>{state.permissionsToDelete}</span></li>
                  </ul>
                  <p className="mt-2 font-medium text-sm lg:text-base text-gray-600">
                    ¿Seguro de que quieres borrarlo?
                  </p>
                </div>
                <div className="mt-4 flex gap-2">
                  <button onClick={() => { sendDelete() }} className="inline-block rounded bg-red-500 px-4 py-2 text-sm font-medium text-white border-[1px] border-red-500 hover:text-red-500 hover:bg-transparent">
                    Si, estoy seguro
                  </button>
                  <button onClick={() => {
                    setState({
                      deleteStage: false,
                      idToDelete: null,
                      nameToDelete: null,
                      emailToDelete: null,
                      phoneToDelete: null,
                      permissionsToDelete: null,
                      error: false,
                      errorMessage: '',
                      errorTitle: ''
                    })
                  }} type="button" className="rounded bg-gray-100 px-4 py-2 text-sm font-medium text-gray-700">
                    No, volver
                  </button>
                </div>
              </div>
            }
            {state.waitingForResponse === true && state.successAlert === false &&
              <Loader />
            }
          </motion.section>
        </>
      }
      {state.error &&
        <ErrorAlert ErrorElement={state.ErrorElement}
          animate={{ x: 0, opacity: 1, zIndex: 50, left: "1rem", bottom: "1rem", position: "fixed" }}
          transition={{ duration: 0.3 }}
          initial={{ x: -50, opacity: 0.1, zIndex: 50, left: "1rem", bottom: "1rem", position: "fixed" }}
          message={state.errorMessage}
          title={state.errorTitle} />
      }
      {state.successAlert === true && state.waitingForResponse === false &&
        <motion.div initial={{ x: '-150%', opacity: 0.5 }} animate={{ x: '0%', opacity: 1 }} role="alert" className="rounded-xl border fixed left-3 sm:left-10 bottom-10 z-50 bg-white border-gray-100 p-4 shadow-xl">
          <div className="flex items-start  gap-4">
            <span className="text-green-600">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                className="h-6 w-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                />
              </svg>
            </span>

            <div className="flex-1">
              <strong className="block font-medium text-gray-900">Cambios completados</strong>

              <p className="mt-1 text-sm text-gray-700">
                Los cambios han sido guardados exitosamente!.
              </p>
            </div>
            <button onClick={() => { setState({ successAlert: false }) }} className="text-gray-500 transition hover:text-gray-600">
              <span className="sr-only">Dismiss popup</span>

              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                className="h-6 w-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
          </div>
        </motion.div>
      }
      <div className="overflow-x-auto mt-10">
        {data && loading === false && loaded === true
          &&
          <table className="min-w-full divide-y-2 divide-gray-200 text-sm">
            <thead>
              <tr>
                <th className="whitespace-nowrap px-4 py-2 text-left font-medium text-gray-900">
                  Id
                </th>
                <th className="whitespace-nowrap px-4 py-2 text-left font-medium text-gray-900">
                  Nombre
                </th>
                <th className="whitespace-nowrap px-4 py-2 text-left font-medium text-gray-900">
                  Apellido
                </th>
                <th className="whitespace-nowrap px-4 py-2 text-left font-medium text-gray-900">
                  Correo electronico
                </th>
                <th className="whitespace-nowrap px-4 py-2 text-left font-medium text-gray-900">
                  Rol
                </th>
                <th className="whitespace-nowrap px-4 py-2 text-left font-medium text-gray-900">
                  Numero de telefono
                </th>
                <th className="px-4 py-2 lg:sticky lg:right-0 lg:bg-gray-100" />
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-200">
              {data.map((el: any) => {
                if (el.id !== user.id) {
                  if (el.user_level < parseInt(user.permissions) || parseInt(user.permissions) === 5) {
                    return (
                      <tr key={el.id} className="relative lg:pr-32">
                        <td className="whitespace-nowrap px-4 py-2 text-gray-700">{el.id}</td>
                        <td className="whitespace-nowrap px-4 py-2 font-medium text-gray-700">{el.name}</td>
                        <td className="whitespace-nowrap px-4 py-2 font-medium text-gray-700">{el.last_name}</td>
                        <td className="whitespace-nowrap px-4 py-2 text-gray-700">{el.email}</td>
                        <td className="whitespace-nowrap px-4 py-2 text-gray-700">{el.user_type}</td>
                        <td className={`whitespace-nowrap px-4 py-2 text-gray-700`}>{el.phone}</td>
                        <td className="whitespace-nowrap px-4 py-2 lg:sticky lg:right-0 lg:bg-gray-100 h-full">
                          <button onClick={() => {
                            loadToUpdate(el)
                          }} className="inline-block rounded bg-indigo-500 px-4 py-2 text-xs font-medium border-[1px] border-indigo-500 text-white hover:text-indigo-500 hover:bg-transparent">
                            Actualizar
                          </button>
                          <button onClick={() => {
                            setState({
                              idToDelete: el.id,
                              nameToDelete: el.name,
                              emailToDelete: el.email,
                              phoneToDelete: el.phone,
                              permissionsToDelete: el.permissions,
                              deleteStage: true,
                              successAlert: false,
                            })
                          }} className="ml-2 inline-block rounded bg-red-500 px-4 py-2 text-xs font-medium text-white border-[1px] border-red-500 hover:text-red-500 hover:bg-transparent">
                            Eliminar
                          </button>
                        </td>
                      </tr>
                    )
                  }
                }
              })
              }
            </tbody>
          </table>
        }
        {(data === null || data === undefined) && loading === false && loaded === true
          &&
          <div className="w-full flex justify-center items-center">
            <span className="text-2xl">Aun no hay datos :(</span>
          </div>}
      </div>
    </>
  )
}
import { Container } from "../Container"
interface DashboardTypes{
  children:JSX.Element | JSX.Element[];
}

export function Dashboard({children}:DashboardTypes) {
  return (
    <Container className="flex items-center justify-center">
      {children}
    </Container>
  )
}

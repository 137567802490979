import { useState } from "react";

const useStateArrActive = (init_state) =>{
  const [_stateArr,_setStateArr] = useState(init_state);

  const setStateArr= (id,value) =>{
    let stateCopy = _stateArr
    for (let i = 0; i < stateCopy.length; i++) {
      const element = stateCopy[i];
      if(element.id === id){
        element.active = value; 
      }else{
        element.active = false; 
      }
    }
    _setStateArr([...stateCopy])
  }
  return [_stateArr,setStateArr];
}

export {useStateArrActive};
import { motion } from "framer-motion"
import { useStateMany } from "../customHooks/useStateMany"
import { Modal } from "../modal"
import { useRef, Fragment, useEffect, useState } from 'react'
import { Listbox, Transition } from '@headlessui/react'
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid'
import { API } from "../API"
import { ErrorAlert } from "../ErrorAlert"
import { Loader } from "../Loader"
import { UpdateOrder } from "../UpdateOrder"
import { TestBox } from "../TestBox"
import { Accesories } from "../Accessories"
import './styles.css'
import { OrderFilter } from '../OrderFilter'
import { removeAccentsFromObject } from "../functions/removeAccents";
import { DownloadPDF } from "../DownloadPDF"
import { PopUp } from "../PopUp"
import { OrderStateHistory } from "../OrderStateHistory"
import { OrderDataHistory } from "../OrderDataHistory"
import { userType } from "../typos"
import { OrderDataLoader } from "../OrderDataLoader"
import { SearchClient } from "../SearchClient"
interface MyOrdersTypes {
  user: userType;
}

function MyOrders({ user }: MyOrdersTypes) {
  const naturalClient = parseInt(user.permissions) === 1 && (parseInt(user?.customerType) === 1 || parseInt(user?.customerType) === 3) ? true : false;
  const [verPedidoState, setVerPedidoState] = useStateMany({
    showOrderStateHistory: false,
    showOrderDataHistory: false,
    updatedOrderDate: '',
    createdOrderDate: '',
    updateOrderStage: false,
    cancelOrderStage: false,
    verPedido: false,
    orderId: 0,
    quienRealizaElPedido: '',
    nombreDelPaciente: '',
    numeroDeDocumentoDelPaciente: '',
    nombreDelDoctor: '',
    tarjetaProfesional: '',
    city: 0,
    address: '',
    left_typeLens: 0,
    right_typeLens: 0,
    left_referenceLens: 0,
    right_referenceLens: 0,
    // ojo izquierdo
    idCaja: 0,
    idAccesorio: 0,
    left_inputs: [],
    left_cantidad: 1,
    left_adicion: '',
    left_ZO: '',
    left_grados: '',
    left_periferia1: '',
    left_periferia2: '',
    left_cInvertida: '',
    left_cAlineamiento: '',
    left_cApertura: '',
    left_ZOcBase: '',
    left_ZOcInvertida: '',
    left_ZOcAlineamiento: '',
    left_ZOcApertura: '',
    left_dZO: '',
    left_dSegundaCurva: '',
    left_c_base: '',
    left_poder: '',
    left_cilindro: '',
    left_diametro: '',
    left_design: 0,
    left_cpp: '',
    left_observaciones: '',
    // ojo derecho
    right_inputs: [],
    right_c_base: '',
    right_poder: '',
    right_cilindro: '',
    right_diametro: '',
    right_design: 0,
    right_cpp: '',
    right_observaciones: '',
    order_state: null,
    right_dZO: '',
    right_dSegundaCurva: '',
    right_cantidad: 1,
    right_adicion: '',
    right_ZO: '',
    right_grados: '',
    right_periferia1: '',
    right_periferia2: '',
    right_cInvertida: '',
    right_cAlineamiento: '',
    right_cApertura: '',
    right_ZOcBase: '',
    right_ZOcInvertida: '',
    right_ZOcAlineamiento: '',
    right_ZOcApertura: '',
  })
  const eyesDiv = useRef<HTMLDivElement | null>(null);

  function mostrarPedido(pedido: any) {
    // encuentra el tipo de lente izquierdo
    let findLeftTypeLens = lensTypes.find((el: any) => parseInt(el.id_typeLens) === pedido.left_typeLens)
    // encuentra el tipo de lente izquierdo
    let findRightTypeLens = lensTypes.find((el: any) => parseInt(el.id_typeLens) === pedido.right_typeLens)
    // encuentra la referencia del lente izquierdo
    let findLeftLensReference = lensReferences.find((el: any) => parseInt(el.id_lens) === pedido.left_referenceLens)
    // encuentra la referencia del lente derecho
    let findRightLensReference = lensReferences.find((el: any) => parseInt(el.id_lens) === pedido.right_referenceLens)
    // encuentra el color del lente izquierdo
    let findLeftLensColor = lensColors.find((el: any) => parseInt(el.id_colorLens) === pedido.left_color)
    // encuentra el color del lente derecho
    let findRightLensColor = lensColors.find((el: any) => parseInt(el.id_colorLens) === pedido.right_color)
    // encuentra la ciudad correspondiente
    let findCity = citysData.find((el: any) => parseInt(el.id) === pedido.city)
    // encuentra el design izquierdo
    let findLeftDesign = lensDesign.find((el: any) => parseInt(el.id_design) === pedido.left_design)
    // encuentra el design derecho
    let findRightDesign = lensDesign.find((el: any) => parseInt(el.id_design) === pedido.right_design)
    if (pedido.type_order === 1) {
      let right_inputs: any = []
      let left_inputs: any = []
      if (findRightLensReference) {
        right_inputs = findRightLensReference.id_input ? findRightLensReference.id_input.split(",") : [""];
      }
      if (findLeftLensReference) {
        left_inputs = findLeftLensReference.id_input ? findLeftLensReference.id_input.split(",") : [""];
      }
      setVerPedidoState({
        updatedOrderDate: pedido.updatedOrderDate,
        createdOrderDate: pedido.createdOrderDate,
        orderId: pedido.orderId,
        verPedido: true,
        quienRealizaElPedido: pedido.quienRealizaElPedido,
        nombreDelPaciente: pedido.nombreDelPaciente,
        numeroDeDocumentoDelPaciente: pedido.numeroDeDocumentoDelPaciente,
        nombreDelDoctor: pedido.nombreDelDoctor,
        tarjetaProfesional: pedido.tarjetaProfesional,
        city: findCity?.name ? findCity.name : 'N/A',
        address: pedido.address ? pedido.address : 'N/A',
        left_typeLens: findLeftTypeLens,
        right_typeLens: findRightTypeLens,
        left_referenceLens: findLeftLensReference,
        right_referenceLens: findRightLensReference,
        // ojo izquierdo
        left_inputs: left_inputs,
        left_c_base: pedido.left_c_base,
        left_poder: pedido.left_poder,
        left_cilindro: pedido.left_cilindro,
        left_diametro: pedido.left_diametro,
        left_cpp: pedido.left_cpp,
        left_observaciones: pedido.left_observaciones,
        left_color: findLeftLensColor,
        // ojo derecho
        right_inputs: right_inputs,
        right_c_base: pedido.right_c_base,
        right_poder: pedido.right_poder,
        right_cilindro: pedido.right_cilindro,
        right_diametro: pedido.right_diametro,
        right_design: findRightDesign,
        right_cpp: pedido.right_cpp,
        right_observaciones: pedido.right_observaciones,
        right_color: findRightLensColor,
        order_state: pedido.order_state,
        //
        type_order: pedido.type_order,
        idCaja: pedido.idCaja,
        idAccesorio: pedido.idAccesorio,
        // ojo izquierdo
        left_design: findLeftDesign,
        left_cantidad: pedido.left_cantidad,
        left_adicion: pedido.left_adicion,
        left_ZO: pedido.left_ZO,
        left_grados: pedido.left_grados,
        left_periferia1: pedido.left_periferia1,
        left_periferia2: pedido.left_periferia2,
        left_cInvertida: pedido.left_cInvertida,
        left_cAlineamiento: pedido.left_cAlineamiento,
        left_cApertura: pedido.left_cApertura,
        left_ZOcBase: pedido.left_ZOcBase,
        left_ZOcInvertida: pedido.left_ZOcInvertida,
        left_ZOcAlineamiento: pedido.left_ZOcAlineamiento,
        left_ZOcApertura: pedido.left_ZOcApertura,
        left_dZO: pedido.left_dZO,
        left_dSegundaCurva: pedido.left_dSegundaCurva,
        // ojo derecho
        right_cantidad: pedido.right_cantidad,
        right_adicion: pedido.right_adicion,
        right_ZO: pedido.right_ZO,
        right_grados: pedido.right_grados,
        right_periferia1: pedido.right_periferia1,
        right_periferia2: pedido.right_periferia2,
        right_cInvertida: pedido.right_cInvertida,
        right_cAlineamiento: pedido.right_cAlineamiento,
        right_cApertura: pedido.right_cApertura,
        right_ZOcBase: pedido.right_ZOcBase,
        right_ZOcInvertida: pedido.right_ZOcInvertida,
        right_ZOcAlineamiento: pedido.right_ZOcAlineamiento,
        right_ZOcApertura: pedido.right_ZOcApertura,
        right_dZO: pedido.right_dZO,
        right_dSegundaCurva: pedido.right_dSegundaCurva,
      })
    } else if (parseInt(pedido.type_order) === 2) {

      // encuentra la caja de prueba
      let findCaja = testBoxes.find((el: any) => parseInt(el.id_box) === pedido.idCaja);
      setVerPedidoState({
        updatedOrderDate: pedido.updatedOrderDate,
        createdOrderDate: pedido.createdOrderDate,
        orderId: pedido.orderId,
        verPedido: true,
        quienRealizaElPedido: pedido.quienRealizaElPedido,
        nombreDelPaciente: pedido.nombreDelPaciente,
        numeroDeDocumentoDelPaciente: pedido.numeroDeDocumentoDelPaciente,
        nombreDelDoctor: pedido.nombreDelDoctor,
        tarjetaProfesional: pedido.tarjetaProfesional,
        city: findCity?.name ? findCity.name : 'N/A',
        address: pedido.address ? pedido.address : 'N/A',
        order_state: pedido.order_state,
        type_order: pedido.type_order,
        idCaja: findCaja,
        // idAccesorio: pedido.idAccesorio,
        left_cantidad: isNaN(pedido.left_cantidad) ? 1 : pedido.left_cantidad,
        right_cantidad: isNaN(pedido.right_cantidad) ? 1 : pedido.right_cantidad,
        right_observaciones: pedido.right_observaciones,
        left_observaciones: pedido.left_observaciones,
      })
    } else if (parseInt(pedido.type_order) === 3) {
      let findAccesorio = accessories.find((el: any) => parseInt(el.id_acc) === pedido.idAccesorio);
      setVerPedidoState({
        updatedOrderDate: pedido.updatedOrderDate,
        createdOrderDate: pedido.createdOrderDate,
        orderId: pedido.orderId,
        verPedido: true,
        quienRealizaElPedido: pedido.quienRealizaElPedido,
        nombreDelPaciente: pedido.nombreDelPaciente,
        numeroDeDocumentoDelPaciente: pedido.numeroDeDocumentoDelPaciente,
        nombreDelDoctor: pedido.nombreDelDoctor,
        tarjetaProfesional: pedido.tarjetaProfesional,
        city: findCity?.name ? findCity.name : 'N/A',
        address: pedido.address ? pedido.address : 'N/A',
        order_state: pedido.order_state,
        type_order: pedido.type_order,
        // idCaja: findCaja,
        idAccesorio: findAccesorio,
        left_cantidad: pedido.left_cantidad,
        right_cantidad: pedido.right_cantidad,
      })
    }
  }
  // estado general del componente
  const [state, setState] = useStateMany({
    fieldValidation: false,
    openAccessoriesCreation: false,
    showSearchClient: false,
    openTestBoxCreation: false,
    loading: false,
    dataLoaded: false,
    data: [],
    filteredData: [],
    createOrderStage: false,
    orderStep: 0,
    showLeftEyeForm: false,
    showRightEyeForm: false,
    reload: false,
    waitingForResponse: false,
    errorMessage: false,
    errorMessageTitle: '',
    errorMessageText: '',
    successMessage: false,
    successMessageTitle: '',
    successMessageText: '',
  })
  useEffect(() => {

    let dataToSend = {
      API_KEY: process.env.REACT_APP_API_KEY,
      idSession: user.id,
    }
    setState({ loading: true })
    API.post('/?myorders', {
      ...dataToSend
    }).then((response) => {
      console.log(response,'myOrders')
      if (response.status === 200 && response.data) {
        setState({ data: response.data.myOrders, filteredData: response.data.myOrders, dataLoaded: true, loading: false })
      }
    })
  }, [state.reload])

  // ! SECCION DE FORMULARIO DE CREAR PEDIDO
  // lista de tipos de lentes
  const [lensTypes, setLensTypes] = useState([
    { id_typeLens: '0', name_typeLens: 'Indique el tipo de lente' },
  ])
  const [lensColors, setLensColors] = useState([
    { id_colorLens: '0', name_colorLens: 'Indique el color del lente', id_lens: '0' },
  ])
  const [citysData, setCitysData]: any = useState([
    { name: "Seleccion una ciudad", id: 0 },
  ])
  const [city, setCity] = useState(citysData[0])
  const [leftColorLens, setLeftColorLens] = useState(lensColors[0]);
  const [rightColorLens, setRightColorLens] = useState(lensColors[0]);

  // lista de referencias del ojo izquierdo
  const [lensReferences, setLensReferences] = useState([
    { id_lens: '0', name_lens: 'indique la referencia del lente', id_typeLens: '0', id_input: "" },
  ]);
  // lista de designs
  const [lensDesign, setLensDesign] = useState([
    { id_design: '0', design_name: 'Seleccione un diseño' },
  ]);
  const [testBoxes, setTestBoxes] = useState([
    { id_box: '0', box: 'Seleccione un diseño', qty: 0, id_input: 0, },
  ]);
  const [accessories, setAccessories] = useState([
    { id_acc: 0, acc: 'Seleccione un accesorio', id_input: 0 },
  ]);

  const [colorsLength, setColorsLength] = useStateMany({
    left: 0,
    right: 0,
  });
  function calcColorLength(obj: any, from: string) {
    if (from === 'left') {
      let left = 0;
      lensColors.forEach((el) => {
        if (el.id_lens === obj.id_lens) {
          left = left + 1;
        }
      })
      setColorsLength({ left: left })
      setLeftColorLens(lensColors[0]);
    }
    if (from === 'right') {
      let right = 0;
      lensColors.forEach((el) => {
        if (el.id_lens === obj.id_lens) {
          right = right + 1;
        }
      })
      setColorsLength({ right: right })
      setRightColorLens(lensColors[0]);
    }
  };
  // selector de caja de prueba
  const [testBoxSelected, setTestBoxSelected] = useState(testBoxes[0]);
  // selector de accesorios
  const [accessoriesSelected, setAccessoriesSelected] = useState(accessories[0]);
  // selector de design de lente izquierdo
  const [leftDesignSelected, setLeftDesignSelected] = useState(lensDesign[0]);
  // selector de design de lente derecho
  const [rightDesignSelected, setRightDesignSelected] = useState(lensDesign[0]);
  // selector de referencia de lente izquierdo
  const [leftReferenceSelected, setLeftReferenceSelected] = useState(lensReferences[0]);
  // selector de referencia de lente derecho
  const [rightReferenceSelected, setRightReferenceSelected] = useState(lensReferences[0]);
  // selector de tipo de lente derecho
  const [rightLensTypeSelected, setRightLensTypesSelected] = useState(lensTypes[0]);
  // selector de tipo de lente izquierdo
  const [leftLensTypeSelected, setLeftLensTypesSelected] = useState(lensTypes[0]);
  const leftInputs = leftReferenceSelected.id_input ? leftReferenceSelected.id_input.split(",") : [''];
  const rightInputs = rightReferenceSelected.id_input ? rightReferenceSelected.id_input.split(",") : [''];
  // estado de los ojos
  const [eyesState, setEyesState] = useStateMany({
    quienRealizaElPedido: '',
    nombreDelPaciente: '',
    numeroDeDocumentoDelPaciente: '',
    nombreDelDoctor: naturalClient ? `${user.name} ${user.lastName}` : '',
    tarjetaProfesional: naturalClient ? user.professionalCard : '',
    address: naturalClient ? user.address : '',
    id_client: naturalClient ? parseInt(user.id) : 0,
    // ojo izquierdo
    // ! agregar estas dos nuevas propiedas al reset
    idCaja: 0,
    idAccesorio: 0,
    left_cantidad: 1,
    left_adicion: '',
    left_ZO: '',
    left_grados: '',
    left_periferia1: '',
    left_periferia2: '',
    left_cInvertida: '',
    left_cAlineamiento: '',
    left_cApertura: '',
    left_ZOcBase: '',
    left_ZOcInvertida: '',
    left_ZOcAlineamiento: '',
    left_ZOcApertura: '',
    left_dZO: '',
    left_dSegundaCurva: '',
    left_c_base: '',
    left_poder: '',
    left_cilindro: '',
    left_diametro: '',
    left_design: '',
    left_cpp: '',
    left_observaciones: '',
    // ojo derecho
    //!nuevos inputs (recordar agregar su correspondiente reset)
    right_dZO: '',
    right_dSegundaCurva: '',
    right_cantidad: 1,
    right_adicion: '',
    right_ZO: '',
    right_grados: '',
    right_periferia1: '',
    right_periferia2: '',
    right_cInvertida: '',
    right_cAlineamiento: '',
    right_cApertura: '',
    right_ZOcBase: '',
    right_ZOcInvertida: '',
    right_ZOcAlineamiento: '',
    right_ZOcApertura: '',
    right_c_base: '',
    right_poder: '',
    right_cilindro: '',
    right_diametro: '',
    right_design: '',
    right_cpp: '',
    right_observaciones: '',
  })
  function loadCityFromProfile() {
    let findCity = citysData.findIndex((el: { id: number, name: string }) => {
      if (el.id !== undefined) {
        return +el.id === +user.city;
      }
    })
    if (findCity !== -1) {
      setCity(citysData[findCity])
    }
  }
  function resetInputs(lado: string) {
    if (lado === 'right') {
      setRightDesignSelected(lensDesign[0])
      setRightColorLens(lensColors[0])
      setEyesState({
        // ojo derecho
        right_dZO: '',
        right_dSegundaCurva: '',
        right_cantidad: 1,
        right_adicion: '',
        right_ZO: '',
        right_grados: '',
        right_periferia1: '',
        right_periferia2: '',
        right_cInvertida: '',
        right_cAlineamiento: '',
        right_cApertura: '',
        right_ZOcBase: '',
        right_ZOcInvertida: '',
        right_ZOcAlineamiento: '',
        right_ZOcApertura: '',
        right_c_base: '',
        right_poder: '',
        right_cilindro: '',
        right_diametro: '',
        right_design: '',
        right_cpp: '',
        right_observaciones: '',
      })
    }
    if (lado === 'left') {
      setLeftColorLens(lensColors[0])
      setLeftDesignSelected(lensDesign[0])
      setEyesState({
        // ojo izquierdo
        left_cantidad: 1,
        left_adicion: '',
        left_ZO: '',
        left_grados: '',
        left_periferia1: '',
        left_periferia2: '',
        left_cInvertida: '',
        left_cAlineamiento: '',
        left_cApertura: '',
        left_ZOcBase: '',
        left_ZOcInvertida: '',
        left_ZOcAlineamiento: '',
        left_ZOcApertura: '',
        left_c_base: '',
        left_poder: '',
        left_cilindro: '',
        left_diametro: '',
        left_cpp: '',
        left_observaciones: '',
        left_dZO: '',
        left_dSegundaCurva: '',
      });
    }
  }
  function cancelOrder() {
    setLeftColorLens(lensColors[0])
    setRightColorLens(lensColors[0])
    setCity(citysData[0])
    setLeftReferenceSelected(lensReferences[0])
    setRightReferenceSelected(lensReferences[0])
    setRightLensTypesSelected(lensTypes[0])
    setLeftLensTypesSelected(lensTypes[0])
    setLeftDesignSelected(lensDesign[0])
    setRightDesignSelected(lensDesign[0])
    setEyesState({
      quienRealizaElPedido: '',
      nombreDelPaciente: '',
      numeroDeDocumentoDelPaciente: '',
      nombreDelDoctor: naturalClient ? `${user.name} ${user.lastName}` : '',
      tarjetaProfesional: naturalClient ? user.professionalCard : '',
      address: naturalClient ? user.address : '',
      id_client: naturalClient ? parseInt(user.id) : 0,
      // ojo izquierdo
      idCaja: 0,
      idAccesorio: 0,
      left_cantidad: 1,
      left_adicion: '',
      left_ZO: '',
      left_grados: '',
      left_periferia1: '',
      left_periferia2: '',
      left_cInvertida: '',
      left_cAlineamiento: '',
      left_cApertura: '',
      left_ZOcBase: '',
      left_ZOcInvertida: '',
      left_ZOcAlineamiento: '',
      left_ZOcApertura: '',
      left_dZO: '',
      left_dSegundaCurva: '',
      left_c_base: '',
      left_poder: '',
      left_cilindro: '',
      left_diametro: '',
      left_cpp: '',
      left_observaciones: '',
      // ojo derecho
      right_dZO: '',
      right_dSegundaCurva: '',
      right_cantidad: 1,
      right_adicion: '',
      right_ZO: '',
      right_grados: '',
      right_periferia1: '',
      right_periferia2: '',
      right_cInvertida: '',
      right_cAlineamiento: '',
      right_cApertura: '',
      right_ZOcBase: '',
      right_ZOcInvertida: '',
      right_ZOcAlineamiento: '',
      right_ZOcApertura: '',
      right_c_base: '',
      right_poder: '',
      right_cilindro: '',
      right_diametro: '',
      right_design: '',
      right_cpp: '',
      right_observaciones: '',
    });
    naturalClient && loadCityFromProfile()
    setState({
      createOrderStage: false,
      showLeftEyeForm: false,
      showRightEyeForm: false,
      orderStep: 0,
      errorMessage: false,
      errorMessageTitle: '',
      errorMessageText: ''
    });
  }
  function goToOrderStep2(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    event.preventDefault();
    if (state.orderStep === 1) {
      if (eyesState.quienRealizaElPedido !== '' && eyesState.nombreDelPaciente !== '' && eyesState.nombreDelDoctor !== '' && eyesState.tarjetaProfesional !== '') {
        setState({
          orderStep: 2,
          errorMessage: false,
          errorMessageTitle: '',
          errorMessageText: '',
          showLeftEyeForm: true,
          showRightEyeForm: false,
        });
      } else {
        setState({ errorMessage: true, errorMessageTitle: 'Por favor llene todo los campos para continuar', errorMessageText: 'No se puede continuar con el proceso de creacion de un pedido sin los datos necesarios.' })
        setTimeout(() => {
          setState({ errorMessage: false, errorMessageTitle: '', errorMessageText: '' })
        }, 7000);
      }
    }
  }
  function validateLeftEye(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    event.preventDefault();
    // valida los datos typeLens y lens no esten vacios
    let cantidadInputs = leftInputs.length;
    let validations = 0;
    leftInputs.includes('1') && parseInt(leftColorLens.id_colorLens) !== 0 && validations++;
    leftInputs.includes('2') && parseInt(leftDesignSelected.id_design) !== 0 && validations++;
    leftInputs.includes('3') && eyesState.left_cantidad !== "" && validations++;
    leftInputs.includes('4') && eyesState.left_c_base !== "" && validations++;
    leftInputs.includes('5') && eyesState.left_poder !== "" && validations++;
    leftInputs.includes('6') && eyesState.left_diametro !== "" && validations++;
    leftInputs.includes('7') && eyesState.left_ZO !== "" && validations++;
    leftInputs.includes('8') && eyesState.left_adicion !== "" && validations++;
    leftInputs.includes('9') && eyesState.left_cilindro !== "" && validations++;
    leftInputs.includes('10') && eyesState.left_grados !== "" && validations++;
    leftInputs.includes('11') && eyesState.left_periferia1 !== "" && validations++;
    leftInputs.includes('12') && eyesState.left_periferia2 !== "" && validations++;
    leftInputs.includes('13') && eyesState.left_cpp !== "" && validations++;
    leftInputs.includes('14') && eyesState.left_observaciones !== "" && validations++;
    leftInputs.includes('15') && eyesState.left_cInvertida !== "" && validations++;
    leftInputs.includes('16') && eyesState.left_cAlineamiento !== "" && validations++;
    leftInputs.includes('17') && eyesState.left_cApertura !== "" && validations++;
    leftInputs.includes('18') && eyesState.left_ZOcBase !== "" && validations++;
    leftInputs.includes('19') && eyesState.left_ZOcInvertida !== "" && validations++;
    leftInputs.includes('20') && eyesState.left_ZOcAlineamiento !== "" && validations++;
    leftInputs.includes('21') && eyesState.left_ZOcApertura !== "" && validations++;
    if (state.fieldValidation) {
      if (parseInt(leftLensTypeSelected.id_typeLens) !== 0 && parseInt(leftReferenceSelected.id_lens) !== 0 && validations === cantidadInputs) {
        eyesDiv.current?.scroll({ top: 0 });
        setState({ showLeftEyeForm: false, showRightEyeForm: true, errorMessage: false, errorMessageTitle: '', errorMessageText: '', })
      } else {
        setState({ errorMessage: true, errorMessageTitle: 'Por favor llene todo los campos para continuar', errorMessageText: 'No se puede continuar con el proceso de creacion de un pedido sin los datos necesarios.' })
      }
    } else {
      eyesDiv.current?.scroll({ top: 0 });
      setState({ showLeftEyeForm: false, showRightEyeForm: true, errorMessage: false, errorMessageTitle: '', errorMessageText: '', })
    }

    // if (eyesState.left_c_base !== '' && eyesState.left_poder !== '' && eyesState.left_cilindro !== '' && eyesState.left_diametro !== '' && eyesState.left_design !== '' && eyesState.left_cpp !== '' && eyesState.left_observaciones !== '' && leftLensTypeSelected.name_typeLens !== 'indique el tipo de lente' && leftReferenceSelected.name_lens !== 'indique la referencia de el lente izquierdo') {
    //   eyesDiv.current?.scroll({ top: 0 });
    //   setState({ showLeftEyeForm: false, showRightEyeForm: true, errorMessage: false, errorMessageTitle: '', errorMessageText: '', })
    // } else {
    //   setState({ errorMessage: true, errorMessageTitle: 'Por favor llene todo los campos para continuar', errorMessageText: 'No se puede continuar con el proceso de creacion de un pedido sin los datos necesarios.' })
    // }
  }
  function goToOrderStep3(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    event.preventDefault();
    let cantidadInputs = rightInputs.length;
    let validations = 0;
    rightInputs.includes('1') && parseInt(rightColorLens.id_colorLens) !== 0 && validations++;
    rightInputs.includes('2') && parseInt(rightDesignSelected.id_design) !== 0 && validations++;
    rightInputs.includes('3') && eyesState.right_cantidad !== "" && validations++;
    rightInputs.includes('4') && eyesState.right_c_base !== "" && validations++;
    rightInputs.includes('5') && eyesState.right_poder !== "" && validations++;
    rightInputs.includes('6') && eyesState.right_diametro !== "" && validations++;
    rightInputs.includes('7') && eyesState.right_ZO !== "" && validations++;
    rightInputs.includes('8') && eyesState.right_adicion !== "" && validations++;
    rightInputs.includes('9') && eyesState.right_cilindro !== "" && validations++;
    rightInputs.includes('10') && eyesState.right_grados !== "" && validations++;
    rightInputs.includes('11') && eyesState.right_periferia1 !== "" && validations++;
    rightInputs.includes('12') && eyesState.right_periferia2 !== "" && validations++;
    rightInputs.includes('13') && eyesState.right_cpp !== "" && validations++;
    rightInputs.includes('14') && eyesState.right_observaciones !== "" && validations++;
    rightInputs.includes('15') && eyesState.right_cInvertida !== "" && validations++;
    rightInputs.includes('16') && eyesState.right_cAlineamiento !== "" && validations++;
    rightInputs.includes('17') && eyesState.right_cApertura !== "" && validations++;
    rightInputs.includes('18') && eyesState.right_ZOcBase !== "" && validations++;
    rightInputs.includes('19') && eyesState.right_ZOcInvertida !== "" && validations++;
    rightInputs.includes('20') && eyesState.right_ZOcAlineamiento !== "" && validations++;
    rightInputs.includes('21') && eyesState.right_ZOcApertura !== "" && validations++;
    if (state.fieldValidation) {
      if (state.orderStep === 2 && validations === cantidadInputs) {
        if (parseInt(rightLensTypeSelected.id_typeLens) !== 0 && parseInt(rightReferenceSelected.id_lens) !== 0) {
          setState({ orderStep: 3, errorMessage: false, errorMessageTitle: '', errorMessageText: '', showLeftEyeForm: false, showRightEyeForm: false, });
        } else {
          setState({ errorMessage: true, errorMessageTitle: 'Por favor llene todo los campos para continuar', errorMessageText: 'No se puede continuar con el proceso de creacion de un pedido sin los datos necesarios.' })
        }
      }
    } else {
      setState({ orderStep: 3, errorMessage: false, errorMessageTitle: '', errorMessageText: '', showLeftEyeForm: false, showRightEyeForm: false, });
    }
    // if (eyesState.left_c_base !== '' && eyesState.left_poder !== '' && eyesState.left_cilindro !== '' && eyesState.left_diametro !== '' && eyesState.left_design !== '' && eyesState.left_cpp !== '' && eyesState.left_observaciones !== '' && eyesState.right_c_base !== '' && eyesState.right_poder !== '' && eyesState.right_cilindro !== '' && eyesState.right_diametro !== '' && eyesState.right_design !== '' && eyesState.right_cpp !== '' && eyesState.right_observaciones !== '' && leftLensTypeSelected.name_typeLens !== 'indique el tipo de lente' && rightLensTypeSelected.name_typeLens !== 'indique el tipo de lente' && leftReferenceSelected.name_lens !== 'indique la referencia de el lente izquierdo' && rightReferenceSelected.name_lens !== 'indique la referencia de el lente derecho') {
    //   setState({ orderStep: 3, errorMessage: false, errorMessageTitle: '', errorMessageText: '', showLeftEyeForm: false, showRightEyeForm: false, });
    // } else {
    //   setState({ errorMessage: true, errorMessageTitle: 'Por favor llene todo los campos para continuar', errorMessageText: 'No se puede continuar con el proceso de creacion de un pedido sin los datos necesarios.' })
    // }
  }
  function submitCreateOrder(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    event.preventDefault();
    let data = {
      API_KEY: process.env.REACT_APP_API_KEY,
      idSession: parseInt(user.id),
      creatorName: eyesState.quienRealizaElPedido,
      patientName: eyesState.nombreDelPaciente,
      patientDoc: eyesState.numeroDeDocumentoDelPaciente,
      optometristName: eyesState.nombreDelDoctor,
      professionalCard: eyesState.tarjetaProfesional,
      city: parseInt(city.id),
      address: eyesState.address,
      id_client: eyesState.id_client,
      // otros 
      idBox: 0,
      idAcc: 0,
      // ojo derecho
      rTypeLens: parseInt(rightLensTypeSelected.id_typeLens),
      rLens: parseInt(rightReferenceSelected.id_lens),
      lQty: parseInt(eyesState.left_cantidad),
      rZo: eyesState.right_ZO,
      rDzo: eyesState.right_dZO,
      rDsecondturn: eyesState.right_dSegundaCurva,
      rAddition: eyesState.right_adicion,
      rDegrees: eyesState.right_grados,
      rPerifery1: eyesState.right_periferia1,
      rPerifery2: eyesState.right_periferia2,
      rCinverted: eyesState.right_cInvertida,
      rCAlignment: eyesState.right_cAlineamiento,
      rCopening: eyesState.right_cApertura,
      rZoCbase: eyesState.right_ZOcBase,
      rZoCinverted: eyesState.right_ZOcInvertida,
      rZoCalignment: eyesState.right_ZOcAlineamiento,
      rZoCopening: eyesState.right_ZOcApertura,

      rCbase: eyesState.right_c_base,
      rPower: eyesState.right_poder,
      rCylinder: eyesState.right_cilindro,
      rDiameter: eyesState.right_diametro,
      rDesign: parseInt(rightDesignSelected.id_design),
      rCpp: eyesState.right_cpp,
      rColor: parseInt(rightColorLens.id_colorLens),
      rObserv: eyesState.right_observaciones,
      // ojo izquierdo
      lTypeLens: parseInt(leftLensTypeSelected.id_typeLens),
      lLens: parseInt(leftReferenceSelected.id_lens),

      rQty: parseInt(eyesState.right_cantidad),
      lZo: eyesState.left_ZO,
      lDzo: eyesState.left_dZO,
      lDsecondturn: eyesState.left_dSegundaCurva,
      lAddition: eyesState.left_adicion,
      lDegrees: eyesState.left_grados,
      lPerifery1: eyesState.left_periferia1,
      lPerifery2: eyesState.left_periferia2,
      lCinverted: eyesState.left_cInvertida,
      lCAlignment: eyesState.left_cAlineamiento,
      lCopening: eyesState.left_cApertura,
      lZoCbase: eyesState.left_ZOcBase,
      lZoCinverted: eyesState.left_ZOcInvertida,
      lZoCalignment: eyesState.left_ZOcAlineamiento,
      lZoCopening: eyesState.left_ZOcApertura,

      lCbase: eyesState.left_c_base,
      lPower: eyesState.left_poder,
      lCylinder: eyesState.left_cilindro,
      lDiameter: eyesState.left_diametro,
      lDesign: parseInt(leftDesignSelected.id_design),
      lCpp: eyesState.left_cpp,
      lColor: parseInt(leftColorLens.id_colorLens),
      lObserv: eyesState.left_observaciones,

      typeOrder: 1,
      statusOrder: 'revision',
    }
    let dataToSend = removeAccentsFromObject(data)
    setState({ waitingForResponse: true, errorMessage: false, errorMessageTitle: '', errorMessageText: '' })
    // se envia la consulta
    API.post('/?insorder', {
      ...dataToSend
    }).then((response) => {
      if (response.data.success === 1 && response.status === 200) {
        cancelOrder()
        setState({
          showLeftEyeForm: false,
          showRightEyeForm: false,
          waitingForResponse: false,
          createOrderStage: false,
          orderStep: 0,
          successMessage: true,
          reload: !state.reload,
          successMessageTitle: 'Su pedido se ha creado con exito!',
          successMessageText: 'Se ha creado su pedido, espere pacientemente a que alguien de nuestro equipo la verifique y sea aceptada.',
        })
        setTimeout(() => {
          setState({
            successMessage: false,
            successMessageTitle: '',
            successMessageText: '',
          })
        }, 7000)
      } else {
        setState({
          waitingForResponse: false,
          errorMessage: true,
          errorMessageTitle: 'Ocurrio un error al intentar guardar la pedido',
          errorMessageText: 'No se pudo guardar el pedido, intente de nuevo mas tarde',
        })
      }
    })
  }
  function cancelarOrden(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    event.preventDefault();
    let dataToSend = {
      API_KEY: process.env.REACT_APP_API_KEY,
      idProfile: parseInt(user.id),
      idOrder: parseInt(verPedidoState.orderId),
    }
    setState({ waitingForResponse: true, errorMessage: false, errorMessageTitle: '', errorMessageText: '' });
    API.post('/?deleteorder', {
      ...dataToSend
    }).then((response) => {
      if (response.status === 200 && response.data.success === 1) {
        setState({
          waitingForResponse: false,
          createOrderStage: false,
          orderStep: 0,
          successMessage: true,
          reload: !state.reload,
          successMessageTitle: 'Se elimino el pedido.',
          successMessageText: 'El pedido se ha eliminado con exito!.',
        })
        resetVerPedidos();
        setTimeout(() => {
          setState({
            successMessage: false,
            successMessageTitle: '',
            successMessageText: '',
          })
        }, 7000)
      }
    })
  }
  function resetVerPedidos() {
    setVerPedidoState({
      orderId: 0,
      verPedido: false,
      cancelOrderStage: false,
      quienRealizaElPedido: '',
      nombreDelPaciente: '',
      numeroDeDocumentoDelPaciente: '',
      nombreDelDoctor: '',
      tarjetaProfesional: '',
      city: 0,
      address: '',
      left_typeLens: 0,
      right_typeLens: 0,
      left_referenceLens: 0,
      right_referenceLens: 0,
      // ojo izquierdo
      left_c_base: '',
      left_poder: '',
      left_cilindro: '',
      left_diametro: '',
      left_design: 0,
      left_cpp: '',
      left_observaciones: '',
      // ojo derecho
      right_c_base: '',
      right_poder: '',
      right_cilindro: '',
      right_diametro: '',
      right_design: 0,
      right_cpp: '',
      right_observaciones: '',
      order_state: null,
      // ojo izquierdo
      idCaja: 0,
      idAccesorio: 0,
      left_inputs: [],
      left_cantidad: 1,
      left_adicion: '',
      left_ZO: '',
      left_grados: '',
      left_periferia1: '',
      left_periferia2: '',
      left_cInvertida: '',
      left_cAlineamiento: '',
      left_cApertura: '',
      left_ZOcBase: '',
      left_ZOcInvertida: '',
      left_ZOcAlineamiento: '',
      left_ZOcApertura: '',
      left_dZO: '',
      left_dSegundaCurva: '',
      // ojo derecho
      right_inputs: [],
      right_dZO: '',
      right_dSegundaCurva: '',
      right_cantidad: 1,
      right_adicion: '',
      right_ZO: '',
      right_grados: '',
      right_periferia1: '',
      right_periferia2: '',
      right_cInvertida: '',
      right_cAlineamiento: '',
      right_cApertura: '',
      right_ZOcBase: '',
      right_ZOcInvertida: '',
      right_ZOcAlineamiento: '',
      right_ZOcApertura: '',
    })
  }
  // busca la informacion de los lentes
  useEffect(() => {
    let dataToSend = {
      API_KEY: process.env.REACT_APP_API_KEY
    }
    API.post('/?lens', {
      ...dataToSend
    }).then((response) => {
      if (response.status === 200 && response.data.lens.length > 0 && response.data.colors.length > 0 && response.data.typeLens.length > 0) {
        let newsLensTypes = [
          { id_typeLens: '0', name_typeLens: 'Indique el tipo de lente' },
          ...response.data.typeLens
        ]
        let newCitysData = [
          { name: "Seleccion una ciudad", id: 0 },
          ...response.data.citys
        ]
        let newLens = [
          { id_lens: '0', name_lens: 'indique la referencia del lente', id_typeLens: '0' },
          ...response.data.lens
        ]
        let newColorLens = [
          { id_colorLens: '0', name_colorLens: 'Indique el color del lente', id_lens: '0' },
          ...response.data.colors
        ]
        let newDesigns = [
          { id_design: '0', design_name: 'Seleccione un diseño' },
          ...response.data.designs
        ]
        let newTestBoxes = [
          { id_box: '0', box: 'Seleccione un diseño', qty: 0, id_input: 0, },
          ...response.data.boxes
        ]
        let newAccessories = [
          { id_acc: 0, acc: 'Seleccione un accesorio', id_input: 0 },
          ...response.data.acc
        ]
        setCitysData(newCitysData)
        setLensDesign(newDesigns);
        setLensColors(newColorLens);
        setLensTypes(newsLensTypes);
        setLensReferences(newLens);
        setTestBoxes(newTestBoxes);
        setAccessories(newAccessories);
        // setInputs(newLens);
      } else {
        setState({ errorMessage: true, errorMessageTitle: 'Ocurrio un error al intentar consultar informacion', errorMessageText: 'No se pudo conseguir la informacion necesaria para continuar.' })
      }
    })
  }, [state.reload])

  return (
    <div className="max-w-full">
      <div className="flex items-center justify-center flex-col h-full w-full">
        <h4 className="text-4xl font-bold mb-2 text-center">
          Mis Pedidos
        </h4>
        <p className="mb-10 text-gray-600 text-center max-w-[500px]">Administra tus pedidos, crea tus propios pedidos, actualizalos, eliminalos y verifica su estado actual.</p>
        <div className="flex justify-center items-center gap-4 flex-wrap">
          <button onClick={() => { setState({ createOrderStage: true, orderStep: 1 }); naturalClient && loadCityFromProfile() }}
            className="inline-block rounded bg-green-500 px-8 py-3 text-sm font-medium text-white transition hover:bg-transparent border-[1px] hover:border-green-500  hover:text-green-500 focus:outline-none focus:ring active:bg-green-400 active:text-white">
            Crear Pedido
          </button>
          <button onClick={() => { setState({ openTestBoxCreation: true }); naturalClient && loadCityFromProfile() }}
            className="inline-block rounded bg-indigo-500 px-8 py-3 text-sm font-medium text-white transition hover:bg-transparent border-[1px] hover:border-indigo-500  hover:text-indigo-500 focus:outline-none focus:ring active:bg-indigo-400 active:text-white">
            Cajas de prueba
          </button>
          <button onClick={() => { setState({ openAccessoriesCreation: true }); naturalClient && loadCityFromProfile() }}
            className="inline-block rounded bg-amber-500 px-8 py-3 text-sm font-medium text-white transition hover:bg-transparent border-[1px] hover:border-amber-500  hover:text-amber-500 focus:outline-none focus:ring active:bg-amber-400 active:text-white">
            Accesorios
          </button>
        </div>
      </div>
      {verPedidoState.updateOrderStage === true || verPedidoState.verPedido === true || state.openTestBoxCreation === true || state.openAccessoriesCreation === true ?
        <Modal />
        : <></>
      }
      {verPedidoState.updateOrderStage === true &&
        <UpdateOrder generalState={state} setGeneralState={setState} user={user} setVerPedido={setVerPedidoState} updateState={verPedidoState} typeLens={lensTypes} lens={lensReferences} colorLens={lensColors} lensDesignList={lensDesign} accessoriesList={accessories} testBoxesList={testBoxes} citysData={citysData} />
      }
      {state.openTestBoxCreation === true &&
        <TestBox user={user} state={state} setState={setState} testBoxSelected={testBoxSelected} setTestBoxSelected={setTestBoxSelected} testBoxes={testBoxes} citysData={citysData} city={city} setCity={setCity} />
      }
      {state.openAccessoriesCreation === true &&
        <Accesories user={user} state={state} setState={setState} accessoriesSelected={accessoriesSelected} setAccessoriesSelected={setAccessoriesSelected} accessories={accessories} citysData={citysData} city={city} setCity={setCity} />
      }
      {/* //? modal para ver el pedido seleccionado */}
      {verPedidoState.verPedido === true &&
        <>
          <motion.form initial={{ y: 50, opacity: 0 }} animate={{ y: 0, opacity: 1 }} transition={{ duration: 0.5 }} className="fixed top-0 left-0 w-full h-full flex justify-center items-center z-40">
            {verPedidoState.cancelOrderStage === true &&
              <div className="z-50">
                <Modal />
                <motion.div initial={{ y: 50, opacity: 0 }} animate={{ y: 0, opacity: 1 }} transition={{ duration: 0.5 }} className="fixed top-0 left-0 w-full h-full flex justify-center items-center z-60">
                  <div className="rounded-lg bg-white p-8 shadow-2xl max-w-lg">
                    <p className="text-lg font-bold">¿Estas seguro que quieres borrar este pedido?</p>
                    <div className="mt-2">
                      <p className="mt-2 font-medium text-sm lg:text-base text-gray-600">
                        Esta accion no es reversible, sin embargo, puedes crear otro pedido en el menu de "<strong>Mis pedidos</strong>"
                      </p>
                    </div>
                    <div className="mt-4 flex gap-2">
                      <button onClick={(event) => { cancelarOrden(event) }} className="inline-block rounded bg-red-500 px-4 py-2 text-sm font-medium text-white border-[1px] border-red-500 hover:text-red-500 hover:bg-transparent">
                        Si, estoy seguro
                      </button>
                      <button onClick={() => {
                        setVerPedidoState({
                          cancelOrderStage: false
                        })
                      }} type="button" className="rounded bg-gray-100 px-4 py-2 text-sm font-medium text-gray-700">
                        No, volver
                      </button>
                    </div>
                  </div>
                  {/* {state.waitingForResponse === true && state.successAlert === false &&
                      <Loader />
                    } */}
                </motion.div>
              </div>
            }
            {verPedidoState.showOrderStateHistory === true &&
              <OrderStateHistory verPedidoState={verPedidoState} setVerPedidoState={setVerPedidoState} />
            }

            {verPedidoState.showOrderDataHistory === true &&
              <OrderDataHistory verPedidoState={verPedidoState} setVerPedidoState={setVerPedidoState} />
            }
            { }
            <div className="bg-white p-4 md:p-10 z-40 rounded-lg grid max-w-[1000px] w-full grid-cols-1 md:grid-cols-4 gap-x-4 gap-y-3 max-h-[90vh] overflow-y-auto">
              <div className="w-full md:col-start-1 md:col-end-6 flex justify-end items-center h-2">
                <PopUp message="Presione par ver el historial de cambios en los datos de el pedido">
                  <button className="group flex items-center justify-center rounded-lg transition-colors hover:bg-gray-300 p-2" type="button" onClick={() => { setVerPedidoState({ showOrderDataHistory: true }) }}>
                    <span className="flex-shrink-0 rounded-full text-gray-500 group-hover:text-red-500">
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-8 h-8">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M12 6.042A8.967 8.967 0 006 3.75c-1.052 0-2.062.18-3 .512v14.25A8.987 8.987 0 016 18c2.305 0 4.408.867 6 2.292m0-14.25a8.966 8.966 0 016-2.292c1.052 0 2.062.18 3 .512v14.25A8.987 8.987 0 0018 18a8.967 8.967 0 00-6 2.292m0-14.25v14.25" />
                      </svg>
                    </span>
                  </button>
                </PopUp>
              </div>
              <div className="flex flex-col gap-y-3 md:col-start-1 md:col-end-6 border-b-[1px] pb-3 border-slate-200">
                <div className="break-words">
                  <strong className="mr-2">Nombre de quien realiza el pedido:</strong><span>{verPedidoState.quienRealizaElPedido}</span>
                </div>
                <div className="break-words">
                  <strong className="mr-2">Nombre del paciente:</strong><span>{verPedidoState.nombreDelPaciente}</span>
                </div>
                <div className="break-words">
                  <strong className="mr-2">Numero de documento del paciente:</strong><span>{verPedidoState.numeroDeDocumentoDelPaciente}</span>
                </div>
                <div className="break-words">
                  <strong className="mr-2">Nombre del optometra u oftalmólogo:</strong><span>{verPedidoState.nombreDelDoctor}</span>
                </div>
                <div className="break-words">
                  <strong className="mr-2">Tarjeta profesional:</strong><span>{verPedidoState.tarjetaProfesional}</span>
                </div>
                <div className="break-words">
                  <strong className="mr-2">Ciudad:</strong><span>{verPedidoState.city ? verPedidoState.city : 'N/A'}</span>
                </div>
                <div className="break-words">
                  <strong className="mr-2">Address:</strong><span>{verPedidoState.address ? verPedidoState.address : 'N/A'}</span>
                </div>
                <div className="break-words">
                  <strong className="mr-2">Fecha de creacion:</strong><span>{verPedidoState.createdOrderDate}</span>
                </div>
                <div className="break-words">
                  <strong className="mr-2">Fecha de ultima actualizacion:</strong><span>{verPedidoState.updatedOrderDate ? verPedidoState.updatedOrderDate : ''}</span>
                </div>
                <div className="break-words flex items-center justify-start">
                  <strong className="mr-2">Estado del pedido:</strong><span><StateBadge status={verPedidoState.order_state} /></span>
                  <PopUp message="Presione par ver el historial de cambios en el estado de el pedido">
                    <button className="group flex items-center justify-center rounded-full transition-colors hover:bg-gray-300 ml-1" type="button" onClick={() => { setVerPedidoState({ showOrderStateHistory: true }) }}>
                      <span className="flex-shrink-0 rounded-full text-gray-500 group-hover:text-red-500">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="h-8 w-8">
                          <path strokeLinecap="round" strokeLinejoin="round" d="M12 9v3.75m9-.75a9 9 0 11-18 0 9 9 0 0118 0zm-9 3.75h.008v.008H12v-.008z" />
                        </svg>
                      </span>
                    </button>
                  </PopUp>
                </div>
              </div>
              {parseInt(verPedidoState.type_order) === 1 &&
                <>
                  <div className="flex flex-col md:col-start-1 md:col-end-3 gap-y-3">
                    <div>
                      <p className="text-slate-600 border-b-[1px] pb-3 border-slate-200 text-center">Ojo izquierdo</p>
                    </div>
                    <div className="break-words">
                      <strong className="mr-2">Tipo de lente:</strong><span>{
                        verPedidoState.left_typeLens ?
                          verPedidoState.left_typeLens.name_typeLens ?
                            verPedidoState.left_typeLens.name_typeLens
                            : "N/A"
                          : "N/A"
                      }</span>
                    </div>
                    <div className="break-words">
                      <strong className="mr-2">Referencia:</strong><span>{
                        verPedidoState.left_referenceLens ?
                          verPedidoState.left_referenceLens.name_lens ?
                            verPedidoState.left_referenceLens.name_lens
                            : "N/A"
                          : "N/A"
                      }</span>
                    </div>
                    {verPedidoState.left_inputs.includes('1') &&
                      <div className="break-words">
                        <strong className="mr-2">Color:</strong><span>{
                          verPedidoState.left_color ?
                            verPedidoState.left_color.name_colorLens === "Indique el color del lente" ?
                              "N/A"
                              : verPedidoState.left_color.name_colorLens
                            : "N/A"
                        }</span>
                      </div>
                    }
                    {verPedidoState.left_inputs.includes('2') &&
                      <div className="break-words">
                        <strong className="mr-2">Diseño:</strong><span>{
                          verPedidoState.left_design ?
                            verPedidoState.left_design.id_design ?
                              parseInt(verPedidoState.left_design.id_design) === 0 ?
                                "N/A"
                                : verPedidoState.left_design.design_name
                              : "N/A"
                            : 'N/A'
                        }
                        </span>
                      </div>
                    }
                    {verPedidoState.left_inputs.includes('2') && verPedidoState.left_design ?
                      parseInt(verPedidoState.left_design.id_design) === 3 ?
                        <>
                          <div className="break-words">
                            <strong className="mr-2">Z.O de Diseño:</strong><span>{verPedidoState.left_dZO ? verPedidoState.left_dZO : "N/A"}</span>
                          </div>
                          <div className="break-words">
                            <strong className="mr-2">Segunda Curva de Diseño:</strong><span>{verPedidoState.left_dSegundaCurva ? verPedidoState.left_dSegundaCurva : "N/A"}</span>
                          </div>
                        </>
                        : <></>
                      : <></>
                    }
                    {verPedidoState.left_inputs.includes('2') && verPedidoState.left_design ?
                      parseInt(verPedidoState.left_design.id_design) === 4 ?
                        <div className="break-words">
                          <strong className="mr-2">Segunda Curva de Diseño:</strong><span>{verPedidoState.left_dSegundaCurva ? verPedidoState.left_dSegundaCurva : "N/A"}</span>
                        </div>
                        : <></>
                      : <></>
                    }
                    {verPedidoState.left_inputs.includes('3') &&
                      <div className="break-words">
                        <strong className="mr-2">Cantidad:</strong><span>{verPedidoState.left_cantidad ? verPedidoState.left_cantidad : "N/A"}</span>
                      </div>
                    }
                    {verPedidoState.left_inputs.includes('4') &&
                      <div className="break-words">
                        <strong className="mr-2">C.base:</strong><span>{verPedidoState.left_c_base ? verPedidoState.left_c_base : "N/A"}</span>
                      </div>
                    }
                    {verPedidoState.left_inputs.includes('5') &&
                      <div className="break-words">
                        <strong className="mr-2">poder:</strong><span>{verPedidoState.left_poder ? verPedidoState.left_poder : "N/A"}</span>
                      </div>
                    }
                    {verPedidoState.left_inputs.includes('6') &&
                      <div className="break-words">
                        <strong className="mr-2">diametro:</strong><span>{verPedidoState.left_diametro ? verPedidoState.left_diametro : "N/A"}</span>
                      </div>
                    }
                    {verPedidoState.left_inputs.includes('7') &&
                      <div className="break-words">
                        <strong className="mr-2">Z.O:</strong><span>{verPedidoState.left_ZO ? verPedidoState.left_ZO : "N/A"}</span>
                      </div>
                    }
                    {verPedidoState.left_inputs.includes('8') &&
                      <div className="break-words">
                        <strong className="mr-2">Adicion:</strong><span>{verPedidoState.left_adicion ? verPedidoState.left_adicion : "N/A"}</span>
                      </div>
                    }
                    {verPedidoState.left_inputs.includes('9') &&
                      <div className="break-words">
                        <strong className="mr-2">cilindro:</strong><span>{verPedidoState.left_cilindro ? verPedidoState.left_cilindro : "N/A"}</span>
                      </div>
                    }
                    {verPedidoState.left_inputs.includes('10') &&
                      <div className="break-words">
                        <strong className="mr-2">Grados:</strong><span>{verPedidoState.left_grados ? verPedidoState.left_grados : "N/A"}</span>
                      </div>
                    }
                    {verPedidoState.left_inputs.includes('11') &&
                      <div className="break-words">
                        <strong className="mr-2">Periferia 1:</strong><span>{verPedidoState.left_periferia1 ? verPedidoState.left_periferia1 : "N/A"}</span>
                      </div>
                    }
                    {verPedidoState.left_inputs.includes('12') &&
                      <div className="break-words">
                        <strong className="mr-2">Periferia 2:</strong><span>{verPedidoState.left_periferia2 ? verPedidoState.left_periferia2 : "N/A"}</span>
                      </div>
                    }
                    {verPedidoState.left_inputs.includes('13') &&
                      <div className="break-words">
                        <strong className="mr-2">cpp:</strong><span>{verPedidoState.left_cpp ? verPedidoState.left_cpp : "N/A"}</span>
                      </div>
                    }
                    {verPedidoState.left_inputs.includes('14') &&
                      <div className="break-words">
                        <strong className="mr-2">Observaciones:</strong><span>{verPedidoState.left_observaciones ? verPedidoState.left_observaciones : "N/A"}</span>
                      </div>
                    }
                    {verPedidoState.left_inputs.includes('15') &&
                      <div className="break-words">
                        <strong className="mr-2">Curva Invertida:</strong><span>{verPedidoState.left_cInvertida ? verPedidoState.left_cInvertida : "N/A"}</span>
                      </div>
                    }
                    {verPedidoState.left_inputs.includes('16') &&
                      <div className="break-words">
                        <strong className="mr-2">Curva de Alineamiento:</strong><span>{verPedidoState.left_cAlineamiento ? verPedidoState.left_cAlineamiento : "N/A"}</span>
                      </div>
                    }
                    {verPedidoState.left_inputs.includes('17') &&
                      <div className="break-words">
                        <strong className="mr-2">Curva de Apertura:</strong><span>{verPedidoState.left_cApertura ? verPedidoState.left_cApertura : "N/A"}</span>
                      </div>
                    }
                    {verPedidoState.left_inputs.includes('18') &&
                      <div className="break-words">
                        <strong className="mr-2">Z.O de Curva Base:</strong><span>{verPedidoState.left_ZOcBase ? verPedidoState.left_ZOcBase : "N/A"}</span>
                      </div>
                    }
                    {verPedidoState.left_inputs.includes('19') &&
                      <div className="break-words">
                        <strong className="mr-2">Z.O de Curva Invertida:</strong><span>{verPedidoState.left_ZOcInvertida ? verPedidoState.left_ZOcInvertida : "N/A"}</span>
                      </div>
                    }
                    {verPedidoState.left_inputs.includes('20') &&
                      <div className="break-words">
                        <strong className="mr-2">Z.O de Curva de Alineamiento:</strong><span>{verPedidoState.left_ZOcAlineamiento ? verPedidoState.left_ZOcAlineamiento : "N/A"}</span>
                      </div>
                    }
                    {verPedidoState.left_inputs.includes('21') &&
                      <div className="break-words">
                        <strong className="mr-2">Z.O de Curva de Apertura:</strong><span>{verPedidoState.left_ZOcApertura ? verPedidoState.left_ZOcApertura : "N/A"}</span>
                      </div>
                    }
                  </div>
                  <div className="flex flex-col gap-y-3 md:col-start-3 md:col-end-6">
                    <div>
                      <p className="text-slate-600 border-b-[1px] pb-3 border-slate-200 text-center">Ojo derecho</p>
                    </div>
                    <div className="break-words">
                      <strong className="mr-2">Tipo de lente:</strong><span>{
                        verPedidoState.right_typeLens ?
                          verPedidoState.right_typeLens.name_typeLens ?
                            verPedidoState.right_typeLens.name_typeLens
                            : "N/A"
                          : "N/A"
                      }</span>
                    </div>
                    <div className="break-words">
                      <strong className="mr-2">Referencia:</strong>
                      <span>{
                        verPedidoState.right_referenceLens ?
                          verPedidoState.right_referenceLens.name_lens ?
                            verPedidoState.right_referenceLens.name_lens
                            : "N/A"
                          : "N/A"
                      }</span>
                    </div>
                    {verPedidoState.right_inputs.includes('1') &&
                      <div className="break-words">
                        <strong className="mr-2">Color:</strong><span>{
                          verPedidoState.right_color ?
                            verPedidoState.right_color.name_colorLens === "Indique el color del lente" ?
                              "N/A"
                              : verPedidoState.right_color.name_colorLens
                            : "N/A"
                        }</span>
                      </div>
                    }
                    {verPedidoState.right_inputs.includes('2') &&
                      <div className="break-words">
                        <strong className="mr-2">Diseño:</strong>
                        <span>
                          {
                            verPedidoState.right_design ?
                              parseInt(verPedidoState.right_design.id_design) === 0 ?
                                "N/A"
                                : verPedidoState.right_design.design_name
                              : "N/A"
                          }
                        </span>
                      </div>
                    }
                    {verPedidoState.right_inputs.includes('2') && verPedidoState.right_design ?
                      parseInt(verPedidoState.right_design.id_design) === 3 ?
                        <>
                          <div className="break-words">
                            <strong className="mr-2">Z.O de Diseño:</strong><span>{verPedidoState.right_dZO ? verPedidoState.right_dZO : "N/A"}</span>
                          </div>
                          <div className="break-words">
                            <strong className="mr-2">Segunda Curva de Diseño:</strong><span>{verPedidoState.right_dSegundaCurva ? verPedidoState.right_dSegundaCurva : "N/A"}</span>
                          </div>
                        </>
                        : <></>
                      : <></>
                    }
                    {verPedidoState.right_inputs.includes('2') && verPedidoState.right_design ?
                      parseInt(verPedidoState.right_design.id_design) === 4 ?
                        <div className="break-words">
                          <strong className="mr-2">Segunda Curva de Diseño:</strong><span>{verPedidoState.right_dSegundaCurva ? verPedidoState.right_dSegundaCurva : "N/A"}</span>
                        </div>
                        : <></>
                      : <></>
                    }
                    {verPedidoState.right_inputs.includes('3') &&
                      <div className="break-words">
                        <strong className="mr-2">Cantidad:</strong><span>{verPedidoState.right_cantidad ? verPedidoState.right_cantidad : "N/A"}</span>
                      </div>
                    }
                    {verPedidoState.right_inputs.includes('4') &&
                      <div className="break-words">
                        <strong className="mr-2">C.base:</strong><span>{verPedidoState.right_c_base ? verPedidoState.right_c_base : "N/A"}</span>
                      </div>
                    }
                    {verPedidoState.right_inputs.includes('5') &&
                      <div className="break-words">
                        <strong className="mr-2">poder:</strong><span>{verPedidoState.right_poder ? verPedidoState.right_poder : "N/A"}</span>
                      </div>
                    }
                    {verPedidoState.right_inputs.includes('6') &&
                      <div className="break-words">
                        <strong className="mr-2">diametro:</strong><span>{verPedidoState.right_diametro ? verPedidoState.right_diametro : "N/A"}</span>
                      </div>
                    }
                    {verPedidoState.right_inputs.includes('7') &&
                      <div className="break-words">
                        <strong className="mr-2">Z.O:</strong><span>{verPedidoState.right_ZO ? verPedidoState.right_ZO : "N/A"}</span>
                      </div>
                    }
                    {verPedidoState.right_inputs.includes('8') &&
                      <div className="break-words">
                        <strong className="mr-2">Adicion:</strong><span>{verPedidoState.right_adicion ? verPedidoState.right_adicion : "N/A"}</span>
                      </div>
                    }
                    {verPedidoState.right_inputs.includes('9') &&
                      <div className="break-words">
                        <strong className="mr-2">cilindro:</strong><span>{verPedidoState.right_cilindro ? verPedidoState.right_cilindro : "N/A"}</span>
                      </div>
                    }
                    {verPedidoState.right_inputs.includes('10') &&
                      <div className="break-words">
                        <strong className="mr-2">Grados:</strong><span>{verPedidoState.right_grados ? verPedidoState.right_grados : "N/A"}</span>
                      </div>
                    }
                    {verPedidoState.right_inputs.includes('11') &&
                      <div className="break-words">
                        <strong className="mr-2">Periferia 1:</strong><span>{verPedidoState.right_periferia1 ? verPedidoState.right_periferia1 : "N/A"}</span>
                      </div>
                    }
                    {verPedidoState.right_inputs.includes('12') &&
                      <div className="break-words">
                        <strong className="mr-2">Periferia 2:</strong><span>{verPedidoState.right_periferia2 ? verPedidoState.right_periferia2 : "N/A"}</span>
                      </div>
                    }
                    {verPedidoState.right_inputs.includes('13') &&
                      <div className="break-words">
                        <strong className="mr-2">cpp:</strong><span>{verPedidoState.right_cpp ? verPedidoState.right_cpp : "N/A"}</span>
                      </div>
                    }
                    {verPedidoState.right_inputs.includes('14') &&
                      <div className="break-words">
                        <strong className="mr-2">Observaciones:</strong><span>{verPedidoState.right_observaciones ? verPedidoState.right_observaciones : "N/A"}</span>
                      </div>
                    }
                    {verPedidoState.right_inputs.includes('15') &&
                      <div className="break-words">
                        <strong className="mr-2">Curva Invertida:</strong><span>{verPedidoState.right_cInvertida ? verPedidoState.right_cInvertida : "N/A"}</span>
                      </div>
                    }
                    {verPedidoState.right_inputs.includes('16') &&
                      <div className="break-words">
                        <strong className="mr-2">Curva de Alineamiento:</strong><span>{verPedidoState.right_cAlineamiento ? verPedidoState.right_cAlineamiento : "N/A"}</span>
                      </div>
                    }
                    {verPedidoState.right_inputs.includes('17') &&
                      <div className="break-words">
                        <strong className="mr-2">Curva de Apertura:</strong><span>{verPedidoState.right_cApertura ? verPedidoState.right_cApertura : "N/A"}</span>
                      </div>
                    }
                    {verPedidoState.right_inputs.includes('18') &&
                      <div className="break-words">
                        <strong className="mr-2">Z.O de Curva Base:</strong><span>{verPedidoState.right_ZOcBase ? verPedidoState.right_ZOcBase : "N/A"}</span>
                      </div>
                    }
                    {verPedidoState.right_inputs.includes('19') &&
                      <div className="break-words">
                        <strong className="mr-2">Z.O de Curva Invertida:</strong><span>{verPedidoState.right_ZOcInvertida ? verPedidoState.right_ZOcInvertida : "N/A"}</span>
                      </div>
                    }
                    {verPedidoState.right_inputs.includes('20') &&
                      <div className="break-words">
                        <strong className="mr-2">Z.O de Curva de Alineamiento:</strong><span>{verPedidoState.right_ZOcAlineamiento ? verPedidoState.right_ZOcAlineamiento : "N/A"}</span>
                      </div>
                    }
                    {verPedidoState.right_inputs.includes('21') &&
                      <div className="break-words">
                        <strong className="mr-2">Z.O de Curva de Apertura:</strong><span>{verPedidoState.right_ZOcApertura ? verPedidoState.right_ZOcApertura : "N/A"}</span>
                      </div>
                    }
                  </div>
                </>
              }
              {parseInt(verPedidoState.type_order) === 2 &&
                <>
                  <div className="flex flex-col md:col-start-1 md:col-end-6 gap-y-3">
                    <div className="break-words">
                      <strong className="mr-2">Caja:</strong><span>{verPedidoState.idCaja.box}</span>
                    </div>
                    <div className="break-words">
                      <strong className="mr-2">Cantidad:</strong><span>{verPedidoState.left_cantidad === null ? 1 : verPedidoState.left_cantidad}</span>
                    </div>
                    <div className="break-words">
                      <strong className="mr-2">Observaciones:</strong><span>{verPedidoState.left_observaciones}</span>
                    </div>
                  </div>
                </>
              }
              {parseInt(verPedidoState.type_order) === 3 &&
                <>
                  <div className="flex flex-col md:col-start-1 md:col-end-6 gap-y-3">
                    <div className="break-words">
                      <strong className="mr-2">Accesorio:</strong><span>{verPedidoState.idAccesorio.acc}</span>
                    </div>
                    <div className="break-words">
                      <strong className="mr-2">Cantidad:</strong><span>{verPedidoState.left_cantidad}</span>
                    </div>
                  </div>
                </>
              }
              <div className="col-span-full flex items-center justify-end mt-5 gap-2">
                <DownloadPDF data={verPedidoState} />
                <button type="button" onClick={() => {
                  resetVerPedidos();
                }} className="group flex items-center justify-center gap-2 rounded-lg border border-gray-400 bg-gray-400 px-3 py-2 transition-colors hover:bg-transparent focus:outline-none focus:ring">
                  <span className="capitalize text-sm font-medium text-white transition-colors group-hover:text-gray-400 group-active:text-gray-400">
                    volver
                  </span>
                  <span className="flex-shrink-0 rounded-full border border-current bg-white p-2 text-gray-400 group-active:text-gray-400">
                    <svg className="h-3 w-3 rotate-180" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path className="fill-green-500" strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M17 8l4 4m0 0l-4 4m4-4H3" />
                    </svg>
                  </span>
                </button>

                {verPedidoState.order_state === 'revision' || verPedidoState.order_state === 'rechazado' ?
                  <>
                    <button type="button" onClick={(event) => {
                      setVerPedidoState({ cancelOrderStage: true });
                    }} className="group flex items-center justify-center gap-2 rounded-lg border border-red-500 bg-red-500 px-3 py-2 transition-colors hover:bg-transparent focus:outline-none focus:ring">
                      <span className="capitalize text-sm font-medium text-white transition-colors group-hover:text-red-500 group-active:text-red-500">
                        cancelar pedido
                      </span>
                      <span className="flex-shrink-0 rounded-full border border-current bg-white p-2 text-red-500 group-active:text-red-500">
                        <svg className="h-3 w-3" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M18 6L6 18" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                          <path d="M6 6L18 18" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>
                      </span>
                    </button>
                    <button onClick={(event) => { setVerPedidoState({ updateOrderStage: true, verPedido: false }) }} className="group flex items-center justify-center gap-2 rounded-lg border border-indigo-500 bg-indigo-500 px-3 py-2 transition-colors hover:bg-transparent focus:outline-none focus:ring">
                      <span className="capitalize text-sm font-medium text-white transition-colors group-hover:text-indigo-500 group-active:text-indigo-500">
                        actualizar
                      </span>
                      <span className="flex-shrink-0 rounded-full border border-current bg-white p-2 text-indigo-500 group-active:text-indigo-500">
                        <svg className="h-3 w-3" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                          <path className="fill-indigo-500" strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M17 8l4 4m0 0l-4 4m4-4H3" />
                        </svg>
                      </span>
                    </button>
                  </>
                  : <></>
                }
              </div>
            </div>
          </motion.form>
        </>
      }
      {/* muestra la lista de pedidos */}
      {state.data && state.dataLoaded !== false ?
        <div className="mt-10">
          <table className={`min-w-full divide-y-2 divide-gray-100 text-sm block overflow-auto 2xl:overflow-visible min-h-[400px]`}>
            <OrderFilter filteredState={state} setFilteredState={setState} />
            <thead>
              <tr className="border-b-gray-200 border-b-[2px]">
                <th className="whitespace-nowrap px-4 py-2 text-left font-medium text-gray-900">
                  Id
                </th>
                <th className="whitespace-nowrap px-4 py-2 text-left font-medium text-gray-900">
                  Nombre del optometra
                </th>
                <th className="whitespace-nowrap px-4 py-2 text-left font-medium text-gray-900">
                  Nombre del paciente
                </th>
                <th className="whitespace-nowrap px-4 py-2 text-left font-medium text-gray-900">
                  Fecha de creacion
                </th>
                <th className="whitespace-nowrap px-4 py-2 text-left font-medium text-gray-900">
                  Estado
                </th>
                <th className="whitespace-nowrap px-4 py-2 text-left font-medium text-gray-900">
                  Tipo
                </th>
                <th className="px-4 py-2 lg:sticky lg:right-0 lg:bg-gray-100" />
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-200">
              {state.filteredData.map((order: any) => {
                if ((order.id_profile === user.id || order.id_client === user.id) && parseInt(order.deleteid) === 0) {
                  return (
                    <tr key={order.id_order} className="relative lg:pr-16">
                      <td className="whitespace-nowrap px-4 py-2 text-gray-700">{order.id_order}</td>
                      <td className="whitespace-nowrap px-4 py-2 font-medium text-gray-700">{order.optometrist_name}</td>
                      <td className="whitespace-nowrap px-4 py-2 text-gray-700">{order.patient_name}</td>
                      <td className="whitespace-nowrap px-4 py-2 text-gray-700">{order.created_at.replace(/-/ig, ' / ')}</td>
                      <td className="whitespace-nowrap px-4 py-2 text-gray-700">
                        <StateBadge status={order.state_order} />
                      </td>
                      <td className="whitespace-nowrap px-4 py-2 text-gray-700">
                        <TypeBadge type={order.type_order} />
                      </td>
                      <td className="whitespace-nowrap px-4 py-2 lg:sticky lg:right-0 lg:bg-gray-100 h-full">
                        <button onClick={() => {
                          mostrarPedido({
                            updatedOrderDate: order.updated_at,
                            createdOrderDate: order.created_at,
                            orderId: parseInt(order.id_order),
                            verPedido: true,
                            quienRealizaElPedido: order.creator_name,
                            nombreDelPaciente: order.patient_name,
                            numeroDeDocumentoDelPaciente: order.patient_doc,
                            nombreDelDoctor: order.optometrist_name,
                            tarjetaProfesional: order.professional_card,
                            city: parseInt(order.city),
                            address: order.address,
                            type_order: parseInt(order.type_order),
                            idCaja: parseInt(order.id_box),
                            idAccesorio: parseInt(order.id_acc),
                            order_state: order.state_order,
                            // ojo izquierdo
                            left_typeLens: parseInt(order.l_id_typeLens),
                            left_referenceLens: parseInt(order.l_id_lens),
                            left_c_base: order.l_cBase,
                            left_poder: order.l_power,
                            left_cilindro: order.l_cylinder,
                            left_diametro: order.l_diameter,
                            left_design: parseInt(order.l_design),
                            left_cpp: order.l_cpp,
                            left_observaciones: order.l_observations,
                            left_color: parseInt(order.l_color),
                            left_cantidad: parseInt(order.lQty),
                            left_adicion: order.l_addition,
                            left_ZO: order.l_zo,
                            left_grados: order.l_degrees,
                            left_periferia1: order.l_perifery1,
                            left_periferia2: order.l_perifery2,
                            left_cInvertida: order.l_cInverted,
                            left_cAlineamiento: order.l_cAlignment,
                            left_cApertura: order.l_cOpening,
                            left_ZOcBase: order.l_ZOcBase,
                            left_ZOcInvertida: order.l_ZOcInverted,
                            left_ZOcAlineamiento: order.l_ZOcAlignment,
                            left_ZOcApertura: order.l_ZOcOpening,
                            left_dZO: order.lD_zo,
                            left_dSegundaCurva: order.lD_secondturn,
                            // ojo derecho
                            right_typeLens: parseInt(order.r_id_typeLens),
                            right_referenceLens: parseInt(order.r_id_lens),
                            right_c_base: order.r_cBase,
                            right_poder: order.r_power,
                            right_cilindro: order.r_cylinder,
                            right_diametro: order.r_diameter,
                            right_design: parseInt(order.r_design),
                            right_cpp: order.r_cpp,
                            right_observaciones: order.r_observations,
                            right_color: parseInt(order.r_color),
                            right_cantidad: parseInt(order.rQty),
                            right_adicion: order.r_addition,
                            right_ZO: order.r_zo,
                            right_grados: order.r_degrees,
                            right_periferia1: order.r_perifery1,
                            right_periferia2: order.r_perifery2,
                            right_cInvertida: order.r_cInverted,
                            right_cAlineamiento: order.r_cAlignment,
                            right_cApertura: order.r_cOpening,
                            right_ZOcBase: order.r_ZOcBase,
                            right_ZOcInvertida: order.r_ZOcInverted,
                            right_ZOcAlineamiento: order.r_ZOcAlignment,
                            right_ZOcApertura: order.r_ZOcOpening,
                            right_dZO: order.rD_zo,
                            right_dSegundaCurva: order.rD_secondturn,
                          })
                        }} className="inline-block rounded bg-indigo-500 px-4 py-2 text-xs font-medium border-[1px] border-indigo-500 text-white hover:text-indigo-500 hover:bg-transparent">
                          Ver pedido
                        </button>
                      </td>
                    </tr>
                  )
                }
              })
              }
            </tbody>
          </table>
        </div>
        : <div className="flex items-center justify-center min-h-[300px]">
          <p className="text-xl text-slate-600 font-medium">Aun no haz creado ningun pedido...</p>
        </div>
      }
      {/* mensaje de error */}
      {state.errorMessage && state.waitingForResponse === false &&
        <ErrorAlert ErrorElement={state.ErrorElement}
          animate={{ x: 0, opacity: 1, zIndex: 50, left: "1rem", bottom: "1rem", position: "fixed" }}
          transition={{ duration: 0.3 }}
          initial={{ x: -50, opacity: 0.1, zIndex: 50, left: "1rem", bottom: "1rem", position: "fixed" }}
          message={state.errorMessageText}
          title={state.errorMessageTitle} />
      }
      {/* mensaje de completado */}
      {state.successMessage === true && state.waitingForResponse === false &&
        <motion.div initial={{ x: '-150%', opacity: 0.5 }} animate={{ x: '0%', opacity: 1 }} role="alert" className="rounded-xl border fixed left-3 sm:left-10 bottom-10 z-40 bg-white border-gray-100 p-4 shadow-xl">
          <div className="flex items-start  gap-4 max-w-[450px]">
            <span className="text-green-600">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                className="h-6 w-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                />
              </svg>
            </span>

            <div className="flex-1">
              <strong className="block font-medium text-gray-900">{state.successMessageTitle}</strong>

              <p className="mt-1 text-sm text-gray-700">
                {state.successMessageText}
              </p>
            </div>

            <button onClick={() => { setState({ successMessage: false, successMessageTitle: '', successMessageText: '' }) }} className="text-gray-500 transition hover:text-gray-600">
              <span className="sr-only">Dismiss popup</span>

              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                className="h-6 w-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
          </div>
        </motion.div>
      }
      {/* modal con formulario de creacion de pedido */}
      {state.createOrderStage === true && state.orderStep !== 0 &&
        <>
          <Modal />
          <motion.form initial={{ y: 50, opacity: 0 }} animate={{ y: 0, opacity: 1 }} transition={{ duration: 0.5 }} className="fixed top-0 left-0 w-full h-full flex justify-center items-center z-40">
            {state.showSearchClient === true &&
              <SearchClient state={state} setState={setState} user={user} eyesState={eyesState} setEyesState={setEyesState} setCity={setCity} citysData={citysData}/>
            }
            {state.orderStep === 1 && state.waitingForResponse === false &&
              <div className="bg-white p-4 md:p-10 z-40 rounded-lg grid grid-cols-1 md:grid-cols-2 gap-x-4 gap-y-3 max-h-[90vh] w-full max-w-[1000px] overflow-y-auto">
                {/* F botton de autcompletar */}
                {/* {parseInt(user.permissions) === 1 &&
                  <div className="col-span-full flex justify-end items-center gap-x-2">
                    <span className="text-sm text-gray-700">Autocompletar</span>
                    <PopUp message="Presione para completar algunos campos con la informacion de su perfil.">
                      <button type="button" onClick={(event) => {
                        loadDataFromProfile(event)
                      }} className="group flex items-center justify-center gap-2 h-12 w-12 rounded-lg border border-gray-500 bg-gray-500 px-2 py-2 transition-colors hover:bg-transparent focus:outline-none focus:ring" title={"Reiniciar los filtros"}>
                        <span className="flex-shrink-0 rounded-full border border-current bg-white p-2 text-gray-500 group-active:text-gray-500">
                          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M9 12h3.75M9 15h3.75M9 18h3.75m3 .75H18a2.25 2.25 0 002.25-2.25V6.108c0-1.135-.845-2.098-1.976-2.192a48.424 48.424 0 00-1.123-.08m-5.801 0c-.065.21-.1.433-.1.664 0 .414.336.75.75.75h4.5a.75.75 0 00.75-.75 2.25 2.25 0 00-.1-.664m-5.8 0A2.251 2.251 0 0113.5 2.25H15c1.012 0 1.867.668 2.15 1.586m-5.8 0c-.376.023-.75.05-1.124.08C9.095 4.01 8.25 4.973 8.25 6.108V8.25m0 0H4.875c-.621 0-1.125.504-1.125 1.125v11.25c0 .621.504 1.125 1.125 1.125h9.75c.621 0 1.125-.504 1.125-1.125V9.375c0-.621-.504-1.125-1.125-1.125H8.25zM6.75 12h.008v.008H6.75V12zm0 3h.008v.008H6.75V15zm0 3h.008v.008H6.75V18z" />
                          </svg>
                        </span>
                      </button>
                    </PopUp>
                  </div>
                } */}
                {/* Quien realiza el pedido */}
                <div className="col-span-full">
                  <p className="font-medium text-slate-600">Quien realiza el pedido?</p>
                </div>
                <div className="col-span-full md:col-span-2">
                  <label className=" text-sm font-medium text-gray-700">
                    <span className="capitalize">Nombre</span>
                    <input
                      type="text"
                      value={eyesState.quienRealizaElPedido}
                      onChange={(event) => { setEyesState({ quienRealizaElPedido: event.target.value }) }}
                      className="h-12 mt-2 w-full rounded-lg border-gray-200 border-[1px] p-3 text-sm"
                    />
                  </label>
                </div>
                {/* Datos del paciente */}
                <div className="md:col-span-full">
                  <p className="font-medium mt-5 text-slate-600">Datos de el paciente</p>
                </div>
                <div className="">
                  <label className="text-sm font-medium text-gray-700">
                    <span className="capitalize">Nombre</span>
                    <input
                      type="text"
                      value={eyesState.nombreDelPaciente}
                      onChange={(event) => { setEyesState({ nombreDelPaciente: event.target.value }) }}
                      className="h-12 mt-2 w-full rounded-lg border-gray-200 border-[1px] p-3 text-sm"
                    />
                  </label>
                </div>
                <div className="">
                  <label className="text-sm font-medium text-gray-700">
                    <span className="capitalize">Numero de documento</span>
                    <input
                      type="text"
                      value={eyesState.numeroDeDocumentoDelPaciente}
                      onChange={(event) => { setEyesState({ numeroDeDocumentoDelPaciente: event.target.value }) }}
                      className="h-12 mt-2 w-full rounded-lg border-gray-200 border-[1px] p-3 text-sm"
                    />
                  </label>
                </div>
                <div className="col-span-full">
                  <p className="font-medium mt-5 text-slate-600">Datos del optómetra u oftalmólogo</p>
                </div>
                <div className="">
                  <label className=" text-sm font-medium text-gray-700">
                    <span className="capitalize">Nombre</span>
                    {/* Se mostrara si es un cliente de tipo empresa */}
                    {parseInt(user.permissions) === 1 && parseInt(user.customerType) === 2 ?
                      <OrderDataLoader user={user} eyesState={eyesState} setEyesState={setEyesState} setCity={setCity} citysData={citysData} />
                      :
                      <>
                        {parseInt(user.permissions) === 5 || parseInt(user.permissions) === 2
                          ?
                          <>
                            <div className="flex items-center justify-center">
                              <input
                                type="text"
                                disabled={true}
                                value={eyesState.nombreDelDoctor}
                                onChange={(event) => { setEyesState({ nombreDelDoctor: event.target.value }) }}
                                className="h-12 mt-2 w-full rounded-l-lg border-gray-200 border-t-[1px] border-b-[1px] border-l-[1px] p-3 text-sm"
                              />
                              <PopUp message="Buscar Optometras" className="z-40">
                                <button onClick={() => { setState({ showSearchClient: true }) }} type="button" className="h-12 w-16 mt-2 bg-blue-500 rounded-r-lg border-gray-200 border-[1px] flex p-3 items-center justify-center">
                                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" className="w-6 h-6 text-white">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z" />
                                  </svg>
                                </button>
                              </PopUp>
                            </div>
                          </>
                          :
                          <input
                            type="text"
                            disabled={naturalClient}
                            value={eyesState.nombreDelDoctor}
                            onChange={(event) => { setEyesState({ nombreDelDoctor: event.target.value }) }}
                            className="h-12 mt-2 w-full rounded-lg border-gray-200 border-[1px] p-3 text-sm"
                          />
                        }
                      </>

                    }
                  </label>
                </div>
                {/* Tarjeta profesional */}
                <div className="">
                  <label className=" text-sm font-medium text-gray-700">
                    <span className="capitalize">Tarjeta profesional</span>
                    <input
                      type="text"
                      disabled={true}
                      value={eyesState.tarjetaProfesional}
                      onChange={(event) => { setEyesState({ tarjetaProfesional: event.target.value }) }}
                      className="h-12 mt-2 w-full rounded-lg border-gray-200 border-[1px] p-3 text-sm"
                    />
                  </label>
                </div>
                {/* Ciudad */}
                <div>
                  <span className="first-letter:capitalize text-sm font-medium text-gray-700">Ciudad</span>
                  <Listbox value={city} onChange={setCity}>
                    <div className="relative rounded-lg">
                      <Listbox.Button className="relative h-12 w-full mt-2 rounded-lg border-gray-200 border-[1px] p-3 text-sm text-left focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-sm">
                        <span className={`block truncate ${city.id === 0 && "text-gray-500"}`}>{city.name}</span>
                        <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                          <ChevronUpDownIcon
                            className="h-5 w-5 text-gray-400"
                            aria-hidden="true"
                          />
                        </span>
                      </Listbox.Button>
                      <Transition
                        as={Fragment}
                        leave="transition ease-in duration-100"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                      >
                        <Listbox.Options className="z-40 fixed max-w-sm mt-1 w-full max-h-60 overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                          {citysData.map((city: { name: string, id: number }, cityIdx: number) => (
                            <Listbox.Option
                              key={cityIdx}
                              onClick={((event) => { })}
                              className={({ active }) =>
                                `relative cursor-default select-none py-2 pl-10 pr-4 ${city.id === 0 && 'hidden'} ${active ? 'bg-blue-600 text-white' : 'text-gray-900'
                                }`
                              }
                              value={city}
                            >
                              {({ selected }) => (
                                <>
                                  <span
                                    className={`block truncate ${city.id === 0 && 'text-gray-500'} ${selected ? 'font-medium' : 'font-normal'
                                      }`}
                                  >
                                    {city.name}
                                  </span>
                                  {selected ? (
                                    <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-indigo-600">
                                      <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                    </span>
                                  ) : null}
                                </>
                              )}
                            </Listbox.Option>
                          ))}
                        </Listbox.Options>
                      </Transition>
                    </div>
                  </Listbox>
                </div>
                {/* Direccion */}
                <div>
                  <label className=" text-sm font-medium text-gray-700">
                    <span className="capitalize">Dirección</span>
                    <input
                      type="text"
                      value={eyesState.address}
                      onChange={(event) => { setEyesState({ address: event.target.value }) }}
                      className="h-12 mt-2 w-full rounded-lg border-gray-200 border-[1px] p-3 text-sm"
                    />
                  </label>
                </div>
                <div className="col-span-full flex items-center justify-end mt-5 gap-2">
                  <button type="button" onClick={(event) => {
                    cancelOrder();
                  }} className="group flex items-center justify-center gap-2 rounded-lg border border-red-500 bg-red-500 px-3 py-2 transition-colors hover:bg-transparent focus:outline-none focus:ring">
                    <span className="capitalize text-sm font-medium text-white transition-colors group-hover:text-red-500 group-active:text-red-500">
                      cancelar
                    </span>
                    <span className="flex-shrink-0 rounded-full border border-current bg-white p-2 text-red-500 group-active:text-red-500">
                      <svg className="h-3 w-3" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M18 6L6 18" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                        <path d="M6 6L18 18" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                      </svg>
                    </span>
                  </button>
                  <button onClick={(event) => { goToOrderStep2(event) }} className="group flex items-center justify-center gap-2 rounded-lg border border-green-500 bg-green-500 px-3 py-2 transition-colors hover:bg-transparent focus:outline-none focus:ring">
                    <span className="capitalize text-sm font-medium text-white transition-colors group-hover:text-green-500 group-active:text-green-500">
                      siguiente
                    </span>
                    <span className="flex-shrink-0 rounded-full border border-current bg-white p-2 text-green-500 group-active:text-green-500">
                      <svg className="h-3 w-3" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path className="fill-green-500" strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M17 8l4 4m0 0l-4 4m4-4H3" />
                      </svg>
                    </span>
                  </button>
                </div>
              </div>
            }
            {state.orderStep === 2 && state.waitingForResponse === false &&
              <div ref={eyesDiv} className="bg-white p-4 md:p-10 z-40 rounded-lg grid grid-cols-1 md:grid-cols-1 mx-5 gap-x-6 gap-y-3 max-h-[95vh] w-full max-w-[800px] overflow-y-auto">
                {state.showLeftEyeForm == true &&
                  <>
                    <div className="flex flex-col gap-y-3">
                      <div className="">
                        <p className="font-medium mt-5 text-slate-600 text-center border-b-[1px] pb-3 border-slate-200">Ojo izquierdo</p>
                      </div>
                      <div>
                        <span className="first-letter:capitalize">Tipo de lente</span>
                        <Listbox value={leftLensTypeSelected} onChange={setLeftLensTypesSelected}>
                          <div className="relative">
                            <Listbox.Button className="relative h-12 mt-2 w-full rounded-lg border-gray-200 border-[1px] p-3 text-sm text-left focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-sm">
                              <span className={`block truncate ${leftLensTypeSelected.id_typeLens === '0' && "text-gray-500"}`}>{leftLensTypeSelected.name_typeLens}</span>
                              <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                                <ChevronUpDownIcon
                                  className="h-5 w-5 text-gray-400"
                                  aria-hidden="true"
                                />
                              </span>
                            </Listbox.Button>
                            <Transition
                              as={Fragment}
                              leave="transition ease-in duration-100"
                              leaveFrom="opacity-100"
                              leaveTo="opacity-0"
                            >
                              <Listbox.Options className="z-40 absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                {lensTypes.map((lens: { name_typeLens: string }, lensTypesIdx: number) => (
                                  <Listbox.Option
                                    key={lensTypesIdx}
                                    onClick={() => { setLeftReferenceSelected(lensReferences[0]); setLeftColorLens(lensColors[0]) }}
                                    className={({ active }) =>
                                      `relative cursor-default select-none py-2 pl-10 pr-4 ${active ? 'bg-blue-600 text-white' : 'text-gray-900'
                                      }`
                                    }
                                    value={lens}
                                  >
                                    {({ selected }) => (
                                      <>
                                        <span
                                          className={`block truncate ${selected ? 'font-medium' : 'font-normal'
                                            }`}
                                        >
                                          {lens.name_typeLens}
                                        </span>
                                        {selected ? (
                                          <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-indigo-600">
                                            <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                          </span>
                                        ) : null}
                                      </>
                                    )}
                                  </Listbox.Option>
                                ))}
                              </Listbox.Options>
                            </Transition>
                          </div>
                        </Listbox>
                      </div>
                      <div>
                        <span className="first-letter:capitalize">Referencia</span>
                        <Listbox value={leftReferenceSelected} onChange={setLeftReferenceSelected}>
                          <div className="relative">
                            <Listbox.Button className="relative h-12 mt-2 w-full rounded-lg border-gray-200 border-[1px] p-3 text-sm text-left focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-sm">
                              <span className={`block truncate ${leftReferenceSelected.id_typeLens === '0' && "text-gray-500"}`}>{leftReferenceSelected.name_lens}</span>
                              <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                                <ChevronUpDownIcon
                                  className="h-5 w-5 text-gray-400"
                                  aria-hidden="true"
                                />
                              </span>
                            </Listbox.Button>
                            <Transition
                              as={Fragment}
                              leave="transition ease-in duration-100"
                              leaveFrom="opacity-100"
                              leaveTo="opacity-0"
                            >
                              <Listbox.Options className="z-40 absolute mt-1 max-h-60 w-full overflow-y-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                {lensReferences.map((lens: { id_lens: string, name_lens: string, id_typeLens: string }, lensIdx: number) => {
                                  if (lens.id_typeLens === leftLensTypeSelected.id_typeLens) {
                                    return (
                                      <Listbox.Option
                                        key={lensIdx}
                                        onClick={() => { calcColorLength(lens, 'left'); resetInputs('left'); }}
                                        className={({ active }) =>
                                          `relative cursor-default select-none py-2 pl-10 pr-4 ${active ? 'bg-blue-600 text-white' : 'text-gray-900'
                                          }`
                                        }
                                        value={lens}
                                      >
                                        {({ selected }) => (
                                          <>
                                            <span
                                              className={`block truncate ${selected ? 'font-medium' : 'font-normal'
                                                }`}
                                            >
                                              {lens.name_lens}
                                            </span>
                                            {selected ? (
                                              <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-indigo-600">
                                                <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                              </span>
                                            ) : null}
                                          </>
                                        )}
                                      </Listbox.Option>
                                    )
                                  } else {
                                    return null;
                                  }
                                })}
                              </Listbox.Options>
                            </Transition>
                          </div>
                        </Listbox>
                      </div>
                      {leftInputs.includes('1') &&
                        <div>
                          <span className="first-letter:capitalize">Color</span>
                          <Listbox value={leftColorLens} onChange={setLeftColorLens}>
                            <div className="relative">
                              <Listbox.Button className="relative h-12 mt-2 w-full rounded-lg border-gray-200 border-[1px] p-3 text-sm text-left focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-sm">
                                <span className={`block truncate ${leftColorLens.id_colorLens === '0' && "text-gray-500"}`}>{colorsLength.left === 0 ? 'No hay colores disponibles' : leftColorLens.name_colorLens}</span>
                                <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                                  <ChevronUpDownIcon
                                    className="h-5 w-5 text-gray-400"
                                    aria-hidden="true"
                                  />
                                </span>
                              </Listbox.Button>
                              <Transition
                                as={Fragment}
                                leave="transition ease-in duration-100"
                                leaveFrom="opacity-100"
                                leaveTo="opacity-0"
                              >
                                <Listbox.Options className="z-40 absolute mt-1 max-h-60 w-full overflow-y-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                  {lensColors.map((lens: { id_colorLens: string, name_colorLens: string, id_lens: string }, lensIdx: number) => {
                                    if (lens.id_lens === leftReferenceSelected.id_lens) {
                                      return (
                                        <Listbox.Option
                                          key={lensIdx}
                                          className={({ active }) =>
                                            `relative cursor-default select-none py-2 pl-10 pr-4 ${active ? 'bg-blue-600 text-white' : 'text-gray-900'
                                            }`
                                          }
                                          value={lens}
                                        >
                                          {({ selected }) => (
                                            <>
                                              <span
                                                className={`block truncate ${selected ? 'font-medium' : 'font-normal'
                                                  }`}
                                              >
                                                {lens.name_colorLens}
                                              </span>
                                              {selected ? (
                                                <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-indigo-600">
                                                  <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                                </span>
                                              ) : null}
                                            </>
                                          )}
                                        </Listbox.Option>
                                      )
                                    } else {
                                      return null;
                                    }
                                  })}
                                </Listbox.Options>
                              </Transition>
                            </div>
                          </Listbox>
                        </div>
                      }
                      {leftInputs.includes('2') &&
                        <div>
                          <span className="first-letter:capitalize">Diseño</span>
                          <Listbox value={leftDesignSelected} onChange={setLeftDesignSelected}>
                            <div className="relative">
                              <Listbox.Button className="relative h-12 mt-2 w-full rounded-lg border-gray-200 border-[1px] p-3 text-sm text-left focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-sm">
                                <span className={`block truncate ${leftDesignSelected.id_design === '0' && "text-gray-500"}`}>{leftDesignSelected.design_name}</span>
                                <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                                  <ChevronUpDownIcon
                                    className="h-5 w-5 text-gray-400"
                                    aria-hidden="true"
                                  />
                                </span>
                              </Listbox.Button>
                              <Transition
                                as={Fragment}
                                leave="transition ease-in duration-100"
                                leaveFrom="opacity-100"
                                leaveTo="opacity-0"
                              >
                                <Listbox.Options className="z-40 absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                  {lensDesign.map((lens: { design_name: string }, lensDesignIdx: number) => (
                                    <Listbox.Option
                                      key={lensDesignIdx}
                                      onClick={() => { setLeftDesignSelected(lensDesign[0]) }}
                                      className={({ active }) =>
                                        `relative cursor-default select-none py-2 pl-10 pr-4 ${active ? 'bg-blue-600 text-white' : 'text-gray-900'
                                        }`
                                      }
                                      value={lens}
                                    >
                                      {({ selected }) => (
                                        <>
                                          <span
                                            className={`block truncate ${selected ? 'font-medium' : 'font-normal'
                                              }`}
                                          >
                                            {lens.design_name}
                                          </span>
                                          {selected ? (
                                            <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-indigo-600">
                                              <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                            </span>
                                          ) : null}
                                        </>
                                      )}
                                    </Listbox.Option>
                                  ))}
                                </Listbox.Options>
                              </Transition>
                            </div>
                          </Listbox>
                        </div>
                      }
                      {parseInt(leftDesignSelected.id_design) === 3 &&
                        <EyesInput title='Diseño Z.O' value={eyesState.left_dZO} onWrite={(event) => { setEyesState({ left_dZO: event.target.value }) }} />
                      }
                      {parseInt(leftDesignSelected.id_design) === 3 || parseInt(leftDesignSelected.id_design) === 4 ?
                        <EyesInput title='Diseño Segunda Curva' value={eyesState.left_dSegundaCurva} onWrite={(event) => { setEyesState({ left_dSegundaCurva: event.target.value }) }} />
                        : <></>
                      }
                      {leftInputs.includes('3') &&
                        <EyesInput title='cantidad' value={eyesState.left_cantidad} onWrite={(event) => { setEyesState({ left_cantidad: event.target.value }) }} />
                      }
                      {leftInputs.includes('4') &&
                        <EyesInput title='c.base' value={eyesState.left_c_base} onWrite={(event) => { setEyesState({ left_c_base: event.target.value }) }} />
                      }

                      {leftInputs.includes('5') &&
                        <EyesInput title='poder' value={eyesState.left_poder} onWrite={(event) => { setEyesState({ left_poder: event.target.value }) }} />
                      }
                      {leftInputs.includes('6') &&
                        <EyesInput title='diametro' value={eyesState.left_diametro} onWrite={(event) => { setEyesState({ left_diametro: event.target.value }) }} />
                      }
                      {leftInputs.includes('7') &&
                        <EyesInput title='Z.O' value={eyesState.left_ZO} onWrite={(event) => { setEyesState({ left_ZO: event.target.value }) }} />
                      }
                      {leftInputs.includes('8') &&
                        <EyesInput title='Adicion' value={eyesState.left_adicion} onWrite={(event) => { setEyesState({ left_adicion: event.target.value }) }} />
                      }
                      {leftInputs.includes('9') &&
                        <EyesInput title='cilindro' value={eyesState.left_cilindro} onWrite={(event) => { setEyesState({ left_cilindro: event.target.value }) }} />
                      }
                      {leftInputs.includes('10') &&
                        <EyesInput title='grados' value={eyesState.left_grados} onWrite={(event) => { setEyesState({ left_grados: event.target.value }) }} />
                      }
                      {leftInputs.includes('11') &&
                        <EyesInput title='Periferia 1' value={eyesState.left_periferia1} onWrite={(event) => { setEyesState({ left_periferia1: event.target.value }) }} />
                      }
                      {leftInputs.includes('12') &&
                        <EyesInput title='Periferia 2' value={eyesState.left_periferia2} onWrite={(event) => { setEyesState({ left_periferia2: event.target.value }) }} />
                      }
                      {leftInputs.includes('13') &&
                        <EyesInput title='cpp' value={eyesState.left_cpp} onWrite={(event) => { setEyesState({ left_cpp: event.target.value }) }} />
                      }
                      {leftInputs.includes('14') &&
                        <EyesInput title='observaciones' type="textarea" value={eyesState.left_observaciones} onWrite={(event) => { setEyesState({ left_observaciones: event.target.value }) }} />
                      }
                      {leftInputs.includes('15') &&
                        <EyesInput title='Curva Invertida' value={eyesState.left_cInvertida} onWrite={(event) => { setEyesState({ left_cInvertida: event.target.value }) }} />
                      }
                      {leftInputs.includes('16') &&
                        <EyesInput title='Curva de Alineamiento' value={eyesState.left_cAlineamiento} onWrite={(event) => { setEyesState({ left_cAlineamiento: event.target.value }) }} />
                      }
                      {leftInputs.includes('17') &&
                        <EyesInput title='Curva de Apertura' value={eyesState.left_cApertura} onWrite={(event) => { setEyesState({ left_cApertura: event.target.value }) }} />
                      }
                      {leftInputs.includes('18') &&
                        <EyesInput title='Z.O Curva Base' value={eyesState.left_ZOcBase} onWrite={(event) => { setEyesState({ left_ZOcBase: event.target.value }) }} />
                      }
                      {leftInputs.includes('19') &&
                        <EyesInput title='Z.O Curva Invertida' value={eyesState.left_ZOcInvertida} onWrite={(event) => { setEyesState({ left_ZOcInvertida: event.target.value }) }} />
                      }
                      {leftInputs.includes('20') &&
                        <EyesInput title='Z.O Curva de Alineamiento' value={eyesState.left_ZOcAlineamiento} onWrite={(event) => { setEyesState({ left_ZOcAlineamiento: event.target.value }) }} />
                      }
                      {leftInputs.includes('21') &&
                        <EyesInput title='Z.O Curva de Apertura' value={eyesState.left_ZOcApertura} onWrite={(event) => { setEyesState({ left_ZOcApertura: event.target.value }) }} />
                      }

                    </div>
                    <div className="col-span-full flex items-center justify-end mt-5 gap-2">
                      <button type="button" onClick={() => { setState({ orderStep: 1, showLeftEyeForm: false, showRightEyeForm: false, }) }} className="group flex items-center justify-center gap-2 rounded-lg border border-gray-400 bg-gray-400 px-3 py-2 transition-colors hover:bg-transparent focus:outline-none focus:ring">
                        <span className="capitalize text-sm font-medium text-white transition-colors group-hover:text-gray-400 group-active:text-gray-400">
                          volver
                        </span>
                        <span className="flex-shrink-0 rounded-full border border-current bg-white p-2 text-gray-400 group-active:text-gray-400">
                          <svg className="h-3 w-3 rotate-180" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path className="fill-green-500" strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M17 8l4 4m0 0l-4 4m4-4H3" />
                          </svg>
                        </span>
                      </button>
                      <button onClick={(event) => { validateLeftEye(event) }} className="group flex items-center justify-center gap-2 rounded-lg border border-green-500 bg-green-500 px-3 py-2 transition-colors hover:bg-transparent focus:outline-none focus:ring">
                        <span className="capitalize text-sm font-medium text-white transition-colors group-hover:text-green-500 group-active:text-green-500">
                          siguiente
                        </span>
                        <span className="flex-shrink-0 rounded-full border border-current bg-white p-2 text-green-500 group-active:text-green-500">
                          <svg className="h-3 w-3" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path className="fill-green-500" strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M17 8l4 4m0 0l-4 4m4-4H3" />
                          </svg>
                        </span>
                      </button>
                    </div>
                  </>
                }
                {state.showRightEyeForm == true &&
                  <>
                    <div className="flex flex-col gap-y-3">
                      <div className="">
                        <p className="font-medium mt-5 text-slate-600 text-center border-b-[1px] pb-3 border-slate-200">Ojo derecho</p>
                      </div>
                      <div>
                        <span className="first-letter:capitalize">Tipo de lente</span>
                        <Listbox value={rightLensTypeSelected} onChange={setRightLensTypesSelected}>
                          <div className="relative">
                            <Listbox.Button className="relative h-12 mt-2 w-full rounded-lg border-gray-200 border-[1px] p-3 text-sm text-left focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-sm">
                              <span className={`block truncate ${rightLensTypeSelected.id_typeLens === '0' && "text-gray-500"}`}>{rightLensTypeSelected.name_typeLens}</span>
                              <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                                <ChevronUpDownIcon
                                  className="h-5 w-5 text-gray-400"
                                  aria-hidden="true"
                                />
                              </span>
                            </Listbox.Button>
                            <Transition
                              as={Fragment}
                              leave="transition ease-in duration-100"
                              leaveFrom="opacity-100"
                              leaveTo="opacity-0"
                            >
                              <Listbox.Options className="z-40 absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                {lensTypes.map((lens: { name_typeLens: string }, lensTypesIdx: number) => (
                                  <Listbox.Option
                                    onClick={() => { setRightReferenceSelected(lensReferences[0]); setRightColorLens(lensColors[0]) }}
                                    key={lensTypesIdx}
                                    className={({ active }) =>
                                      `relative cursor-default select-none py-2 pl-10 pr-4 ${active ? 'bg-blue-600 text-white' : 'text-gray-900'
                                      }`
                                    }
                                    value={lens}
                                  >
                                    {({ selected }) => (
                                      <>
                                        <span
                                          className={`block truncate ${selected ? 'font-medium' : 'font-normal'
                                            }`}
                                        >
                                          {lens.name_typeLens}
                                        </span>
                                        {selected ? (
                                          <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-indigo-600">
                                            <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                          </span>
                                        ) : null}
                                      </>
                                    )}
                                  </Listbox.Option>
                                ))}
                              </Listbox.Options>
                            </Transition>
                          </div>
                        </Listbox>
                      </div>
                      <div>
                        <span className="first-letter:capitalize">Referencia</span>
                        <Listbox value={rightReferenceSelected} onChange={setRightReferenceSelected}>
                          <div className="relative">
                            <Listbox.Button className="relative h-12 mt-2 w-full rounded-lg border-gray-200 border-[1px] p-3 text-sm text-left focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-sm">
                              <span className={`block truncate ${rightReferenceSelected.id_typeLens === '0' && "text-gray-500"}`}>{rightReferenceSelected.name_lens}</span>
                              <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                                <ChevronUpDownIcon
                                  className="h-5 w-5 text-gray-400"
                                  aria-hidden="true"
                                />
                              </span>
                            </Listbox.Button>
                            <Transition
                              as={Fragment}
                              leave="transition ease-in duration-100"
                              leaveFrom="opacity-100"
                              leaveTo="opacity-0"
                            >
                              <Listbox.Options className="z-40 absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                {lensReferences.map((lens: { id_lens: string, name_lens: string, id_typeLens: string }, lensIdx: number) => {
                                  if (lens.id_typeLens === rightLensTypeSelected.id_typeLens) {
                                    return (
                                      <Listbox.Option
                                        key={lensIdx}
                                        onClick={() => { calcColorLength(lens, 'right'); resetInputs('right'); }}
                                        className={({ active }) =>
                                          `relative cursor-default select-none py-2 pl-10 pr-4 ${active ? 'bg-blue-600 text-white' : 'text-gray-900'
                                          }`
                                        }
                                        value={lens}
                                      >
                                        {({ selected }) => (
                                          <>
                                            <span
                                              className={`block truncate ${selected ? 'font-medium' : 'font-normal'
                                                }`}
                                            >
                                              {lens.name_lens}
                                            </span>
                                            {selected ? (
                                              <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-indigo-600">
                                                <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                              </span>
                                            ) : null}
                                          </>
                                        )}
                                      </Listbox.Option>
                                    )
                                  }
                                })}
                              </Listbox.Options>
                            </Transition>
                          </div>
                        </Listbox>
                      </div>
                      {rightInputs.includes('1') &&
                        <div>
                          <span className="first-letter:capitalize">Color</span>
                          <Listbox value={rightColorLens} onChange={setRightColorLens}>
                            <div className="relative">
                              <Listbox.Button className="relative h-12 mt-2 w-full rounded-lg border-gray-200 border-[1px] p-3 text-sm text-left focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-sm">
                                <span className={`block truncate ${rightColorLens.id_colorLens === '0' && "text-gray-500"}`}>{colorsLength.right === 0 ? 'No hay colores disponibles' : rightColorLens.name_colorLens}</span>
                                <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                                  <ChevronUpDownIcon
                                    className="h-5 w-5 text-gray-400"
                                    aria-hidden="true"
                                  />
                                </span>
                              </Listbox.Button>
                              <Transition
                                as={Fragment}
                                leave="transition ease-in duration-100"
                                leaveFrom="opacity-100"
                                leaveTo="opacity-0"
                              >
                                <Listbox.Options className="z-40 absolute mt-1 max-h-60 w-full overflow-y-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                  {lensColors.map((lens: { id_colorLens: string, name_colorLens: string, id_lens: string }, lensIdx: number) => {
                                    if (lens.id_lens === rightReferenceSelected.id_lens) {
                                      return (
                                        <Listbox.Option
                                          key={lensIdx}
                                          className={({ active }) =>
                                            `relative cursor-default select-none py-2 pl-10 pr-4 ${active ? 'bg-blue-600 text-white' : 'text-gray-900'
                                            }`
                                          }
                                          value={lens}
                                        >
                                          {({ selected }) => (
                                            <>
                                              <span
                                                className={`block truncate ${selected ? 'font-medium' : 'font-normal'
                                                  }`}
                                              >
                                                {lens.name_colorLens}
                                              </span>
                                              {selected ? (
                                                <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-indigo-600">
                                                  <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                                </span>
                                              ) : null}
                                            </>
                                          )}
                                        </Listbox.Option>
                                      )
                                    } else {
                                      return null;
                                    }
                                  })}
                                </Listbox.Options>
                              </Transition>
                            </div>
                          </Listbox>
                        </div>
                      }
                      {rightInputs.includes('2') &&
                        // <EyesInput title='diseño' value={eyesState.right_design} onWrite={(event) => { setEyesState({ right_design: event.target.value }) }} />
                        <div>
                          <span className="first-letter:capitalize">Diseño</span>
                          <Listbox value={rightDesignSelected} onChange={setRightDesignSelected}>
                            <div className="relative">
                              <Listbox.Button className="relative h-12 mt-2 w-full rounded-lg border-gray-200 border-[1px] p-3 text-sm text-left focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-sm">
                                <span className={`block truncate ${rightDesignSelected.id_design === '0' && "text-gray-500"}`}>{rightDesignSelected.design_name}</span>
                                <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                                  <ChevronUpDownIcon
                                    className="h-5 w-5 text-gray-400"
                                    aria-hidden="true"
                                  />
                                </span>
                              </Listbox.Button>
                              <Transition
                                as={Fragment}
                                leave="transition ease-in duration-100"
                                leaveFrom="opacity-100"
                                leaveTo="opacity-0"
                              >
                                <Listbox.Options className="z-40 absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                  {lensDesign.map((lens: { design_name: string }, lensDesignIdx: number) => (
                                    <Listbox.Option
                                      key={lensDesignIdx}
                                      onClick={() => { setRightDesignSelected(lensDesign[0]) }}
                                      className={({ active }) =>
                                        `relative cursor-default select-none py-2 pl-10 pr-4 ${active ? 'bg-blue-600 text-white' : 'text-gray-900'
                                        }`
                                      }
                                      value={lens}
                                    >
                                      {({ selected }) => (
                                        <>
                                          <span
                                            className={`block truncate ${selected ? 'font-medium' : 'font-normal'
                                              }`}
                                          >
                                            {lens.design_name}
                                          </span>
                                          {selected ? (
                                            <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-indigo-600">
                                              <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                            </span>
                                          ) : null}
                                        </>
                                      )}
                                    </Listbox.Option>
                                  ))}
                                </Listbox.Options>
                              </Transition>
                            </div>
                          </Listbox>
                        </div>
                      }
                      {parseInt(rightDesignSelected.id_design) === 3 &&
                        <EyesInput title='Diseño Z.O' value={eyesState.right_dZO} onWrite={(event) => { setEyesState({ right_dZO: event.target.value }) }} />
                      }
                      {parseInt(rightDesignSelected.id_design) === 3 || parseInt(rightDesignSelected.id_design) === 4 ?
                        <EyesInput title='Diseño Segunda Curva' value={eyesState.right_dSegundaCurva} onWrite={(event) => { setEyesState({ right_dSegundaCurva: event.target.value }) }} />
                        : <></>
                      }
                      {rightInputs.includes('3') &&
                        <EyesInput title='cantidad' value={eyesState.right_cantidad} onWrite={(event) => { setEyesState({ right_cantidad: event.target.value }) }} />
                      }
                      {rightInputs.includes('4') &&
                        <EyesInput title='c.base' value={eyesState.right_c_base} onWrite={(event) => { setEyesState({ right_c_base: event.target.value }) }} />
                      }
                      {rightInputs.includes('5') &&
                        <EyesInput title='poder' value={eyesState.right_poder} onWrite={(event) => { setEyesState({ right_poder: event.target.value }) }} />
                      }
                      {rightInputs.includes('6') &&
                        <EyesInput title='diametro' value={eyesState.right_diametro} onWrite={(event) => { setEyesState({ right_diametro: event.target.value }) }} />

                      }
                      {rightInputs.includes('7') &&
                        <EyesInput title='Z.O' value={eyesState.right_ZO} onWrite={(event) => { setEyesState({ right_ZO: event.target.value }) }} />
                      }
                      {rightInputs.includes('8') &&
                        <EyesInput title='Adicion' value={eyesState.right_adicion} onWrite={(event) => { setEyesState({ right_adicion: event.target.value }) }} />
                      }
                      {rightInputs.includes('9') &&
                        <EyesInput title='cilindro' value={eyesState.right_cilindro} onWrite={(event) => { setEyesState({ right_cilindro: event.target.value }) }} />
                      }
                      {rightInputs.includes('10') &&
                        <EyesInput title='grados' value={eyesState.right_grados} onWrite={(event) => { setEyesState({ right_grados: event.target.value }) }} />
                      }
                      {rightInputs.includes('11') &&
                        <EyesInput title='Periferia 1' value={eyesState.right_periferia1} onWrite={(event) => { setEyesState({ right_periferia1: event.target.value }) }} />
                      }
                      {rightInputs.includes('12') &&
                        <EyesInput title='Periferia 2' value={eyesState.right_periferia2} onWrite={(event) => { setEyesState({ right_periferia2: event.target.value }) }} />
                      }
                      {/* ewe */}
                      {rightInputs.includes('13') &&
                        <EyesInput title='cpp' value={eyesState.right_cpp} onWrite={(event) => { setEyesState({ right_cpp: event.target.value }) }} />
                      }
                      {rightInputs.includes('14') &&
                        <EyesInput title='observaciones' type="textarea" value={eyesState.right_observaciones} onWrite={(event) => { setEyesState({ right_observaciones: event.target.value }) }} />
                      }
                      {rightInputs.includes('15') &&
                        <EyesInput title='Curva Invertida' value={eyesState.right_cInvertida} onWrite={(event) => { setEyesState({ right_cInvertida: event.target.value }) }} />
                      }
                      {rightInputs.includes('16') &&
                        <EyesInput title='Curva de Alineamiento' value={eyesState.right_cAlineamiento} onWrite={(event) => { setEyesState({ right_cAlineamiento: event.target.value }) }} />
                      }
                      {rightInputs.includes('17') &&
                        <EyesInput title='Curva de Apertura' value={eyesState.right_cApertura} onWrite={(event) => { setEyesState({ right_cApertura: event.target.value }) }} />
                      }

                      {rightInputs.includes('18') &&
                        <EyesInput title='Z.O Curva Base' value={eyesState.right_ZOcBase} onWrite={(event) => { setEyesState({ right_ZOcBase: event.target.value }) }} />
                      }
                      {rightInputs.includes('19') &&
                        <EyesInput title='Z.O Curva Invertida' value={eyesState.right_ZOcInvertida} onWrite={(event) => { setEyesState({ right_ZOcInvertida: event.target.value }) }} />
                      }
                      {rightInputs.includes('20') &&
                        <EyesInput title='Z.O Curva de Alineamiento' value={eyesState.right_ZOcAlineamiento} onWrite={(event) => { setEyesState({ right_ZOcAlineamiento: event.target.value }) }} />
                      }
                      {rightInputs.includes('21') &&
                        <EyesInput title='Z.O Curva de Apertura' value={eyesState.right_ZOcApertura} onWrite={(event) => { setEyesState({ right_ZOcApertura: event.target.value }) }} />
                      }
                    </div>
                    <div className="col-span-full flex items-center justify-end mt-5 gap-2">
                      <button type="button" onClick={() => { setState({ showLeftEyeForm: true, showRightEyeForm: false, }) }} className="group flex items-center justify-center gap-2 rounded-lg border border-gray-400 bg-gray-400 px-3 py-2 transition-colors hover:bg-transparent focus:outline-none focus:ring">
                        <span className="capitalize text-sm font-medium text-white transition-colors group-hover:text-gray-400 group-active:text-gray-400">
                          volver
                        </span>
                        <span className="flex-shrink-0 rounded-full border border-current bg-white p-2 text-gray-400 group-active:text-gray-400">
                          <svg className="h-3 w-3 rotate-180" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path className="fill-green-500" strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M17 8l4 4m0 0l-4 4m4-4H3" />
                          </svg>
                        </span>
                      </button>
                      <button onClick={(event) => { goToOrderStep3(event) }} className="group flex items-center justify-center gap-2 rounded-lg border border-green-500 bg-green-500 px-3 py-2 transition-colors hover:bg-transparent focus:outline-none focus:ring">
                        <span className="capitalize text-sm font-medium text-white transition-colors group-hover:text-green-500 group-active:text-green-500">
                          siguiente
                        </span>
                        <span className="flex-shrink-0 rounded-full border border-current bg-white p-2 text-green-500 group-active:text-green-500">
                          <svg className="h-3 w-3" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path className="fill-green-500" strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M17 8l4 4m0 0l-4 4m4-4H3" />
                          </svg>
                        </span>
                      </button>
                    </div>
                  </>
                }
              </div>
            }
            {state.orderStep === 3 && state.waitingForResponse === false &&
              <div className="bg-white p-4 md:p-10 z-40 rounded-lg grid max-w-[1000px] w-full grid-cols-1 md:grid-cols-4 gap-x-4 gap-y-3 max-h-[90vh] overflow-y-auto">
                <div className="flex flex-col gap-y-3 md:col-start-1 md:col-end-6 border-b-[1px] pb-3 border-slate-100">
                  <div className="breal-all">
                    <p className="text-xl mb-2 text-slate-500 font-black">Verifica que los datos de el pedido esten correctos</p>
                    <p className="text-slate-600">Presiona el boton de <strong>Confirmar</strong> para enviar el pedido</p>
                  </div>
                </div>
                <div className="flex flex-col gap-y-3 md:col-start-1 md:col-end-6 border-b-[1px] pb-3 border-slate-200">
                  <div className="break-words">
                    <strong className="mr-2">Nombre de quien realiza el pedido:</strong><span>{eyesState.quienRealizaElPedido}</span>
                  </div>
                  <div className="break-words">
                    <strong className="mr-2">Nombre del paciente:</strong><span>{eyesState.nombreDelPaciente}</span>
                  </div>
                  <div className="break-words">
                    <strong className="mr-2">Numero de documento del paciente:</strong><span>{eyesState.numeroDeDocumentoDelPaciente}</span>
                  </div>
                  <div className="break-words">
                    <strong className="mr-2">Nombre del optometra u oftalmólogo:</strong><span>{eyesState.nombreDelDoctor}</span>
                  </div>
                  <div className="break-words">
                    <strong className="mr-2">Tarjeta profesional:</strong><span>{eyesState.tarjetaProfesional}</span>
                  </div>
                  <div className="break-words">
                    <strong className="mr-2">Ciudad:</strong><span>{city?.name ? city.name : ''}</span>
                  </div>
                  <div className="break-words">
                    <strong className="mr-2">Direccion:</strong><span>{eyesState.address ? eyesState.address : ''}</span>
                  </div>
                </div>
                <div className="flex flex-col md:col-start-1 md:col-end-3 gap-y-3">
                  <div>
                    <p className="text-slate-600 border-b-[1px] pb-3 border-slate-200 text-center">Ojo izquierdo</p>
                  </div>
                  <div className="break-words">
                    <strong className="mr-2">Tipo de lente:</strong><span>{leftLensTypeSelected.name_typeLens === 'Indique el tipo de lente' ? 'N/A' : leftLensTypeSelected.name_typeLens}</span>
                  </div>
                  <div className="break-words">
                    <strong className="mr-2">Referencia:</strong><span>{leftReferenceSelected.name_lens === 'indique la referencia del lente' ? 'N/A' : leftReferenceSelected.name_lens}</span>
                  </div>
                  {leftInputs.includes('1') &&
                    <div className="break-words">
                      <strong className="mr-2">Color:</strong><span>{leftColorLens.name_colorLens === 'Indique el color del lente' ? 'N/A' : leftColorLens.name_colorLens}</span>
                    </div>
                  }
                  {leftInputs.includes('2') &&
                    <div className="break-words">
                      <strong className="mr-2">Diseño:</strong><span>{leftDesignSelected.id_design === '0' ? 'N/A' : leftDesignSelected.design_name}</span>
                    </div>
                  }
                  {parseInt(leftDesignSelected.id_design) === 3 &&
                    <div className="break-words">
                      <strong className="mr-2">Diseño Z.O:</strong><span>{eyesState.left_dZO}</span>
                    </div>
                  }
                  {parseInt(leftDesignSelected.id_design) === 3 || parseInt(leftDesignSelected.id_design) === 4 ?
                    <div className="break-words">
                      <strong className="mr-2">Diseño Segunda Curva:</strong><span>{eyesState.left_dSegundaCurva}</span>
                    </div>
                    : <></>
                  }
                  {leftInputs.includes('3') &&
                    <div className="break-words">
                      <strong className="mr-2">Cantidad:</strong><span>{eyesState.left_cantidad}</span>
                    </div>
                  }
                  {leftInputs.includes('4') &&
                    <div className="break-words">
                      <strong className="mr-2">C.base:</strong><span>{eyesState.left_c_base}</span>
                    </div>
                  }
                  {leftInputs.includes('5') &&
                    <div className="break-words">
                      <strong className="mr-2">poder:</strong><span>{eyesState.left_poder}</span>
                    </div>
                  }
                  {leftInputs.includes('6') &&
                    <div className="break-words">
                      <strong className="mr-2">diametro:</strong><span>{eyesState.left_diametro}</span>
                    </div>
                  }
                  {leftInputs.includes('7') &&
                    <div className="break-words">
                      <strong className="mr-2">Z.O:</strong><span>{eyesState.left_ZO}</span>
                    </div>
                  }
                  {leftInputs.includes('8') &&
                    <div className="break-words">
                      <strong className="mr-2">Adicion:</strong><span>{eyesState.left_adicion}</span>
                    </div>
                  }
                  {leftInputs.includes('9') &&
                    <div className="break-words">
                      <strong className="mr-2">cilindro:</strong><span>{eyesState.left_cilindro}</span>
                    </div>
                  }

                  {leftInputs.includes('10') &&
                    <div className="break-words">
                      <strong className="mr-2">Grados:</strong><span>{eyesState.left_grados}</span>
                    </div>
                  }

                  {leftInputs.includes('11') &&
                    <div className="break-words">
                      <strong className="mr-2">Periferia 1:</strong><span>{eyesState.left_periferia1}</span>
                    </div>
                  }

                  {leftInputs.includes('12') &&
                    <div className="break-words">
                      <strong className="mr-2">Periferia 2:</strong><span>{eyesState.left_periferia2}</span>
                    </div>
                  }

                  {leftInputs.includes('13') &&
                    <div className="break-words">
                      <strong className="mr-2">cpp:</strong><span>{eyesState.left_cpp}</span>
                    </div>
                  }

                  {leftInputs.includes('14') &&
                    <div className="break-words">
                      <strong className="mr-2">Observaciones:</strong><span>{eyesState.left_observaciones}</span>
                    </div>
                  }

                  {leftInputs.includes('15') &&
                    <div className="break-words">
                      <strong className="mr-2">Curva Invertida:</strong><span>{eyesState.left_cInvertida}</span>
                    </div>
                  }

                  {leftInputs.includes('16') &&
                    <div className="break-words">
                      <strong className="mr-2">Curva de Alineamiento:</strong><span>{eyesState.left_cAlineamiento}</span>
                    </div>
                  }

                  {leftInputs.includes('17') &&
                    <div className="break-words">
                      <strong className="mr-2">Curva de Apertura:</strong><span>{eyesState.left_cApertura}</span>
                    </div>
                  }

                  {leftInputs.includes('18') &&
                    <div className="break-words">
                      <strong className="mr-2">Z.O Curva de Base:</strong><span>{eyesState.left_ZOcBase}</span>
                    </div>
                  }

                  {leftInputs.includes('19') &&
                    <div className="break-words">
                      <strong className="mr-2">Z.O Curva Invertidas:</strong><span>{eyesState.left_ZOcInvertida}</span>
                    </div>
                  }

                  {leftInputs.includes('20') &&
                    <div className="break-words">
                      <strong className="mr-2">Z.O Curva de Alineamiento:</strong><span>{eyesState.left_ZOcAlineamiento}</span>
                    </div>
                  }
                  {leftInputs.includes('21') &&
                    <div className="break-words">
                      <strong className="mr-2">Z.O Curva de Apertura:</strong><span>{eyesState.left_ZOcApertura}</span>
                    </div>
                  }
                </div>
                <div className="flex flex-col gap-y-3 md:col-start-3 md:col-end-6">
                  <div>
                    <p className="text-slate-600 border-b-[1px] pb-3 border-slate-200 text-center">Ojo derecho</p>
                  </div>
                  <div className="break-words">
                    <strong className="mr-2">Tipo de lente:</strong><span>{rightLensTypeSelected.name_typeLens === 'Indique el tipo de lente' ? 'N/A' : rightLensTypeSelected.name_typeLens}</span>
                  </div>
                  <div className="break-words">
                    <strong className="mr-2">Referencia:</strong><span>{rightReferenceSelected.name_lens === 'indique la referencia del lente' ? 'N/A' : rightReferenceSelected.name_lens}</span>
                  </div>
                  {rightInputs.includes('1') &&
                    <div className="break-words">
                      <strong className="mr-2">Color:</strong><span>{rightColorLens.name_colorLens === 'Indique el color del lente' ? 'N/A' : rightColorLens.name_colorLens}</span>
                    </div>
                  }
                  {rightInputs.includes('2') &&
                    <div className="break-words">
                      <strong className="mr-2">Diseño:</strong><span>{rightDesignSelected.id_design === '0' ? 'N/A' : rightDesignSelected.design_name}</span>
                    </div>
                  }
                  {parseInt(rightDesignSelected.id_design) === 3 &&
                    <div className="break-words">
                      <strong className="mr-2">Diseño Z.O:</strong><span>{eyesState.right_dZO}</span>
                    </div>
                  }
                  {parseInt(rightDesignSelected.id_design) === 3 || parseInt(rightDesignSelected.id_design) === 4 ?
                    <div className="break-words">
                      <strong className="mr-2">Diseño Segunda Curva:</strong><span>{eyesState.right_dSegundaCurva}</span>
                    </div>
                    : <></>
                  }
                  {rightInputs.includes('3') &&
                    <div className="break-words">
                      <strong className="mr-2">Cantidad:</strong><span>{eyesState.right_cantidad}</span>
                    </div>
                  }
                  {rightInputs.includes('4') &&
                    <div className="break-words">
                      <strong className="mr-2">C.base:</strong><span>{eyesState.right_c_base}</span>
                    </div>
                  }
                  {rightInputs.includes('5') &&
                    <div className="break-words">
                      <strong className="mr-2">poder:</strong><span>{eyesState.right_poder}</span>
                    </div>
                  }
                  {rightInputs.includes('6') &&
                    <div className="break-words">
                      <strong className="mr-2">diametro:</strong><span>{eyesState.right_diametro}</span>
                    </div>
                  }
                  {rightInputs.includes('7') &&
                    <div className="break-words">
                      <strong className="mr-2">Z.O:</strong><span>{eyesState.right_ZO}</span>
                    </div>
                  }
                  {rightInputs.includes('8') &&
                    <div className="break-words">
                      <strong className="mr-2">Adicion:</strong><span>{eyesState.right_adicion}</span>
                    </div>
                  }
                  {rightInputs.includes('9') &&
                    <div className="break-words">
                      <strong className="mr-2">cilindro:</strong><span>{eyesState.right_cilindro}</span>
                    </div>
                  }

                  {rightInputs.includes('10') &&
                    <div className="break-words">
                      <strong className="mr-2">Grados:</strong><span>{eyesState.right_grados}</span>
                    </div>
                  }

                  {rightInputs.includes('11') &&
                    <div className="break-words">
                      <strong className="mr-2">Periferia 1:</strong><span>{eyesState.right_periferia1}</span>
                    </div>
                  }

                  {rightInputs.includes('12') &&
                    <div className="break-words">
                      <strong className="mr-2">Periferia 2:</strong><span>{eyesState.right_periferia2}</span>
                    </div>
                  }

                  {rightInputs.includes('13') &&
                    <div className="break-words">
                      <strong className="mr-2">cpp:</strong><span>{eyesState.right_cpp}</span>
                    </div>
                  }

                  {rightInputs.includes('14') &&
                    <div className="break-words">
                      <strong className="mr-2">Observaciones:</strong><span>{eyesState.right_observaciones}</span>
                    </div>
                  }

                  {rightInputs.includes('15') &&
                    <div className="break-words">
                      <strong className="mr-2">Curva Invertida:</strong><span>{eyesState.right_cInvertida}</span>
                    </div>
                  }

                  {rightInputs.includes('16') &&
                    <div className="break-words">
                      <strong className="mr-2">Curva de Alineamiento:</strong><span>{eyesState.right_cAlineamiento}</span>
                    </div>
                  }

                  {rightInputs.includes('17') &&
                    <div className="break-words">
                      <strong className="mr-2">Curva de Apertura:</strong><span>{eyesState.right_cApertura}</span>
                    </div>
                  }

                  {rightInputs.includes('18') &&
                    <div className="break-words">
                      <strong className="mr-2">Z.O Curva de Base:</strong><span>{eyesState.right_ZOcBase}</span>
                    </div>
                  }

                  {rightInputs.includes('19') &&
                    <div className="break-words">
                      <strong className="mr-2">Z.O Curva Invertidas:</strong><span>{eyesState.right_ZOcInvertida}</span>
                    </div>
                  }

                  {rightInputs.includes('20') &&
                    <div className="break-words">
                      <strong className="mr-2">Z.O Curva de Alineamiento:</strong><span>{eyesState.right_ZOcAlineamiento}</span>
                    </div>
                  }
                  {rightInputs.includes('21') &&
                    <div className="break-words">
                      <strong className="mr-2">Z.O Curva de Apertura:</strong><span>{eyesState.right_ZOcApertura}</span>
                    </div>
                  }
                </div>
                <div className="col-span-full flex items-center justify-end mt-5 gap-2">
                  <button type="button" onClick={() => { setState({ orderStep: 2, showLeftEyeForm: false, showRightEyeForm: true }) }} className="group flex items-center justify-center gap-2 rounded-lg border border-gray-400 bg-gray-400 px-3 py-2 transition-colors hover:bg-transparent focus:outline-none focus:ring">
                    <span className="capitalize text-sm font-medium text-white transition-colors group-hover:text-gray-400 group-active:text-gray-400">
                      volver
                    </span>
                    <span className="flex-shrink-0 rounded-full border border-current bg-white p-2 text-gray-400 group-active:text-gray-400">
                      <svg className="h-3 w-3 rotate-180" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path className="fill-green-500" strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M17 8l4 4m0 0l-4 4m4-4H3" />
                      </svg>
                    </span>
                  </button>
                  <button onClick={(event) => { submitCreateOrder(event) }} className="group flex items-center justify-center gap-2 rounded-lg border border-green-500 bg-green-500 px-3 py-2 transition-colors hover:bg-transparent focus:outline-none focus:ring">
                    <span className="capitalize text-sm font-medium text-white transition-colors group-hover:text-green-500 group-active:text-green-500">
                      confirmar
                    </span>
                    <span className="flex-shrink-0 rounded-full border border-current bg-white p-2 text-green-500 group-active:text-green-500">
                      <svg className="h-3 w-3" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path className="fill-green-500" strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M17 8l4 4m0 0l-4 4m4-4H3" />
                      </svg>
                    </span>
                  </button>
                </div>
              </div>
            }
            {state.waitingForResponse &&
              <Loader />
            }
          </motion.form>
        </>
      }
    </div>
  )
}

const StateBadge = ({ status }: { status?: string }) => {
  if (status === 'aceptado') {
    return (
      <span className="inline-flex items-center justify-center rounded-full bg-emerald-200 min-w-[115px] px-3 py-1 text-emerald-800">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="-ml-1 mr-1.5 h-4 w-4">
          <path strokeLinecap="round" strokeLinejoin="round" d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
        </svg>
        <p className="whitespace-nowrap text-sm">Aceptada</p>
      </span>
    )
  } else if (status === 'revision') {
    return (
      <span className="inline-flex items-center justify-center rounded-full bg-gray-300 min-w-[115px] px-3 py-1 text-slate-700">
        <svg className="-ml-1 mr-1.5 h-4 w-4" viewBox="0 0 22 16" version="1.1">
          <g id="Icons" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g id="Rounded" transform="translate(-747.000000, -2904.000000)">
              <g id="Image" transform="translate(100.000000, 2626.000000)">
                <g id="-Round-/-Image-/-remove_red_eye" transform="translate(646.000000, 274.000000)">
                  <g className="fill-slate-700">
                    <polygon className="fill-transparent" id="Path" points="0 0 24 0 24 24 0 24" />
                    <path d="M12,4.5 C7,4.5 2.73,7.61 1,12 C2.73,16.39 7,19.5 12,19.5 C17,19.5 21.27,16.39 23,12 C21.27,7.61 17,4.5 12,4.5 Z M12,17 C9.24,17 7,14.76 7,12 C7,9.24 9.24,7 12,7 C14.76,7 17,9.24 17,12 C17,14.76 14.76,17 12,17 Z M12,9 C10.34,9 9,10.34 9,12 C9,13.66 10.34,15 12,15 C13.66,15 15,13.66 15,12 C15,10.34 13.66,9 12,9 Z" />
                  </g>
                </g>
              </g>
            </g>
          </g>
        </svg>
        <p className="whitespace-nowrap text-sm">En revision</p>
      </span>
    )
  } else if (status === 'rechazado') {
    return (
      <span className="inline-flex items-center justify-center rounded-full bg-red-100 min-w-[115px] px-3 py-1 text-red-700">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="-ml-1 mr-1.5 h-4 w-4">
          <path strokeLinecap="round" strokeLinejoin="round" d="M8.25 9.75h4.875a2.625 2.625 0 010 5.25H12M8.25 9.75L10.5 7.5M8.25 9.75L10.5 12m9-7.243V21.75l-3.75-1.5-3.75 1.5-3.75-1.5-3.75 1.5V4.757c0-1.108.806-2.057 1.907-2.185a48.507 48.507 0 0111.186 0c1.1.128 1.907 1.077 1.907 2.185z" />
        </svg>
        <p className="whitespace-nowrap text-sm">Rechazado</p>
      </span>
    )
  } else if (status === 'completado') {
    return (
      <span className="inline-flex items-center justify-center rounded-full bg-amber-200 min-w-[115px] px-3 py-1 text-amber-800 outline outline-1 outline-amber-800">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="-ml-1 mr-1.5 h-4 w-4">
          <path d="M12.378 1.602a.75.75 0 00-.756 0L3 6.632l9 5.25 9-5.25-8.622-5.03zM21.75 7.93l-9 5.25v9l8.628-5.032a.75.75 0 00.372-.648V7.93zM11.25 22.18v-9l-9-5.25v8.57a.75.75 0 00.372.648l8.628 5.033z" />
        </svg>

        <p className="whitespace-nowrap text-sm">Completado</p>
      </span>
    )
  } else if (status === 'entregado') {
    return (
      <span className="inline-flex items-center justify-center rounded-full bg-indigo-200 min-w-[115px] px-3 py-1 text-indigo-800 outline outline-1 outline-indigo-800">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="-ml-1 mr-1.5 h-4 w-4">
          <path d="M11.47 1.72a.75.75 0 011.06 0l3 3a.75.75 0 01-1.06 1.06l-1.72-1.72V7.5h-1.5V4.06L9.53 5.78a.75.75 0 01-1.06-1.06l3-3zM11.25 7.5V15a.75.75 0 001.5 0V7.5h3.75a3 3 0 013 3v9a3 3 0 01-3 3h-9a3 3 0 01-3-3v-9a3 3 0 013-3h3.75z" />
        </svg>
        <p className="whitespace-nowrap text-sm">Entregado</p>
      </span>
    )
  } else if (status === 'cancelado') {
    return (
      <span className="inline-flex items-center justify-center rounded-full bg-red-600 min-w-[115px] px-3 py-1 text-white outline outline-1 outline-red-400">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="-ml-1 mr-1.5 h-4 w-4">
          <path fillRule="evenodd" d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25zm-1.72 6.97a.75.75 0 10-1.06 1.06L10.94 12l-1.72 1.72a.75.75 0 101.06 1.06L12 13.06l1.72 1.72a.75.75 0 101.06-1.06L13.06 12l1.72-1.72a.75.75 0 10-1.06-1.06L12 10.94l-1.72-1.72z" clipRule="evenodd" />
        </svg>
        <p className="whitespace-nowrap text-sm">Anulado</p>
      </span>
    )
  } else {
    return <></>
  }
}
interface EyesInputTypes {
  title: string,
  value: string,
  type?: string,
  onWrite: (value: any) => void,
}

const TypeBadge = ({ type }: { type?: string }) => {
  if (type === '1') {
    return (
      <span className="inline-flex items-center justify-center rounded-full bg-sky-200 min-w-[115px] px-3 py-1 text-sky-800">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="-ml-1 mr-1.5 h-4 w-4">
          <path strokeLinecap="round" strokeLinejoin="round" d="M12 7.5h1.5m-1.5 3h1.5m-7.5 3h7.5m-7.5 3h7.5m3-9h3.375c.621 0 1.125.504 1.125 1.125V18a2.25 2.25 0 01-2.25 2.25M16.5 7.5V18a2.25 2.25 0 002.25 2.25M16.5 7.5V4.875c0-.621-.504-1.125-1.125-1.125H4.125C3.504 3.75 3 4.254 3 4.875V18a2.25 2.25 0 002.25 2.25h13.5M6 7.5h3v3H6v-3z" />
        </svg>

        <p className="whitespace-nowrap text-sm">Pedido</p>
      </span>
    )
  } else if (type === '2') {
    return (
      <span className="inline-flex items-center justify-center rounded-full bg-lime-300 min-w-[115px] px-3 py-1 text-lime-700">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="-ml-1 mr-1.5 h-4 w-4">
          <path strokeLinecap="round" strokeLinejoin="round" d="M20.25 7.5l-.625 10.632a2.25 2.25 0 01-2.247 2.118H6.622a2.25 2.25 0 01-2.247-2.118L3.75 7.5M10 11.25h4M3.375 7.5h17.25c.621 0 1.125-.504 1.125-1.125v-1.5c0-.621-.504-1.125-1.125-1.125H3.375c-.621 0-1.125.504-1.125 1.125v1.5c0 .621.504 1.125 1.125 1.125z" />
        </svg>
        <p className="whitespace-nowrap text-sm">Caja</p>
      </span>
    )
  } else if (type === '3') {
    return (
      <span className="inline-flex items-center justify-center rounded-full bg-orange-200 min-w-[115px] px-3 py-1 text-orange-700">

        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="-ml-1 mr-1.5 h-4 w-4">
          <path strokeLinecap="round" strokeLinejoin="round" d="M6 6.878V6a2.25 2.25 0 012.25-2.25h7.5A2.25 2.25 0 0118 6v.878m-12 0c.235-.083.487-.128.75-.128h10.5c.263 0 .515.045.75.128m-12 0A2.25 2.25 0 004.5 9v.878m13.5-3A2.25 2.25 0 0119.5 9v.878m0 0a2.246 2.246 0 00-.75-.128H5.25c-.263 0-.515.045-.75.128m15 0A2.25 2.25 0 0121 12v6a2.25 2.25 0 01-2.25 2.25H5.25A2.25 2.25 0 013 18v-6c0-.98.626-1.813 1.5-2.122" />
        </svg>

        <p className="whitespace-nowrap text-sm">Accesorio</p>
      </span>
    )
  } else {
    return <></>
  }
}
const EyesInput = ({ title, value, onWrite, type = "text" }: EyesInputTypes) => {
  if (type === "textarea") {
    return (
      <div>
        <label className="block text-sm font-medium text-gray-700">
          <span className="capitalize">{title}</span>
          <textarea
            value={value}
            onChange={(event) => { onWrite(event) }}
            className="min-h-[150px] mt-2 w-full rounded-lg border-gray-200 border-[1px] p-3 text-sm"
          />
        </label>
      </div>
    )
  } else {
    return (
      <div>
        <label className="block text-sm font-medium text-gray-700">
          <span className="capitalize">{title}</span>
          <input
            type={type}
            placeholder=""
            value={value}
            onChange={(event) => { onWrite(event) }}
            className="h-12 mt-2 w-full rounded-lg border-gray-200 border-[1px] p-3 text-sm"
          />
        </label>
      </div>
    )
  }
}

export { MyOrders, EyesInput, StateBadge, TypeBadge, };
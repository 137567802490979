import { motion } from "framer-motion"
import { Modal } from "../modal"
import { Fragment, useEffect, useState } from 'react'
import { Combobox, Transition } from '@headlessui/react'
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid'
import { useStateMany } from '../customHooks/useStateMany'
import { API } from '../API'
import { userType } from '../typos'
const SearchClient = ({ state, setState, eyesState, setEyesState, setCity, citysData }: any) => {
  const [searchClientState, setSearchClientState] = useStateMany({
    clients: [
      { id: 0, name: '' },
    ],
    optometrists: [
      { id: 0, name: 'Seleccione un optometra' },
    ],
    showOptometristsListBox: false,
    reload: false,
    loading: false,
    loaded: false,
  })
  const [selected, setSelected] = useState(searchClientState.clients[0])
  const [selectedOptometrists, setSelectedOptometrist] = useState(searchClientState.optometrists[0])
  const [query, setQuery] = useState('')
  const [queryOptometrists, setQueryOptometrists] = useState('')
  // const filteredPeople =
  //   query === ''
  //     ? searchClientState.clients
  //     : searchClientState.clients.filter((person: any) =>
  //       person.name
  //         .toLowerCase()
  //         .replace(/\s+/g, '')
  //         .includes(query.toLowerCase().replace(/\s+/g, ''))
  //     )

  let timer: any;
  let timerInterval = 1000;
  function clearCountdown() {
    clearTimeout(timer);
  }
  function startCountdown() {
    if (query.trim() !== '') {
      clearTimeout(timer);
      timer = setTimeout(sendData, timerInterval);
    }
  }
  function consultClientOptometrists(client: any) {
    let dataToSend = {
      API_KEY: process.env.REACT_APP_API_KEY,
      toSearch: parseInt(client.id),
    }
    setSearchClientState({ loading: true, showOptometristsListBox: false });

    API.post('/?searchClientsOptometrists', {
      ...dataToSend
    }).then((response) => {
      console.log(response, 'responde de searchClientsOptometrists')
      if (response.status === 200) {
        let toSet = response.data.optometrists ? response.data.optometrists : [];
        let optometrists = [
          { id: 0, name: 'Seleccione un optometra' },
          ...toSet,
        ]
        setSearchClientState({ optometrists: optometrists, loading: false, loaded: true, showOptometristsListBox: true });
      } else {
        setSearchClientState({ loading: false, loaded: true, error: true, errorTitle: 'Error al consultar la informacion de los usuarios', errorMessage: 'No se pudo obtener los datos de los usuarios, por favor comunique este error al equipo de desarrollo' })
      }
    }).catch((error) => {
      console.log("Ha ocurrido un error", error)
      setSearchClientState({ loading: false, loaded: true, error: true, errorTitle: 'Error al consultar la informacion de los usuarios', errorMessage: 'No se pudo obtener los datos de los usuarios, por favor comunique este error al equipo de desarrollo' })
    });
  }
  function sendData() {
    if (query.trim() !== '') {
      try {
        let dataToSend = {
          API_KEY: process.env.REACT_APP_API_KEY,
          toSearch: query,
        }
        setSearchClientState({ loading: true });

        API.post('/?searchClients', {
          ...dataToSend
        }).then((response) => {
          console.log(response, 'responde de searchClients')
          if (response.status === 200) {
            let toSet = response.data.profiles ? response.data.profiles : [];
            let profiles = [
              { id: 0, name: '' },
              ...toSet,
            ]
            setSearchClientState({ clients: profiles, loading: false, loaded: true });
          } else {
            setSearchClientState({ loading: false, loaded: true, error: true, errorTitle: 'Error al consultar la informacion de los usuarios', errorMessage: 'No se pudo obtener los datos de los usuarios, por favor comunique este error al equipo de desarrollo' })
          }
        }).catch((error) => {
          console.log("Ha ocurrido un error", error)
          setSearchClientState({ loading: false, loaded: true, error: true, errorTitle: 'Error al consultar la informacion de los usuarios', errorMessage: 'No se pudo obtener los datos de los usuarios, por favor comunique este error al equipo de desarrollo' })
        });
      } catch (error) {
        console.log(error)
      }
    }
  }
  // usa el optometrista o cliente seleccionado y pone sus datos en el estado
  function setClient() {
    if (parseInt(selected.customer_type) === 2) {
      let item = selectedOptometrists;
      // consultClientOptometrists(selected)
      if (item.id !== 0) {
        setEyesState({
          nombreDelDoctor: `${item.name ? item.name : ''} ${item.last_name ? item.last_name : ''}`,
          tarjetaProfesional: item.professional_card ? item.professional_card : '',
          address: item.address ? item.address : '',
          id_client: parseInt(item.id),
        })
        if (item.city !== "" && item.city) {
          let findCity = citysData.findIndex((el: { id: number, name: string }) => el.name === item.city)
          setCity(citysData[findCity])
        } else {
          setCity(citysData[0])
        }
      }
    } else if (parseInt(selected.customer_type) === 1) {
      let item = selected;
      if (item.id !== 0) {
        setEyesState({
          nombreDelDoctor: `${item.name ? item.name : ''} ${item.last_name ? item.last_name : ''}`,
          tarjetaProfesional: item.professional_card ? item.professional_card : '',
          address: item.address ? item.address : '',
          id_client: parseInt(item.id),
        })
        if (item.city !== "" && item.city) {
          let findCity = citysData.findIndex((el: { id: number, name: string }) => el.name === item.city)
          setCity(citysData[findCity])
        } else {
          setCity(citysData[0])
        }
      }
    }
  }
  const filteredOptometrists =
    queryOptometrists === ''
      ? searchClientState.optometrists
      : searchClientState.optometrists.filter((person: any) =>
        person.name
          .toLowerCase()
          .replace(/\s+/g, '')
          .includes(queryOptometrists.toLowerCase().replace(/\s+/g, ''))
      )
  // detecta cuando se selecciona un elemento
  useEffect(() => {
    // verifica si el cliente es tipo empresa
    if (parseInt(selected.customer_type) === 2) {
      setSelectedOptometrist(searchClientState.optometrists[0])
      consultClientOptometrists(selected)
    } else {
      setSearchClientState({ showOptometristsListBox: false });
    }

  }, [selected])
  return (
    <div className="z-50">
      <Modal />
      <motion.div initial={{ y: 50, opacity: 0 }} animate={{ y: 0, opacity: 1 }} transition={{ duration: 0.5 }} className="fixed top-0 left-0 w-full h-full flex justify-center items-center z-60">
        <div className="rounded-lg bg-white p-8 shadow-2xl max-w-lg w-full flex flex-col justify-center min-h-[500px]">
          <p className="text-base font-normal">Seleccione un cliente</p>
          <div className={` flex flex-col gap-y-6`}>
            <div className="">
              <Combobox value={selected} onChange={setSelected}>
                <div className="relative">
                  <div className="relative h-12 mt-2 w-full rounded-lg border-gray-200 border-[1px] text-sm cursor-default overflow-hidden bg-white text-left focus:outline-none sm:text-sm">
                    <Combobox.Input
                      className={`w-full h-12 border-none py-2 pl-3 pr-10 text-sm ${selected.id === 0 ? 'text-gray-500' : 'text-gray-700'} focus:outline-none`}
                      displayValue={(person: { id: number, name: string, last_name: string }) => person.id === 0 ? '' : `${person.name} ${person.last_name ? person.last_name : ''}`}
                      onChange={(event) => { setQuery(event.target.value) }}
                      onKeyDownCapture={() => { clearCountdown() }}
                      onKeyUp={() => { startCountdown() }}
                      placeholder="Buscar cliente..."
                    />

                    {/* {searchClientState.clients.length > 1 && */}
                    <Combobox.Button className="absolute inset-y-0 right-0 flex items-center pl-4 pr-2">
                      <ChevronUpDownIcon
                        className="h-5 w-5 text-gray-400"
                        aria-hidden="true"
                      />
                    </Combobox.Button>
                    {/* } */}
                  </div>
                  <Transition
                    as={Fragment}
                    leave="transition ease-in duration-100"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                    afterLeave={() => setQuery('')}
                  >
                    <Combobox.Options className="fixed z-40 max-w-[450px] mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                      {searchClientState.clients.length === 0 && query !== '' ? (
                        <div className="relative cursor-default select-none py-2 px-4 text-gray-700">
                          Sin coincidencias
                        </div>
                      ) : (
                        searchClientState.clients && searchClientState.clients.length > 1 && searchClientState.loaded === true && searchClientState.loading === false ?
                          searchClientState.clients.map((person: any) => {
                            if (person) {
                              return (
                                <Combobox.Option
                                  key={person.id}
                                  className={({ active }) =>
                                    `relative cursor-default select-none py-2 pl-10 pr-4 ${person.id === 0 && 'hidden'} ${active ? 'bg-blue-600 text-white' : 'text-gray-900'
                                    }`
                                  }
                                  value={person}
                                >
                                  {({ selected, active }) => (
                                    <>
                                      <span
                                        className={`block truncate ${selected ? 'font-medium' : 'font-normal'
                                          }`}
                                      >
                                        {`${person.name} ${person.last_name ? person.last_name : ''}`}
                                      </span>
                                      {selected ? (
                                        <span
                                          className={`absolute inset-y-0 left-0 flex items-center pl-3 ${active ? 'text-white' : 'text-blue-600'
                                            }`}
                                        >
                                          <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                        </span>
                                      ) : null}
                                    </>
                                  )}
                                </Combobox.Option>
                              )
                            }
                          })
                          : <div className="p-3 text-center">
                            <p className="text-gray-600">No se encontraron coincidencias</p>
                          </div>
                      )}
                    </Combobox.Options>
                  </Transition>
                </div>
              </Combobox>
            </div>
            {searchClientState.showOptometristsListBox === true &&
              <div className="">
                <p className="text-base font-normal">Optometras del cliente {selected.name}</p>
                <Combobox value={selectedOptometrists} onChange={setSelectedOptometrist}>
                  <div className="relative">
                    <div className="relative h-12 mt-2 w-full rounded-lg border-gray-200 border-[1px] text-sm cursor-default overflow-hidden bg-white text-left focus:outline-none sm:text-sm">
                      <Combobox.Input
                        className={`w-full h-12 border-none py-2 pl-3 pr-10 text-sm ${selectedOptometrists.id === 0 ? 'text-gray-500' : 'text-gray-700'} focus:outline-none`}
                        displayValue={(person: { id: number, name: string, last_name: string }) => person.id === 0 ? '' : `${person.name} ${person.last_name ? person.last_name : ''}`}
                        onChange={(event) => { setQueryOptometrists(event.target.value) }}
                        placeholder="Seleccione un optometra"
                      />
                      <Combobox.Button className="absolute inset-y-0 right-0 flex items-center pl-4 pr-2">
                        <ChevronUpDownIcon
                          className="h-5 w-5 text-gray-400"
                          aria-hidden="true"
                        />
                      </Combobox.Button>
                      {/* } */}
                    </div>
                    <Transition
                      as={Fragment}
                      leave="transition ease-in duration-100"
                      leaveFrom="opacity-100"
                      leaveTo="opacity-0"
                      afterLeave={() => setQueryOptometrists('')}
                    >
                      <Combobox.Options className="fixed z-40 max-w-[450px] mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                        {filteredOptometrists.length === 0 && query !== '' ? (
                          <div className="relative cursor-default select-none py-2 px-4 text-gray-700">
                            Sin coincidencias
                          </div>
                        ) : (
                          searchClientState.optometrists && searchClientState.optometrists.length > 1 && searchClientState.loaded === true && searchClientState.loading === false ?
                            filteredOptometrists.map((person: any) => {
                              if (person) {
                                return (
                                  <Combobox.Option
                                    key={person.id}
                                    className={({ active }) =>
                                      `relative cursor-default select-none py-2 pl-10 pr-4 ${person.id === 0 && 'hidden'} ${active ? 'bg-blue-600 text-white' : 'text-gray-900'
                                      }`
                                    }
                                    value={person}
                                  >
                                    {({ selected, active }) => (
                                      <>
                                        <span
                                          className={`block truncate ${selected ? 'font-medium' : 'font-normal'
                                            }`}
                                        >
                                          {`${person.name} ${person.last_name ? person.last_name : ''}`}
                                        </span>
                                        {selected ? (
                                          <span
                                            className={`absolute inset-y-0 left-0 flex items-center pl-3 ${active ? 'text-white' : 'text-blue-600'
                                              }`}
                                          >
                                            <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                          </span>
                                        ) : null}
                                      </>
                                    )}
                                  </Combobox.Option>
                                )
                              }
                            })
                            : <div className="p-3 text-center">
                              <p className="text-gray-600">No tiene optometras registrados</p>
                            </div>
                        )}
                      </Combobox.Options>
                    </Transition>
                  </div>
                </Combobox>
              </div>
            }
          </div>
          <div className="flex gap-2 mt-auto">
            <button onClick={() => {
              setClient();
              setState({ showSearchClient: false })
            }} type="button" className="rounded w-full bg-green-500 px-4 py-3 text-sm font-medium text-white hover:text-gray-800 hover:outline outline-offset-0 outline-2 outline-gray-800">
              Seleccionar
            </button>
            <button onClick={() => {
              setState({ showSearchClient: false })
            }} type="button" className="rounded w-full bg-gray-100 px-4 py-3 text-sm font-medium text-gray-700 hover:bg-gray-400 hover:text-white">
              Volver
            </button>
          </div>
        </div>

      </motion.div>
    </div>
  )
}

export { SearchClient }
import { ReactNode } from "react";
interface interfaceTypes {
  children?:JSX.Element | JSX.Element[] | boolean | ReactNode;
}
export function Interface({children}:interfaceTypes):JSX.Element {
  return (
      <div className="ml-[80px] sm:ml-[200px] lg:ml-[275px] w-full min-h-screen p-2 sm:p-5 lg:p-10 flex overflow-x-hidden justify-start flex-col items-center bg-gray-100">
        {children}
      </div>
  )
}

import { motion } from "framer-motion"
import { useStateMany } from "../customHooks/useStateMany";
import { Listbox, Transition } from '@headlessui/react'
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid'
import { API } from "../API"
import { Fragment, useEffect, useState } from 'react'
import { Loader } from "../Loader";
import { ErrorAlert } from "../ErrorAlert";
import { userType } from "../typos";
interface ProfilesTypes {
  user: userType;
  updateUser: (value: object) => void;
}
function Details({ user, updateUser }: ProfilesTypes) {
  // console.log(user,'user')

  // informacion para los select
  const clientTypeData = [
    { name: "Seleccione un tipo", id: 0 },
    { name: "Credito", id: 1 },
    { name: "Pago contra entrega", id: 2 }
  ]
  function findAndSetCity() {
    let index = 0
    if (user.city) {
      index = citysData.findIndex((el: any) => parseInt(el.id) === parseInt(user.city))
    }
    setCity(citysData[index])
  }
  function findAndSetDocument() {
    let index = 0
    if (user.documentType) {
      index = documentTypeData.findIndex((el: any) => parseInt(el.id) === parseInt(user.documentType))
    }
    setDocumentType(documentTypeData[index])
  }
  const [citysData, setCitysData]: any = useState([
    { name: "Seleccion una ciudad", id: 0 },
  ])
  const [documentTypeData, setDocumentTypeData]: any = useState([
    { name: "Seleccion un tipo de documento", id: 0, abbrev: '' },
  ])
  // falta traer esta del backend
  const [clientPayMethodData, setClientsPayMethod] = useState([
    { name: "Seleccione un tipo", id: 0 },
  ])
  const [userTypesData, setUserTypesData] = useState([
    { name: "", id: 0, allow_created_by: '', color: '', level: '' },
  ])
  const [customerTypesData, setCustomerTypeData]: any = useState([
    { name: "", id: 0 },
  ])

  const [city, setCity] = useState(citysData[0])
  const [clientType, setClientType] = useState(clientTypeData[0])
  const [documentType, setDocumentType] = useState(documentTypeData[0])
  const [state, setState] = useStateMany({
    editName: false,
    editUserName:false,
    editLastName:false,
    editPhone: false,
    editCity: false,
    editAddress: false,
    editDocument: false,
    editProfessionalCard: false,
    waitingForResponse: false,
    successAlert: false,
    errorAlert: false,
    errorMessage: '',
    errorTitle: '',
    reload:false,
  })
  const [updateState, setUpdateState] = useStateMany({
    username: user.username,
    name: user.name,
    lastName: user.lastName,
    city: user.city ? user.city : '',
    address: user.address ? user.address : '',
    customerType: user.customerType ? user.customerType : '',
    documentNumber: user.documentNumber ? user.documentNumber : '',
    documentType: user.documentType ? user.documentType : '',
    email: user.email,
    id: user.id,
    permissions: user.permissions,
    phone: user.phone,
    professionalCard: user.professionalCard,
  })
  // submitProfileUpdate('city', 'select', () => { setState({ editCity: false }) }, city) }}
  function submitProfileUpdate(data: any, type: string, executeOnSuccess?: any, value?: any) {
    setState({
      errorAlert: false,
      errorTitle: '',
      errorMessage: '',
    })
    let dataToSend:any = {
      API_KEY: process.env.REACT_APP_API_KEY,
      id: parseInt(user.id),
      email: user.email,
      data: null,
      documentNumber: '',
      type: 'none',
      property: '',

    }
    if (type === 'text') {
      if (updateState[data]) {
        dataToSend = {
          API_KEY: process.env.REACT_APP_API_KEY,
          id: parseInt(user.id),
          email: user.email,
          data: updateState[data],
          documentNumber: '',
          type: 'text',
          property: data,
        }
      } else {
        setState({
          errorAlert: true,
          errorTitle: 'Error: no se permiten campos vacios',
          errorMessage: 'No se permiten campos vacios, por favor rellene los campos para continuar',
        })
        setTimeout(() => {
          setState({
            errorAlert: false,
            errorTitle: '',
            errorMessage: '',
          })
        }, 7000)
        return '';
      }
    }
    if (type === 'select') {
      dataToSend = {
        API_KEY: process.env.REACT_APP_API_KEY,
        id: parseInt(user.id),
        email: user.email,
        data: parseInt(value.id),
        documentNumber: '',
        type: 'select',
        property: data,

      }
    }
    if (type === 'double select') {
      if (updateState.documentNumber) {
        dataToSend = {
          API_KEY: process.env.REACT_APP_API_KEY,
          id: parseInt(user.id),
          email: user.email,
          data: parseInt(value.id),
          documentNumber: updateState.documentNumber,
          type: 'double select',
          property: data,
        }
      } else {
        setState({
          errorAlert: true,
          errorTitle: 'Error: no se permiten campos vacios',
          errorMessage: 'No se permiten campos vacios, por favor rellene los campos para continuar',
        })
        setTimeout(() => {
          setState({
            errorAlert: false,
            errorTitle: '',
            errorMessage: '',
          })
        }, 7000)
        return '';
      }

    }
    // console.log(dataToSend,'data to sennd')
    // return '';
    // se muestra el loading
    setState({ waitingForResponse: true })
    // se envia la consulta
    try {
      API.post('/?updateProfile', {
        ...dataToSend
      }).then((response) => {
        // console.log(response, 'response de update profile')
        setState({ waitingForResponse: false })
        if (response.data.success === 1 && response.status === 200) {
          updateProfile()
          executeOnSuccess()
          setState({ successAlert: true })
          setTimeout(() => {
            setState({ successAlert: false })
          }, 5000)
        } else {
          // error
          setState({
            errorAlert: true,
            errorTitle: 'Error: hubo un error al intentar guardar la informacion',
            errorMessage: 'El servidor respondio de forma inesperada',
          })
          setTimeout(() => {
            setState({
              errorAlert: false,
              errorTitle: '',
              errorMessage: '',
            })
          }, 7000)
        }
      }).catch(function (error) {
        setState({ waitingForResponse: false })

        console.log(error)
        setState({
          errorAlert: true,
          errorTitle: 'Error: No se pudo establecer conexion con el servidor',
          errorMessage: 'error:' + error,
        })
        setTimeout(() => {
          setState({
            errorAlert: false,
            errorTitle: '',
            errorMessage: '',
          })
        }, 7000)
      })
    } catch (error) {
      console.log(error)
      setState({
        errorAlert: true,
        errorTitle: 'Error: No se pudo establecer conexion con el servidor',
        errorMessage: 'error:' + error,
      })
      setTimeout(() => {
        setState({
          errorAlert: false,
          errorTitle: '',
          errorMessage: '',
        })
      }, 7000)
    }
  }
  function updateProfile() {
    let dataToSend = {
      API_KEY: process.env.REACT_APP_API_KEY,
      id: parseInt(user.id),
      email: user.email,
      idUser:user.permissions,
    }
    API.post('/?getProfile', {
      ...dataToSend
    }).then((response) => {
      setState({ waitingForResponse: false })

      // console.log(response, 'respuesta de getProfile')
      if (response.data && response.status === 200) {
        updateUser({
          login: true,
          user: {
            // ...response.data.user,
            ...user,
            username: response.data.username,
            name: response.data.name,
            lastName: response.data.lastName,
            phone: response.data.phone,
            address: response.data.address,
            city: response.data.city,
            customerType: response.data.customerType,
            documentNumber: response.data.documentNumber,
            professionalCard: response.data.professionalCard,
            documentType: response.data.documentType,
            documentAbbrev: response.data.documentAbbrev,
          }
        })
      } else {
        // error
        // console.log("Ha ocurrido un error con la respuesta:", response)
        setState({ waitingForResponse: false })
      }
    }).catch(function (error) {
      console.log(error)
      setState({ waitingForResponse: false })
    })
  }
  useEffect(() => {
    let dataToSend = {
      API_KEY: process.env.REACT_APP_API_KEY
    }
    API.post('/?lens', {
      ...dataToSend
    }).then((response) => {
      // console.log(response,'lens')
      if (response.status === 200 && response.data) {
        let newCitysData = [
          { name: "Seleccion una ciudad", id: 0 },
          ...response.data.citys
        ]
        let newDocumentTypeData = [
          ...response.data.documentType
        ]
        let newCustomerTypesData = [
          ...response.data.customerType
        ]
        let newUserTypesData = [
          ...response.data.userType
        ]
        let newClientsPayMethod = [
          ...response.data.clientPayMethods
        ]
        setCitysData(newCitysData)
        setDocumentTypeData(newDocumentTypeData)
        setCustomerTypeData(newCustomerTypesData)
        setUserTypesData(newUserTypesData)
        setClientsPayMethod(newClientsPayMethod)
      } else {
        setState({ waitingForResponse: false, error: true, errorTitle: 'Ocurrio un error al intentar consultar informacion', errorMessage: 'No se pudo conseguir la informacion necesaria para continuar.' })
      }
    }).catch((error) => {
      console.log(error)
    })
  }, [state.reload])
  // console.log(city, 'city')
  return (
    <>
      {state.waitingForResponse &&
        <div className="w-full fixed h-full z-80 left-0 top-0">
          <Loader
            animate={{ transform: "rotate(45deg) scale(0.8) translate(0px,0px)", opacity: 1, zIndex: 50, left: "2.5rem", bottom: "1.25rem", position: "fixed" }} transition={{ duration: 0.3 }} initial={{ transform: "rotate(45deg) scale(0.8) translate(-50px,50px)", opacity: 0.1, zIndex: 50, left: "2.5rem", bottom: "1.25rem", position: "fixed" }} />
        </div>
      }
      {state.successAlert &&
        <motion.div initial={{ x: '-150%', opacity: 0.5 }} animate={{ x: '0%', opacity: 1 }} role="alert" className="rounded-xl border fixed  left-3 sm:left-10  bottom-10 z-50 bg-white border-gray-100 p-4 shadow-xl">
          <div className="flex items-start  gap-4">
            <span className="text-green-600">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                className="h-6 w-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                />
              </svg>
            </span>
            <div className="flex-1">
              <strong className="block font-medium text-gray-900">Cambios completados</strong>
              <p className="mt-1 text-sm text-gray-700">
                Tu perfil se ha actualizado con exito.
              </p>
            </div>
            <button onClick={() => { setState({ successAlert: false }) }} className="text-gray-500 transition hover:text-gray-600">
              <span className="sr-only">Dismiss popup</span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                className="h-6 w-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
          </div>
        </motion.div>
      }
      {state.errorAlert &&
        <ErrorAlert ErrorElement={state.ErrorElement}
          animate={{ x: 0, opacity: 1, zIndex: 50, left: "1rem", bottom: "1rem", position: "fixed" }}
          transition={{ duration: 0.3 }}
          initial={{ x: -50, opacity: 0.1, zIndex: 50, left: "1rem", bottom: "1rem", position: "fixed" }}
          message={state.errorMessage}
          title={state.errorTitle} />
      }
      <div className="flex relative sm:flex-col md:flex-row w-full gap-x-5 items-start justify-center">

        <div className="bg-blue-600 rounded-lg shadow-lg py-4 px-4 text-center flex justify-center items-center w-full max-w-[250px] min-h-[250px] h-[250px]">
          <div>
            <h2 className="text-3xl font-bold text-white">{user.username}</h2>
            <p className="text-xs font-medium mt-2 text-gray-200 nivel">{user.email}</p>
          </div>
        </div>
        <div className="bg-white rounded-lg py-8 px-8 w-full max-w-[700px]">
          <div className="flex flex-col items-start gap-1">
            <div className="flex gap-2 justify-center items-center h-10">
              <p className="text-sm font-medium text-gray-700">Nombre de usuario:</p>
              {state.editUserName
                ? <>
                  <input value={updateState.username} className="h-10 w-48 rounded-lg text-gray-700 border-gray-200 border-[1px] p-3 text-sm" type="text" onChange={(event) => { setUpdateState({ username: event.target.value }) }} />
                  {/* submit button */}
                  <button onClick={() => { submitProfileUpdate('username', 'text', () => { setState({ editUserName: false }) }) }} className="group flex items-center justify-center gap-2 rounded-lg border border-green-500 bg-green-500 h-10 px-1 transition-colors hover:bg-transparent focus:outline-none focus:ring">
                    <span className="flex-shrink-0 rounded-full border border-current bg-white p-2 text-green-500 group-active:text-green-500">
                      <svg className="h-3 w-3" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 12.75l6 6 9-13.5" />
                      </svg>
                    </span>
                  </button>
                  {/* cancel update button */}
                  <button onClick={() => { setState({ editUserName: false }); setUpdateState({ username: user.username }) }} className="group flex items-center justify-center gap-2 rounded-lg border border-red-500 bg-red-500 h-10 px-1 transition-colors hover:bg-transparent focus:outline-none focus:ring">
                    <span className="flex-shrink-0 rounded-full border border-current bg-white p-2 text-red-500 group-active:text-red-500">
                      <svg className="h-3 w-3" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M18 6L6 18" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                        <path d="M6 6L18 18" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                      </svg>
                    </span>
                  </button>
                </>
                : <>
                  <p className="text-sm text-gray-600">{user.username}</p>
                  <button onClick={() => { setState({ editUserName: true }) }} className="text-gray-400 underline-offset-2 underline text-xs">Editar</button>
                </>
              }
            </div>
            <div className="flex gap-2 justify-center items-center h-10">
              <p className="text-sm font-medium text-gray-700">Nombre:</p>
              {state.editName
                ? <>
                  <input value={updateState.name} className="h-10 w-48 rounded-lg text-gray-700 border-gray-200 border-[1px] p-3 text-sm" type="text" onChange={(event) => { setUpdateState({ name: event.target.value }) }} />
                  {/* submit button */}
                  <button onClick={() => { submitProfileUpdate('name', 'text', () => { setState({ editName: false }) }) }} className="group flex items-center justify-center gap-2 rounded-lg border border-green-500 bg-green-500 h-10 px-1 transition-colors hover:bg-transparent focus:outline-none focus:ring">
                    <span className="flex-shrink-0 rounded-full border border-current bg-white p-2 text-green-500 group-active:text-green-500">
                      <svg className="h-3 w-3" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 12.75l6 6 9-13.5" />
                      </svg>
                    </span>
                  </button>
                  {/* cancel update button */}
                  <button onClick={() => { setState({ editName: false }); setUpdateState({ name: user.name }) }} className="group flex items-center justify-center gap-2 rounded-lg border border-red-500 bg-red-500 h-10 px-1 transition-colors hover:bg-transparent focus:outline-none focus:ring">
                    <span className="flex-shrink-0 rounded-full border border-current bg-white p-2 text-red-500 group-active:text-red-500">
                      <svg className="h-3 w-3" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M18 6L6 18" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                        <path d="M6 6L18 18" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                      </svg>
                    </span>
                  </button>
                </>
                : <>
                  <p className="text-sm text-gray-600">{user.name}</p>
                  <button onClick={() => { setState({ editName: true }) }} className="text-gray-400 underline-offset-2 underline text-xs">Editar</button>
                </>
              }
            </div>
            <div className="flex gap-2 justify-center items-center h-10">
              <p className="text-sm font-medium text-gray-700">Apellido:</p>
              {state.editLastName
                ? <>
                  <input value={updateState.lastName} className="h-10 w-48 rounded-lg text-gray-700 border-gray-200 border-[1px] p-3 text-sm" type="text" onChange={(event) => { setUpdateState({ lastName: event.target.value }) }} />
                  {/* submit button */}
                  <button onClick={() => { submitProfileUpdate('lastName', 'text', () => { setState({ editLastName: false }) }) }} className="group flex items-center justify-center gap-2 rounded-lg border border-green-500 bg-green-500 h-10 px-1 transition-colors hover:bg-transparent focus:outline-none focus:ring">
                    <span className="flex-shrink-0 rounded-full border border-current bg-white p-2 text-green-500 group-active:text-green-500">
                      <svg className="h-3 w-3" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 12.75l6 6 9-13.5" />
                      </svg>
                    </span>
                  </button>
                  {/* cancel update button */}
                  <button onClick={() => { setState({ editLastName: false }); setUpdateState({ lastName: user.lastName }) }} className="group flex items-center justify-center gap-2 rounded-lg border border-red-500 bg-red-500 h-10 px-1 transition-colors hover:bg-transparent focus:outline-none focus:ring">
                    <span className="flex-shrink-0 rounded-full border border-current bg-white p-2 text-red-500 group-active:text-red-500">
                      <svg className="h-3 w-3" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M18 6L6 18" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                        <path d="M6 6L18 18" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                      </svg>
                    </span>
                  </button>
                </>
                : <>
                  <p className="text-sm text-gray-600">{user.lastName}</p>
                  <button onClick={() => { setState({ editLastName: true }) }} className="text-gray-400 underline-offset-2 underline text-xs">Editar</button>
                </>
              }
            </div>
            <div className="flex gap-2 justify-center items-center h-10">
              <p className="text-sm font-medium text-gray-700">Correo electrónico:</p>
              <p className="text-sm text-gray-600">{user.email}</p>
            </div>
            <div className="flex gap-2 justify-center items-center h-10">
              <p className="text-sm font-medium text-gray-700">Número de teléfono:</p>
              {state.editPhone
                ? <>
                  <input value={updateState.phone} className="h-10 w-48 rounded-lg text-gray-700 border-gray-200 border-[1px] p-3 text-sm" type="text" onChange={(event) => { setUpdateState({ phone: event.target.value }) }} />
                  {/* submit button */}
                  <button onClick={() => { submitProfileUpdate('phone', 'text', () => { setState({ editPhone: false }) }) }} className="group flex items-center justify-center gap-2 rounded-lg border border-green-500 bg-green-500 h-10 px-1 transition-colors hover:bg-transparent focus:outline-none focus:ring">
                    <span className="flex-shrink-0 rounded-full border border-current bg-white p-2 text-green-500 group-active:text-green-500">
                      <svg className="h-3 w-3" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 12.75l6 6 9-13.5" />
                      </svg>
                    </span>
                  </button>
                  {/* cancel update button */}
                  <button onClick={() => { setState({ editPhone: false }); setUpdateState({ phone: user.phone }) }} className="group flex items-center justify-center gap-2 rounded-lg border border-red-500 bg-red-500 h-10 px-1 transition-colors hover:bg-transparent focus:outline-none focus:ring">
                    <span className="flex-shrink-0 rounded-full border border-current bg-white p-2 text-red-500 group-active:text-red-500">
                      <svg className="h-3 w-3" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M18 6L6 18" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                        <path d="M6 6L18 18" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                      </svg>
                    </span>
                  </button>
                </>
                : <>
                  <p className="text-sm text-gray-600">{user.phone}</p>
                  <button onClick={() => { setState({ editPhone: true }) }} className="text-gray-400 underline-offset-2 underline text-xs">Editar</button>
                </>
              }
            </div>
            {user.permissions === '1' &&
              <>
                <div className="flex gap-2 justify-center items-center h-10">
                  <p className="text-sm font-medium text-gray-700">Ciudad:</p>
                  {state.editCity
                    ?
                    <>
                      <div>
                        <Listbox value={city} onChange={setCity}>
                          <div className="relative rounded-lg">
                            <Listbox.Button className="relative h-10 w-48 rounded-lg text-gray-700 border-gray-200 border-[1px] pl-3 text-sm text-left focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-sm">
                              <span className={`block truncate ${city.id === 0 && "text-gray-500"}`}>{city.name}</span>
                              <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                                <ChevronUpDownIcon
                                  className="h-5 w-5 text-gray-400"
                                  aria-hidden="true"
                                />
                              </span>
                            </Listbox.Button>
                            <Transition
                              as={Fragment}
                              leave="transition ease-in duration-100"
                              leaveFrom="opacity-100"
                              leaveTo="opacity-0"
                            >
                              <Listbox.Options className="z-40 absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                {citysData.map((city: { name: string, id: number }, cityIdx: number) => (
                                  <Listbox.Option
                                    key={cityIdx}
                                    // onClick={((event) => { updateCity() })}
                                    className={({ active }) =>
                                      `relative cursor-default select-none py-2 pl-4 pr-1 ${city.id === 0 && 'hidden'} ${active ? 'bg-blue-600 text-white' : 'text-gray-900'
                                      }`
                                    }
                                    value={city}
                                  >
                                    {({ selected }) => (
                                      <>
                                        <span
                                          className={`block truncate pl-4 ${city.id === 0 && 'text-gray-500'} ${selected ? 'font-medium' : 'font-normal'
                                            }`}
                                        >
                                          {city.name}
                                        </span>
                                        {selected ? (
                                          <span className="absolute inset-y-0 left-0 flex items-center pl-2 text-indigo-600">
                                            <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                          </span>
                                        ) : null}
                                      </>
                                    )}
                                  </Listbox.Option>
                                ))}
                              </Listbox.Options>
                            </Transition>
                          </div>
                        </Listbox>
                      </div>
                      <button onClick={() => { submitProfileUpdate('city', 'select', () => { setState({ editCity: false }) }, city) }} className="group flex items-center justify-center gap-2 rounded-lg border border-green-500 bg-green-500 h-10 px-1 transition-colors hover:bg-transparent focus:outline-none focus:ring">
                        <span className="flex-shrink-0 rounded-full border border-current bg-white p-2 text-green-500 group-active:text-green-500">
                          <svg className="h-3 w-3" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 12.75l6 6 9-13.5" />
                          </svg>
                        </span>
                      </button>
                      {/* cancel update button */}
                      <button onClick={() => { setState({ editCity: false }); setUpdateState({ city: user.city }); findAndSetCity() }} className="group flex items-center justify-center gap-2 rounded-lg border border-red-500 bg-red-500 h-10 px-1 transition-colors hover:bg-transparent focus:outline-none focus:ring">
                        <span className="flex-shrink-0 rounded-full border border-current bg-white p-2 text-red-500 group-active:text-red-500">
                          <svg className="h-3 w-3" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M18 6L6 18" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M6 6L18 18" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                          </svg>
                        </span>
                      </button>
                    </>

                    :
                    <>
                      <p className="text-sm text-gray-600">{citysData.find((el:any) => parseInt(el.id) === parseInt(user.city))?.name}</p>
                      <button onClick={() => { setState({ editCity: true }); findAndSetCity() }} className="text-gray-400 underline-offset-2 underline text-xs">Editar</button>
                    </>
                  }
                </div>
                <div className="flex gap-2 justify-center items-center h-10">
                  <p className="text-sm font-medium text-gray-700">Direccion:</p>
                  {state.editAddress
                    ? <>
                      <input value={updateState.address} className="h-10 w-48 rounded-lg text-gray-700 border-gray-200 border-[1px] p-3 text-sm" type="text" onChange={(event) => { setUpdateState({ address: event.target.value }) }} />
                      {/* submit button */}
                      <button onClick={() => { submitProfileUpdate('address', 'text', () => { setState({ editAddress: false }) }) }} className="group flex items-center justify-center gap-2 rounded-lg border border-green-500 bg-green-500 h-10 px-1 transition-colors hover:bg-transparent focus:outline-none focus:ring">
                        <span className="flex-shrink-0 rounded-full border border-current bg-white p-2 text-green-500 group-active:text-green-500">
                          <svg className="h-3 w-3" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 12.75l6 6 9-13.5" />
                          </svg>
                        </span>
                      </button>
                      {/* cancel update button */}
                      <button onClick={() => { setState({ editAddress: false }); setUpdateState({ address: user.address }) }} className="group flex items-center justify-center gap-2 rounded-lg border border-red-500 bg-red-500 h-10 px-1 transition-colors hover:bg-transparent focus:outline-none focus:ring">
                        <span className="flex-shrink-0 rounded-full border border-current bg-white p-2 text-red-500 group-active:text-red-500">
                          <svg className="h-3 w-3" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M18 6L6 18" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M6 6L18 18" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                          </svg>
                        </span>
                      </button>
                    </>
                    : <>
                      <p className="text-sm text-gray-600">{user.address ? user.address : ''}</p>
                      <button onClick={() => { setState({ editAddress: true }) }} className="text-gray-400 underline-offset-2 underline text-xs">Editar</button>
                    </>
                  }
                </div>
                <div className="flex gap-2 justify-center items-center h-10">
                  <p className="text-sm font-medium text-gray-700">Numero de documento:</p>
                  {state.editDocument
                    ?
                    <>
                      <div className="flex justify-center items-center border-gray-200 border-[1px] rounded-lg">
                        <Listbox value={documentType} onChange={setDocumentType}>
                          <div className="relative rounded-l-lg">
                            <Listbox.Button className="relative h-10 w-[70px] mt-0 rounded-l-lg p-3 text-sm text-left focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-sm">
                              <span className={`block truncate`}>{documentType.abbrev}</span>
                              <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                                <ChevronUpDownIcon
                                  className="h-5 w-5 text-gray-400"
                                  aria-hidden="true"
                                />
                              </span>
                            </Listbox.Button>
                            <Transition
                              as={Fragment}
                              leave="transition ease-in duration-100"
                              leaveFrom="opacity-100"
                              leaveTo="opacity-0"
                            >
                              <Listbox.Options className="z-40 absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                {documentTypeData.map((documentType: { name: string, id: number,abbrev:string }, documentIdx: number) => (
                                  <Listbox.Option
                                    key={documentIdx}
                                    onClick={((event) => { })}
                                    className={({ active }) =>
                                      `relative cursor-default select-none py-2 pl-7 pr-1 ${active ? 'bg-blue-600 text-white' : 'text-gray-900'
                                      }`
                                    }
                                    value={documentType}
                                  >
                                    {({ selected }) => (
                                      <>
                                        <span
                                          className={`block truncate ${selected ? 'font-medium' : 'font-normal'
                                            }`}
                                        >
                                          {documentType.abbrev}
                                        </span>
                                        {selected ? (
                                          <span className="absolute inset-y-0 left-0 flex items-center pl-1.5 text-indigo-600">
                                            <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                          </span>
                                        ) : null}
                                      </>
                                    )}
                                  </Listbox.Option>
                                ))}
                              </Listbox.Options>
                            </Transition>
                          </div>
                        </Listbox>
                        <input className="h-10 w-48 rounded-r-lg border-gray-200 border-l-[1px] p-3 text-sm" type="text" id="documentNumber" value={updateState.documentNumber} onChange={(event) => { setUpdateState({ documentNumber: event.target.value }) }} />
                      </div>
                      {/* send update button */}
                      <button onClick={() => { submitProfileUpdate('document', 'double select', () => { setState({ editDocument: false }) }, documentType) }} className="group flex items-center justify-center gap-2 rounded-lg border border-green-500 bg-green-500 h-10 px-1 transition-colors hover:bg-transparent focus:outline-none focus:ring">
                        <span className="flex-shrink-0 rounded-full border border-current bg-white p-2 text-green-500 group-active:text-green-500">
                          <svg className="h-3 w-3" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 12.75l6 6 9-13.5" />
                          </svg>
                        </span>
                      </button>
                      {/* cancel update button */}
                      <button onClick={() => { setState({ editDocument: false }); setUpdateState({ documentNumber: user.documentNumber }); findAndSetDocument() }} className="group flex items-center justify-center gap-2 rounded-lg border border-red-500 bg-red-500 h-10 px-1 transition-colors hover:bg-transparent focus:outline-none focus:ring">
                        <span className="flex-shrink-0 rounded-full border border-current bg-white p-2 text-red-500 group-active:text-red-500">
                          <svg className="h-3 w-3" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M18 6L6 18" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M6 6L18 18" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                          </svg>
                        </span>
                      </button>
                    </>

                    :
                    <>
                      <p className="text-sm text-gray-600">{user.documentNumber && user.documentAbbrev ? `${user.documentAbbrev} ${user.documentNumber}` : ''}</p>

                      <button onClick={() => { setState({ editDocument: true }); findAndSetDocument() }} className="text-gray-400 underline-offset-2 underline text-xs">Editar</button>
                    </>
                  }
                </div>
                <div className="flex gap-2 justify-center items-center h-10">
                  <p className="text-sm font-medium text-gray-700">Tipo de cliente:</p>
                  <p className="text-sm text-gray-600">{customerTypesData.find((el:any) => parseInt(el.id) === parseInt(user.customerType))?.name}</p>
                </div>
                {/* user.customerType ? user.customerType : '' */}
                <div className="flex gap-2 justify-center items-center h-10">
                  <p className="text-sm font-medium text-gray-700">Tarjeta profesional:</p>
                  {state.editProfessionalCard
                    ? <>
                      <input value={updateState.professionalCard} className="h-10 rounded-lg text-gray-700 border-gray-200 border-[1px] p-3 text-sm" type="text" onChange={(event) => { setUpdateState({ professionalCard: event.target.value }) }} />
                      {/* submit button */}
                      <button onClick={() => { submitProfileUpdate('professionalCard', 'text', () => { setState({ editProfessionalCard: false }) }) }} className="group flex items-center justify-center gap-2 rounded-lg border border-green-500 bg-green-500 h-10 px-1 transition-colors hover:bg-transparent focus:outline-none focus:ring">
                        <span className="flex-shrink-0 rounded-full border border-current bg-white p-2 text-green-500 group-active:text-green-500">
                          <svg className="h-3 w-3" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 12.75l6 6 9-13.5" />
                          </svg>
                        </span>
                      </button>
                      {/* cancel update button */}
                      <button onClick={() => { setState({ editProfessionalCard: false }); setUpdateState({ professionalCard: user.professionalCard }) }} className="group flex items-center justify-center gap-2 rounded-lg border border-red-500 bg-red-500 h-10 px-1 transition-colors hover:bg-transparent focus:outline-none focus:ring">
                        <span className="flex-shrink-0 rounded-full border border-current bg-white p-2 text-red-500 group-active:text-red-500">
                          <svg className="h-3 w-3" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M18 6L6 18" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M6 6L18 18" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                          </svg>
                        </span>
                      </button>
                    </>
                    : <>
                      <p className="text-sm text-gray-600">{user.professionalCard ? user.professionalCard : ''}</p>
                      <button onClick={() => { setState({ editProfessionalCard: true }) }} className="text-gray-400 underline-offset-2 underline text-xs">Editar</button>
                    </>
                  }
                </div>
              </>
            }
            {/* TODO: hacer el menu para cambiar password y otros datos */}
          </div>
        </div>
      </div>
    </>
  )
}

export { Details }

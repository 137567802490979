import { motion } from "framer-motion"
import { useStateMany } from "../customHooks/useStateMany"
import { Modal } from "../modal"
import { useRef, Fragment, useEffect, useState } from 'react'
import { Listbox, Transition } from '@headlessui/react'
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid'
import { API } from "../API"
import { ErrorAlert } from "../ErrorAlert"
import { Loader } from "../Loader"
import { EyesInput } from "../MyOrders"
import { PopUp } from "../PopUp"
import { OrderDataLoader } from "../OrderDataLoader"
import { SearchClient } from "../SearchClient"
interface TestBoxTypes {
  state: any,
  setState: any,
  testBoxSelected: any,
  setTestBoxSelected: any,
  testBoxes: any,
  citysData: any,
  city: any,
  setCity: any,
  user: any,
}
function TestBox({ state, setState, testBoxSelected, setTestBoxSelected, testBoxes, user, citysData, city, setCity }: TestBoxTypes) {
  const naturalClient = parseInt(user.permissions) === 1 && (parseInt(user?.customerType) === 1 || parseInt(user?.customerType) === 3) ? true : false;
  const [eyesState, setEyesState] = useStateMany({
    observaciones: '',
    quienRealizaElPedido: '',
    nombreDelPaciente: '',
    numeroDeDocumentoDelPaciente: '',
    nombreDelDoctor: naturalClient ? `${user.name} ${user.lastName}` : '',
    tarjetaProfesional: naturalClient ? user.professionalCard : '',
    address: naturalClient ? user.address : '',
    id_client: naturalClient ? parseInt(user.id) : 0,
    orderStep: 1,
  })

  function goToOrderStep2(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    event.preventDefault();
    if (eyesState.orderStep === 1) {
      if (eyesState.quienRealizaElPedido !== '' && eyesState.nombreDelPaciente !== '' && eyesState.nombreDelDoctor !== '' && eyesState.tarjetaProfesional !== '') {
        setEyesState({
          orderStep: 2,
          // errorMessage: false,
          // errorMessageTitle: '',
          // errorMessageText: '',
        });
      } else {
        // setState({ errorMessage: true, errorMessageTitle: 'Por favor llene todo los campos para continuar', errorMessageText: 'No se puede continuar con el proceso de creacion de un pedido sin los datos necesarios.' })
        // setTimeout(() => {
        //   setState({ errorMessage: false, errorMessageTitle: '', errorMessageText: '' })
        // }, 7000);
      }
    }
  }
  function cancelOrder(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    event.preventDefault();
    setTestBoxSelected(testBoxes[0])
    setEyesState({
      quienRealizaElPedido: '',
      nombreDelPaciente: '',
      numeroDeDocumentoDelPaciente: '',
      nombreDelDoctor: naturalClient ? `${user.name} ${user.lastName}` : '',
      tarjetaProfesional: naturalClient ? user.professionalCard : '',
      address: naturalClient ? user.address : '',
      id_client: naturalClient ? parseInt(user.id) : 0,
      observaciones: '',
      // ojo izquierdo
      left_observaciones: '',
      // ojo derecho
      right_observaciones: '',
      orderStep: 1,
    });
    setState({ openTestBoxCreation: false })
    // setState({
    //   errorMessage: false,
    //   errorMessageTitle: '',
    //   errorMessageText: ''
    // });
  }
  function sendTestBox(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    event.preventDefault();
    if (parseInt(testBoxSelected.id_box) !== 0) {
      let dataToSend = {
        API_KEY: process.env.REACT_APP_API_KEY,
        idSession: parseInt(user.id),
        creatorName: eyesState.quienRealizaElPedido,
        patientName: eyesState.nombreDelPaciente,
        patientDoc: eyesState.numeroDeDocumentoDelPaciente,
        optometristName: eyesState.nombreDelDoctor,
        professionalCard: eyesState.tarjetaProfesional,
        city: parseInt(city.id),
        address: eyesState.address,
        id_client: eyesState.id_client,
        // otros 
        idBox: parseInt(testBoxSelected.id_box),
        idAcc: 0,
        // ojo derecho
        rTypeLens: 0,
        rLens: 0,
        rQty: parseInt(testBoxSelected.qty),
        rZo: '',
        rAddition: '',
        rDegrees: '',
        rPerifery1: '',
        rPerifery2: '',
        rCinverted: '',
        rCAlignment: '',
        rCopening: '',
        rZoCbase: '',
        rZoCinverted: '',
        rZoCalignment: '',
        rZoCopening: '',

        rCbase: '',
        rPower: '',
        rCylinder: '',
        rDiameter: '',
        rDesign: 0,
        rCpp: '',
        rColor: 0,
        rObserv: eyesState.observaciones,
        // ojo izquierdo
        lTypeLens: 0,
        lLens: 0,
        lQty: parseInt(testBoxSelected.qty),
        lZo: '',
        lAddition: '',
        lDegrees: '',
        lPerifery1: '',
        lPerifery2: '',
        lCinverted: '',
        lCAlignment: '',
        lCopening: '',
        lZoCbase: '',
        lZoCinverted: '',
        lZoCalignment: '',
        lZoCopening: '',

        lCbase: '',
        lPower: '',
        lCylinder: '',
        lDiameter: '',
        lDesign: '',
        lCpp: '',
        lColor: 0,
        lObserv: eyesState.observaciones,

        typeOrder: 2,
        statusOrder: 'revision',
      }
      setState({ waitingForResponse: true, errorMessage: false, errorMessageTitle: '', errorMessageText: '' })
      API.post('/?insorder', {
        ...dataToSend
      }).then((response) => {
        if (response.data.success === 1 && response.status === 200) {
          setTestBoxSelected(testBoxes[0])
          setEyesState({
            quienRealizaElPedido: '',
            nombreDelPaciente: '',
            numeroDeDocumentoDelPaciente: '',
            nombreDelDoctor: '',
            tarjetaProfesional: '',
            id_client: naturalClient ? parseInt(user.id) : 0,
            observaciones: '',

            // ojo izquierdo
            left_observaciones: '',
            // ojo derecho
            right_observaciones: '',
            orderStep: 1,
          });
          setState({
            waitingForResponse: false,
            orderStep: 0,
            successMessage: true,
            reload: !state.reload,
            successMessageTitle: 'Su caja de prueba se ha creado con exito!',
            successMessageText: 'Se ha creado su pedido, espere pacientemente a que alguien de nuestro equipo la verifique y sea aceptada.',
            openTestBoxCreation: false,
          })
          setTimeout(() => {
            setState({
              successMessage: false,
              successMessageTitle: '',
              successMessageText: '',
            })
          }, 7000)
        } else {
          setState({
            waitingForResponse: false,
            errorMessage: true,
            errorMessageTitle: 'Ocurrio un error al intentar guardar la pedido',
            errorMessageText: 'No se pudo guardar el pedido, intente de nuevo mas tarde',
          })
        }
      })
    }
  }

  return (
    <>
      <motion.form initial={{ y: 50, opacity: 0 }} animate={{ y: 0, opacity: 1 }} transition={{ duration: 0.5 }} className="fixed top-0 left-0 w-full h-full flex justify-center items-center z-40">
        {state.showSearchClient === true &&
          <SearchClient state={state} setState={setState} user={user} eyesState={eyesState} setEyesState={setEyesState} setCity={setCity} citysData={citysData} />
        }
        {eyesState.orderStep === 1 && state.waitingForResponse === false &&
          <div className="bg-white p-4 md:p-10 z-40 rounded-lg grid grid-cols-1 md:grid-cols-2 gap-x-4 gap-y-3 max-h-[90vh] w-full max-w-[1000px] overflow-y-auto">
            {/* {parseInt(user.permissions) === 1 &&
              <div className="col-span-full flex justify-end items-center gap-x-2">
                <span className="text-sm text-gray-700">Autocompletar</span>
                <PopUp message="Presione para completar algunos campos con la informacion de su perfil.">
                  <button type="button" onClick={(event) => {
                    loadDataFromProfile(event)
                  }} className="group flex items-center justify-center gap-2 h-12 w-12 rounded-lg border border-gray-500 bg-gray-500 px-2 py-2 transition-colors hover:bg-transparent focus:outline-none focus:ring" title={"Reiniciar los filtros"}>
                    <span className="flex-shrink-0 rounded-full border border-current bg-white p-2 text-gray-500 group-active:text-gray-500">
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M9 12h3.75M9 15h3.75M9 18h3.75m3 .75H18a2.25 2.25 0 002.25-2.25V6.108c0-1.135-.845-2.098-1.976-2.192a48.424 48.424 0 00-1.123-.08m-5.801 0c-.065.21-.1.433-.1.664 0 .414.336.75.75.75h4.5a.75.75 0 00.75-.75 2.25 2.25 0 00-.1-.664m-5.8 0A2.251 2.251 0 0113.5 2.25H15c1.012 0 1.867.668 2.15 1.586m-5.8 0c-.376.023-.75.05-1.124.08C9.095 4.01 8.25 4.973 8.25 6.108V8.25m0 0H4.875c-.621 0-1.125.504-1.125 1.125v11.25c0 .621.504 1.125 1.125 1.125h9.75c.621 0 1.125-.504 1.125-1.125V9.375c0-.621-.504-1.125-1.125-1.125H8.25zM6.75 12h.008v.008H6.75V12zm0 3h.008v.008H6.75V15zm0 3h.008v.008H6.75V18z" />
                      </svg>
                    </span>
                  </button>
                </PopUp>
              </div>
            } */}
            {/* Quien realiza el pedido */}
            <div className="col-span-full">
              <p className="font-medium text-slate-600">Quien realiza el pedido?</p>
            </div>
            <div className="col-span-full md:col-span-2">
              <label className=" text-sm font-medium text-gray-700">
                <span className="capitalize">Nombre</span>
                <input
                  type="text"
                  value={eyesState.quienRealizaElPedido}
                  onChange={(event) => { setEyesState({ quienRealizaElPedido: event.target.value }) }}
                  className="h-12 mt-2 w-full rounded-lg border-gray-200 border-[1px] p-3 text-sm"
                />
              </label>
            </div>
            {/* Datos del paciente */}
            <div className="md:col-span-full">
              <p className="font-medium mt-5 text-slate-600">Datos de el paciente</p>
            </div>
            <div className="">
              <label className="text-sm font-medium text-gray-700">
                <span className="capitalize">Nombre</span>
                <input
                  type="text"
                  value={eyesState.nombreDelPaciente}
                  onChange={(event) => { setEyesState({ nombreDelPaciente: event.target.value }) }}
                  className="h-12 mt-2 w-full rounded-lg border-gray-200 border-[1px] p-3 text-sm"
                />
              </label>
            </div>
            <div className="">
              <label className="text-sm font-medium text-gray-700">
                <span className="capitalize">Numero de documento</span>
                <input
                  type="text"
                  value={eyesState.numeroDeDocumentoDelPaciente}
                  onChange={(event) => { setEyesState({ numeroDeDocumentoDelPaciente: event.target.value }) }}
                  className="h-12 mt-2 w-full rounded-lg border-gray-200 border-[1px] p-3 text-sm"
                />
              </label>
            </div>
            <div className="col-span-full">
              <p className="font-medium mt-5 text-slate-600">Datos del optómetra u oftalmólogo</p>
            </div>
            <div className="">
              <label className=" text-sm font-medium text-gray-700">
                <span className="capitalize">Nombre</span>
                {/* Se mostrara si es un cliente de tipo empresa */}
                {parseInt(user.permissions) === 1 && parseInt(user.customerType) === 2 ?
                  <OrderDataLoader user={user} eyesState={eyesState} setEyesState={setEyesState} setCity={setCity} citysData={citysData} />
                  :
                  <>
                    {parseInt(user.permissions) === 5 || parseInt(user.permissions) === 2
                      ?
                      <>
                        <div className="flex items-center justify-center">
                          <input
                            type="text"
                            disabled={true}
                            value={eyesState.nombreDelDoctor}
                            onChange={(event) => { setEyesState({ nombreDelDoctor: event.target.value }) }}
                            className="h-12 mt-2 w-full rounded-l-lg border-gray-200 border-t-[1px] border-b-[1px] border-l-[1px] p-3 text-sm"
                          />
                          <PopUp message="Buscar Optometras" className="z-40">
                            <button onClick={() => { setState({ showSearchClient: true }) }} type="button" className="h-12 w-16 mt-2 bg-blue-500 rounded-r-lg border-gray-200 border-[1px] flex p-3 items-center justify-center">
                              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" className="w-6 h-6 text-white">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z" />
                              </svg>
                            </button>
                          </PopUp>
                        </div>
                      </>
                      :
                      <input
                        type="text"
                        disabled={naturalClient}
                        value={eyesState.nombreDelDoctor}
                        onChange={(event) => { setEyesState({ nombreDelDoctor: event.target.value }) }}
                        className="h-12 mt-2 w-full rounded-lg border-gray-200 border-[1px] p-3 text-sm"
                      />
                    }
                  </>

                }
              </label>
            </div>
            <div className="">
              <label className=" text-sm font-medium text-gray-700">
                <span className="capitalize">Tarjeta profesional</span>
                <input
                  type="text"
                  disabled={true}
                  value={eyesState.tarjetaProfesional}
                  onChange={(event) => { setEyesState({ tarjetaProfesional: event.target.value }) }}
                  className="h-12 mt-2 w-full rounded-lg border-gray-200 border-[1px] p-3 text-sm"
                />
              </label>
            </div>
            {/* Ciudad */}
            <div>
              <span className="first-letter:capitalize text-sm font-medium text-gray-700">Ciudad</span>
              <Listbox value={city} onChange={setCity}>
                <div className="relative rounded-lg">
                  <Listbox.Button className="relative h-12 w-full mt-2 rounded-lg border-gray-200 border-[1px] p-3 text-sm text-left focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-sm">
                    <span className={`block truncate ${city.id === 0 && "text-gray-500"}`}>{city.name}</span>
                    <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                      <ChevronUpDownIcon
                        className="h-5 w-5 text-gray-400"
                        aria-hidden="true"
                      />
                    </span>
                  </Listbox.Button>
                  <Transition
                    as={Fragment}
                    leave="transition ease-in duration-100"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <Listbox.Options className="z-40 fixed max-w-sm mt-1 w-full max-h-60 overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                      {citysData.map((city: { name: string, id: number }, cityIdx: number) => (
                        <Listbox.Option
                          key={cityIdx}
                          onClick={((event) => { })}
                          className={({ active }) =>
                            `relative cursor-default select-none py-2 pl-10 pr-4 ${city.id === 0 && 'hidden'} ${active ? 'bg-blue-600 text-white' : 'text-gray-900'
                            }`
                          }
                          value={city}
                        >
                          {({ selected }) => (
                            <>
                              <span
                                className={`block truncate ${city.id === 0 && 'text-gray-500'} ${selected ? 'font-medium' : 'font-normal'
                                  }`}
                              >
                                {city.name}
                              </span>
                              {selected ? (
                                <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-indigo-600">
                                  <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                </span>
                              ) : null}
                            </>
                          )}
                        </Listbox.Option>
                      ))}
                    </Listbox.Options>
                  </Transition>
                </div>
              </Listbox>
            </div>
            {/* Direccion */}
            <div>
              <label className=" text-sm font-medium text-gray-700">
                <span className="capitalize">Dirección</span>
                <input
                  type="text"
                  value={eyesState.address}
                  onChange={(event) => { setEyesState({ address: event.target.value }) }}
                  className="h-12 mt-2 w-full rounded-lg border-gray-200 border-[1px] p-3 text-sm"
                />
              </label>
            </div>
            <div className="col-span-full flex items-center justify-end mt-5 gap-2">
              <button type="button" onClick={(event) => {
                cancelOrder(event);
              }} className="group flex items-center justify-center gap-2 rounded-lg border border-red-500 bg-red-500 px-3 py-2 transition-colors hover:bg-transparent focus:outline-none focus:ring">
                <span className="capitalize text-sm font-medium text-white transition-colors group-hover:text-red-500 group-active:text-red-500">
                  cancelar
                </span>
                <span className="flex-shrink-0 rounded-full border border-current bg-white p-2 text-red-500 group-active:text-red-500">
                  <svg className="h-3 w-3" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M18 6L6 18" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M6 6L18 18" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                  </svg>
                </span>
              </button>
              <button onClick={(event) => { goToOrderStep2(event) }} className="group flex items-center justify-center gap-2 rounded-lg border border-green-500 bg-green-500 px-3 py-2 transition-colors hover:bg-transparent focus:outline-none focus:ring">
                <span className="capitalize text-sm font-medium text-white transition-colors group-hover:text-green-500 group-active:text-green-500">
                  siguiente
                </span>
                <span className="flex-shrink-0 rounded-full border border-current bg-white p-2 text-green-500 group-active:text-green-500">
                  <svg className="h-3 w-3" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path className="fill-green-500" strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M17 8l4 4m0 0l-4 4m4-4H3" />
                  </svg>
                </span>
              </button>
            </div>
          </div>
        }
        {eyesState.orderStep === 2 && state.waitingForResponse === false &&
          <div className="bg-white p-4 md:p-10 z-40 rounded-lg grid grid-cols-1 gap-x-4 gap-y-3 max-h-[90vh] w-full max-w-[1000px] overflow-y-auto">
            <div className="flex flex-col gap-y-3">
              <div className="">
                <p className="font-medium mt-5 text-slate-600 text-center border-b-[1px] pb-3 border-slate-200">Caja de prueba</p>
              </div>
              <div>
                <span className="first-letter:capitalize">Nombre</span>
                <Listbox value={testBoxSelected} onChange={setTestBoxSelected}>
                  <div className="relative">
                    <Listbox.Button className="relative h-12 mt-2 w-full rounded-lg border-gray-200 border-[1px] p-3 text-sm text-left focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-sm">
                      <span className={`block truncate ${testBoxSelected.id_box === '0' && "text-gray-500"}`}>{testBoxSelected.box}{testBoxSelected.id_box !== '0' && <span className="font-medium">{testBoxSelected.qty > 0 ? ` x${testBoxSelected.qty}` : ' x1'} unidades</span>}</span>
                      <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                        <ChevronUpDownIcon
                          className="h-5 w-5 text-gray-400"
                          aria-hidden="true"
                        />
                      </span>
                    </Listbox.Button>
                    <Transition
                      as={Fragment}
                      leave="transition ease-in duration-100"
                      leaveFrom="opacity-100"
                      leaveTo="opacity-0"
                    >
                      <Listbox.Options className="z-40 absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                        {testBoxes.map((item: { box: string, qty: number, id_box: string }, boxIdx: number) => (
                          <Listbox.Option
                            key={boxIdx}
                            className={({ active }) =>
                              `relative cursor-default select-none py-2 pl-10 pr-4 ${active ? 'bg-indigo-600 text-white' : 'text-gray-900'
                              }`
                            }
                            value={item}
                          >
                            {({ selected }) => (
                              <>
                                <span
                                  className={`block truncate ${selected ? 'font-medium' : 'font-normal'
                                    }`}
                                >
                                  {item.box} {item.id_box !== '0' && <span className="font-medium">{item.qty > 0 ? ` x${item.qty}` : ' x1'} unidades</span>}
                                </span>
                                {selected ? (
                                  <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-indigo-600">
                                    <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                  </span>
                                ) : null}
                              </>
                            )}
                          </Listbox.Option>
                        ))}
                      </Listbox.Options>
                    </Transition>
                  </div>
                </Listbox>
              </div>
              {testBoxSelected.id_box !== '0' &&
                <span>{testBoxSelected.qty > 0 ?
                  <span>La caja seleccionada contiene <span className="font-medium">{testBoxSelected.qty}</span> unidades de <span className="font-medium">{testBoxSelected.box}</span></span>
                  : <span>La caja seleccionada contiene <span className="font-medium">1</span> unidad de {testBoxSelected.box}</span>}</span>}
              <EyesInput title='observaciones' type="textarea" value={eyesState.observaciones} onWrite={(event) => { setEyesState({ observaciones: event.target.value }) }} />
              <div className="col-span-full flex items-center justify-end mt-5 gap-2">
                <button type="button" onClick={() => {
                  setEyesState({ orderStep: 1 })
                }} className="group flex items-center justify-center gap-2 rounded-lg border border-gray-400 bg-gray-400 px-3 py-2 transition-colors hover:bg-transparent focus:outline-none focus:ring">
                  <span className="capitalize text-sm font-medium text-white transition-colors group-hover:text-gray-400 group-active:text-gray-400">
                    volver
                  </span>
                  <span className="flex-shrink-0 rounded-full border border-current bg-white p-2 text-gray-400 group-active:text-gray-400">
                    <svg className="h-3 w-3 rotate-180" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path className="fill-green-500" strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M17 8l4 4m0 0l-4 4m4-4H3" />
                    </svg>
                  </span>
                </button>
                <button onClick={(event) => { sendTestBox(event) }} className="group flex items-center justify-center gap-2 rounded-lg border border-green-500 bg-green-500 px-3 py-2 transition-colors hover:bg-transparent focus:outline-none focus:ring">
                  <span className="capitalize text-sm font-medium text-white transition-colors group-hover:text-green-500 group-active:text-green-500">
                    confirmar
                  </span>
                  <span className="flex-shrink-0 rounded-full border border-current bg-white p-2 text-green-500 group-active:text-green-500">
                    <svg className="h-3 w-3" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path className="fill-green-500" strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M17 8l4 4m0 0l-4 4m4-4H3" />
                    </svg>
                  </span>
                </button>
              </div>
            </div>
          </div>
        }
        {
          state.waitingForResponse &&
          <Loader />
        }
      </motion.form >
    </>
  )
}

export { TestBox };
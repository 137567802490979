import { API } from '../API';
import logo from '../assets/logo.png'
import { useStateMany } from '../customHooks/useStateMany';
import { ErrorAlert } from '../ErrorAlert';
import { Loader } from '../Loader';
const md5 = require('blueimp-md5');
interface LoginType {
  appName: string;
  setState: (value: object) => void;
  state: any;
}
export function Login({ appName, setState, state }: LoginType): JSX.Element {
  const [loginState, setLoginState] = useStateMany({
    password: '',
    userName: '',
    waitingForResponse: false,
    error: false,
    errorTitle: '',
    errorMessage: '',
  })
  const submit = (event: any) => {
    event.preventDefault();
    if (state.browserRoute === 'developmentnt') {
      setState({
        login: true,
        user: {
          id: '1',
          name: 'johangly',
          email: 'johangly@gmail.com',
          permissions: '2',
          phone: '042489232405',
          status: 0,
        }
      })
    } else {
      try {
        let dataToSend = {
          API_KEY: process.env.REACT_APP_API_KEY,
          username: loginState.userName.trim(),
          email: loginState.userName.trim(),
          password: md5(loginState.password),
        }
        setLoginState({ waitingForResponse: true })
        API.post("/?consult", {
          ...dataToSend
        }).then((response) => {
          // en caso de que el la respuesta fuera correcta, la clave y el username coincidan con los registros en la base de datos.
          // console.log(response,'response')
          if (response.status === 200 && response.data.login === true) {
            setLoginState({ waitingForResponse: false, error: false, errorTitle: '', errorMessage: '' })
            // console.log(response, 'response en login')
            setState({
              login: true, user: {
                id: response.data.id,
                name: response.data.name,
                username: response.data.username,
                lastName: response.data.lastName,
                email: response.data.email,
                permissions: response.data.userLevel,
                userType: response.data.userType,
                phone: response.data.phone,
                status: parseInt(response.data.status),
                address: response.data.address ? response.data.address : '',
                city: response.data.city ? response.data.city : '',
                customerType: response.data.customerType ? response.data.customerType : '',
                documentNumber: response.data.documentNumber ? response.data.documentNumber : '',
                documentAbbrev: response.data.documentAbbrev ? response.data.documentAbbrev : '',
                professionalCard: response.data.professionalCard ? response.data.professionalCard : '',
                documentType: response.data.documentType ? response.data.documentType : ''
              }
            })
          } else {
            // no encontro se encontro el perfil o la clave no coincide
            setLoginState({ waitingForResponse: false, error: true, errorTitle: 'El correo electronico o la clave ingresada no se encuentra registrado', errorMessage: 'Verifique los datos de su usuario e intente de nuevo.' })
          }
        }).catch(function (error) {
          setLoginState({ waitingForResponse: false, error: true, errorTitle: 'error', errorMessage: error });
          console.log(error);
        })
      } catch (error) {
        setLoginState({ waitingForResponse: false, error: true, errorTitle: 'error', errorMessage: error });
        console.log(error);
      }
    }
  }
  return (
    <>
      {loginState.error &&
        <ErrorAlert className='shadow-lg' ErrorElement={loginState.ErrorElement}
          animate={{ x: 0, opacity: 1, zIndex: 50, right: "2rem", bottom: "2rem", position: "fixed" }}
          transition={{ duration: 0.3 }}
          initial={{ x: 50, opacity: 0.1, zIndex: 50, right: "2rem", bottom: "2rem", position: "fixed" }}
          message={loginState.errorMessage}
          title={loginState.errorTitle} />
      }
      {loginState.waitingForResponse &&
        <Loader
          animate={{ transform: "rotate(45deg) scale(0.8) translate(0px,0px)", opacity: 1, zIndex: 50, left: "2.5rem", bottom: "1.25rem", position: "fixed" }} transition={{ duration: 0.3 }} initial={{ transform: "rotate(45deg) scale(0.8) translate(-50px,50px)", opacity: 0.1, zIndex: 50, left: "2.5rem", bottom: "1.25rem", position: "fixed" }} />
      }
      <section className="bg-white min-h-screen">
        <div className="h-screen w-full flex justify-center items-center flex-col">
          {/* <aside
            className="relative block h-16 lg:order-last lg:col-span-5 lg:h-full xl:col-span-6 w-full"
          >
            <img
              alt="Pattern"
              src="https://images.unsplash.com/photo-1605106702734-205df224ecce?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=870&q=80"
              className="absolute inset-0 h-full w-full object-cover"
            />
          </aside> */}
          <main
            aria-label="Main"
            className="flex items-center w-full h-full justify-center px-8 py-8 sm:px-12 lg:col-span-7 lg:py-12 lg:px-16 xl:col-span-6"
          >
            <div className="max-w-xl lg:max-w-1xl">
              <a className="block text-blue-600" href="https://laboratorioskeratos.com/" target="_blank" rel="noreferrer">
                <span className="sr-only">Home</span>
                <img src={logo} alt="keratos" className="h-20 sm:h-28 mx-auto" title='keratos' />
              </a>

              <h1
                className="mt-6 text-2xl font-bold text-center text-blue-700 sm:text-3xl md:text-4xl"
              >
                Bienvenido a {appName}
              </h1>
              <p className="mt-4 leading-relaxed text-gray-500 text-center">
                En Laboratorios Keratos desarrollamos,  producimos y distribuimos un amplio portafolio de Lentes de Contacto Especiales  para uso diario, uso convencional, de remplazo programado  y de diseños especializados.
              </p>
              <form action="#" className={`mt-8 grid grid-cols-1 grid-rows-2 gap-y-5 max-w-[400px] mx-auto`}>
                <div className={`mx-auto w-full`}>
                  <label
                    htmlFor="UserName"
                    className="block text-sm font-medium text-gray-700 text-left"
                  >
                    Correo electronico o Nombre de usuario
                  </label>
                  <input
                    onChange={(event) => { setLoginState({ userName: event.target.value }) }}
                    value={loginState.userName}
                    type="text"
                    id="UserName"
                    name="first_name"
                    className="mt-1 w-full py-2 px-3 min-h-[30px] rounded-md border-gray-300 bg-white text-sm text-gray-700 border-solid border-[1px]"
                  />
                </div>
                <div className={`mx-auto w-full`}>
                  <label
                    htmlFor="Password"
                    className="block text-sm font-medium text-gray-700 text-left"
                  >
                    Contraseña
                  </label>

                  <input
                    onChange={(event) => { setLoginState({ password: event.target.value }) }}
                    value={loginState.password}
                    type="password"
                    id="Password"
                    name="password"
                    className="mt-1 w-full py-2 px-3 min-h-[30px] rounded-md border-gray-300 bg-white text-sm text-gray-700 border-solid border-[1px]"
                  />
                </div>
                <div className={`flex sm:items-center flex-col sm:gap-4 mx-auto w-full mt-10`}>
                  <button onClick={(event) => { submit(event) }}
                    className="block shrink-0 rounded-md border border-blue-600 bg-blue-600 px-12 py-3 text-sm font-medium text-white transition hover:bg-transparent hover:text-blue-600 focus:outline-none focus:ring active:text-blue-500 min-h-[30px] w-full"
                  >
                    Login
                  </button>
                </div>
              </form>
            </div>
          </main>
        </div>
      </section>
    </>
  )
}

import { Page, Text, Image, View, Document, StyleSheet, PDFDownloadLink, usePDF, Font } from '@react-pdf/renderer';
import { robotoRegular, robotoMedium, robotoBold } from './fonts';
import keratos from '../assets/keratos-white.jpg'

const headerColor = '#eee';
const fieldGray = '#eee';

Font.register({
  family: 'Roboto',
  fonts: [
    {
      src: robotoRegular,
    },
    {
      src: robotoMedium,
      fontWeight: 'medium',
    },
    {
      src: robotoBold,
      fontWeight: 'bold',
    },
  ],
});
const styles = StyleSheet.create({
  page: {
    backgroundColor: headerColor,
  },
  pageDivider: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    margin: 10,
    padding: 10,
    gap: '5px',
  },
  section: {
    flexDirection: 'column',
    display: 'flex',
    fontSize: '7px',
    fontFamily: 'Roboto',
    width: '50%'
  },
  viewer: {
    width: '100%',
    height: '100%',
    // minHeight: '900px'
  },
  header: {
    width: "100%",
    backgroundColor: headerColor,
    padding: '4px',
    color: '#333',
    textAlign: 'center',
    fontWeight: "medium",
    borderBottom: '2px solid #fbfbfb',
    textTransform: 'capitalize',
    minHeight: '15px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  field: {
    textAlign: 'left',
    fontFamily: 'Roboto',
    padding: '4px',
    minHeight: '15px',
    backgroundColor: '#fbfbfb',
    color: '#333',
    borderBottom: `1px solid ${fieldGray}`,
    borderLeft: `1px solid ${fieldGray}`,
    borderRight: `1px solid ${fieldGray}`,
    maxWidth: '100%',
  },
  dateField: {
    textAlign: 'center',
    fontFamily: 'Roboto',
    padding: '4px',
    minHeight: '15px',
    backgroundColor: '#fbfbfb',
    color: '#333',
    borderBottom: `1px solid ${fieldGray}`,
    borderLeft: `1px solid ${fieldGray}`,
    borderRight: `1px solid ${fieldGray}`,
    maxWidth: '100%',
  },
  dataFieldLeft: {
    width: '120px',
    backgroundColor: headerColor,
    textAlign: 'right',
    color: '#333',
    padding: '4px',
    fontWeight: 'medium'
  },
  dataFieldRight: {
    width: '100%',
    fontFamily: 'Roboto',
    padding: '4px',
    backgroundColor: '#fbfbfb',
    color: '#333',
    borderBottom: `1px solid ${fieldGray}`,
    borderRight: `1px solid ${fieldGray}`,
    borderLeft: `1px solid ${fieldGray}`,
    borderTop: `1px solid ${fieldGray}`,
    textAlign: 'center',
  },
  dataFieldContainer: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
  }
});

function MyDocument({ data }: any) {
  return (
    <Document >
      <Page size="A4" orientation="landscape" style={styles.viewer} wrap>
        <View style={styles.pageDivider}>
          <View style={styles.section}>
            <View style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              flexDirection: 'row',
            }}>
              <Image style={{
                width: '70px',
                paddingVertical: '20px',
              }} src={keratos} />
              <View>
                <Text>
                  ID: {data.orderId}
                </Text>
              </View>
            </View>
            <View style={styles.header}>
              <Text>SOLICITUD DE LENTES DE CONTACTO A LABORATORIOS KERATOS SAS</Text>
            </View>
            <View style={{
              display: 'flex',
              flexDirection: 'row',
              width: '100%',
              gap: '0px 2px'
            }}>
              <View style={{
                width: '50%',
              }}>
                <Text style={styles.header}>fecha</Text>
                <Text style={styles.dateField}>{data.createdOrderDate}</Text>
              </View>
              <View style={{
                width: '50%'
              }}>
                <Text style={styles.header}>Nombre de quien realiza el pedido</Text>
                <Text style={styles.field}>{data.quienRealizaElPedido}</Text>
              </View>
            </View>
            <View style={{
              display: 'flex',
              flexDirection: 'row',
              width: '100%',
              gap: '0px 2px'
            }}>
              <View style={{
                width: '50%',
              }}>
                <Text style={styles.header}>Datos del paciente</Text>
              </View>
              <View style={{
                width: '50%'
              }}>
                <Text style={styles.header}>Datos del optometra u oftalmologo</Text>
              </View>
            </View>
            <View style={{
              display: 'flex',
              flexDirection: 'row',
              width: '100%',
              gap: '0px 2px'
            }}>
              <View style={{
                width: '50%',
                display: 'flex',
                flexDirection: 'row',
              }}>
                <View style={{
                  width: '60%'
                }}>
                  <Text style={styles.header}>Nombre</Text>
                  <Text style={styles.field}>{data.nombreDelPaciente}</Text>
                </View>

                <View style={{
                  width: '40%'
                }}>
                  <Text style={[styles.header, { fontSize: '7px' }]}>Numero Documento</Text>
                  <Text style={styles.field}>{data.numeroDeDocumentoDelPaciente}</Text>
                </View>
              </View>
              {/* datos de oftalmologo */}
              <View style={{
                width: '50%',
                display: 'flex',
                flexDirection: 'row',
              }}>
                <View style={{
                  width: '100%'
                }}>
                  <Text style={styles.header}>Nombre</Text>
                  <Text style={styles.field}>{data.nombreDelDoctor}</Text>
                </View>
                {/* Por los momentos el cliente no quiere que se muestre la tarjeta profesional de el optometra */}
                {/* <View style={{
                  width: '40%',
                  textOverflow: 'ellipsis',
                  display: 'flex',
                  flex: 1,
                }}>
                  <Text style={[styles.header, { fontSize: '7px' }]}>Tarjeta Profesional</Text>
                  <Text style={[styles.field, { textOverflow: 'ellipsis', flex: 1, display: 'flex', }]}>{data.tarjetaProfesional}</Text>
                </View> */}
              </View>
            </View>
            <View style={{
              display: 'flex',
              flexDirection: 'row',
              width: '100%',
              gap: '0px 2px'
            }}>
              <View style={{
                width: '50%',
              }}>
                <Text style={styles.header}>ojo izquierdo</Text>
              </View>
              <View style={{
                width: '50%'
              }}>
                <Text style={styles.header}>ojo derecho</Text>
              </View>
            </View>
            <View style={{
              display: 'flex',
              flexDirection: 'row',
              width: '100%',
              gap: '0px 2px'
            }}>
              {data.type_order === 1 &&
                <>
                  {/* ojo izquierdo */}
                  <View style={{ display: 'flex', flexDirection: 'column', width: '100%', gap: '2px' }}>
                    <View style={styles.dataFieldContainer}>
                      <Text style={styles.dataFieldLeft}>Tipo de lente</Text>
                      <Text style={styles.dataFieldRight}>{
                        data.left_typeLens ?
                          data.left_typeLens.name_typeLens ?
                            data.left_typeLens.name_typeLens
                            : ""
                          : ""
                      }</Text>
                    </View>
                    <View style={styles.dataFieldContainer}>
                      <Text style={styles.dataFieldLeft}>Referencia</Text>
                      <Text style={styles.dataFieldRight}>{
                        data.left_referenceLens ?
                          data.left_referenceLens.name_lens ?
                            data.left_referenceLens.name_lens
                            : ""
                          : ""
                      }</Text>
                    </View>
                    {data.left_inputs.includes('1') &&
                      <View style={styles.dataFieldContainer}>
                        <Text style={styles.dataFieldLeft}>Color</Text>
                        <Text style={styles.dataFieldRight}>{
                          data.left_color ?
                            data.left_color.name_colorLens === 'Indique el color del lente' ? 'N/A' : data.left_color.name_colorLens
                            : ""
                        }</Text>
                      </View>
                    }
                    {data.left_inputs.includes('2') &&
                      <View style={styles.dataFieldContainer}>
                        <Text style={styles.dataFieldLeft}>Diseño</Text>
                        <Text style={styles.dataFieldRight}>{
                          data.left_design ?
                            parseInt(data.left_design.id_design) === 0 ? 'N/A' : data.left_design.design_name
                            : ""
                        }</Text>
                      </View>
                    }
                    {data.left_inputs.includes('2') && data.left_design ?
                      parseInt(data.left_design.id_design) === 3 &&
                      <>
                        <View style={styles.dataFieldContainer}>
                          <Text style={styles.dataFieldLeft}>Z.O de Diseño</Text>
                          <Text style={styles.dataFieldRight}>{data.left_dZO ? data.left_dZO : ''}</Text>
                        </View>
                        <View style={styles.dataFieldContainer}>
                          <Text style={styles.dataFieldLeft}>Segunda Curva de Diseño</Text>
                          <Text style={styles.dataFieldRight}>{data.left_dSegundaCurva ? data.left_dSegundaCurva : ''}</Text>
                        </View>
                      </>
                      : <></>
                    }
                    {data.left_inputs.includes('2') && data.left_design ?
                      parseInt(data.left_design.id_design) === 4 &&
                      <View style={styles.dataFieldContainer}>
                        <Text style={styles.dataFieldLeft}>Segunda Curva de Diseño</Text>
                        <Text style={styles.dataFieldRight}>{data.left_dSegundaCurva}</Text>
                      </View>
                      : <></>
                    }
                    {data.left_inputs.includes('3') &&
                      <View style={styles.dataFieldContainer}>
                        <Text style={styles.dataFieldLeft}>Cantidad</Text>
                        <Text style={styles.dataFieldRight}>{data.left_cantidad ? data.left_cantidad : ""}</Text>
                      </View>
                    }
                    {data.left_inputs.includes('4') &&
                      <View style={styles.dataFieldContainer}>
                        <Text style={styles.dataFieldLeft}>C.base</Text>
                        <Text style={styles.dataFieldRight}>{data.left_c_base ? data.left_c_base : ""}</Text>
                      </View>
                    }
                    {data.left_inputs.includes('5') &&
                      <View style={styles.dataFieldContainer}>
                        <Text style={styles.dataFieldLeft}>Poder</Text>
                        <Text style={styles.dataFieldRight}>{data.left_poder ? data.left_poder : ""}</Text>
                      </View>
                    }
                    {data.left_inputs.includes('6') &&
                      <View style={styles.dataFieldContainer}>
                        <Text style={styles.dataFieldLeft}>Diametro</Text>
                        <Text style={styles.dataFieldRight}>{data.left_diametro ? data.left_diametro : ""}</Text>
                      </View>
                    }
                    {data.left_inputs.includes('7') &&
                      <View style={styles.dataFieldContainer}>
                        <Text style={styles.dataFieldLeft}>Z.O</Text>
                        <Text style={styles.dataFieldRight}>{data.left_ZO ? data.left_ZO : ""}</Text>
                      </View>
                    }
                    {data.left_inputs.includes('8') &&
                      <View style={styles.dataFieldContainer}>
                        <Text style={styles.dataFieldLeft}>Adicion</Text>
                        <Text style={styles.dataFieldRight}>{data.left_adicion ? data.left_adicion : ""}</Text>
                      </View>
                    }
                    {data.left_inputs.includes('9') &&
                      <View style={styles.dataFieldContainer}>
                        <Text style={styles.dataFieldLeft}>Cilindro</Text>
                        <Text style={styles.dataFieldRight}>{data.left_cilindro ? data.left_cilindro : ""}</Text>
                      </View>
                    }
                    {data.left_inputs.includes('10') &&
                      <View style={styles.dataFieldContainer}>
                        <Text style={styles.dataFieldLeft}>Grados</Text>
                        <Text style={styles.dataFieldRight}>{data.left_grados ? data.left_grados : ""}</Text>
                      </View>
                    }
                    {data.left_inputs.includes('11') &&
                      <View style={styles.dataFieldContainer}>
                        <Text style={styles.dataFieldLeft}>Periferia 1</Text>
                        <Text style={styles.dataFieldRight}>{data.left_periferia1 ? data.left_periferia1 : ""}</Text>
                      </View>
                    }
                    {data.left_inputs.includes('12') &&
                      <View style={styles.dataFieldContainer}>
                        <Text style={styles.dataFieldLeft}>Periferia 2</Text>
                        <Text style={styles.dataFieldRight}>{data.left_periferia2 ? data.left_periferia2 : ""}</Text>
                      </View>
                    }
                    {data.left_inputs.includes('13') &&
                      <View style={styles.dataFieldContainer}>
                        <Text style={styles.dataFieldLeft}>Cpp</Text>
                        <Text style={styles.dataFieldRight}>{data.left_cpp ? data.left_cpp : ""}</Text>
                      </View>
                    }
                    {data.left_inputs.includes('14') &&
                      <View style={{
                        width: '100%',
                        maxWidth: '100%',
                      }}>
                        <Text style={styles.header}>Observaciones</Text>
                        <Text style={styles.field}>{data.left_observaciones ? data.left_observaciones : ''}</Text>
                      </View>
                    }
                    {data.left_inputs.includes('15') &&
                      <View style={styles.dataFieldContainer}>
                        <Text style={styles.dataFieldLeft}>Curva Invertida</Text>
                        <Text style={styles.dataFieldRight}>{data.left_cInvertida ? data.left_cInvertida : ''}</Text>
                      </View>
                    }
                    {data.left_inputs.includes('16') &&
                      <View style={styles.dataFieldContainer}>
                        <Text style={styles.dataFieldLeft}>Curva de Alineamiento</Text>
                        <Text style={styles.dataFieldRight}>{data.left_cAlineamiento ? data.left_cAlineamiento : ''}</Text>
                      </View>
                    }
                    {data.left_inputs.includes('17') &&
                      <View style={styles.dataFieldContainer}>
                        <Text style={styles.dataFieldLeft}>Curva de Apertura</Text>
                        <Text style={styles.dataFieldRight}>{data.left_cApertura ? data.left_cApertura : ''}</Text>
                      </View>
                    }
                    {data.left_inputs.includes('18') &&
                      <View style={styles.dataFieldContainer}>
                        <Text style={styles.dataFieldLeft}>Z.O de Curva Base</Text>
                        <Text style={styles.dataFieldRight}>{data.left_ZOcBase ? data.left_ZOcBase : ''}</Text>
                      </View>
                    }
                    {data.left_inputs.includes('19') &&
                      <View style={styles.dataFieldContainer}>
                        <Text style={styles.dataFieldLeft}>Z.O de Curva Invertida</Text>
                        <Text style={styles.dataFieldRight}>{data.left_ZOcInvertida ? data.left_ZOcInvertida : ''}</Text>
                      </View>
                    }
                    {data.left_inputs.includes('20') &&
                      <View style={styles.dataFieldContainer}>
                        <Text style={styles.dataFieldLeft}>Z.O de Curva de Alineamiento</Text>
                        <Text style={styles.dataFieldRight}>{data.left_ZOcAlineamiento ? data.left_ZOcAlineamiento : ''}</Text>
                      </View>
                    }
                    {data.left_inputs.includes('21') &&
                      <View style={styles.dataFieldContainer}>
                        <Text style={styles.dataFieldLeft}>Z.O de Curva de Apertura</Text>
                        <Text style={styles.dataFieldRight}>{data.left_ZOcApertura ? data.left_ZOcApertura : ''}</Text>
                      </View>
                    }
                  </View>
                  {/* ojo derecho */}
                  <View style={{ display: 'flex', flexDirection: 'column', width: '100%', gap: '2px' }}>
                    <View style={styles.dataFieldContainer}>
                      <Text style={styles.dataFieldLeft}>Tipo de lente</Text>
                      <Text style={styles.dataFieldRight}>{
                        data.right_typeLens ?
                          data.right_typeLens.name_typeLens ?
                            data.right_typeLens.name_typeLens
                            : ''
                          : ''
                      }</Text>
                    </View>
                    <View style={styles.dataFieldContainer}>
                      <Text style={styles.dataFieldLeft}>Referencia</Text>
                      <Text style={styles.dataFieldRight}>{
                        data.right_referenceLens ?
                          data.right_referenceLens.name_lens ?
                            data.right_referenceLens.name_lens
                            : ''
                          : ''
                      }</Text>
                    </View>
                    {data.right_inputs.includes('1') &&
                      <View style={styles.dataFieldContainer}>
                        <Text style={styles.dataFieldLeft}>Color</Text>
                        <Text style={styles.dataFieldRight}>{
                          data.right_color ?
                            data.right_color.name_colorLens === 'Indique el color del lente' ?
                              '' :
                              data.right_color.name_colorLens
                            : ''
                        }</Text>
                      </View>
                    }
                    {data.right_inputs.includes('2') &&
                      <View style={styles.dataFieldContainer}>
                        <Text style={styles.dataFieldLeft}>Diseño</Text>
                        <Text style={styles.dataFieldRight}>{
                          data.right_design ?
                            parseInt(data.right_design.id_design) === 0 ?
                              ''
                              :
                              data.right_design.design_name
                            : ''
                        }</Text>
                      </View>
                    }
                    {data.right_inputs.includes('2') && data.right_design ?
                      parseInt(data.right_design.id_design) === 3 ?
                        <>
                          <View style={styles.dataFieldContainer}>
                            <Text style={styles.dataFieldLeft}>Z.O de Diseño</Text>
                            <Text style={styles.dataFieldRight}>{data.right_dZO ? data.right_dZO : ''}</Text>
                          </View>
                          <View style={styles.dataFieldContainer}>
                            <Text style={styles.dataFieldLeft}>Segunda Curva de Diseño</Text>
                            <Text style={styles.dataFieldRight}>{data.right_dSegundaCurva ? data.right_dSegundaCurva : ''}</Text>
                          </View>
                        </> : <></>
                      : <></>
                    }
                    {data.right_inputs.includes('2') && data.right_design ?
                      parseInt(data.right_design.id_design) === 4 ?
                        <View style={styles.dataFieldContainer}>
                          <Text style={styles.dataFieldLeft}>Segunda Curva de Diseño</Text>
                          <Text style={styles.dataFieldRight}>{data.right_dSegundaCurva ? data.right_dSegundaCurva : ''}</Text>
                        </View>
                        : <></>
                      : <></>
                    }
                    {data.right_inputs.includes('3') &&
                      <View style={styles.dataFieldContainer}>
                        <Text style={styles.dataFieldLeft}>Cantidad</Text>
                        <Text style={styles.dataFieldRight}>{data.right_cantidad ? data.right_cantidad : ''}</Text>
                      </View>
                    }
                    {data.right_inputs.includes('4') &&
                      <View style={styles.dataFieldContainer}>
                        <Text style={styles.dataFieldLeft}>C.base</Text>
                        <Text style={styles.dataFieldRight}>{data.right_c_base ? data.right_c_base : ''}</Text>
                      </View>
                    }
                    {data.right_inputs.includes('5') &&
                      <View style={styles.dataFieldContainer}>
                        <Text style={styles.dataFieldLeft}>Poder</Text>
                        <Text style={styles.dataFieldRight}>{data.right_poder ? data.right_poder : ''}</Text>
                      </View>
                    }
                    {data.right_inputs.includes('6') &&
                      <View style={styles.dataFieldContainer}>
                        <Text style={styles.dataFieldLeft}>Diametro</Text>
                        <Text style={styles.dataFieldRight}>{data.right_diametro ? data.right_diametro : ''}</Text>
                      </View>
                    }
                    {data.right_inputs.includes('7') &&
                      <View style={styles.dataFieldContainer}>
                        <Text style={styles.dataFieldLeft}>Z.O</Text>
                        <Text style={styles.dataFieldRight}>{data.right_ZO ? data.right_ZO : ''}</Text>
                      </View>
                    }
                    {data.right_inputs.includes('8') &&
                      <View style={styles.dataFieldContainer}>
                        <Text style={styles.dataFieldLeft}>Adicion</Text>
                        <Text style={styles.dataFieldRight}>{data.right_adicion ? data.right_adicion : ''}</Text>
                      </View>
                    }
                    {data.right_inputs.includes('9') &&
                      <View style={styles.dataFieldContainer}>
                        <Text style={styles.dataFieldLeft}>Cilindro</Text>
                        <Text style={styles.dataFieldRight}>{data.right_cilindro ? data.right_cilindro : ''}</Text>
                      </View>
                    }
                    {data.right_inputs.includes('10') &&
                      <View style={styles.dataFieldContainer}>
                        <Text style={styles.dataFieldLeft}>Grados</Text>
                        <Text style={styles.dataFieldRight}>{data.right_grados ? data.right_grados : ''}</Text>
                      </View>
                    }
                    {data.right_inputs.includes('11') &&
                      <View style={styles.dataFieldContainer}>
                        <Text style={styles.dataFieldLeft}>Periferia 1</Text>
                        <Text style={styles.dataFieldRight}>{data.right_periferia1 ? data.right_periferia1 : ''}</Text>
                      </View>
                    }
                    {data.right_inputs.includes('12') &&
                      <View style={styles.dataFieldContainer}>
                        <Text style={styles.dataFieldLeft}>Periferia 2</Text>
                        <Text style={styles.dataFieldRight}>{data.right_periferia2 ? data.right_periferia2 : ''}</Text>
                      </View>
                    }
                    {data.right_inputs.includes('13') &&
                      <View style={styles.dataFieldContainer}>
                        <Text style={styles.dataFieldLeft}>Cpp</Text>
                        <Text style={styles.dataFieldRight}>{data.right_cpp ? data.right_cpp : ''}</Text>
                      </View>
                    }
                    {data.right_inputs.includes('14') &&
                      <View style={{
                        width: '100%',
                        maxWidth: '100%',
                      }}>
                        <Text style={styles.header}>Observaciones</Text>
                        <Text style={styles.field}>{data.right_observaciones ? data.right_observaciones : ''}</Text>
                      </View>
                    }
                    {data.right_inputs.includes('15') &&
                      <View style={styles.dataFieldContainer}>
                        <Text style={styles.dataFieldLeft}>Curva Invertida</Text>
                        <Text style={styles.dataFieldRight}>{data.right_cInvertida ? data.right_cInvertida : ''}</Text>
                      </View>
                    }
                    {data.right_inputs.includes('16') &&
                      <View style={styles.dataFieldContainer}>
                        <Text style={styles.dataFieldLeft}>Curva de Alineamiento</Text>
                        <Text style={styles.dataFieldRight}>{data.right_cAlineamiento ? data.right_cAlineamiento : ''}</Text>
                      </View>
                    }
                    {data.right_inputs.includes('17') &&
                      <View style={styles.dataFieldContainer}>
                        <Text style={styles.dataFieldLeft}>Curva de Apertura</Text>
                        <Text style={styles.dataFieldRight}>{data.right_cApertura ? data.right_cApertura : ''}</Text>
                      </View>
                    }
                    {data.right_inputs.includes('18') &&
                      <View style={styles.dataFieldContainer}>
                        <Text style={styles.dataFieldLeft}>Z.O de Curva Base</Text>
                        <Text style={styles.dataFieldRight}>{data.right_ZOcBase ? data.right_ZOcBase : ''}</Text>
                      </View>
                    }
                    {data.right_inputs.includes('19') &&
                      <View style={styles.dataFieldContainer}>
                        <Text style={styles.dataFieldLeft}>Z.O de Curva Invertida</Text>
                        <Text style={styles.dataFieldRight}>{data.right_ZOcInvertida ? data.right_ZOcInvertida : ''}</Text>
                      </View>
                    }
                    {data.right_inputs.includes('20') &&
                      <View style={styles.dataFieldContainer}>
                        <Text style={styles.dataFieldLeft}>Z.O de Curva de Alineamiento</Text>
                        <Text style={styles.dataFieldRight}>{data.right_ZOcAlineamiento ? data.right_ZOcAlineamiento : ''}</Text>
                      </View>
                    }
                    {data.right_inputs.includes('21') &&
                      <View style={styles.dataFieldContainer}>
                        <Text style={styles.dataFieldLeft}>Z.O de Curva de Apertura</Text>
                        <Text style={styles.dataFieldRight}>{data.right_ZOcApertura ? data.right_ZOcApertura : ''}</Text>
                      </View>
                    }
                  </View>
                </>
              }
              {data.type_order === 2 &&
                <View style={{ display: 'flex', flexDirection: 'column', width: '100%', gap: '2px' }}>
                  <View style={styles.dataFieldContainer}>
                    <Text style={styles.dataFieldLeft}>Caja de Prueba</Text>
                    <Text style={styles.dataFieldRight}>{data.idCaja.box}</Text>
                  </View>
                  <View style={styles.dataFieldContainer}>
                    <Text style={styles.dataFieldLeft}>Cantidad</Text>
                    <Text style={styles.dataFieldRight}>{parseInt(data.idCaja.qty) === 0 ? 1 : data.idCaja.qty}</Text>
                  </View>
                  <View style={{
                    width: '100%',
                    maxWidth: '100%',
                  }}>
                    <Text style={styles.header}>Observaciones</Text>
                    <Text style={styles.field}>{data.left_observaciones}</Text>
                  </View>
                </View>
              }
              {data.type_order === 3 &&
                <View style={{ display: 'flex', flexDirection: 'column', width: '100%', gap: '2px' }}>
                  <View style={styles.dataFieldContainer}>
                    <Text style={styles.dataFieldLeft}>Accesorio</Text>
                    <Text style={styles.dataFieldRight}>{data.idAccesorio.acc}</Text>
                  </View>
                  <View style={styles.dataFieldContainer}>
                    <Text style={styles.dataFieldLeft}>Cantidad</Text>
                    <Text style={styles.dataFieldRight}>{data.left_cantidad}</Text>
                  </View>
                </View>
              }
            </View>
          </View>
          <View style={styles.section}>
            <View style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              flexDirection: 'row',
            }}>
              <Image style={{
                width: '70px',
                paddingVertical: '20px',
              }} src={keratos} />
              <View>
                <Text>
                  ID: {data.orderId}
                </Text>
              </View>
            </View>
            <View style={styles.header}>
              <Text>SOLICITUD DE LENTES DE CONTACTO A LABORATORIOS KERATOS SAS</Text>
            </View>
            <View style={{
              display: 'flex',
              flexDirection: 'row',
              width: '100%',
              gap: '0px 2px'
            }}>
              <View style={{
                width: '50%',
              }}>
                <Text style={styles.header}>fecha</Text>
                <Text style={styles.dateField}>{data.createdOrderDate}</Text>
              </View>
              <View style={{
                width: '50%'
              }}>
                <Text style={styles.header}>Nombre de quien realiza el pedido</Text>
                <Text style={styles.field}>{data.quienRealizaElPedido}</Text>
              </View>
            </View>
            <View style={{
              display: 'flex',
              flexDirection: 'row',
              width: '100%',
              gap: '0px 2px'
            }}>
              <View style={{
                width: '50%',
              }}>
                <Text style={styles.header}>Datos del paciente</Text>
              </View>
              <View style={{
                width: '50%'
              }}>
                <Text style={styles.header}>Datos del optometra u oftalmologo</Text>
              </View>
            </View>
            <View style={{
              display: 'flex',
              flexDirection: 'row',
              width: '100%',
              gap: '0px 2px'
            }}>
              <View style={{
                width: '50%',
                display: 'flex',
                flexDirection: 'row',
              }}>
                <View style={{
                  width: '60%'
                }}>
                  <Text style={styles.header}>Nombre</Text>
                  <Text style={styles.field}>{data.nombreDelPaciente}</Text>
                </View>

                <View style={{
                  width: '40%'
                }}>
                  <Text style={[styles.header, { fontSize: '7px' }]}>Numero Documento</Text>
                  <Text style={styles.field}>{data.numeroDeDocumentoDelPaciente}</Text>
                </View>
              </View>
              {/* datos de oftalmologo */}
              <View style={{
                width: '50%',
                display: 'flex',
                flexDirection: 'row',
              }}>
                <View style={{
                  width: '100%'
                }}>
                  <Text style={styles.header}>Nombre</Text>
                  <Text style={styles.field}>{data.nombreDelDoctor}</Text>
                </View>
                {/* Por los momentos el cliente no quiere que se muestre la tarjeta profesional de el optometra */}
                {/* <View style={{
                  width: '40%',
                  textOverflow: 'ellipsis',
                  display: 'flex',
                  flex: 1,
                }}>
                  <Text style={[styles.header, { fontSize: '7px' }]}>Tarjeta Profesional</Text>
                  <Text style={[styles.field, { textOverflow: 'ellipsis', flex: 1, display: 'flex', }]}>{data.tarjetaProfesional}</Text>
                </View> */}
              </View>
            </View>
            <View style={{
              display: 'flex',
              flexDirection: 'row',
              width: '100%',
              gap: '0px 2px'
            }}>
              <View style={{
                width: '50%',
              }}>
                <Text style={styles.header}>ojo izquierdo</Text>
              </View>
              <View style={{
                width: '50%'
              }}>
                <Text style={styles.header}>ojo derecho</Text>
              </View>
            </View>
            <View style={{
              display: 'flex',
              flexDirection: 'row',
              width: '100%',
              gap: '0px 2px'
            }}>
              {data.type_order === 1 &&
                <>
                  {/* ojo izquierdo */}
                  <View style={{ display: 'flex', flexDirection: 'column', width: '100%', gap: '2px' }}>
                    <View style={styles.dataFieldContainer}>
                      <Text style={styles.dataFieldLeft}>Tipo de lente</Text>
                      <Text style={styles.dataFieldRight}>{
                        data.left_typeLens ?
                          data.left_typeLens.name_typeLens ?
                            data.left_typeLens.name_typeLens
                            : ""
                          : ""
                      }</Text>
                    </View>
                    <View style={styles.dataFieldContainer}>
                      <Text style={styles.dataFieldLeft}>Referencia</Text>
                      <Text style={styles.dataFieldRight}>{
                        data.left_referenceLens ?
                          data.left_referenceLens.name_lens ?
                            data.left_referenceLens.name_lens
                            : ""
                          : ""
                      }</Text>
                    </View>
                    {data.left_inputs.includes('1') &&
                      <View style={styles.dataFieldContainer}>
                        <Text style={styles.dataFieldLeft}>Color</Text>
                        <Text style={styles.dataFieldRight}>{
                          data.left_color ?
                            data.left_color.name_colorLens === 'Indique el color del lente' ? 'N/A' : data.left_color.name_colorLens
                            : ""
                        }</Text>
                      </View>
                    }
                    {data.left_inputs.includes('2') &&
                      <View style={styles.dataFieldContainer}>
                        <Text style={styles.dataFieldLeft}>Diseño</Text>
                        <Text style={styles.dataFieldRight}>{
                          data.left_design ?
                            parseInt(data.left_design.id_design) === 0 ? 'N/A' : data.left_design.design_name
                            : ""
                        }</Text>
                      </View>
                    }
                    {data.left_inputs.includes('2') && data.left_design ?
                      parseInt(data.left_design.id_design) === 3 &&
                      <>
                        <View style={styles.dataFieldContainer}>
                          <Text style={styles.dataFieldLeft}>Z.O de Diseño</Text>
                          <Text style={styles.dataFieldRight}>{data.left_dZO ? data.left_dZO : ''}</Text>
                        </View>
                        <View style={styles.dataFieldContainer}>
                          <Text style={styles.dataFieldLeft}>Segunda Curva de Diseño</Text>
                          <Text style={styles.dataFieldRight}>{data.left_dSegundaCurva ? data.left_dSegundaCurva : ''}</Text>
                        </View>
                      </>
                      : <></>
                    }
                    {data.left_inputs.includes('2') && data.left_design ?
                      parseInt(data.left_design.id_design) === 4 &&
                      <View style={styles.dataFieldContainer}>
                        <Text style={styles.dataFieldLeft}>Segunda Curva de Diseño</Text>
                        <Text style={styles.dataFieldRight}>{data.left_dSegundaCurva}</Text>
                      </View>
                      : <></>
                    }
                    {data.left_inputs.includes('3') &&
                      <View style={styles.dataFieldContainer}>
                        <Text style={styles.dataFieldLeft}>Cantidad</Text>
                        <Text style={styles.dataFieldRight}>{data.left_cantidad ? data.left_cantidad : ""}</Text>
                      </View>
                    }
                    {data.left_inputs.includes('4') &&
                      <View style={styles.dataFieldContainer}>
                        <Text style={styles.dataFieldLeft}>C.base</Text>
                        <Text style={styles.dataFieldRight}>{data.left_c_base ? data.left_c_base : ""}</Text>
                      </View>
                    }
                    {data.left_inputs.includes('5') &&
                      <View style={styles.dataFieldContainer}>
                        <Text style={styles.dataFieldLeft}>Poder</Text>
                        <Text style={styles.dataFieldRight}>{data.left_poder ? data.left_poder : ""}</Text>
                      </View>
                    }
                    {data.left_inputs.includes('6') &&
                      <View style={styles.dataFieldContainer}>
                        <Text style={styles.dataFieldLeft}>Diametro</Text>
                        <Text style={styles.dataFieldRight}>{data.left_diametro ? data.left_diametro : ""}</Text>
                      </View>
                    }
                    {data.left_inputs.includes('7') &&
                      <View style={styles.dataFieldContainer}>
                        <Text style={styles.dataFieldLeft}>Z.O</Text>
                        <Text style={styles.dataFieldRight}>{data.left_ZO ? data.left_ZO : ""}</Text>
                      </View>
                    }
                    {data.left_inputs.includes('8') &&
                      <View style={styles.dataFieldContainer}>
                        <Text style={styles.dataFieldLeft}>Adicion</Text>
                        <Text style={styles.dataFieldRight}>{data.left_adicion ? data.left_adicion : ""}</Text>
                      </View>
                    }
                    {data.left_inputs.includes('9') &&
                      <View style={styles.dataFieldContainer}>
                        <Text style={styles.dataFieldLeft}>Cilindro</Text>
                        <Text style={styles.dataFieldRight}>{data.left_cilindro ? data.left_cilindro : ""}</Text>
                      </View>
                    }
                    {data.left_inputs.includes('10') &&
                      <View style={styles.dataFieldContainer}>
                        <Text style={styles.dataFieldLeft}>Grados</Text>
                        <Text style={styles.dataFieldRight}>{data.left_grados ? data.left_grados : ""}</Text>
                      </View>
                    }
                    {data.left_inputs.includes('11') &&
                      <View style={styles.dataFieldContainer}>
                        <Text style={styles.dataFieldLeft}>Periferia 1</Text>
                        <Text style={styles.dataFieldRight}>{data.left_periferia1 ? data.left_periferia1 : ""}</Text>
                      </View>
                    }
                    {data.left_inputs.includes('12') &&
                      <View style={styles.dataFieldContainer}>
                        <Text style={styles.dataFieldLeft}>Periferia 2</Text>
                        <Text style={styles.dataFieldRight}>{data.left_periferia2 ? data.left_periferia2 : ""}</Text>
                      </View>
                    }
                    {data.left_inputs.includes('13') &&
                      <View style={styles.dataFieldContainer}>
                        <Text style={styles.dataFieldLeft}>Cpp</Text>
                        <Text style={styles.dataFieldRight}>{data.left_cpp ? data.left_cpp : ""}</Text>
                      </View>
                    }
                    {data.left_inputs.includes('14') &&
                      <View style={{
                        width: '100%',
                        maxWidth: '100%',
                      }}>
                        <Text style={styles.header}>Observaciones</Text>
                        <Text style={styles.field}>{data.left_observaciones ? data.left_observaciones : ''}</Text>
                      </View>
                    }
                    {data.left_inputs.includes('15') &&
                      <View style={styles.dataFieldContainer}>
                        <Text style={styles.dataFieldLeft}>Curva Invertida</Text>
                        <Text style={styles.dataFieldRight}>{data.left_cInvertida ? data.left_cInvertida : ''}</Text>
                      </View>
                    }
                    {data.left_inputs.includes('16') &&
                      <View style={styles.dataFieldContainer}>
                        <Text style={styles.dataFieldLeft}>Curva de Alineamiento</Text>
                        <Text style={styles.dataFieldRight}>{data.left_cAlineamiento ? data.left_cAlineamiento : ''}</Text>
                      </View>
                    }
                    {data.left_inputs.includes('17') &&
                      <View style={styles.dataFieldContainer}>
                        <Text style={styles.dataFieldLeft}>Curva de Apertura</Text>
                        <Text style={styles.dataFieldRight}>{data.left_cApertura ? data.left_cApertura : ''}</Text>
                      </View>
                    }
                    {data.left_inputs.includes('18') &&
                      <View style={styles.dataFieldContainer}>
                        <Text style={styles.dataFieldLeft}>Z.O de Curva Base</Text>
                        <Text style={styles.dataFieldRight}>{data.left_ZOcBase ? data.left_ZOcBase : ''}</Text>
                      </View>
                    }
                    {data.left_inputs.includes('19') &&
                      <View style={styles.dataFieldContainer}>
                        <Text style={styles.dataFieldLeft}>Z.O de Curva Invertida</Text>
                        <Text style={styles.dataFieldRight}>{data.left_ZOcInvertida ? data.left_ZOcInvertida : ''}</Text>
                      </View>
                    }
                    {data.left_inputs.includes('20') &&
                      <View style={styles.dataFieldContainer}>
                        <Text style={styles.dataFieldLeft}>Z.O de Curva de Alineamiento</Text>
                        <Text style={styles.dataFieldRight}>{data.left_ZOcAlineamiento ? data.left_ZOcAlineamiento : ''}</Text>
                      </View>
                    }
                    {data.left_inputs.includes('21') &&
                      <View style={styles.dataFieldContainer}>
                        <Text style={styles.dataFieldLeft}>Z.O de Curva de Apertura</Text>
                        <Text style={styles.dataFieldRight}>{data.left_ZOcApertura ? data.left_ZOcApertura : ''}</Text>
                      </View>
                    }
                  </View>
                  {/* ojo derecho */}
                  <View style={{ display: 'flex', flexDirection: 'column', width: '100%', gap: '2px' }}>
                    <View style={styles.dataFieldContainer}>
                      <Text style={styles.dataFieldLeft}>Tipo de lente</Text>
                      <Text style={styles.dataFieldRight}>{
                        data.right_typeLens ?
                          data.right_typeLens.name_typeLens ?
                            data.right_typeLens.name_typeLens
                            : ''
                          : ''
                      }</Text>
                    </View>
                    <View style={styles.dataFieldContainer}>
                      <Text style={styles.dataFieldLeft}>Referencia</Text>
                      <Text style={styles.dataFieldRight}>{
                        data.right_referenceLens ?
                          data.right_referenceLens.name_lens ?
                            data.right_referenceLens.name_lens
                            : ''
                          : ''
                      }</Text>
                    </View>
                    {data.right_inputs.includes('1') &&
                      <View style={styles.dataFieldContainer}>
                        <Text style={styles.dataFieldLeft}>Color</Text>
                        <Text style={styles.dataFieldRight}>{
                          data.right_color ?
                            data.right_color.name_colorLens === 'Indique el color del lente' ?
                              '' :
                              data.right_color.name_colorLens
                            : ''
                        }</Text>
                      </View>
                    }
                    {data.right_inputs.includes('2') &&
                      <View style={styles.dataFieldContainer}>
                        <Text style={styles.dataFieldLeft}>Diseño</Text>
                        <Text style={styles.dataFieldRight}>{
                          data.right_design ?
                            parseInt(data.right_design.id_design) === 0 ?
                              ''
                              :
                              data.right_design.design_name
                            : ''
                        }</Text>
                      </View>
                    }
                    {data.right_inputs.includes('2') && data.right_design ?
                      parseInt(data.right_design.id_design) === 3 ?
                        <>
                          <View style={styles.dataFieldContainer}>
                            <Text style={styles.dataFieldLeft}>Z.O de Diseño</Text>
                            <Text style={styles.dataFieldRight}>{data.right_dZO ? data.right_dZO : ''}</Text>
                          </View>
                          <View style={styles.dataFieldContainer}>
                            <Text style={styles.dataFieldLeft}>Segunda Curva de Diseño</Text>
                            <Text style={styles.dataFieldRight}>{data.right_dSegundaCurva ? data.right_dSegundaCurva : ''}</Text>
                          </View>
                        </> : <></>
                      : <></>
                    }
                    {data.right_inputs.includes('2') && data.right_design ?
                      parseInt(data.right_design.id_design) === 4 ?
                        <View style={styles.dataFieldContainer}>
                          <Text style={styles.dataFieldLeft}>Segunda Curva de Diseño</Text>
                          <Text style={styles.dataFieldRight}>{data.right_dSegundaCurva ? data.right_dSegundaCurva : ''}</Text>
                        </View>
                        : <></>
                      : <></>
                    }
                    {data.right_inputs.includes('3') &&
                      <View style={styles.dataFieldContainer}>
                        <Text style={styles.dataFieldLeft}>Cantidad</Text>
                        <Text style={styles.dataFieldRight}>{data.right_cantidad ? data.right_cantidad : ''}</Text>
                      </View>
                    }
                    {data.right_inputs.includes('4') &&
                      <View style={styles.dataFieldContainer}>
                        <Text style={styles.dataFieldLeft}>C.base</Text>
                        <Text style={styles.dataFieldRight}>{data.right_c_base ? data.right_c_base : ''}</Text>
                      </View>
                    }
                    {data.right_inputs.includes('5') &&
                      <View style={styles.dataFieldContainer}>
                        <Text style={styles.dataFieldLeft}>Poder</Text>
                        <Text style={styles.dataFieldRight}>{data.right_poder ? data.right_poder : ''}</Text>
                      </View>
                    }
                    {data.right_inputs.includes('6') &&
                      <View style={styles.dataFieldContainer}>
                        <Text style={styles.dataFieldLeft}>Diametro</Text>
                        <Text style={styles.dataFieldRight}>{data.right_diametro ? data.right_diametro : ''}</Text>
                      </View>
                    }
                    {data.right_inputs.includes('7') &&
                      <View style={styles.dataFieldContainer}>
                        <Text style={styles.dataFieldLeft}>Z.O</Text>
                        <Text style={styles.dataFieldRight}>{data.right_ZO ? data.right_ZO : ''}</Text>
                      </View>
                    }
                    {data.right_inputs.includes('8') &&
                      <View style={styles.dataFieldContainer}>
                        <Text style={styles.dataFieldLeft}>Adicion</Text>
                        <Text style={styles.dataFieldRight}>{data.right_adicion ? data.right_adicion : ''}</Text>
                      </View>
                    }
                    {data.right_inputs.includes('9') &&
                      <View style={styles.dataFieldContainer}>
                        <Text style={styles.dataFieldLeft}>Cilindro</Text>
                        <Text style={styles.dataFieldRight}>{data.right_cilindro ? data.right_cilindro : ''}</Text>
                      </View>
                    }
                    {data.right_inputs.includes('10') &&
                      <View style={styles.dataFieldContainer}>
                        <Text style={styles.dataFieldLeft}>Grados</Text>
                        <Text style={styles.dataFieldRight}>{data.right_grados ? data.right_grados : ''}</Text>
                      </View>
                    }
                    {data.right_inputs.includes('11') &&
                      <View style={styles.dataFieldContainer}>
                        <Text style={styles.dataFieldLeft}>Periferia 1</Text>
                        <Text style={styles.dataFieldRight}>{data.right_periferia1 ? data.right_periferia1 : ''}</Text>
                      </View>
                    }
                    {data.right_inputs.includes('12') &&
                      <View style={styles.dataFieldContainer}>
                        <Text style={styles.dataFieldLeft}>Periferia 2</Text>
                        <Text style={styles.dataFieldRight}>{data.right_periferia2 ? data.right_periferia2 : ''}</Text>
                      </View>
                    }
                    {data.right_inputs.includes('13') &&
                      <View style={styles.dataFieldContainer}>
                        <Text style={styles.dataFieldLeft}>Cpp</Text>
                        <Text style={styles.dataFieldRight}>{data.right_cpp ? data.right_cpp : ''}</Text>
                      </View>
                    }
                    {data.right_inputs.includes('14') &&
                      <View style={{
                        width: '100%',
                        maxWidth: '100%',
                      }}>
                        <Text style={styles.header}>Observaciones</Text>
                        <Text style={styles.field}>{data.right_observaciones ? data.right_observaciones : ''}</Text>
                      </View>
                    }
                    {data.right_inputs.includes('15') &&
                      <View style={styles.dataFieldContainer}>
                        <Text style={styles.dataFieldLeft}>Curva Invertida</Text>
                        <Text style={styles.dataFieldRight}>{data.right_cInvertida ? data.right_cInvertida : ''}</Text>
                      </View>
                    }
                    {data.right_inputs.includes('16') &&
                      <View style={styles.dataFieldContainer}>
                        <Text style={styles.dataFieldLeft}>Curva de Alineamiento</Text>
                        <Text style={styles.dataFieldRight}>{data.right_cAlineamiento ? data.right_cAlineamiento : ''}</Text>
                      </View>
                    }
                    {data.right_inputs.includes('17') &&
                      <View style={styles.dataFieldContainer}>
                        <Text style={styles.dataFieldLeft}>Curva de Apertura</Text>
                        <Text style={styles.dataFieldRight}>{data.right_cApertura ? data.right_cApertura : ''}</Text>
                      </View>
                    }
                    {data.right_inputs.includes('18') &&
                      <View style={styles.dataFieldContainer}>
                        <Text style={styles.dataFieldLeft}>Z.O de Curva Base</Text>
                        <Text style={styles.dataFieldRight}>{data.right_ZOcBase ? data.right_ZOcBase : ''}</Text>
                      </View>
                    }
                    {data.right_inputs.includes('19') &&
                      <View style={styles.dataFieldContainer}>
                        <Text style={styles.dataFieldLeft}>Z.O de Curva Invertida</Text>
                        <Text style={styles.dataFieldRight}>{data.right_ZOcInvertida ? data.right_ZOcInvertida : ''}</Text>
                      </View>
                    }
                    {data.right_inputs.includes('20') &&
                      <View style={styles.dataFieldContainer}>
                        <Text style={styles.dataFieldLeft}>Z.O de Curva de Alineamiento</Text>
                        <Text style={styles.dataFieldRight}>{data.right_ZOcAlineamiento ? data.right_ZOcAlineamiento : ''}</Text>
                      </View>
                    }
                    {data.right_inputs.includes('21') &&
                      <View style={styles.dataFieldContainer}>
                        <Text style={styles.dataFieldLeft}>Z.O de Curva de Apertura</Text>
                        <Text style={styles.dataFieldRight}>{data.right_ZOcApertura ? data.right_ZOcApertura : ''}</Text>
                      </View>
                    }
                  </View>
                </>
              }
              {data.type_order === 2 &&
                <View style={{ display: 'flex', flexDirection: 'column', width: '100%', gap: '2px' }}>
                  <View style={styles.dataFieldContainer}>
                    <Text style={styles.dataFieldLeft}>Caja de Prueba</Text>
                    <Text style={styles.dataFieldRight}>{data.idCaja.box}</Text>
                  </View>
                  <View style={styles.dataFieldContainer}>
                    <Text style={styles.dataFieldLeft}>Cantidad</Text>
                    <Text style={styles.dataFieldRight}>{parseInt(data.idCaja.qty) === 0 ? 1 : data.idCaja.qty}</Text>
                  </View>
                  <View style={{
                    width: '100%',
                    maxWidth: '100%',
                  }}>
                    <Text style={styles.header}>Observaciones</Text>
                    <Text style={styles.field}>{data.left_observaciones}</Text>
                  </View>
                </View>
              }
              {data.type_order === 3 &&
                <View style={{ display: 'flex', flexDirection: 'column', width: '100%', gap: '2px' }}>
                  <View style={styles.dataFieldContainer}>
                    <Text style={styles.dataFieldLeft}>Accesorio</Text>
                    <Text style={styles.dataFieldRight}>{data.idAccesorio.acc}</Text>
                  </View>
                  <View style={styles.dataFieldContainer}>
                    <Text style={styles.dataFieldLeft}>Cantidad</Text>
                    <Text style={styles.dataFieldRight}>{data.left_cantidad}</Text>
                  </View>
                </View>
              }
            </View>
          </View>
        </View>
      </Page>
    </Document>
  )
}

export function DownloadPDF({ data }: any) {
  const [instance, updateInstance] = usePDF({ document: <MyDocument data={data} /> });
  if (instance.loading) return <div>Cargando PDF...</div>;

  if (instance.error) return <div>Algo anda mal: {instance.error}</div>;

  return (
    <>
      <PDFDownloadLink className="group flex items-center justify-center gap-2 rounded-lg border border-blue-900 bg-blue-700 px-3 py-2 transition-colors hover:bg-blue-200 focus:outline-none focus:ring" document={<MyDocument data={data} />} fileName={`pedido-${data.orderId}.pdf`}>
        <span className="capitalize text-sm font-medium text-white transition-colors group-active:text-blue-900 group-hover:text-blue-900">Descargar en PDF</span>
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="h-[30px] w-[30px] text-white group-hover:text-blue-900">
          <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m.75 12l3 3m0 0l3-3m-3 3v-6m-1.5-9H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z" />
        </svg>

      </PDFDownloadLink>
    </>
  )
}



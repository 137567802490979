import { Menu, Transition } from '@headlessui/react'
import { Fragment, useEffect } from 'react'
import { PopUp } from '../PopUp'
import { useStateMany } from '../customHooks/useStateMany'
import { userType } from '../typos'
import { API } from '../API'

interface ProfilesTypes {
  user: userType;
}

function Notification({ user }: ProfilesTypes) {
  const initialState = {
    newNotifications: 0,
    notifications: [],
    reload: false,
    dataLoaded: false,
    waitingForResponse: false,
  }
  const [state, setState] = useStateMany({
    ...initialState
  })

  function markAsRead(id_noti: any) {
    let dataToSend = {
      API_KEY: process.env.REACT_APP_API_KEY,
      id_noti: parseInt(id_noti),
    }
    API.post('/?markNotifationAsRead', {
      ...dataToSend
    }).then((response) => {
      setState({ waitingForResponse: false })
      if (response.status === 200 && response.data.success === 1) {
        // declaro una copia de las notificaciones
        let notificationsCopy = state.notifications;
        // busco el id de la notificacion que recibo como parametro
        notificationsCopy.forEach((item: any) => {
          if (parseInt(item.id_noti) === parseInt(id_noti)) {
            // si lo encuentro lo cambio a 1 (leido)
            item.readed = 1;
          }
        })
        // luego reemplazo el estado actual por el nuevo con la notificacion leida
        setState({
          notifications: [...notificationsCopy],
          newNotifications: calcUnreadNotifications(notificationsCopy)
        })
      } else {
        console.log('Ocurrio un error:', response)
      }
    }).catch((error) => {
      console.log('Ha ocurrido un error con la respuesta del servidor', error)
    })
  }

  function markAllAsRead() {
    let dataToSend = {
      API_KEY: process.env.REACT_APP_API_KEY,
      idSession: parseInt(user.id),
    }
    API.post('/?markAllNotifationAsRead', {
      ...dataToSend
    }).then((response) => {
      setState({ waitingForResponse: false })
      if (response.status === 200 && response.data.success === 1) {
        setState({ reload: !state.reload })
      } else {
        console.log('Ocurrio un error:', response)
      }
    }).catch((error) => {
      console.log('Ha ocurrido un error con la respuesta del servidor', error)
    })
  }
  function calcUnreadNotifications(toSet:any){
    let newsNotificationsAmount = 0;
    toSet.forEach((item: any) => parseInt(item.readed) === 0 && newsNotificationsAmount++)
    return newsNotificationsAmount;
  }
  useEffect(() => {
    let dataToSend = {
      API_KEY: process.env.REACT_APP_API_KEY,
      idSession: parseInt(user.id),
    }
    setState({ waitingForResponse: true, dataLoaded: false })
    API.post('/?searchNotifications', {
      ...dataToSend
    }).then((response) => {
      setState({ waitingForResponse: false })
      if (response.status === 200) {
        let toSet = response.data.notifications ? response.data.notifications : [];
        calcUnreadNotifications(toSet)
        setState({
          notifications: [...toSet],
          dataLoaded: true,
          newNotifications: calcUnreadNotifications(toSet),
        })
      } else {
        console.log('Ocurrio un error:', response)
      }
    }).catch((error) => {
      console.log('Ha ocurrido un error con la respuesta del servidor', error)
    })
  }, [state.reload])
  return (
    <div className="text-right">
      <Menu as="div" className="relative inline-block text-left">
        <div className='relative'>
          <Menu.Button className='p-2 bg-gray-200 rounded-lg hover:bg-gray-600 hover:text-white'>
            {state.newNotifications > 0 ?
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-7 h-7">
                <path strokeLinecap="round" strokeLinejoin="round" d="M14.857 17.082a23.848 23.848 0 005.454-1.31A8.967 8.967 0 0118 9.75v-.7V9A6 6 0 006 9v.75a8.967 8.967 0 01-2.312 6.022c1.733.64 3.56 1.085 5.455 1.31m5.714 0a24.255 24.255 0 01-5.714 0m5.714 0a3 3 0 11-5.714 0M3.124 7.5A8.969 8.969 0 015.292 3m13.416 0a8.969 8.969 0 012.168 4.5" />
              </svg>
              :
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-7 h-7">
                <path strokeLinecap="round" strokeLinejoin="round" d="M14.857 17.082a23.848 23.848 0 005.454-1.31A8.967 8.967 0 0118 9.75v-.7V9A6 6 0 006 9v.75a8.967 8.967 0 01-2.312 6.022c1.733.64 3.56 1.085 5.455 1.31m5.714 0a24.255 24.255 0 01-5.714 0m5.714 0a3 3 0 11-5.714 0" />
              </svg>
            }


          </Menu.Button>
          <div className={`w-6 h-6 ${state.newNotifications > 0 ? 'flex' : 'hidden'} items-center justify-center text-white font-bold text-sm rounded-full bg-red-500 absolute right-[-10px] top-[-10px]`}>{state.newNotifications}</div>
        </div>
        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="scroll-smooth z-60 absolute right-0 mt-2 max-w-[380px] w-screen origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none max-h-[450px] overflow-y-auto">
            <div className="px-2 py-2 z-60 flex items-center justify-between sticky bg-white w-full top-0 shadow-sm">
              <span className='font-medium'>Notificaciones</span>
              <div className='flex items-center justify-center gap-x-2'>
                <PopUp message='Actualizar notificaciones'>
                  {/* reload notifications button */}
                  <button onClick={() => { setState({ reload: !state.reload }) }} type="button" className='bg-gray-100 p-1 rounded-lg'>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                      <path strokeLinecap="round" strokeLinejoin="round" d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99" />
                    </svg>
                  </button>
                </PopUp>
                <PopUp message='Marcar todo como leido'>
                  <button className='bg-gray-100 p-1 rounded-lg' type="button" onClick={()=>{markAllAsRead()}}>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                      <path strokeLinecap="round" strokeLinejoin="round" d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z" />
                      <path strokeLinecap="round" strokeLinejoin="round" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                    </svg>
                  </button>
                </PopUp>
              </div>
            </div>

            <div className={`flex-col flex divide-y divide-gray-300 pb-8 ${state.waitingForResponse === false && state.dataLoaded === true && state.notifications.length === 0 && 'flex items-center justify-center py-10'}`}>
              {state.waitingForResponse === false && state.dataLoaded === true && state.notifications.length > 0 ?
                state.notifications.map((item: any) => {
                  return (
                    <NotificationItem key={item.id_noti} item={item} markAsRead={markAsRead} />
                  )
                })
                : <></>
              }
              {state.waitingForResponse === false && state.dataLoaded === true && state.notifications.length === 0 &&
                <p className="text-gray-500 font-medium">No tienes Notificaciones</p>
              }
            </div>
          </Menu.Items>
        </Transition>
      </Menu>
    </div>
  )
}

function NotificationItem({ item, markAsRead }: any) {
  let itemDate = item.created_at.replace(/-/ig, '/');
  if (item.name_type === "entregado") {
    return (
      <div className={`py-3 px-3 relative hover:bg-gray-300 flex items-center justify-between ${parseInt(item.readed) === 0 && 'bg-gray-200'} cursor-pointer`}>
        <div>
          <p className='text-sm mb-2'>Se ha <strong className='font-medium text-indigo-600'>Entregado</strong> el pedido <strong className='font-medium'>{item.id_order}</strong> por <strong className='font-medium'>{item.email}</strong></p>
          <p className='text-sm'>Fecha: {itemDate}</p>
        </div>
        <div className='text-indigo-500 group hover:bg-gray-800 hover:text-white bg-white rounded-full p-1.5 flex items-center justify-center'>
          <PopUp message="Marcar como leido" className="z-50">
            <button type="button" onClick={() => { markAsRead(item.id_noti) }} className='w-10 h-10 hidden group-hover:block'>
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-10 h-10">
                <path strokeLinecap="round" strokeLinejoin="round" d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z" />
                <path strokeLinecap="round" strokeLinejoin="round" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
              </svg>
            </button>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-10 h-10 group-hover:hidden">
              <path d="M11.47 1.72a.75.75 0 011.06 0l3 3a.75.75 0 01-1.06 1.06l-1.72-1.72V7.5h-1.5V4.06L9.53 5.78a.75.75 0 01-1.06-1.06l3-3zM11.25 7.5V15a.75.75 0 001.5 0V7.5h3.75a3 3 0 013 3v9a3 3 0 01-3 3h-9a3 3 0 01-3-3v-9a3 3 0 013-3h3.75z" />
            </svg>
          </PopUp>
        </div>
        <div className='absolute left-0 top-0 w-1 h-full bg-indigo-500'></div>
      </div>
    )
  }
  if (item.name_type === "rechazado") {
    return (
      <div className={`py-3 px-3 relative hover:bg-gray-300 flex items-center justify-between ${parseInt(item.readed) === 0 && 'bg-gray-200'} cursor-pointer`}>
        <div>
          <p className='text-sm mb-2'>Se ha <strong className='font-medium text-red-500'>Rechazado</strong> el pedido <strong className='font-medium'>{item.id_order}</strong> por <strong className='font-medium'>{item.email}</strong></p>
          <p className='text-sm'>Fecha: {itemDate}</p>
        </div>
        <div className='text-red-500 group hover:bg-gray-800 hover:text-white bg-white rounded-full p-1.5 flex items-center justify-center'>
          <PopUp message="Marcar como leido" className="z-50">
            <button type="button" className='w-10 h-10 hidden group-hover:block' onClick={() => { markAsRead(item.id_noti) }}>
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-10 h-10">
                <path strokeLinecap="round" strokeLinejoin="round" d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z" />
                <path strokeLinecap="round" strokeLinejoin="round" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
              </svg>
            </button>
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-10 h-10 group-hover:hidden">
              <path strokeLinecap="round" strokeLinejoin="round" d="M8.25 9.75h4.875a2.625 2.625 0 010 5.25H12M8.25 9.75L10.5 7.5M8.25 9.75L10.5 12m9-7.243V21.75l-3.75-1.5-3.75 1.5-3.75-1.5-3.75 1.5V4.757c0-1.108.806-2.057 1.907-2.185a48.507 48.507 0 0111.186 0c1.1.128 1.907 1.077 1.907 2.185z" />
            </svg>
          </PopUp>
        </div>
        <div className='absolute left-0 top-0 w-1 h-full bg-red-500'></div>
      </div>
    )
  }
  if (item.name_type === "anulado") {
    return (
      <div className={`py-3 px-3 relative hover:bg-gray-300 flex items-center justify-between ${parseInt(item.readed) === 0 && 'bg-gray-200'} cursor-pointer`}>
        <div>
          <p className='text-sm mb-2'>Se ha <strong className='font-medium text-red-700'>Anulado</strong> el pedido <strong className='font-medium'>{item.id_order}</strong> por <strong className='font-medium'>{item.email}</strong></p>
          <p className='text-sm'>Fecha: {itemDate}</p>
        </div>
        <div className='text-red-700 group hover:bg-gray-800 hover:text-white bg-white rounded-full p-1.5 flex items-center justify-center'>
          <PopUp message="Marcar como leido" className="z-50">
            <button type="button" className='w-10 h-10 hidden group-hover:block' onClick={() => { markAsRead(item.id_noti) }}>
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-10 h-10">
                <path strokeLinecap="round" strokeLinejoin="round" d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z" />
                <path strokeLinecap="round" strokeLinejoin="round" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
              </svg>
            </button>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-10 h-10 group-hover:hidden">
              <path fillRule="evenodd" d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25zm-1.72 6.97a.75.75 0 10-1.06 1.06L10.94 12l-1.72 1.72a.75.75 0 101.06 1.06L12 13.06l1.72 1.72a.75.75 0 101.06-1.06L13.06 12l1.72-1.72a.75.75 0 10-1.06-1.06L12 10.94l-1.72-1.72z" clipRule="evenodd" />
            </svg>
          </PopUp>
        </div>
        <div className='absolute left-0 top-0 w-1 h-full bg-red-700'></div>
      </div>
    )
  }
  if (item.name_type === "aceptado") {
    return (
      <div className={`py-3 px-3 relative hover:bg-gray-300 flex items-center justify-between ${parseInt(item.readed) === 0 && 'bg-gray-200'} cursor-pointer`}>
        <div>
          <p className='text-sm mb-2'>Se ha <strong className='font-medium text-green-600'>Aceptado</strong> el pedido <strong className='font-medium'>{item.id_order}</strong> por <strong className='font-medium'>{item.email}</strong></p>
          <p className='text-sm'>Fecha: {itemDate}</p>
        </div>
        <div className='text-green-500 group hover:bg-gray-800 hover:text-white bg-white rounded-full p-1.5 flex items-center justify-center'>
          <PopUp message="Marcar como leido" className="z-50">
            <button type="button" className='w-10 h-10 hidden group-hover:block' onClick={() => { markAsRead(item.id_noti) }}>
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-10 h-10">
                <path strokeLinecap="round" strokeLinejoin="round" d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z" />
                <path strokeLinecap="round" strokeLinejoin="round" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
              </svg>
            </button>
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-10 h-10 group-hover:hidden">
              <path strokeLinecap="round" strokeLinejoin="round" d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
            </svg>
          </PopUp>
        </div>
        <div className='absolute left-0 top-0 w-1 h-full bg-green-500'></div>
      </div>
    )
  }

  if (item.name_type === "revision") {
    return (
      <div className={`py-3 px-3 relative hover:bg-gray-300 flex items-center justify-between ${parseInt(item.readed) === 0 && 'bg-gray-200'} cursor-pointer`}>
        <div>
          <p className='text-sm mb-2'>El pedido <strong className='font-medium'>{item.id_order}</strong> a cambiado su estado a <strong className='font-medium text-slate-500'>Revision</strong>  por <strong className='font-medium'>{item.email}</strong></p>
          <p className='text-sm'>Fecha: {itemDate}</p>
        </div>
        <div className='text-slate-500 group hover:bg-gray-800 hover:text-white bg-white rounded-full p-1.5 flex items-center justify-center'>
          <PopUp message="Marcar como leido" className="z-50">
            <button type="button" className='w-10 h-10 hidden group-hover:block' onClick={() => { markAsRead(item.id_noti) }}>
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-10 h-10">
                <path strokeLinecap="round" strokeLinejoin="round" d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z" />
                <path strokeLinecap="round" strokeLinejoin="round" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
              </svg>
            </button>
            <svg className="h-10 w-10 group-hover:hidden" viewBox="0 0 22 16" version="1.1">
              <g id="Icons" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="Rounded" transform="translate(-747.000000, -2904.000000)">
                  <g id="Image" transform="translate(100.000000, 2626.000000)">
                    <g id="-Round-/-Image-/-remove_red_eye" transform="translate(646.000000, 274.000000)">
                      <g className="fill-slate-600 group-hover:fill-white">
                        <polygon className="fill-transparent" id="Path" points="0 0 24 0 24 24 0 24" />
                        <path d="M12,4.5 C7,4.5 2.73,7.61 1,12 C2.73,16.39 7,19.5 12,19.5 C17,19.5 21.27,16.39 23,12 C21.27,7.61 17,4.5 12,4.5 Z M12,17 C9.24,17 7,14.76 7,12 C7,9.24 9.24,7 12,7 C14.76,7 17,9.24 17,12 C17,14.76 14.76,17 12,17 Z M12,9 C10.34,9 9,10.34 9,12 C9,13.66 10.34,15 12,15 C13.66,15 15,13.66 15,12 C15,10.34 13.66,9 12,9 Z" />
                      </g>
                    </g>
                  </g>
                </g>
              </g>
            </svg>
          </PopUp>
        </div>
        <div className='absolute left-0 top-0 w-1 h-full bg-slate-500'></div>
      </div>
    )
  }
  if (item.name_type === "completado") {
    return (
      <div className={`py-3 px-3 relative hover:bg-gray-300 flex items-center justify-between ${parseInt(item.readed) === 0 && 'bg-gray-200'} cursor-pointer`}>
        <div>
          <p className='text-sm mb-2'>Se ha <strong className='font-medium text-amber-600'>Completado</strong> el pedido <strong className='font-medium'>{item.id_order}</strong> por <strong className='font-medium'>{item.email}</strong></p>
          <p className='text-sm'>Fecha: {itemDate}</p>
        </div>
        <div className='text-amber-500 group hover:bg-gray-800 hover:text-white bg-white rounded-full p-1.5 flex items-center justify-center'>
          <PopUp message="Marcar como leido" className="z-50">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-10 h-10 group-hover:hidden">
              <path d="M12.378 1.602a.75.75 0 00-.756 0L3 6.632l9 5.25 9-5.25-8.622-5.03zM21.75 7.93l-9 5.25v9l8.628-5.032a.75.75 0 00.372-.648V7.93zM11.25 22.18v-9l-9-5.25v8.57a.75.75 0 00.372.648l8.628 5.033z" />
            </svg>
            <button type="button" className='w-10 h-10 hidden group-hover:block' onClick={() => { markAsRead(item.id_noti) }}>
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-10 h-10">
                <path strokeLinecap="round" strokeLinejoin="round" d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z" />
                <path strokeLinecap="round" strokeLinejoin="round" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
              </svg>
            </button>
          </PopUp>
        </div>
        <div className='absolute left-0 top-0 w-1 h-full bg-amber-500'></div>
      </div>
    )
  }
  if (item.name_type === "normal") {
    return (
      <div className={`py-3 px-3 relative hover:bg-gray-300 flex items-center justify-between ${parseInt(item.readed) === 0 && 'bg-gray-200'} cursor-pointer`}>
        <div>
          <p className='text-sm mb-2'>{item.data} con el ID <span className='font-medium'>{item.id_order}</span> por <strong className='font-medium'>{item.email}</strong></p>
          <p className='text-sm'>Fecha: {itemDate}</p>
        </div>
        <div className='text-slate-500 group hover:bg-gray-800 hover:text-white bg-white rounded-full p-1.5 flex items-center justify-center'>
          <PopUp message="Marcar como leido" className="z-50">
            <button type="button" className='w-10 h-10 hidden group-hover:block' onClick={() => { markAsRead(item.id_noti) }}>
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-10 h-10">
                <path strokeLinecap="round" strokeLinejoin="round" d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z" />
                <path strokeLinecap="round" strokeLinejoin="round" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
              </svg>
            </button>
            <svg className="h-10 w-10 group-hover:hidden" viewBox="0 0 22 16" version="1.1">
              <g id="Icons" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="Rounded" transform="translate(-747.000000, -2904.000000)">
                  <g id="Image" transform="translate(100.000000, 2626.000000)">
                    <g id="-Round-/-Image-/-remove_red_eye" transform="translate(646.000000, 274.000000)">
                      <g className="fill-slate-600 group-hover:fill-white">
                        <polygon className="fill-transparent" id="Path" points="0 0 24 0 24 24 0 24" />
                        <path d="M12,4.5 C7,4.5 2.73,7.61 1,12 C2.73,16.39 7,19.5 12,19.5 C17,19.5 21.27,16.39 23,12 C21.27,7.61 17,4.5 12,4.5 Z M12,17 C9.24,17 7,14.76 7,12 C7,9.24 9.24,7 12,7 C14.76,7 17,9.24 17,12 C17,14.76 14.76,17 12,17 Z M12,9 C10.34,9 9,10.34 9,12 C9,13.66 10.34,15 12,15 C13.66,15 15,13.66 15,12 C15,10.34 13.66,9 12,9 Z" />
                      </g>
                    </g>
                  </g>
                </g>
              </g>
            </svg>
          </PopUp>
        </div>
        <div className='absolute left-0 top-0 w-1 h-full bg-slate-500'></div>
      </div>
      // <div className={`py-3 px-3 relative hover:bg-gray-300 ${parseInt(item.readed) === 0 && 'bg-gray-200'} cursor-pointer`}>

      //   <p className='text-sm'>Fecha: {itemDate}</p>
      //   <div className='absolute left-0 top-0 w-1 h-full bg-gray-400'></div>

      // </div>
    )

  }
  return <></>
}

export { Notification }
<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
  <path strokeLinecap="round" strokeLinejoin="round" d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z" />
</svg>

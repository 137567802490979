import React, { useCallback, useEffect, useState } from "react";
import { read, utils, writeFileXLSX } from 'xlsx';
import { API } from '../API';

// import excelIcon from '../assets/excel.svg'

// const f = await (await fetch("https://sheetjs.com/pres.xlsx")).arrayBuffer();
// const wb = read(f);
// const data = utils.sheet_to_json<President>(wb.Sheets[wb.SheetNames[0]]);
function DownloadExcel({ data }: any) {
  const [pres, setPres] = useState([]);
  // const [sheetData, setSheetData] = useState(null);

  /* Fetch and update the state once */
  useEffect(() => {
    (async () => {
      // const f = await (await fetch("https://sheetjs.com/pres.xlsx")).arrayBuffer();
      // const wb = read(f); // parse the array buffer
      // const ws = wb.Sheets[wb.SheetNames[0]]; // get the first worksheet
      // const data: any = utils.sheet_to_json(ws); // generate objects
      // setPres(data); // update state
    })();
  }, []);
  /* get state data and export to XLSX */
  const exportFile = useCallback((event: React.MouseEvent<HTMLButtonElement, MouseEvent>, data: any) => {
    event.preventDefault();
    const searchData = (e?: any) => {
      let dataToSend = {}
      if ((data.estado === "seleccione un estado" || data.estado === "todos") && (data.tipo === 4 || data.tipo === 0)) {
        dataToSend = {
          API_KEY: process.env.REACT_APP_API_KEY,
          idSession: data.user.id,
          permissions: data.user.permissions,
          date: `${data.fecha}`,
        }

      } else if (data.tipo === 4 || data.tipo === 0) {
        dataToSend = {
          API_KEY: process.env.REACT_APP_API_KEY,
          idSession: data.user.id,
          permissions: data.user.permissions,
          date: `${data.fecha}`,
          statusOrder: data.estado,
        }
      } else if (data.estado === "seleccione un estado" || data.estado === "todos") {
        dataToSend = {
          API_KEY: process.env.REACT_APP_API_KEY,
          idSession: data.user.id,
          permissions: data.user.permissions,
          date: `${data.fecha}`,
          typeOrder: data.tipo,
        }
      }else{
        dataToSend = {
          API_KEY: process.env.REACT_APP_API_KEY,
          idSession: data.user.id,
          permissions: data.user.permissions,
          date: `${data.fecha}`,
          statusOrder: data.estado,
          typeOrder: data.tipo,
        }
      }
      if (data.fecha !== '' || data.fecha !== null) {
        API.post('/?orderfilter', {
          ...dataToSend
        }).then((response) => {
          if (response.status === 200 && response.data) {
            let arr:any = []
            let responseData = response.data.orders;
            responseData.forEach((el:any)=>{
              arr.push({
                "Fecha orden del pedido":el.created_order,
                "Año":`${el.created_order[2]}${el.created_order[3]}`,
                "Mes":`${el.created_order[5]}${el.created_order[6]}`,
                "Orden del pedido":el.id_order,
                "Cliente":el.optometrist_name,
                "Paciente":el.patient_name,
                "Ciudad":"",
                "Nombre de quien recibe el pedido":el.creator_name,
                "Producto 1 O.D":'',
                "Producto 2 O.I":'',
                "Cantidad Producto 1":el.rQty === null ? 1 : el.rQty,
                "Cantidad Producto 2":el.lQty === null ? 1 : el.lQty,
              })
            })
            // setSheetData(data);
            const ws = utils.json_to_sheet(arr);
            ws["!cols"] = [ { wch: 20 },{ wch: 8 },{ wch: 8 },{ wch: 15 },{ wch: 15 },{ wch: 15 },{ wch: 15 },{ wch: 25 },{ wch:13 },{ wch:13 },{ wch:16 },{ wch: 16 } ];
            const wb = utils.book_new();
            utils.book_append_sheet(wb, ws, "Data1");
            writeFileXLSX(wb, "SheetJSReactAoO.xlsx");
            // setState({ data: response.data.myOrders, dataLoaded: true, loading: false })
          }
        })
      }
    }
    searchData()

  }, [pres]);
  // return (
  //   <>
  //     {/* <table><thead><th>Name</th><th>Index</th></thead><tbody>
  //       {
  //         pres.map((pres: any) => (
  //           <tr>
  //             <td>{pres.Name}</td>
  //             <td>{pres.Index}</td>
  //           </tr>
  //         ))
  //       }
  //     </tbody><tfoot><td colSpan={2}>
  //     </td></tfoot></table> */}
  //     <button >Export XLSX</button>
  //   </>
  // );
  return (
    <button onClick={(event) => { exportFile(event, data) }} className="group flex items-center justify-center gap-2 rounded-lg border border-[#1D6F42] bg-[#1D6F42] px-3 py-2 transition-colors hover:bg-white focus:outline-none focus:ring" >
      <span className="capitalize text-sm font-medium text-white transition-colors group-active:text-[#1D6F42] group-hover:text-[#1D6F42]">Descargar en Excel</span>
      <svg xmlns="http://www.w3.org/2000/svg" className="h-[30px] w-[30px] text-white group-hover:text-green-900" viewBox="0 0 50 50"><path className={`fill-white text-white group-hover:fill-[#1D6F42]`} d="M 28.875 0 C 28.855469 0.0078125 28.832031 0.0195313 28.8125 0.03125 L 0.8125 5.34375 C 0.335938 5.433594 -0.0078125 5.855469 0 6.34375 L 0 43.65625 C -0.0078125 44.144531 0.335938 44.566406 0.8125 44.65625 L 28.8125 49.96875 C 29.101563 50.023438 29.402344 49.949219 29.632813 49.761719 C 29.859375 49.574219 29.996094 49.296875 30 49 L 30 44 L 47 44 C 48.09375 44 49 43.09375 49 42 L 49 8 C 49 6.90625 48.09375 6 47 6 L 30 6 L 30 1 C 30.003906 0.710938 29.878906 0.4375 29.664063 0.246094 C 29.449219 0.0546875 29.160156 -0.0351563 28.875 0 Z M 28 2.1875 L 28 6.53125 C 27.867188 6.808594 27.867188 7.128906 28 7.40625 L 28 42.8125 C 27.972656 42.945313 27.972656 43.085938 28 43.21875 L 28 47.8125 L 2 42.84375 L 2 7.15625 Z M 30 8 L 47 8 L 47 42 L 30 42 L 30 37 L 34 37 L 34 35 L 30 35 L 30 29 L 34 29 L 34 27 L 30 27 L 30 22 L 34 22 L 34 20 L 30 20 L 30 15 L 34 15 L 34 13 L 30 13 Z M 36 13 L 36 15 L 44 15 L 44 13 Z M 6.6875 15.6875 L 12.15625 25.03125 L 6.1875 34.375 L 11.1875 34.375 L 14.4375 28.34375 C 14.664063 27.761719 14.8125 27.316406 14.875 27.03125 L 14.90625 27.03125 C 15.035156 27.640625 15.160156 28.054688 15.28125 28.28125 L 18.53125 34.375 L 23.5 34.375 L 17.75 24.9375 L 23.34375 15.6875 L 18.65625 15.6875 L 15.6875 21.21875 C 15.402344 21.941406 15.199219 22.511719 15.09375 22.875 L 15.0625 22.875 C 14.898438 22.265625 14.710938 21.722656 14.5 21.28125 L 11.8125 15.6875 Z M 36 20 L 36 22 L 44 22 L 44 20 Z M 36 27 L 36 29 L 44 29 L 44 27 Z M 36 35 L 36 37 L 44 37 L 44 35 Z" /></svg>

    </button>
  )
}

export { DownloadExcel };

import { motion } from "framer-motion";
import { useEffect, useState } from "react";
import { API } from "../API";
import { useStateMany } from "../customHooks/useStateMany";
import { DownloadPDF } from "../DownloadPDF";
import { ErrorAlert } from "../ErrorAlert";
import { Loader } from "../Loader";
import { Modal } from "../modal";
import { StateBadge, TypeBadge } from "../MyOrders";
import { OrderFilter } from '../OrderFilter'
import { OrderStateHistory } from "../OrderStateHistory";
import { PopUp } from "../PopUp";
import { OrderDataHistory } from "../OrderDataHistory";
import { UpdateOrder } from "../UpdateOrder";
const md5 = require('blueimp-md5');

interface OrdersTypes {
  user: {
    id: string,
    name: string,
    email: string,
    permissions: string,
    phone: string,
  }
}
function Orders({ user }: OrdersTypes) {
  const [state, setState] = useStateMany({
    loading: false,
    dataLoaded: false,
    data: [],
    filteredData: [],
    createOrderStage: false,
    orderStep: 0,
    reload: false,
    waitingForResponse: false,
    errorMessage: false,
    errorMessageTitle: '',
    errorMessageText: '',
    successMessage: false,
    successMessageTitle: '',
    successMessageText: '',
  })
  const [verPedidoState, setVerPedidoState] = useStateMany({
    showOrderDataHistory: false,
    showOrderStateHistory: false,
    updatedOrderDate: '',
    createdOrderDate: '',
    updateOrderStage: false,
    cancelOrderStage: false,
    verPedido: false,
    orderId: 0,
    quienRealizaElPedido: '',
    nombreDelPaciente: '',
    numeroDeDocumentoDelPaciente: '',
    nombreDelDoctor: '',
    tarjetaProfesional: '',
    city: 0,
    address: '',
    left_typeLens: 0,
    right_typeLens: 0,
    left_referenceLens: 0,
    right_referenceLens: 0,
    // ojo izquierdo
    idCaja: 0,
    idAccesorio: 0,
    left_inputs: [],
    left_cantidad: 0,
    left_adicion: '',
    left_ZO: '',
    left_grados: '',
    left_periferia1: '',
    left_periferia2: '',
    left_cInvertida: '',
    left_cAlineamiento: '',
    left_cApertura: '',
    left_ZOcBase: '',
    left_ZOcInvertida: '',
    left_ZOcAlineamiento: '',
    left_ZOcApertura: '',
    left_dZO: '',
    left_dSegundaCurva: '',
    left_c_base: '',
    left_poder: '',
    left_cilindro: '',
    left_diametro: '',
    left_design: 0,
    left_cpp: '',
    left_observaciones: '',
    // ojo derecho
    right_inputs: [],
    right_c_base: '',
    right_poder: '',
    right_cilindro: '',
    right_diametro: '',
    right_design: 0,
    right_cpp: '',
    right_observaciones: '',
    order_state: null,
    right_dZO: '',
    right_dSegundaCurva: '',
    right_cantidad: 0,
    right_adicion: '',
    right_ZO: '',
    right_grados: '',
    right_periferia1: '',
    right_periferia2: '',
    right_cInvertida: '',
    right_cAlineamiento: '',
    right_cApertura: '',
    right_ZOcBase: '',
    right_ZOcInvertida: '',
    right_ZOcAlineamiento: '',
    right_ZOcApertura: '',
  })
  const [lensReferences, setLensReferences] = useState([
    { id_lens: '0', name_lens: 'indique la referencia del lente', id_typeLens: '0', id_input: "" },
  ]);
  const [citysData, setCitysData]: any = useState([
    { name: "Seleccion una ciudad", id: 0 },
  ])
  const [lensTypes, setLensTypes] = useState([
    { id_typeLens: '0', name_typeLens: 'Indique el tipo de lente' },
  ])
  const [lensColors, setLensColors] = useState([
    { id_colorLens: '0', name_colorLens: 'Indique el color del lente', id_lens: '0' },
  ])
  const [lensDesign, setLensDesign] = useState([
    { id_design: '0', design_name: 'Seleccione un diseño' },
  ]);
  const [testBoxes, setTestBoxes] = useState([
    { id_box: '0', box: 'Seleccione un diseño', qty: 0, id_input: 0, },
  ]);
  const [accessories, setAccessories] = useState([
    { id_acc: 0, acc: 'Seleccione un accesorio', id_input: 0 },
  ]);
  useEffect(() => {
    let dataToSend = {
      API_KEY: process.env.REACT_APP_API_KEY,
      idSession: user.id,
      permissions: parseInt(user.permissions)
    }
    setState({ loading: true })
    API.post('/?orders', {
      ...dataToSend
    }).then((response) => {
      if (response.status === 200 && response.data) {
        setState({ data: response.data.orders, filteredData: response.data.orders, dataLoaded: true, loading: false })
      }
    })
  }, [state.reload])
  useEffect(() => {
    let dataToSend = {
      API_KEY: process.env.REACT_APP_API_KEY
    }
    API.post('/?lens', {
      ...dataToSend
    }).then((response) => {
      if (response.status === 200 && response.data.lens.length > 0 && response.data.colors.length > 0 && response.data.typeLens.length > 0) {
        let newsLensTypes = [
          { id_typeLens: '0', name_typeLens: 'Indique el tipo de lente' },
          ...response.data.typeLens
        ]
        let newLens = [
          { id_lens: '0', name_lens: 'indique la referencia del lente', id_typeLens: '0' },
          ...response.data.lens
        ]
        let newCitysData = [
          { name: "Seleccion una ciudad", id: 0 },
          ...response.data.citys
        ]
        let newColorLens = [
          { id_colorLens: '0', name_colorLens: 'Indique el color del lente', id_lens: '0' },
          ...response.data.colors
        ]
        let newDesigns = [
          { id_design: '0', design_name: 'Seleccione un diseño' },
          ...response.data.designs
        ]
        let newTestBoxes = [
          { id_box: '0', box: 'Seleccione un diseño', qty: 0, id_input: 0, },
          ...response.data.boxes
        ]
        let newAccessories = [
          { id_acc: 0, acc: 'Seleccione un accesorio', id_input: 0 },
          ...response.data.acc
        ]
        setCitysData(newCitysData)
        setLensDesign(newDesigns);
        setLensColors(newColorLens);
        setLensTypes(newsLensTypes);
        setLensReferences(newLens);
        setTestBoxes(newTestBoxes);
        setAccessories(newAccessories);
      } else {
        setState({ errorMessage: true, errorMessageTitle: 'Ocurrio un error al intentar consultar informacion', errorMessageText: 'No se pudo conseguir la informacion necesaria para continuar.' })
      }
    })
  }, [])
  function mostrarPedido(pedido: any) {
    // encuentra el tipo de lente izquierdo
    let findLeftTypeLens = lensTypes.find((el: any) => parseInt(el.id_typeLens) === pedido.left_typeLens)
    // encuentra el tipo de lente izquierdo
    let findRightTypeLens = lensTypes.find((el: any) => parseInt(el.id_typeLens) === pedido.right_typeLens)
    // encuentra la referencia del lente izquierdo
    let findLeftLensReference = lensReferences.find((el: any) => parseInt(el.id_lens) === pedido.left_referenceLens)
    // encuentra la referencia del lente derecho
    let findRightLensReference = lensReferences.find((el: any) => parseInt(el.id_lens) === pedido.right_referenceLens)
    // encuentra el color del lente izquierdo
    let findLeftLensColor = lensColors.find((el: any) => parseInt(el.id_colorLens) === pedido.left_color)
    // encuentra el color del lente derecho
    let findRightLensColor = lensColors.find((el: any) => parseInt(el.id_colorLens) === pedido.right_color)
    // encuentra la ciudad correspondiente
    let findCity = citysData.find((el: any) => parseInt(el.id) === pedido.city)
    // encuentra el design izquierdo
    let findLeftDesign = lensDesign.find((el: any) => parseInt(el.id_design) === pedido.left_design)
    // encuentra el design derecho
    let findRightDesign = lensDesign.find((el: any) => parseInt(el.id_design) === pedido.right_design)
    if (pedido.type_order === 1) {
      let right_inputs: any = []
      let left_inputs: any = []
      if (findRightLensReference) {
        right_inputs = findRightLensReference.id_input ? findRightLensReference.id_input.split(",") : [""];
      }
      if (findLeftLensReference) {
        left_inputs = findLeftLensReference.id_input ? findLeftLensReference.id_input.split(",") : [""];

      }
      setVerPedidoState({
        updatedOrderDate: pedido.updatedOrderDate,
        createdOrderDate: pedido.createdOrderDate,
        orderId: pedido.orderId,
        verPedido: true,
        quienRealizaElPedido: pedido.quienRealizaElPedido,
        nombreDelPaciente: pedido.nombreDelPaciente,
        numeroDeDocumentoDelPaciente: pedido.numeroDeDocumentoDelPaciente,
        nombreDelDoctor: pedido.nombreDelDoctor,
        tarjetaProfesional: pedido.tarjetaProfesional,
        city: findCity?.name ? findCity.name : 'N/A',
        address: pedido.address ? pedido.address : 'N/A',
        left_typeLens: findLeftTypeLens,
        right_typeLens: findRightTypeLens,
        left_referenceLens: findLeftLensReference,
        right_referenceLens: findRightLensReference,
        // ojo izquierdo
        left_inputs: left_inputs,
        left_c_base: pedido.left_c_base,
        left_poder: pedido.left_poder,
        left_cilindro: pedido.left_cilindro,
        left_diametro: pedido.left_diametro,
        left_cpp: pedido.left_cpp,
        left_observaciones: pedido.left_observaciones,
        left_color: findLeftLensColor,
        // ojo derecho
        right_inputs: right_inputs,
        right_c_base: pedido.right_c_base,
        right_poder: pedido.right_poder,
        right_cilindro: pedido.right_cilindro,
        right_diametro: pedido.right_diametro,
        right_design: findRightDesign,
        right_cpp: pedido.right_cpp,
        right_observaciones: pedido.right_observaciones,
        right_color: findRightLensColor,
        order_state: pedido.order_state,
        //
        type_order: pedido.type_order,
        idCaja: pedido.idCaja,
        idAccesorio: pedido.idAccesorio,
        // ojo izquierdo
        left_design: findLeftDesign,
        left_cantidad: pedido.left_cantidad,
        left_adicion: pedido.left_adicion,
        left_ZO: pedido.left_ZO,
        left_grados: pedido.left_grados,
        left_periferia1: pedido.left_periferia1,
        left_periferia2: pedido.left_periferia2,
        left_cInvertida: pedido.left_cInvertida,
        left_cAlineamiento: pedido.left_cAlineamiento,
        left_cApertura: pedido.left_cApertura,
        left_ZOcBase: pedido.left_ZOcBase,
        left_ZOcInvertida: pedido.left_ZOcInvertida,
        left_ZOcAlineamiento: pedido.left_ZOcAlineamiento,
        left_ZOcApertura: pedido.left_ZOcApertura,
        left_dZO: pedido.left_dZO,
        left_dSegundaCurva: pedido.left_dSegundaCurva,
        // ojo derecho
        right_cantidad: pedido.right_cantidad,
        right_adicion: pedido.right_adicion,
        right_ZO: pedido.right_ZO,
        right_grados: pedido.right_grados,
        right_periferia1: pedido.right_periferia1,
        right_periferia2: pedido.right_periferia2,
        right_cInvertida: pedido.right_cInvertida,
        right_cAlineamiento: pedido.right_cAlineamiento,
        right_cApertura: pedido.right_cApertura,
        right_ZOcBase: pedido.right_ZOcBase,
        right_ZOcInvertida: pedido.right_ZOcInvertida,
        right_ZOcAlineamiento: pedido.right_ZOcAlineamiento,
        right_ZOcApertura: pedido.right_ZOcApertura,
        right_dZO: pedido.right_dZO,
        right_dSegundaCurva: pedido.right_dSegundaCurva,
      })
    } else if (parseInt(pedido.type_order) === 2) {

      // encuentra la caja de prueba
      let findCaja = testBoxes.find((el: any) => parseInt(el.id_box) === pedido.idCaja);
      setVerPedidoState({
        updatedOrderDate: pedido.updatedOrderDate,
        createdOrderDate: pedido.createdOrderDate,
        city: findCity?.name ? findCity.name : 'N/A',
        address: pedido.address ? pedido.address : 'N/A',
        orderId: pedido.orderId,
        verPedido: true,
        quienRealizaElPedido: pedido.quienRealizaElPedido,
        nombreDelPaciente: pedido.nombreDelPaciente,
        numeroDeDocumentoDelPaciente: pedido.numeroDeDocumentoDelPaciente,
        nombreDelDoctor: pedido.nombreDelDoctor,
        tarjetaProfesional: pedido.tarjetaProfesional,
        order_state: pedido.order_state,
        type_order: pedido.type_order,
        idCaja: findCaja,
        // idAccesorio: pedido.idAccesorio,
        left_cantidad: isNaN(pedido.left_cantidad) ? 1 : pedido.left_cantidad,
        right_cantidad: isNaN(pedido.right_cantidad) ? 1 : pedido.right_cantidad,
        right_observaciones: pedido.right_observaciones,
        left_observaciones: pedido.left_observaciones,
      })
    } else if (parseInt(pedido.type_order) === 3) {
      let findAccesorio = accessories.find((el: any) => parseInt(el.id_acc) === pedido.idAccesorio);
      setVerPedidoState({
        updatedOrderDate: pedido.updatedOrderDate,
        createdOrderDate: pedido.createdOrderDate,
        city: findCity?.name ? findCity.name : 'N/A',
        address: pedido.address ? pedido.address : 'N/A',
        orderId: pedido.orderId,
        verPedido: true,
        quienRealizaElPedido: pedido.quienRealizaElPedido,
        nombreDelPaciente: pedido.nombreDelPaciente,
        numeroDeDocumentoDelPaciente: pedido.numeroDeDocumentoDelPaciente,
        nombreDelDoctor: pedido.nombreDelDoctor,
        tarjetaProfesional: pedido.tarjetaProfesional,
        order_state: pedido.order_state,
        type_order: pedido.type_order,
        // idCaja: findCaja,
        idAccesorio: findAccesorio,
        left_cantidad: pedido.left_cantidad,
        right_cantidad: pedido.right_cantidad,
      })
    }
  }
  function updateOrderState(event: React.MouseEvent<HTMLButtonElement, MouseEvent>, type: string) {
    event.preventDefault();
    let dataToSend = {
      API_KEY: process.env.REACT_APP_API_KEY,
      permissions: parseInt(user.permissions),
      idOrder: parseInt(verPedidoState.orderId),
      id: parseInt(user.id),
      state: type,
    }
    setState({ waitingForResponse: true, errorMessage: false, errorMessageTitle: '', errorMessageText: '' })
    // ordersaccepted
    API.post('/?status', {
      ...dataToSend
    }).then((response) => {
      if (response.status === 200 && response.data.success === 1) {
        setState({
          waitingForResponse: false,
          createOrderStage: false,
          orderStep: 0,
          successMessage: true,
          reload: !state.reload,
          successMessageTitle: 'El estado del pedido se ha actualizado con exito!',
          successMessageText: 'Se ha modificado exitosamente el estado del pedido, puede volver a cambiarlo cuando quiera.',
        })
        setVerPedidoState({
          updateOrderStage: false,
          cancelOrderStage: false,
          verPedido: false,
          orderId: 0,
          quienRealizaElPedido: '',
          nombreDelPaciente: '',
          numeroDeDocumentoDelPaciente: '',
          nombreDelDoctor: '',
          tarjetaProfesional: '',
          left_typeLens: 0,
          right_typeLens: 0,
          left_referenceLens: 0,
          right_referenceLens: 0,
          // ojo izquierdo
          left_c_base: '',
          left_poder: '',
          left_cilindro: '',
          left_diametro: '',
          left_design: '',
          left_cpp: '',
          left_observaciones: '',
          // ojo derecho
          right_c_base: '',
          right_poder: '',
          right_cilindro: '',
          right_diametro: '',
          right_design: '',
          right_cpp: '',
          right_observaciones: '',
          order_state: null
        })
        setTimeout(() => {
          setState({
            successMessage: false,
            successMessageTitle: '',
            successMessageText: '',
          })
        }, 7000)
        setState({ reload: !state.reload })
      } else {
        setState({ errorMessage: true, waitingForResponse: false, errorMessageTitle: 'Ocurrio un error al tratar de cambiar el estado del pedido', errorMessageText: 'No se puedo cambiar el estado de el pedido,' })
        setTimeout(() => {
          setState({ errorMessage: false, errorMessageTitle: '', errorMessageText: '' })
        }, 7000);
      }
    })
  }
  // esta funcion es unicamente para que el creador de la orden la elimine
  function cancelarOrden(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    event.preventDefault();
    let dataToSend = {
      API_KEY: process.env.REACT_APP_API_KEY,
      idProfile: parseInt(user.id),
      idOrder: parseInt(verPedidoState.orderId),
    }
    setState({ waitingForResponse: true, errorMessage: false, errorMessageTitle: '', errorMessageText: '' });
    API.post('/?deleteorder', {
      ...dataToSend
    }).then((response) => {
      if (response.status === 200 && response.data.success === 1) {
        setState({
          waitingForResponse: false,
          createOrderStage: false,
          orderStep: 0,
          successMessage: true,
          reload: !state.reload,
          successMessageTitle: 'Se elimino el pedido.',
          successMessageText: 'El pedido se ha eliminado con exito!.',
        })
        resetVerPedidos();
        setTimeout(() => {
          setState({
            successMessage: false,
            successMessageTitle: '',
            successMessageText: '',
          })
        }, 7000)
      }
    })
  }

  function resetVerPedidos() {
    setVerPedidoState({
      orderId: 0,
      verPedido: false,
      cancelOrderStage: false,
      quienRealizaElPedido: '',
      nombreDelPaciente: '',
      numeroDeDocumentoDelPaciente: '',
      nombreDelDoctor: '',
      tarjetaProfesional: '',
      left_typeLens: 0,
      right_typeLens: 0,
      left_referenceLens: 0,
      right_referenceLens: 0,
      // ojo izquierdo
      left_c_base: '',
      left_poder: '',
      left_cilindro: '',
      left_diametro: '',
      left_design: 0,
      left_cpp: '',
      left_observaciones: '',
      // ojo derecho
      right_c_base: '',
      right_poder: '',
      right_cilindro: '',
      right_diametro: '',
      right_design: 0,
      right_cpp: '',
      right_observaciones: '',
      order_state: null,
      // ojo izquierdo
      idCaja: 0,
      idAccesorio: 0,
      left_inputs: [],
      left_cantidad: 1,
      left_adicion: '',
      left_ZO: '',
      left_grados: '',
      left_periferia1: '',
      left_periferia2: '',
      left_cInvertida: '',
      left_cAlineamiento: '',
      left_cApertura: '',
      left_ZOcBase: '',
      left_ZOcInvertida: '',
      left_ZOcAlineamiento: '',
      left_ZOcApertura: '',
      left_dZO: '',
      left_dSegundaCurva: '',
      // ojo derecho
      right_inputs: [],
      right_dZO: '',
      right_dSegundaCurva: '',
      right_cantidad: 1,
      right_adicion: '',
      right_ZO: '',
      right_grados: '',
      right_periferia1: '',
      right_periferia2: '',
      right_cInvertida: '',
      right_cAlineamiento: '',
      right_cApertura: '',
      right_ZOcBase: '',
      right_ZOcInvertida: '',
      right_ZOcAlineamiento: '',
      right_ZOcApertura: '',
    })
  }
  return (
    <div className="max-w-full">
      <div className="flex items-center justify-center flex-col h-full w-full">
        <h4 className="text-4xl font-bold mb-2 text-center">
          Pedidos
        </h4>
        <p className="mb-10 text-gray-600 text-center max-w-[500px]">Aqui podras ver y administra los pedidos de los optometristas u oftalmologos de nivel 1, puedes revisarlos y cambiar su estado a <strong className="text-green-600">Aceptado</strong> / <strong className="text-red-500">Rechazado</strong> / <strong className="text-amber-600">Completado</strong> / <strong className="text-red-700">Cancelado</strong> / <strong className="text-indigo-600">Entregado</strong> segun creas conveniente.</p>
        <div className="flex justify-center items-center gap-4">
        </div>
      </div>
      {state.errorMessage && state.waitingForResponse === false &&
        <ErrorAlert ErrorElement={state.ErrorElement}
          animate={{ x: 0, opacity: 1, zIndex: 50, left: "1rem", bottom: "1rem", position: "fixed" }}
          transition={{ duration: 0.3 }}
          initial={{ x: -50, opacity: 0.1, zIndex: 50, left: "1rem", bottom: "1rem", position: "fixed" }}
          message={state.errorMessageText}
          title={state.errorMessageTitle} />
      }
      {/* mensaje de completado */}
      {state.successMessage === true && state.waitingForResponse === false &&
        <motion.div initial={{ x: '-150%', opacity: 0.5 }} animate={{ x: '0%', opacity: 1 }} role="alert" className="rounded-xl border fixed left-3 sm:left-10 bottom-10 z-40 bg-white border-gray-100 p-4 shadow-xl">
          <div className="flex items-start  gap-4 max-w-[450px]">
            <span className="text-green-600">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                className="h-6 w-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                />
              </svg>
            </span>

            <div className="flex-1">
              <strong className="block font-medium text-gray-900">{state.successMessageTitle}</strong>

              <p className="mt-1 text-sm text-gray-700">
                {state.successMessageText}
              </p>
            </div>

            <button onClick={() => { setState({ successMessage: false, successMessageTitle: '', successMessageText: '' }) }} className="text-gray-500 transition hover:text-gray-600">
              <span className="sr-only">Dismiss popup</span>

              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                className="h-6 w-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
          </div>
        </motion.div>
      }
      {verPedidoState.updateOrderStage === true || verPedidoState.verPedido === true ?
        <Modal />
        : <></>
      }
      {verPedidoState.updateOrderStage === true &&
        <UpdateOrder generalState={state} setGeneralState={setState} user={user} setVerPedido={setVerPedidoState} updateState={verPedidoState} typeLens={lensTypes} lens={lensReferences} colorLens={lensColors} lensDesignList={lensDesign} accessoriesList={accessories} testBoxesList={testBoxes} citysData={citysData}/>
      }
      {verPedidoState.verPedido === true &&
        <>
          <motion.form initial={{ y: 50, opacity: 0 }} animate={{ y: 0, opacity: 1 }} transition={{ duration: 0.5 }} className="fixed top-0 left-0 w-full h-full flex justify-center items-center z-40">
            {verPedidoState.cancelOrderStage === true &&
              <div className="z-50">
                <Modal />
                <motion.div initial={{ y: 50, opacity: 0 }} animate={{ y: 0, opacity: 1 }} transition={{ duration: 0.5 }} className="fixed top-0 left-0 w-full h-full flex justify-center items-center z-60">
                  <div className="rounded-lg bg-white p-8 shadow-2xl max-w-lg">
                    <p className="text-lg font-bold">¿Estas seguro que quieres rechazar este pedido?</p>
                    <div className="mt-2">
                      <p className="mt-2 font-medium text-sm lg:text-base text-gray-600">
                        Esta accion solo es reversible una vez usando el boton "<strong>Restaurar</strong>", o puedes esperar a que el cliente actualice la orden
                      </p>
                    </div>
                    <div className="mt-4 flex gap-2">
                      <button onClick={(event) => {
                        // updateOrderState(event, 'cancelado');
                        updateOrderState(event, 'rechazado');

                        // cancelarOrden(event)
                      }} className="inline-block rounded bg-red-500 px-4 py-2 text-sm font-medium text-white border-[1px] border-red-500 hover:text-red-500 hover:bg-transparent">
                        Si, estoy seguro
                      </button>
                      <button onClick={() => {
                        setVerPedidoState({
                          cancelOrderStage: false
                        })
                      }} type="button" className="rounded bg-gray-100 px-4 py-2 text-sm font-medium text-gray-700">
                        No, volver
                      </button>
                    </div>
                  </div>
                  {/* {state.waitingForResponse === true && state.successAlert === false &&
                      <Loader />
                    } */}
                </motion.div>
              </div>
            }
            {verPedidoState.showOrderStateHistory === true &&
              <OrderStateHistory verPedidoState={verPedidoState} setVerPedidoState={setVerPedidoState} />
            }
            {verPedidoState.showOrderDataHistory === true &&
              <OrderDataHistory verPedidoState={verPedidoState} setVerPedidoState={setVerPedidoState} />
            }
            {state.waitingForResponse === false && state.successMessage === false &&
              <div className="bg-white p-4 md:p-10 z-40 rounded-lg grid max-w-[1000px] w-full grid-cols-1 md:grid-cols-4 gap-x-4 gap-y-3 max-h-[90vh] overflow-y-auto">
                <div className="w-full md:col-start-1 md:col-end-6 flex justify-end items-center h-2">
                  <PopUp message="Presione par ver el historial de cambios en los datos de el pedido">
                    <button className="group flex items-center justify-center rounded-lg transition-colors hover:bg-gray-300 p-2" type="button" onClick={() => { setVerPedidoState({ showOrderDataHistory: true }) }}>
                      <span className="flex-shrink-0 rounded-full text-gray-500 group-hover:text-red-500">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-8 h-8">
                          <path strokeLinecap="round" strokeLinejoin="round" d="M12 6.042A8.967 8.967 0 006 3.75c-1.052 0-2.062.18-3 .512v14.25A8.987 8.987 0 016 18c2.305 0 4.408.867 6 2.292m0-14.25a8.966 8.966 0 016-2.292c1.052 0 2.062.18 3 .512v14.25A8.987 8.987 0 0018 18a8.967 8.967 0 00-6 2.292m0-14.25v14.25" />
                        </svg>
                      </span>
                    </button>
                  </PopUp>
                </div>
                <div className="flex flex-col gap-y-3 md:col-start-1 md:col-end-6 border-b-[1px] pb-3 border-slate-200">
                  <div className="break-words">
                    <strong className="mr-2">Nombre de quien realiza el pedido:</strong><span>{verPedidoState.quienRealizaElPedido}</span>
                  </div>
                  <div className="break-words">
                    <strong className="mr-2">Nombre del paciente:</strong><span>{verPedidoState.nombreDelPaciente}</span>
                  </div>
                  <div className="break-words">
                    <strong className="mr-2">Numero de documento del paciente:</strong><span>{verPedidoState.numeroDeDocumentoDelPaciente}</span>
                  </div>
                  <div className="break-words">
                    <strong className="mr-2">Nombre del optometra u oftalmólogo:</strong><span>{verPedidoState.nombreDelDoctor}</span>
                  </div>
                  <div className="break-words">
                    <strong className="mr-2">Tarjeta profesional:</strong><span>{verPedidoState.tarjetaProfesional}</span>
                  </div>
                  <div className="break-words">
                    <strong className="mr-2">Ciudad:</strong><span>{verPedidoState.city ? verPedidoState.city : "N/A"}</span>
                  </div>
                  <div className="break-words">
                    <strong className="mr-2">Direccion:</strong><span>{verPedidoState.address ? verPedidoState.address : "N/A"}</span>
                  </div>
                  <div className="break-words">
                    <strong className="mr-2">Fecha de creacion:</strong><span>{verPedidoState.createdOrderDate}</span>
                  </div>
                  <div className="break-words">
                    <strong className="mr-2">Fecha de ultima actualizacion:</strong><span>{verPedidoState.updatedOrderDate ? verPedidoState.updatedOrderDate : ''}</span>
                  </div>
                  <div className="break-words flex items-center justify-start">
                    <strong className="mr-2">Estado del pedido:</strong><span><StateBadge status={verPedidoState.order_state} /></span>
                    <PopUp message="Presione par ver el historial de cambios en el estado de el pedido">
                      <button className="group flex items-center justify-center rounded-full transition-colors hover:bg-gray-300 ml-1" type="button" onClick={() => { setVerPedidoState({ showOrderStateHistory: true }) }}>
                        <span className="flex-shrink-0 rounded-full text-gray-500 group-hover:text-red-500">
                          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="h-8 w-8">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M12 9v3.75m9-.75a9 9 0 11-18 0 9 9 0 0118 0zm-9 3.75h.008v.008H12v-.008z" />
                          </svg>
                        </span>
                      </button>
                    </PopUp>
                  </div>
                </div>
                {parseInt(verPedidoState.type_order) === 1 &&
                  <>
                    <div className="flex flex-col md:col-start-1 md:col-end-3 gap-y-3">
                      <div>
                        <p className="text-slate-600 border-b-[1px] pb-3 border-slate-200 text-center">Ojo izquierdo</p>
                      </div>
                      <div className="break-words">
                        <strong className="mr-2">Tipo de lente:</strong><span>{
                          verPedidoState.left_typeLens ?
                            verPedidoState.left_typeLens.name_typeLens ?
                              verPedidoState.left_typeLens.name_typeLens
                              : "N/A"
                            : "N/A"
                        }</span>
                      </div>
                      <div className="break-words">
                        <strong className="mr-2">Referencia:</strong><span>{
                          verPedidoState.left_referenceLens ?
                            verPedidoState.left_referenceLens.name_lens ?
                              verPedidoState.left_referenceLens.name_lens
                              : "N/A"
                            : "N/A"
                        }</span>
                      </div>
                      {verPedidoState.left_inputs.includes('1') &&
                        <div className="break-words">
                          <strong className="mr-2">Color:</strong><span>{
                            verPedidoState.left_color ?
                              verPedidoState.left_color.name_colorLens === "Indique el color del lente" ?
                                "N/A"
                                : verPedidoState.left_color.name_colorLens
                              : "N/A"
                          }</span>
                        </div>
                      }
                      {verPedidoState.left_inputs.includes('2') &&
                        <div className="break-words">
                          <strong className="mr-2">Diseño:</strong><span>{
                            verPedidoState.left_design ?
                              verPedidoState.left_design.id_design ?
                                parseInt(verPedidoState.left_design.id_design) === 0 ?
                                  "N/A"
                                  : verPedidoState.left_design.design_name
                                : "N/A"
                              : 'N/A'
                          }
                          </span>
                        </div>
                      }
                      {verPedidoState.left_inputs.includes('2') && verPedidoState.left_design ?
                        parseInt(verPedidoState.left_design.id_design) === 3 ?
                          <>
                            <div className="break-words">
                              <strong className="mr-2">Z.O de Diseño:</strong><span>{verPedidoState.left_dZO ? verPedidoState.left_dZO : "N/A"}</span>
                            </div>
                            <div className="break-words">
                              <strong className="mr-2">Segunda Curva de Diseño:</strong><span>{verPedidoState.left_dSegundaCurva ? verPedidoState.left_dSegundaCurva : "N/A"}</span>
                            </div>
                          </>
                          : <></>
                        : <></>
                      }
                      {verPedidoState.left_inputs.includes('2') && verPedidoState.left_design ?
                        parseInt(verPedidoState.left_design.id_design) === 4 ?
                          <div className="break-words">
                            <strong className="mr-2">Segunda Curva de Diseño:</strong><span>{verPedidoState.left_dSegundaCurva ? verPedidoState.left_dSegundaCurva : "N/A"}</span>
                          </div>
                          : <></>
                        : <></>
                      }
                      {verPedidoState.left_inputs.includes('3') &&
                        <div className="break-words">
                          <strong className="mr-2">Cantidad:</strong><span>{verPedidoState.left_cantidad ? verPedidoState.left_cantidad : "N/A"}</span>
                        </div>
                      }
                      {verPedidoState.left_inputs.includes('4') &&
                        <div className="break-words">
                          <strong className="mr-2">C.base:</strong><span>{verPedidoState.left_c_base ? verPedidoState.left_c_base : "N/A"}</span>
                        </div>
                      }
                      {verPedidoState.left_inputs.includes('5') &&
                        <div className="break-words">
                          <strong className="mr-2">poder:</strong><span>{verPedidoState.left_poder ? verPedidoState.left_poder : "N/A"}</span>
                        </div>
                      }
                      {verPedidoState.left_inputs.includes('6') &&
                        <div className="break-words">
                          <strong className="mr-2">diametro:</strong><span>{verPedidoState.left_diametro ? verPedidoState.left_diametro : "N/A"}</span>
                        </div>
                      }
                      {verPedidoState.left_inputs.includes('7') &&
                        <div className="break-words">
                          <strong className="mr-2">Z.O:</strong><span>{verPedidoState.left_ZO ? verPedidoState.left_ZO : "N/A"}</span>
                        </div>
                      }
                      {verPedidoState.left_inputs.includes('8') &&
                        <div className="break-words">
                          <strong className="mr-2">Adicion:</strong><span>{verPedidoState.left_adicion ? verPedidoState.left_adicion : "N/A"}</span>
                        </div>
                      }
                      {verPedidoState.left_inputs.includes('9') &&
                        <div className="break-words">
                          <strong className="mr-2">cilindro:</strong><span>{verPedidoState.left_cilindro ? verPedidoState.left_cilindro : "N/A"}</span>
                        </div>
                      }
                      {verPedidoState.left_inputs.includes('10') &&
                        <div className="break-words">
                          <strong className="mr-2">Grados:</strong><span>{verPedidoState.left_grados ? verPedidoState.left_grados : "N/A"}</span>
                        </div>
                      }
                      {verPedidoState.left_inputs.includes('11') &&
                        <div className="break-words">
                          <strong className="mr-2">Periferia 1:</strong><span>{verPedidoState.left_periferia1 ? verPedidoState.left_periferia1 : "N/A"}</span>
                        </div>
                      }
                      {verPedidoState.left_inputs.includes('12') &&
                        <div className="break-words">
                          <strong className="mr-2">Periferia 2:</strong><span>{verPedidoState.left_periferia2 ? verPedidoState.left_periferia2 : "N/A"}</span>
                        </div>
                      }
                      {verPedidoState.left_inputs.includes('13') &&
                        <div className="break-words">
                          <strong className="mr-2">cpp:</strong><span>{verPedidoState.left_cpp ? verPedidoState.left_cpp : "N/A"}</span>
                        </div>
                      }
                      {verPedidoState.left_inputs.includes('14') &&
                        <div className="break-words">
                          <strong className="mr-2">Observaciones:</strong><span>{verPedidoState.left_observaciones ? verPedidoState.left_observaciones : "N/A"}</span>
                        </div>
                      }
                      {verPedidoState.left_inputs.includes('15') &&
                        <div className="break-words">
                          <strong className="mr-2">Curva Invertida:</strong><span>{verPedidoState.left_cInvertida ? verPedidoState.left_cInvertida : "N/A"}</span>
                        </div>
                      }
                      {verPedidoState.left_inputs.includes('16') &&
                        <div className="break-words">
                          <strong className="mr-2">Curva de Alineamiento:</strong><span>{verPedidoState.left_cAlineamiento ? verPedidoState.left_cAlineamiento : "N/A"}</span>
                        </div>
                      }
                      {verPedidoState.left_inputs.includes('17') &&
                        <div className="break-words">
                          <strong className="mr-2">Curva de Apertura:</strong><span>{verPedidoState.left_cApertura ? verPedidoState.left_cApertura : "N/A"}</span>
                        </div>
                      }
                      {verPedidoState.left_inputs.includes('18') &&
                        <div className="break-words">
                          <strong className="mr-2">Z.O de Curva Base:</strong><span>{verPedidoState.left_ZOcBase ? verPedidoState.left_ZOcBase : "N/A"}</span>
                        </div>
                      }
                      {verPedidoState.left_inputs.includes('19') &&
                        <div className="break-words">
                          <strong className="mr-2">Z.O de Curva Invertida:</strong><span>{verPedidoState.left_ZOcInvertida ? verPedidoState.left_ZOcInvertida : "N/A"}</span>
                        </div>
                      }
                      {verPedidoState.left_inputs.includes('20') &&
                        <div className="break-words">
                          <strong className="mr-2">Z.O de Curva de Alineamiento:</strong><span>{verPedidoState.left_ZOcAlineamiento ? verPedidoState.left_ZOcAlineamiento : "N/A"}</span>
                        </div>
                      }
                      {verPedidoState.left_inputs.includes('21') &&
                        <div className="break-words">
                          <strong className="mr-2">Z.O de Curva de Apertura:</strong><span>{verPedidoState.left_ZOcApertura ? verPedidoState.left_ZOcApertura : "N/A"}</span>
                        </div>
                      }
                    </div>
                    <div className="flex flex-col gap-y-3 md:col-start-3 md:col-end-6">
                      <div>
                        <p className="text-slate-600 border-b-[1px] pb-3 border-slate-200 text-center">Ojo derecho</p>
                      </div>
                      <div className="break-words">
                        <strong className="mr-2">Tipo de lente:</strong><span>{
                          verPedidoState.right_typeLens ?
                            verPedidoState.right_typeLens.name_typeLens ?
                              verPedidoState.right_typeLens.name_typeLens
                              : "N/A"
                            : "N/A"
                        }</span>
                      </div>
                      <div className="break-words">
                        <strong className="mr-2">Referencia:</strong>
                        <span>{
                          verPedidoState.right_referenceLens ?
                            verPedidoState.right_referenceLens.name_lens ?
                              verPedidoState.right_referenceLens.name_lens
                              : "N/A"
                            : "N/A"
                        }</span>
                      </div>
                      {verPedidoState.right_inputs.includes('1') &&
                        <div className="break-words">
                          <strong className="mr-2">Color:</strong><span>{
                            verPedidoState.right_color ?
                              verPedidoState.right_color.name_colorLens === "Indique el color del lente" ?
                                "N/A"
                                : verPedidoState.right_color.name_colorLens
                              : "N/A"
                          }</span>
                        </div>
                      }
                      {verPedidoState.right_inputs.includes('2') &&
                        <div className="break-words">
                          <strong className="mr-2">Diseño:</strong>
                          <span>
                            {
                              verPedidoState.right_design ?
                                parseInt(verPedidoState.right_design.id_design) === 0 ?
                                  "N/A"
                                  : verPedidoState.right_design.design_name
                                : "N/A"
                            }
                          </span>
                        </div>
                      }
                      {verPedidoState.right_inputs.includes('2') && verPedidoState.right_design ?
                        parseInt(verPedidoState.right_design.id_design) === 3 ?
                          <>
                            <div className="break-words">
                              <strong className="mr-2">Z.O de Diseño:</strong><span>{verPedidoState.right_dZO ? verPedidoState.right_dZO : "N/A"}</span>
                            </div>
                            <div className="break-words">
                              <strong className="mr-2">Segunda Curva de Diseño:</strong><span>{verPedidoState.right_dSegundaCurva ? verPedidoState.right_dSegundaCurva : "N/A"}</span>
                            </div>
                          </>
                          : <></>
                        : <></>
                      }
                      {verPedidoState.right_inputs.includes('2') && verPedidoState.right_design ?
                        parseInt(verPedidoState.right_design.id_design) === 4 ?
                          <div className="break-words">
                            <strong className="mr-2">Segunda Curva de Diseño:</strong><span>{verPedidoState.right_dSegundaCurva ? verPedidoState.right_dSegundaCurva : "N/A"}</span>
                          </div>
                          : <></>
                        : <></>
                      }
                      {verPedidoState.right_inputs.includes('3') &&
                        <div className="break-words">
                          <strong className="mr-2">Cantidad:</strong><span>{verPedidoState.right_cantidad ? verPedidoState.right_cantidad : "N/A"}</span>
                        </div>
                      }
                      {verPedidoState.right_inputs.includes('4') &&
                        <div className="break-words">
                          <strong className="mr-2">C.base:</strong><span>{verPedidoState.right_c_base ? verPedidoState.right_c_base : "N/A"}</span>
                        </div>
                      }
                      {verPedidoState.right_inputs.includes('5') &&
                        <div className="break-words">
                          <strong className="mr-2">poder:</strong><span>{verPedidoState.right_poder ? verPedidoState.right_poder : "N/A"}</span>
                        </div>
                      }
                      {verPedidoState.right_inputs.includes('6') &&
                        <div className="break-words">
                          <strong className="mr-2">diametro:</strong><span>{verPedidoState.right_diametro ? verPedidoState.right_diametro : "N/A"}</span>
                        </div>
                      }
                      {verPedidoState.right_inputs.includes('7') &&
                        <div className="break-words">
                          <strong className="mr-2">Z.O:</strong><span>{verPedidoState.right_ZO ? verPedidoState.right_ZO : "N/A"}</span>
                        </div>
                      }
                      {verPedidoState.right_inputs.includes('8') &&
                        <div className="break-words">
                          <strong className="mr-2">Adicion:</strong><span>{verPedidoState.right_adicion ? verPedidoState.right_adicion : "N/A"}</span>
                        </div>
                      }
                      {verPedidoState.right_inputs.includes('9') &&
                        <div className="break-words">
                          <strong className="mr-2">cilindro:</strong><span>{verPedidoState.right_cilindro ? verPedidoState.right_cilindro : "N/A"}</span>
                        </div>
                      }
                      {verPedidoState.right_inputs.includes('10') &&
                        <div className="break-words">
                          <strong className="mr-2">Grados:</strong><span>{verPedidoState.right_grados ? verPedidoState.right_grados : "N/A"}</span>
                        </div>
                      }
                      {verPedidoState.right_inputs.includes('11') &&
                        <div className="break-words">
                          <strong className="mr-2">Periferia 1:</strong><span>{verPedidoState.right_periferia1 ? verPedidoState.right_periferia1 : "N/A"}</span>
                        </div>
                      }
                      {verPedidoState.right_inputs.includes('12') &&
                        <div className="break-words">
                          <strong className="mr-2">Periferia 2:</strong><span>{verPedidoState.right_periferia2 ? verPedidoState.right_periferia2 : "N/A"}</span>
                        </div>
                      }
                      {verPedidoState.right_inputs.includes('13') &&
                        <div className="break-words">
                          <strong className="mr-2">cpp:</strong><span>{verPedidoState.right_cpp ? verPedidoState.right_cpp : "N/A"}</span>
                        </div>
                      }
                      {verPedidoState.right_inputs.includes('14') &&
                        <div className="break-words">
                          <strong className="mr-2">Observaciones:</strong><span>{verPedidoState.right_observaciones ? verPedidoState.right_observaciones : "N/A"}</span>
                        </div>
                      }
                      {verPedidoState.right_inputs.includes('15') &&
                        <div className="break-words">
                          <strong className="mr-2">Curva Invertida:</strong><span>{verPedidoState.right_cInvertida ? verPedidoState.right_cInvertida : "N/A"}</span>
                        </div>
                      }
                      {verPedidoState.right_inputs.includes('16') &&
                        <div className="break-words">
                          <strong className="mr-2">Curva de Alineamiento:</strong><span>{verPedidoState.right_cAlineamiento ? verPedidoState.right_cAlineamiento : "N/A"}</span>
                        </div>
                      }
                      {verPedidoState.right_inputs.includes('17') &&
                        <div className="break-words">
                          <strong className="mr-2">Curva de Apertura:</strong><span>{verPedidoState.right_cApertura ? verPedidoState.right_cApertura : "N/A"}</span>
                        </div>
                      }
                      {verPedidoState.right_inputs.includes('18') &&
                        <div className="break-words">
                          <strong className="mr-2">Z.O de Curva Base:</strong><span>{verPedidoState.right_ZOcBase ? verPedidoState.right_ZOcBase : "N/A"}</span>
                        </div>
                      }
                      {verPedidoState.right_inputs.includes('19') &&
                        <div className="break-words">
                          <strong className="mr-2">Z.O de Curva Invertida:</strong><span>{verPedidoState.right_ZOcInvertida ? verPedidoState.right_ZOcInvertida : "N/A"}</span>
                        </div>
                      }
                      {verPedidoState.right_inputs.includes('20') &&
                        <div className="break-words">
                          <strong className="mr-2">Z.O de Curva de Alineamiento:</strong><span>{verPedidoState.right_ZOcAlineamiento ? verPedidoState.right_ZOcAlineamiento : "N/A"}</span>
                        </div>
                      }
                      {verPedidoState.right_inputs.includes('21') &&
                        <div className="break-words">
                          <strong className="mr-2">Z.O de Curva de Apertura:</strong><span>{verPedidoState.right_ZOcApertura ? verPedidoState.right_ZOcApertura : "N/A"}</span>
                        </div>
                      }
                    </div>
                  </>
                }
                {parseInt(verPedidoState.type_order) === 2 &&
                  <>
                    <div className="flex flex-col md:col-start-1 md:col-end-6 gap-y-3">
                      <div className="break-words">
                        <strong className="mr-2">Caja:</strong><span>{verPedidoState.idCaja.box}</span>
                      </div>
                      <div className="break-words">
                        <strong className="mr-2">Cantidad:</strong><span>{verPedidoState.left_cantidad === null ? 1 : verPedidoState.left_cantidad}</span>
                      </div>
                      <div className="break-words">
                        <strong className="mr-2">Observaciones:</strong><span>{verPedidoState.left_observaciones}</span>
                      </div>
                    </div>
                  </>
                }
                {parseInt(verPedidoState.type_order) === 3 &&
                  <>
                    <div className="flex flex-col md:col-start-1 md:col-end-6 gap-y-3">
                      <div className="break-words">
                        <strong className="mr-2">Accesorio:</strong><span>{verPedidoState.idAccesorio.acc}</span>
                      </div>
                      <div className="break-words">
                        <strong className="mr-2">Cantidad:</strong><span>{verPedidoState.left_cantidad}</span>
                      </div>
                    </div>
                  </>
                }
                <div className="col-span-full flex items-center justify-end mt-5 gap-2">
                  <DownloadPDF data={verPedidoState} />
                  <button type="button" onClick={() => {
                    setVerPedidoState({
                      verPedido: false,
                      orderId: 0,
                      quienRealizaElPedido: '',
                      nombreDelPaciente: '',
                      numeroDeDocumentoDelPaciente: '',
                      nombreDelDoctor: '',
                      tarjetaProfesional: '',
                      left_typeLens: 0,
                      right_typeLens: 0,
                      left_referenceLens: 0,
                      right_referenceLens: 0,
                      // ojo izquierdo
                      left_c_base: '',
                      left_poder: '',
                      left_cilindro: '',
                      left_diametro: '',
                      left_design: '',
                      left_cpp: '',
                      left_observaciones: '',
                      // ojo derecho
                      right_c_base: '',
                      right_poder: '',
                      right_cilindro: '',
                      right_diametro: '',
                      right_design: '',
                      right_cpp: '',
                      right_observaciones: '',
                      order_state: null,
                    })
                  }} className="group flex items-center justify-center gap-2 rounded-lg border border-gray-400 bg-gray-400 px-3 py-2 transition-colors hover:bg-transparent focus:outline-none focus:ring">
                    <span className="capitalize text-sm font-medium text-white transition-colors group-hover:text-gray-400 group-active:text-gray-400">
                      volver
                    </span>
                    <span className="flex-shrink-0 rounded-full border border-current bg-white p-2 text-gray-400 group-active:text-gray-400">
                      <svg className="h-3 w-3 rotate-180" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path className="fill-green-500" strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M17 8l4 4m0 0l-4 4m4-4H3" />
                      </svg>
                    </span>
                  </button>

                  {verPedidoState.order_state === 'completado' &&
                    <>
                      {user.permissions !== '3' &&
                        <button type="button" onClick={(event) => {
                          setVerPedidoState({ cancelOrderStage: true })
                        }} className="group flex items-center justify-center gap-2 rounded-lg border border-red-900 bg-red-700 px-3 py-2 transition-colors hover:bg-red-200 focus:outline-none focus:ring">
                          <span className="capitalize text-sm font-medium text-white transition-colors group-active:text-red-900 group-hover:text-red-900">
                            Anular
                          </span>
                          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="h-[30px] w-[30px] text-white group-hover:text-red-900">
                            <path fillRule="evenodd" d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25zm-1.72 6.97a.75.75 0 10-1.06 1.06L10.94 12l-1.72 1.72a.75.75 0 101.06 1.06L12 13.06l1.72 1.72a.75.75 0 101.06-1.06L13.06 12l1.72-1.72a.75.75 0 10-1.06-1.06L12 10.94l-1.72-1.72z" clipRule="evenodd" />
                          </svg>
                        </button>
                      }
                      {user.permissions === '3' || user.permissions === '5' ?
                        <button type="button" onClick={(event) => {
                          updateOrderState(event, 'entregado');
                        }} className="group flex items-center justify-center gap-2 rounded-lg border border-indigo-600 bg-indigo-600 px-3 py-2 transition-colors hover:bg-transparent focus:outline-none focus:ring">
                          <span className="capitalize text-sm font-medium text-white transition-colors group-active:text-indigo-900 group-hover:text-indigo-900">
                            Entregado
                          </span>
                          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="h-[30px] w-[30px] text-white group-hover:text-indigo-900">
                            <path d="M11.47 1.72a.75.75 0 011.06 0l3 3a.75.75 0 01-1.06 1.06l-1.72-1.72V7.5h-1.5V4.06L9.53 5.78a.75.75 0 01-1.06-1.06l3-3zM11.25 7.5V15a.75.75 0 001.5 0V7.5h3.75a3 3 0 013 3v9a3 3 0 01-3 3h-9a3 3 0 01-3-3v-9a3 3 0 013-3h3.75z" />
                          </svg>
                        </button>
                        : <></>
                      }
                    </>
                  }
                  {(verPedidoState.order_state === "rechazado" || verPedidoState.order_state === "cancelado") && user.permissions === '5' &&
                    <button type="button" onClick={(event) => {
                      updateOrderState(event, 'revision');
                    }} className="group flex items-center justify-center gap-2 rounded-lg border border-amber-500 bg-amber-500 px-3 py-2 transition-colors hover:bg-transparent focus:outline-none focus:ring">
                      <span className="capitalize text-sm font-medium text-white transition-colors group-hover:text-amber-500 group-active:text-amber-500">
                        Restaurar
                      </span>
                      <span className="flex-shrink-0 rounded-full border border-current bg-white p-2 text-amber-500 group-active:text-amber-500">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="h-3 w-3" >
                          <path strokeLinecap="round" strokeLinejoin="round" d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99" />
                        </svg>
                      </span>


                    </button>
                  }
                  {verPedidoState.order_state === "revision" ?
                    user.permissions !== '3' &&
                    <>
                      {parseInt(user.permissions) === 5 || parseInt(user.permissions) === 2 ?
                        <button onClick={(event) => { setVerPedidoState({ updateOrderStage: true, verPedido: false }) }} className="group flex items-center justify-center gap-2 rounded-lg border border-indigo-500 bg-indigo-500 px-3 py-2 transition-colors hover:bg-transparent focus:outline-none focus:ring">
                          <span className="capitalize text-sm font-medium text-white transition-colors group-hover:text-indigo-500 group-active:text-indigo-500">
                            actualizar
                          </span>
                          <span className="flex-shrink-0 rounded-full border border-current bg-white p-2 text-indigo-500 group-active:text-indigo-500">
                            <svg className="h-3 w-3" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                              <path className="fill-indigo-500" strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M17 8l4 4m0 0l-4 4m4-4H3" />
                            </svg>
                          </span>
                        </button>
                        : <p>uwu</p>
                      }
                      <button type="button" onClick={(event) => {
                        setVerPedidoState({
                          cancelOrderStage: true
                        })
                      }} className="group flex items-center justify-center gap-2 rounded-lg border border-red-500 bg-red-500 px-3 py-2 transition-colors hover:bg-transparent focus:outline-none focus:ring">
                        <span className="capitalize text-sm font-medium text-white transition-colors group-hover:text-red-500 group-active:text-red-500">
                          Rechazar
                        </span>
                        <span className="flex-shrink-0 rounded-full border border-current bg-white p-2 text-red-500 group-active:text-red-500">
                          <svg className="h-3 w-3" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M18 6L6 18" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M6 6L18 18" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                          </svg>
                        </span>
                      </button>
                      <button onClick={(event) => { updateOrderState(event, 'aceptado') }} className="group flex items-center justify-center gap-2 rounded-lg border border-green-500 bg-green-500 px-3 py-2 transition-colors hover:bg-transparent focus:outline-none focus:ring">
                        <span className="capitalize text-sm font-medium text-white transition-colors group-hover:text-green-500 group-active:text-green-500">
                          Aceptar
                        </span>
                        <span className="flex-shrink-0 rounded-full border border-current bg-white p-2 text-green-500 group-active:text-green-500">
                          <svg className="h-3 w-3" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path className="fill-green-500" strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M17 8l4 4m0 0l-4 4m4-4H3" />
                          </svg>
                        </span>
                      </button>
                    </>
                    : <></>
                  }
                  {verPedidoState.order_state === 'aceptado' && user.permissions !== '3' &&
                    <>
                      <button type="button" onClick={(event) => {
                        updateOrderState(event, 'cancelado');
                      }} className="group flex items-center justify-center gap-2 rounded-lg border border-red-900 bg-red-700 px-3 py-2 transition-colors hover:bg-red-200 focus:outline-none focus:ring">
                        <span className="capitalize text-sm font-medium text-white transition-colors group-active:text-red-900 group-hover:text-red-900">
                          Anular
                        </span>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="h-[30px] w-[30px] text-white group-hover:text-red-900">
                          <path fillRule="evenodd" d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25zm-1.72 6.97a.75.75 0 10-1.06 1.06L10.94 12l-1.72 1.72a.75.75 0 101.06 1.06L12 13.06l1.72 1.72a.75.75 0 101.06-1.06L13.06 12l1.72-1.72a.75.75 0 10-1.06-1.06L12 10.94l-1.72-1.72z" clipRule="evenodd" />
                        </svg>
                      </button>
                      <button type="button" onClick={(event) => {
                        updateOrderState(event, 'completado');
                      }} className="group flex items-center justify-center gap-2 rounded-lg border border-amber-900 bg-amber-400 px-3 py-2 transition-colors hover:bg-amber-200 focus:outline-none focus:ring">
                        <span className="capitalize text-sm font-medium text-amber-900 transition-colors group-active:text-amber-900 group-hover:text-amber-900">
                          Completado
                        </span>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="h-[30px] w-[30px] text-amber-900 group-hover:text-amber-900">
                          <path d="M12.378 1.602a.75.75 0 00-.756 0L3 6.632l9 5.25 9-5.25-8.622-5.03zM21.75 7.93l-9 5.25v9l8.628-5.032a.75.75 0 00.372-.648V7.93zM11.25 22.18v-9l-9-5.25v8.57a.75.75 0 00.372.648l8.628 5.033z" />
                        </svg>
                      </button>
                    </>
                  }
                </div>
              </div>
            }

            {state.waitingForResponse === true && state.successMessage === false &&
              <Loader />
            }
          </motion.form>
        </>
      }
      {state.data && state.dataLoaded !== false ?
        <div className="mt-10">
          <table className="min-w-full divide-y-2 divide-gray-100 text-sm block overflow-auto 2xl:overflow-visible min-h-[400px]">
            <OrderFilter filteredState={state} setFilteredState={setState} />

            <thead>
              <tr>
                <th className="whitespace-nowrap px-4 py-2 text-left font-medium text-gray-900">
                  Id
                </th>
                <th className="whitespace-nowrap px-4 py-2 text-left font-medium text-gray-900">
                  Nombre del optometra
                </th>
                <th className="whitespace-nowrap px-4 py-2 text-left font-medium text-gray-900">
                  Nombre del paciente
                </th>
                <th className="whitespace-nowrap px-4 py-2 text-left font-medium text-gray-900">
                  Fecha de creacion
                </th>
                <th className="whitespace-nowrap px-4 py-2 text-left font-medium text-gray-900">
                  Estado
                </th>
                <th className="whitespace-nowrap px-4 py-2 text-left font-medium text-gray-900">
                  Tipo
                </th>
                <th className="px-4 py-2 lg:sticky lg:right-0 lg:bg-gray-100" />
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-200">
              {state.filteredData.map((order: any) => {
                return (
                  <tr key={order.id_order} className="relative lg:pr-16">
                    <td className="whitespace-nowrap px-4 py-2 text-gray-700">{order.id_order}</td>
                    <td className="whitespace-nowrap px-4 py-2 font-medium text-gray-700">{order.optometrist_name}</td>
                    <td className="whitespace-nowrap px-4 py-2 text-gray-700">{order.patient_name}</td>
                    <td className="wwhitespace-nowrap px-4 py-2 text-gray-700">{order.created_at.replace(/-/ig, ' / ')}</td>
                    <td className="whitespace-nowrap px-4 py-2 text-gray-700">
                      <StateBadge status={order.state_order} />
                    </td>
                    <td className="whitespace-nowrap px-4 py-2 text-gray-700">
                      <TypeBadge type={order.type_order} />
                    </td>
                    <td className="whitespace-nowrap px-4 py-2 lg:sticky lg:right-0 lg:bg-gray-100 h-full">
                      <button onClick={() => {
                        setState({ successMessage: false })
                        mostrarPedido({
                          updatedOrderDate: order.updated_at,
                          createdOrderDate: order.created_at,
                          orderId: parseInt(order.id_order),
                          verPedido: true,
                          quienRealizaElPedido: order.creator_name,
                          nombreDelPaciente: order.patient_name,
                          numeroDeDocumentoDelPaciente: order.patient_doc,
                          nombreDelDoctor: order.optometrist_name,
                          tarjetaProfesional: order.professional_card,
                          city: parseInt(order.city),
                          address: order.address,
                          type_order: parseInt(order.type_order),
                          idCaja: parseInt(order.id_box),
                          idAccesorio: parseInt(order.id_acc),
                          order_state: order.state_order,
                          // ojo izquierdo
                          left_typeLens: parseInt(order.l_id_typeLens),
                          left_referenceLens: parseInt(order.l_id_lens),
                          left_c_base: order.l_cBase,
                          left_poder: order.l_power,
                          left_cilindro: order.l_cylinder,
                          left_diametro: order.l_diameter,
                          left_design: parseInt(order.l_design),
                          left_cpp: order.l_cpp,
                          left_observaciones: order.l_observations,
                          left_color: parseInt(order.l_color),
                          left_cantidad: parseInt(order.lQty),
                          left_adicion: order.l_addition,
                          left_ZO: order.l_zo,
                          left_grados: order.l_degrees,
                          left_periferia1: order.l_perifery1,
                          left_periferia2: order.l_perifery2,
                          left_cInvertida: order.l_cInverted,
                          left_cAlineamiento: order.l_cAlignment,
                          left_cApertura: order.l_cOpening,
                          left_ZOcBase: order.l_ZOcBase,
                          left_ZOcInvertida: order.l_ZOcInverted,
                          left_ZOcAlineamiento: order.l_ZOcAlignment,
                          left_ZOcApertura: order.l_ZOcOpening,
                          left_dZO: order.lD_zo,
                          left_dSegundaCurva: order.lD_secondturn,
                          // ojo derecho
                          right_typeLens: parseInt(order.r_id_typeLens),
                          right_referenceLens: parseInt(order.r_id_lens),
                          right_c_base: order.r_cBase,
                          right_poder: order.r_power,
                          right_cilindro: order.r_cylinder,
                          right_diametro: order.r_diameter,
                          right_design: parseInt(order.r_design),
                          right_cpp: order.r_cpp,
                          right_observaciones: order.r_observations,
                          right_color: parseInt(order.r_color),
                          right_cantidad: parseInt(order.rQty),
                          right_adicion: order.r_addition,
                          right_ZO: order.r_zo,
                          right_grados: order.r_degrees,
                          right_periferia1: order.r_perifery1,
                          right_periferia2: order.r_perifery2,
                          right_cInvertida: order.r_cInverted,
                          right_cAlineamiento: order.r_cAlignment,
                          right_cApertura: order.r_cOpening,
                          right_ZOcBase: order.r_ZOcBase,
                          right_ZOcInvertida: order.r_ZOcInverted,
                          right_ZOcAlineamiento: order.r_ZOcAlignment,
                          right_ZOcApertura: order.r_ZOcOpening,
                          right_dZO: order.rD_zo,
                          right_dSegundaCurva: order.rD_secondturn,
                        })
                      }} className="inline-block rounded bg-indigo-500 px-4 py-2 text-xs font-medium border-[1px] border-indigo-500 text-white hover:text-indigo-500 hover:bg-transparent">
                        Ver pedido
                      </button>
                    </td>
                  </tr>
                )
              })
              }
            </tbody>
          </table>
        </div>
        : <div className="flex items-center justify-center min-h-[300px]">
          <p className="text-xl text-slate-600 font-medium">Aun no hay ningun pedido...</p>
        </div>
      }
    </div>
  )
}

export { Orders };
import { Children } from 'react';
import logo from '../assets/logo.png'
import { useStateMany } from '../customHooks/useStateMany'
import { userType } from '../typos';

interface SideNavTypes {
  state: {
    currentOpen: string,
    user: userType;
  };
  setState: (value: object) => void;
}

export function SideNav({ state, setState }: SideNavTypes): JSX.Element {
  const [onOpen, setOpenTeam] = useStateMany({
    team: false,
    account: false,
  })
  const LogOut = () => {
    setState({
      login: false,
      user: {
        id: '',
        name: '',
        email: '',
        permissions: '',
        phone: '',
      },
    })
  }
  const openTeamSection = () => {
    setOpenTeam({ team: !onOpen.team });
  }
  const openAccountSection = () => {
    setOpenTeam({ account: !onOpen.account });
  }
  return (
    <div className={`w-[80px] sm:w-[200px] lg:w-[275px] flex h-full flex-col justify-between border-r bg-white fixed left-0 top-0 z-20`}>
      <div className="px-4 py-6">
        <img src={logo} className="max-h-16 w-full object-contain justify-center items-center hidden sm:flex" alt="Keratos" />
        <nav aria-label="Main Nav" className="mt-6 flex flex-col gap-y-1 items-start">

          {state.user.permissions === '1' &&
            <Level1 setState={setState} state={state} onOpen={onOpen} openAccountSection={openAccountSection} openTeamSection={openTeamSection} />
          }
          {state.user.permissions === '2' &&
            <Level2 setState={setState} state={state} onOpen={onOpen} openAccountSection={openAccountSection} openTeamSection={openTeamSection} />
          }
          {state.user.permissions === '3' &&
            <Level3 setState={setState} state={state} onOpen={onOpen} openAccountSection={openAccountSection} openTeamSection={openTeamSection} />
          }
          {state.user.permissions === '4' &&
            <p>El rol que posee esta cuenta no se le ha asignado acceso a nada por los momentos, consulte con el proveedor de la cuenta</p>
          }
          {state.user.permissions === '5' &&
            <Level5 setState={setState} state={state} onOpen={onOpen} openAccountSection={openAccountSection} openTeamSection={openTeamSection} />
          }
          <SideNavButtonDropdown name='Cuenta' toExecute={openAccountSection}>
            <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 opacity-75" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
              <path strokeLinecap="round" strokeLinejoin="round" d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z" />
            </svg>
          </SideNavButtonDropdown>
          <nav aria-label={"Account Nav"} className={`flex flex-col items-end justify-end transition-[height,margin] duration-[400ms] w-full bg-gray-100 rounded-lg  overflow-hidden ${onOpen.account === true ? `h-[150px] mt-2` : "h-[0.01px]"}`}>
            <SideNavButton className="rounded-t-lg" name="Detalles" active='details' setState={setState} state={state}>
              <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 opacity-75" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                <path strokeLinecap="round" strokeLinejoin="round" d="M10 6H5a2 2 0 00-2 2v9a2 2 0 002 2h14a2 2 0 002-2V8a2 2 0 00-2-2h-5m-4 0V5a2 2 0 114 0v1m-4 0a2 2 0 104 0m-5 8a2 2 0 100-4 2 2 0 000 4zm0 0c1.306 0 2.417.835 2.83 2M9 14a3.001 3.001 0 00-2.83 2M15 11h3m-3 4h2"></path>
              </svg>
            </SideNavButton>
            <SideNavButton className="rounded-none" name="Seguridad" active='security' setState={setState} state={state}>
              <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 opacity-75" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                <path strokeLinecap="round" strokeLinejoin="round" d="M9 12l2 2 4-4m5.618-4.016A11.955 11.955 0 0112 2.944a11.955 11.955 0 01-8.618 3.04A12.02 12.02 0 003 9c0 5.591 3.824 10.29 9 11.622 5.176-1.332 9-6.03 9-11.622 0-1.042-.133-2.052-.382-3.016z" />
              </svg>
            </SideNavButton>
            <SideNavButton className="rounded-b-lg" name="Cerrar sesion" active='logout' setState={setState} state={state}>
              <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 opacity-75" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                <path strokeLinecap="round" strokeLinejoin="round" d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1" />
              </svg>
            </SideNavButton>
          </nav>
        </nav>
      </div>
      <div className="sticky inset-x-0 bottom-0 border-t border-gray-100">
        <div className="flex items-center gap-2 bg-white p-4 hover:bg-gray-50">
          <div className='h-10 w-10 rounded-full bg-gray-200 flex items-center justify-center'>
            <span className='font-bold text-slate-700'>{state.user.permissions}</span>
          </div>
          <div>
            <p className="text-xs flex flex-col justify-start items-start gap-y-1">
              <strong className="font-medium hidden sm:inline">{state.user.name}</strong>
              <span className='hidden sm:inline'>{state.user.email}</span>
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}
interface SideNavButtonTypes {
  name: string;
  children: JSX.Element[] | JSX.Element | [];
  setState: (value: {}) => void;
  active: string;
  className?: string;
  state: {
    currentOpen: string,
  };
}
const SideNavButton = ({ name, children, setState, state, active, className }: SideNavButtonTypes) => {
  return (
    <button className={`flex h-[50px] w-full justify-center items-center sm:justify-start gap-2 ${className ? className : 'rounded-lg'} px-3 py-3 sm:w-full ${state.currentOpen === `${active}` ? 'bg-blue-600 text-white' : 'text-gray-700 hover:bg-gray-100'}`}
      onClick={() => {
        if (active === 'logout') {
          setState({
            login: false,
            user: {
              id: '',
              name: '',
              email: '',
              permissions: '',
              phone: '',
              staus: 0,
            },
            currentOpen: 'general',
          })
        } else {
          setState({ currentOpen: `${active}` });
        }
      }}>
      {children}
      <span className="text-sm font-medium hidden sm:inline-block first-letter:uppercase"> {name} </span>
    </button>
  )
}

interface SideNavButtonDropdownTypes {
  name: string;
  children: JSX.Element;
  setState?: (value: {}) => void;
  active?: string;
  state?: {
    currentOpen: string,
  };
  toExecute: () => void;
}

const SideNavButtonDropdown = ({ name, children, toExecute }: SideNavButtonDropdownTypes) => {
  return (
    <button className={`flex h-[50px] w-full justify-center items-center sm:justify-start gap-2 rounded-lg hover:bg-gray-100 px-3 py-3 text-gray-700 sm:w-full`} onClick={() => {
      toExecute();
    }}>
      {children}
      <span className="text-sm font-medium hidden sm:inline-block"> {name} </span>
    </button>
  )
}

interface DropdownTypes {
  active: boolean;
  children: JSX.Element | JSX.Element[] | any
}

const Dropdown = ({ active, children }: DropdownTypes) => {
  const count = Children.count(children);
  return (
    <nav aria-label={"Nav"} className={`flex flex-col items-end justify-end transition-[height,margin] duration-[400ms] w-full bg-gray-100 rounded-lg  overflow-hidden ${active === true ? `h-[${50 * count}px] mt-2` : "h-[0.01px]"}`}>
      {children}
    </nav>
  )
}
interface LevelsTypes {
  setState: (value: object) => void;
  state: {
    currentOpen: string;
    user: userType;
  }
  openAccountSection?: () => void;
  openTeamSection: () => void;
  onOpen: {
    account: boolean,
    team: boolean,
  };
}
function Level1({ setState, state, openTeamSection, onOpen }: LevelsTypes) {
  return (
    <>
      <SideNavButton name="Mis pedidos" active='misPedidos' setState={setState} state={state}>
        <svg id="Layer_1" className={`h-5 w-5 opacity-75 ${state.currentOpen === 'misPedidos' ? 'fill-white' : 'fill-slate-700'}`} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M23,6a.8.8,0,0,0-.06-.3.64.64,0,0,0,0-.07s0,0,0,0l-2-4A1,1,0,0,0,20,1H3a1,1,0,0,0-1,.76l-1,4a.34.34,0,0,0,0,.1A.7.7,0,0,0,1,6V22a1,1,0,0,0,1,1H22a1,1,0,0,0,1-1V6S23,6,23,6ZM3.78,3h15.6l1,2H3.28ZM21,21H3V7H21Z" /><path d="M11.45,18.83a1,1,0,0,0,1.1,0l3-2a1,1,0,1,0-1.1-1.66l-1.45,1V10a1,1,0,0,0-2,0v6.13l-1.45-1a1,1,0,0,0-1.1,1.66Z" /></svg>
      </SideNavButton>
      {parseInt(state.user.customerType) === 2 &&
        <>
          <SideNavButtonDropdown name='Personas' toExecute={openTeamSection}>
            <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 opacity-75" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
              <path strokeLinecap="round" strokeLinejoin="round" d="M17 20h5v-2a3 3 0 00-5.356-1.857M17 20H7m10 0v-2c0-.656-.126-1.283-.356-1.857M7 20H2v-2a3 3 0 015.356-1.857M7 20v-2c0-.656.126-1.283.356-1.857m0 0a5.002 5.002 0 019.288 0M15 7a3 3 0 11-6 0 3 3 0 016 0zm6 3a2 2 0 11-4 0 2 2 0 014 0zM7 10a2 2 0 11-4 0 2 2 0 014 0z" />
            </svg>
          </SideNavButtonDropdown>
          <nav aria-label={"Profiles"} className={`flex flex-col items-end justify-end transition-[height,margin] duration-[400ms] w-full bg-gray-100 rounded-lg  overflow-hidden ${onOpen.team === true ? `h-[50px] mt-2` : "h-[0.01px]"}`}>
            <SideNavButton className="rounded-none" name="Optometras" active='optometrists' setState={setState} state={state}>
              <svg width="32" height="23" className="h-5 w-5 opacity-75" viewBox="0 0 32 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M24 21.0679C27.3137 21.0679 30 18.8294 30 16.0679C30 13.3065 27.3137 11.0679 24 11.0679C20.6863 11.0679 18 13.3065 18 16.0679C18 18.8294 20.6863 21.0679 24 21.0679Z" stroke={`${state.currentOpen === 'optometrists' ? '#fff' : '#334155'}`} strokeWidth="3" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M8 21.0679C11.3137 21.0679 14 18.8294 14 16.0679C14 13.3065 11.3137 11.0679 8 11.0679C4.68629 11.0679 2 13.3065 2 16.0679C2 18.8294 4.68629 21.0679 8 21.0679Z" stroke={`${state.currentOpen === 'optometrists' ? '#fff' : '#334155'}`} strokeWidth="3" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M11.0002 3.06796C9.7002 1.36796 7.0002 1.76796 6.3002 3.76796L2.7002 13.468" stroke={`${state.currentOpen === 'optometrists' ? '#fff' : '#334155'}`} strokeWidth="3" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M21 3.06796C22.3 1.36796 25 1.76796 25.7 3.76796L29.3 13.468" stroke={`${state.currentOpen === 'optometrists' ? '#fff' : '#334155'}`} strokeWidth="3" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M14 15.5679C14.6 15.2679 15.3 15.0679 16 15.0679C16.7 15.0679 17.4 15.2679 18 15.5679" stroke={`${state.currentOpen === 'optometrists' ? '#fff' : '#334155'}`} strokeWidth="3" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
              </svg>
            </SideNavButton>
          </nav>
        </>
      }
    </>
  )
}
function Level2({ setState, state, openTeamSection, onOpen }: LevelsTypes) {
  return (
    <>
      <SideNavButton name="Mis pedidos" active='misPedidos' setState={setState} state={state}>
        <svg id="Layer_1" className={`h-5 w-5 opacity-75 ${state.currentOpen === 'misPedidos' ? 'fill-white' : 'fill-slate-700'}`} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M23,6a.8.8,0,0,0-.06-.3.64.64,0,0,0,0-.07s0,0,0,0l-2-4A1,1,0,0,0,20,1H3a1,1,0,0,0-1,.76l-1,4a.34.34,0,0,0,0,.1A.7.7,0,0,0,1,6V22a1,1,0,0,0,1,1H22a1,1,0,0,0,1-1V6S23,6,23,6ZM3.78,3h15.6l1,2H3.28ZM21,21H3V7H21Z" /><path d="M11.45,18.83a1,1,0,0,0,1.1,0l3-2a1,1,0,1,0-1.1-1.66l-1.45,1V10a1,1,0,0,0-2,0v6.13l-1.45-1a1,1,0,0,0-1.1,1.66Z" /></svg>
      </SideNavButton>
      <SideNavButton name="pedidos" active='pedidos' setState={setState} state={state}>
        <svg className={`h-5 w-5 opacity-75 ${state.currentOpen === 'pedidos' ? 'fill-white' : 'fill-slate-700'}`} viewBox="0 0 40 38" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M13.8 13.1001L6.4 20.4001L2.2 16.3001L0 18.5001L6.4 24.9001L16 15.3001L13.8 13.1001Z" />
          <path d="M13.8 0.100098L6.4 7.4001L2.2 3.3001L0 5.5001L6.4 11.9001L16 2.3001L13.8 0.100098Z" />
          <path d="M13.8 26.1001L6.4 33.4001L2.2 29.3001L0 31.5001L6.4 37.9001L16 28.3001L13.8 26.1001Z" />
          <path d="M40 17H20V21H40V17Z" />
          <path d="M40 4H20V8H40V4Z" />
          <path d="M40 30H20V34H40V30Z" />
        </svg>
      </SideNavButton>
      <SideNavButton name="reportes" active='reportes' setState={setState} state={state}>
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="h-5 w-5 opacity-75">
          <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25M9 16.5v.75m3-3v3M15 12v5.25m-4.5-15H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z" />
        </svg>
      </SideNavButton>
      <SideNavButtonDropdown name='Personas' toExecute={openTeamSection}>
        <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 opacity-75" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
          <path strokeLinecap="round" strokeLinejoin="round" d="M17 20h5v-2a3 3 0 00-5.356-1.857M17 20H7m10 0v-2c0-.656-.126-1.283-.356-1.857M7 20H2v-2a3 3 0 015.356-1.857M7 20v-2c0-.656.126-1.283.356-1.857m0 0a5.002 5.002 0 019.288 0M15 7a3 3 0 11-6 0 3 3 0 016 0zm6 3a2 2 0 11-4 0 2 2 0 014 0zM7 10a2 2 0 11-4 0 2 2 0 014 0z" />
        </svg>
      </SideNavButtonDropdown>
      <Dropdown active={onOpen.team}>
        <SideNavButton className="rounded-t-lg" name="perfiles" active='profiles' setState={setState} state={state}>
          <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 opacity-75" viewBox="0 0 61.33 64">
            <path className={`${state.currentOpen === 'profiles' ? 'fill-white' : 'fill-slate-700'}`} d="M42.67,56H48v5.33a2.67,2.67,0,1,0,5.33,0V56h5.34a2.67,2.67,0,0,0,0-5.33H53.33V45.33a2.67,2.67,0,0,0-5.33,0v5.34H42.67a2.67,2.67,0,1,0,0,5.33Z" /><path className={`${state.currentOpen === 'profiles' ? 'fill-white' : 'fill-slate-700'} cls-1`} d="M29.33,26.67A13.34,13.34,0,1,1,42.67,13.33,13.35,13.35,0,0,1,29.33,26.67Zm0-21.34a8,8,0,1,0,8,8A8,8,0,0,0,29.33,5.33Z" /><path className={`${state.currentOpen === 'profiles' ? 'fill-white' : 'fill-slate-700'} cls-1`} d="M32,58.67H5.33A24,24,0,0,1,43.68,39.45a2.66,2.66,0,0,0,1.59.55h0a2.69,2.69,0,0,0,1.58-4.85A29.26,29.26,0,0,0,27,29.42C11.58,30.62,0,44.13,0,59.6V61a2.74,2.74,0,0,0,2.67,3H32a2.67,2.67,0,0,0,2.67-2.67h0A2.67,2.67,0,0,0,32,58.67Z" /></svg>
        </SideNavButton>
      </Dropdown>
    </>
  )
}
function Level3({ setState, state, openTeamSection, onOpen }: LevelsTypes) {
  return (
    <>
      <SideNavButton name="pedidos" active='pedidos' setState={setState} state={state}>
        <svg className={`h-5 w-5 opacity-75 ${state.currentOpen === 'pedidos' ? 'fill-white' : 'fill-slate-700'}`} viewBox="0 0 40 38" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M13.8 13.1001L6.4 20.4001L2.2 16.3001L0 18.5001L6.4 24.9001L16 15.3001L13.8 13.1001Z" />
          <path d="M13.8 0.100098L6.4 7.4001L2.2 3.3001L0 5.5001L6.4 11.9001L16 2.3001L13.8 0.100098Z" />
          <path d="M13.8 26.1001L6.4 33.4001L2.2 29.3001L0 31.5001L6.4 37.9001L16 28.3001L13.8 26.1001Z" />
          <path d="M40 17H20V21H40V17Z" />
          <path d="M40 4H20V8H40V4Z" />
          <path d="M40 30H20V34H40V30Z" />
        </svg>
      </SideNavButton>
      {/* <SideNavButton name="reportes" active='reportes' setState={setState} state={state}>
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="h-5 w-5 opacity-75">
          <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25M9 16.5v.75m3-3v3M15 12v5.25m-4.5-15H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z" />
        </svg>
      </SideNavButton> */}
      {/* <SideNavButtonDropdown name='Personas' toExecute={openTeamSection}>
        <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 opacity-75" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
          <path strokeLinecap="round" strokeLinejoin="round" d="M17 20h5v-2a3 3 0 00-5.356-1.857M17 20H7m10 0v-2c0-.656-.126-1.283-.356-1.857M7 20H2v-2a3 3 0 015.356-1.857M7 20v-2c0-.656.126-1.283.356-1.857m0 0a5.002 5.002 0 019.288 0M15 7a3 3 0 11-6 0 3 3 0 016 0zm6 3a2 2 0 11-4 0 2 2 0 014 0zM7 10a2 2 0 11-4 0 2 2 0 014 0z" />
        </svg>
      </SideNavButtonDropdown> */}
      {/* <Dropdown active={onOpen.team}>
        <SideNavButton className="rounded-t-lg" name="perfiles" active='profiles' setState={setState} state={state}>
          <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 opacity-75" viewBox="0 0 61.33 64">
            <path className={`${state.currentOpen === 'profiles' ? 'fill-white' : 'fill-slate-700'}`} d="M42.67,56H48v5.33a2.67,2.67,0,1,0,5.33,0V56h5.34a2.67,2.67,0,0,0,0-5.33H53.33V45.33a2.67,2.67,0,0,0-5.33,0v5.34H42.67a2.67,2.67,0,1,0,0,5.33Z" /><path className={`${state.currentOpen === 'profiles' ? 'fill-white' : 'fill-slate-700'} cls-1`} d="M29.33,26.67A13.34,13.34,0,1,1,42.67,13.33,13.35,13.35,0,0,1,29.33,26.67Zm0-21.34a8,8,0,1,0,8,8A8,8,0,0,0,29.33,5.33Z" /><path className={`${state.currentOpen === 'profiles' ? 'fill-white' : 'fill-slate-700'} cls-1`} d="M32,58.67H5.33A24,24,0,0,1,43.68,39.45a2.66,2.66,0,0,0,1.59.55h0a2.69,2.69,0,0,0,1.58-4.85A29.26,29.26,0,0,0,27,29.42C11.58,30.62,0,44.13,0,59.6V61a2.74,2.74,0,0,0,2.67,3H32a2.67,2.67,0,0,0,2.67-2.67h0A2.67,2.67,0,0,0,32,58.67Z" /></svg>
        </SideNavButton>
      </Dropdown> */}
    </>
  )
}
function Level5({ setState, state, openTeamSection, onOpen }: LevelsTypes) {
  return (
    <>
      <SideNavButton name="Mis pedidos" active='misPedidos' setState={setState} state={state}>
        <svg id="Layer_1" className={`h-5 w-5 opacity-75 ${state.currentOpen === 'misPedidos' ? 'fill-white' : 'fill-slate-700'}`} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M23,6a.8.8,0,0,0-.06-.3.64.64,0,0,0,0-.07s0,0,0,0l-2-4A1,1,0,0,0,20,1H3a1,1,0,0,0-1,.76l-1,4a.34.34,0,0,0,0,.1A.7.7,0,0,0,1,6V22a1,1,0,0,0,1,1H22a1,1,0,0,0,1-1V6S23,6,23,6ZM3.78,3h15.6l1,2H3.28ZM21,21H3V7H21Z" /><path d="M11.45,18.83a1,1,0,0,0,1.1,0l3-2a1,1,0,1,0-1.1-1.66l-1.45,1V10a1,1,0,0,0-2,0v6.13l-1.45-1a1,1,0,0,0-1.1,1.66Z" /></svg>
      </SideNavButton>
      <SideNavButton name="pedidos" active='pedidos' setState={setState} state={state}>
        <svg className={`h-5 w-5 opacity-75 ${state.currentOpen === 'pedidos' ? 'fill-white' : 'fill-slate-700'}`} viewBox="0 0 40 38" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M13.8 13.1001L6.4 20.4001L2.2 16.3001L0 18.5001L6.4 24.9001L16 15.3001L13.8 13.1001Z" />
          <path d="M13.8 0.100098L6.4 7.4001L2.2 3.3001L0 5.5001L6.4 11.9001L16 2.3001L13.8 0.100098Z" />
          <path d="M13.8 26.1001L6.4 33.4001L2.2 29.3001L0 31.5001L6.4 37.9001L16 28.3001L13.8 26.1001Z" />
          <path d="M40 17H20V21H40V17Z" />
          <path d="M40 4H20V8H40V4Z" />
          <path d="M40 30H20V34H40V30Z" />
        </svg>
      </SideNavButton>
      <SideNavButton name="reportes" active='reportes' setState={setState} state={state}>
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="h-5 w-5 opacity-75">
          <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25M9 16.5v.75m3-3v3M15 12v5.25m-4.5-15H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z" />
        </svg>
      </SideNavButton>
      <SideNavButtonDropdown name='Personas' toExecute={openTeamSection}>
        <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 opacity-75" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
          <path strokeLinecap="round" strokeLinejoin="round" d="M17 20h5v-2a3 3 0 00-5.356-1.857M17 20H7m10 0v-2c0-.656-.126-1.283-.356-1.857M7 20H2v-2a3 3 0 015.356-1.857M7 20v-2c0-.656.126-1.283.356-1.857m0 0a5.002 5.002 0 019.288 0M15 7a3 3 0 11-6 0 3 3 0 016 0zm6 3a2 2 0 11-4 0 2 2 0 014 0zM7 10a2 2 0 11-4 0 2 2 0 014 0z" />
        </svg>
      </SideNavButtonDropdown>
      <nav aria-label={"Profiles"} className={`flex flex-col items-end justify-end transition-[height,margin] duration-[400ms] w-full bg-gray-100 rounded-lg  overflow-hidden ${onOpen.team === true ? `h-[100px] mt-2` : "h-[0.01px]"}`}>
        <SideNavButton className="rounded-t-lg" name="Perfiles" active='profiles' setState={setState} state={state}>
          <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 opacity-75" viewBox="0 0 61.33 64">
            <path className={`${state.currentOpen === 'profiles' ? 'fill-white' : 'fill-slate-700'}`} d="M42.67,56H48v5.33a2.67,2.67,0,1,0,5.33,0V56h5.34a2.67,2.67,0,0,0,0-5.33H53.33V45.33a2.67,2.67,0,0,0-5.33,0v5.34H42.67a2.67,2.67,0,1,0,0,5.33Z" /><path className={`${state.currentOpen === 'profiles' ? 'fill-white' : 'fill-slate-700'} cls-1`} d="M29.33,26.67A13.34,13.34,0,1,1,42.67,13.33,13.35,13.35,0,0,1,29.33,26.67Zm0-21.34a8,8,0,1,0,8,8A8,8,0,0,0,29.33,5.33Z" /><path className={`${state.currentOpen === 'profiles' ? 'fill-white' : 'fill-slate-700'} cls-1`} d="M32,58.67H5.33A24,24,0,0,1,43.68,39.45a2.66,2.66,0,0,0,1.59.55h0a2.69,2.69,0,0,0,1.58-4.85A29.26,29.26,0,0,0,27,29.42C11.58,30.62,0,44.13,0,59.6V61a2.74,2.74,0,0,0,2.67,3H32a2.67,2.67,0,0,0,2.67-2.67h0A2.67,2.67,0,0,0,32,58.67Z" /></svg>
        </SideNavButton>
        <SideNavButton className="rounded-none" name="Optometras" active='optometrists' setState={setState} state={state}>
          <svg width="32" height="23" className="h-5 w-5 opacity-75" viewBox="0 0 32 23" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M24 21.0679C27.3137 21.0679 30 18.8294 30 16.0679C30 13.3065 27.3137 11.0679 24 11.0679C20.6863 11.0679 18 13.3065 18 16.0679C18 18.8294 20.6863 21.0679 24 21.0679Z" stroke={`${state.currentOpen === 'optometrists' ? '#fff' : '#334155'}`} strokeWidth="3" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M8 21.0679C11.3137 21.0679 14 18.8294 14 16.0679C14 13.3065 11.3137 11.0679 8 11.0679C4.68629 11.0679 2 13.3065 2 16.0679C2 18.8294 4.68629 21.0679 8 21.0679Z" stroke={`${state.currentOpen === 'optometrists' ? '#fff' : '#334155'}`} strokeWidth="3" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M11.0002 3.06796C9.7002 1.36796 7.0002 1.76796 6.3002 3.76796L2.7002 13.468" stroke={`${state.currentOpen === 'optometrists' ? '#fff' : '#334155'}`} strokeWidth="3" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M21 3.06796C22.3 1.36796 25 1.76796 25.7 3.76796L29.3 13.468" stroke={`${state.currentOpen === 'optometrists' ? '#fff' : '#334155'}`} strokeWidth="3" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M14 15.5679C14.6 15.2679 15.3 15.0679 16 15.0679C16.7 15.0679 17.4 15.2679 18 15.5679" stroke={`${state.currentOpen === 'optometrists' ? '#fff' : '#334155'}`} strokeWidth="3" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
          </svg>
        </SideNavButton>
      </nav>
      {/* <Dropdown active={onOpen.team}> */}
      {/* <SideNavButton className="rounded-t-lg" name="Perfiles" active='profiles' setState={setState} state={state}>
          <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 opacity-75" viewBox="0 0 61.33 64">
            <path className={`${state.currentOpen === 'profiles' ? 'fill-white' : 'fill-slate-700'}`} d="M42.67,56H48v5.33a2.67,2.67,0,1,0,5.33,0V56h5.34a2.67,2.67,0,0,0,0-5.33H53.33V45.33a2.67,2.67,0,0,0-5.33,0v5.34H42.67a2.67,2.67,0,1,0,0,5.33Z" /><path className={`${state.currentOpen === 'profiles' ? 'fill-white' : 'fill-slate-700'} cls-1`} d="M29.33,26.67A13.34,13.34,0,1,1,42.67,13.33,13.35,13.35,0,0,1,29.33,26.67Zm0-21.34a8,8,0,1,0,8,8A8,8,0,0,0,29.33,5.33Z" /><path className={`${state.currentOpen === 'profiles' ? 'fill-white' : 'fill-slate-700'} cls-1`} d="M32,58.67H5.33A24,24,0,0,1,43.68,39.45a2.66,2.66,0,0,0,1.59.55h0a2.69,2.69,0,0,0,1.58-4.85A29.26,29.26,0,0,0,27,29.42C11.58,30.62,0,44.13,0,59.6V61a2.74,2.74,0,0,0,2.67,3H32a2.67,2.67,0,0,0,2.67-2.67h0A2.67,2.67,0,0,0,32,58.67Z" /></svg>
        </SideNavButton>
        <SideNavButton className="rounded-none" name="Optometras" active='optometrist' setState={setState} state={state}>
              <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 opacity-75" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                <path strokeLinecap="round" strokeLinejoin="round" d="M9 12l2 2 4-4m5.618-4.016A11.955 11.955 0 0112 2.944a11.955 11.955 0 01-8.618 3.04A12.02 12.02 0 003 9c0 5.591 3.824 10.29 9 11.622 5.176-1.332 9-6.03 9-11.622 0-1.042-.133-2.052-.382-3.016z" />
              </svg>
            </SideNavButton> */}
      {/* <SideNavButton className="rounded-t-lg" name="Optometras" active='optometrists' setState={setState} state={state}>
          <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 opacity-75" viewBox="0 0 61.33 64">
            <path className={`${state.currentOpen === 'optometrists' ? 'fill-white' : 'fill-slate-700'}`} d="M42.67,56H48v5.33a2.67,2.67,0,1,0,5.33,0V56h5.34a2.67,2.67,0,0,0,0-5.33H53.33V45.33a2.67,2.67,0,0,0-5.33,0v5.34H42.67a2.67,2.67,0,1,0,0,5.33Z" /><path className={`${state.currentOpen === 'optometrists' ? 'fill-white' : 'fill-slate-700'} cls-1`} d="M29.33,26.67A13.34,13.34,0,1,1,42.67,13.33,13.35,13.35,0,0,1,29.33,26.67Zm0-21.34a8,8,0,1,0,8,8A8,8,0,0,0,29.33,5.33Z" /><path className={`${state.currentOpen === 'optometrists' ? 'fill-white' : 'fill-slate-700'} cls-1`} d="M32,58.67H5.33A24,24,0,0,1,43.68,39.45a2.66,2.66,0,0,0,1.59.55h0a2.69,2.69,0,0,0,1.58-4.85A29.26,29.26,0,0,0,27,29.42C11.58,30.62,0,44.13,0,59.6V61a2.74,2.74,0,0,0,2.67,3H32a2.67,2.67,0,0,0,2.67-2.67h0A2.67,2.67,0,0,0,32,58.67Z" /></svg>
        </SideNavButton> */}
      {/* </Dropdown> */}
    </>
  )
}
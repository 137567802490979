import { createRef, useEffect } from "react"
import { Loader } from "../Loader";
import { motion } from 'framer-motion'
import { useStateMany } from "../customHooks/useStateMany";
import { ErrorAlert } from "../ErrorAlert";
import { API } from "../API";
import { Modal } from "../modal";
import { Fragment, useState } from 'react'
import { Listbox, Transition } from '@headlessui/react'
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid'
import { userType } from "../typos";

interface ProfilesTypes {
  user: userType
}
export function Optometrists({ user }: ProfilesTypes) {
  const [state, setState] = useStateMany({
    data: [],
    reload: false,
    loading: false,
    loaded: false,
    showCreateProfile: false,
    error: false,
    errorMessage: '',
    errorTitle: '',
  })
  const [citysData, setCitysData]: any = useState([
    { name: "Seleccion una ciudad", id: 0 },
  ])
  const [documentTypeData, setDocumentTypeData]: any = useState([
    { name: "Seleccion un tipo de documento", id: 0, abbrev: '' },
  ])
  useEffect(() => {
    try {
      let dataToSend = {
        API_KEY: process.env.REACT_APP_API_KEY,
        id_profile_creator: parseInt(user.id),
      }
      setState({ loading: true });

      API.post('/?optometrists', {
        ...dataToSend
      }).then((response) => {
        if (response.status === 200) {
          setState({ data: response.data.optometrists, loading: false, loaded: true });
        } else {
          setState({ loading: false, loaded: true, error: true, errorTitle: 'Error al consultar la informacion de los usuarios', errorMessage: 'No se pudo obtener los datos de los usuarios, por favor comunique este error al equipo de desarrollo' })
        }
      }).catch((error) => {
        console.log("Ha ocurrido un error", error)
        setState({ loading: false, loaded: true, error: true, errorTitle: 'Error al consultar la informacion de los usuarios', errorMessage: 'No se pudo obtener los datos de los usuarios, por favor comunique este error al equipo de desarrollo' })
      });
    } catch (error) {
      console.log(error)
    }
    let dataToSend = {
      API_KEY: process.env.REACT_APP_API_KEY
    }
    API.post('/?lens', {
      ...dataToSend
    }).then((response) => {
      // console.log('response de lens', response)
      if (response.status === 200 && response.data) {
        let newCitysData = [
          { name: "Seleccion una ciudad", id: 0 },
          ...response.data.citys
        ]
        let newDocumentTypeData = [
          ...response.data.documentType
        ]

        setCitysData(newCitysData)
        setDocumentTypeData(newDocumentTypeData)
      } else {
        setState({ waitingForResponse: false, error: true, errorTitle: 'Ocurrio un error al intentar consultar informacion', errorMessage: 'No se pudo conseguir la informacion necesaria para continuar.' })
      }
    }).catch((error) => {
      console.log(error)
    })
  }, [state.reload])
  return (
    <>
      {state.error &&
        <ErrorAlert ErrorElement={state.ErrorElement}
          animate={{ x: 0, opacity: 1, zIndex: 50, left: "1rem", bottom: "1rem", position: "fixed" }}
          transition={{ duration: 0.3 }}
          initial={{ x: -50, opacity: 0.1, zIndex: 50, left: "1rem", bottom: "1rem", position: "fixed" }}
          message={state.errorMessage}
          title={state.errorTitle} />
      }
      <div className="max-w-full">
        <div className="flex items-center justify-center flex-col h-full w-full">
          <h4 className="text-4xl font-bold mb-2 text-center">
            Optometras
          </h4>
          <p className="mb-10 text-gray-600 text-center max-w-[500px]">En esta seccion podras crear y administrar los optometras para que puedan entrar a la aplicacion y crear pedidos</p>
          <div className="flex justify-center items-center gap-4">
            <button onClick={() => { setState({ showCreateProfile: true }) }}
              className="inline-block rounded bg-green-500 px-8 py-3 text-sm font-medium text-white transition hover:bg-transparent border-[1px] hover:border-green-500  hover:text-green-500 focus:outline-none focus:ring active:bg-green-400 active:text-white">
              Crear Perfil
            </button>
          </div>
        </div>
        {state.loading &&
          <Loader className="mt-16" />
        }
        <ProfilesList
          citysData={citysData}
          documentTypeData={documentTypeData}
          loading={state.loading}
          data={state.data}
          loaded={state.loaded}
          user={user}
          reload={state.reload}
          updateProfiles={setState}
        />
        <CreateProfile
          citysData={citysData}
          documentTypeData={documentTypeData}
          user={user}
          showCreateProfile={state.showCreateProfile}
          reload={state.reload}
          setShowCreateProfile={setState}
        />
      </div>
    </>
  )
}
interface CreateProfileTypes {
  showCreateProfile: boolean;
  setShowCreateProfile: (value: boolean | {}) => void;
  user: userType;
  reload: boolean;
  citysData: any;
  documentTypeData: any;
}

const CreateProfile = ({ showCreateProfile, setShowCreateProfile, user, reload, citysData, documentTypeData }: CreateProfileTypes) => {
  const [city, setCity] = useState(citysData[0])
  // con esto hago que al actualizarse los datos, se cambie y seleccione por defecto a el primero y no se quede en uno vacio
  const [documentType, setDocumentType] = useState(documentTypeData.length > 0 ? documentTypeData[0] : documentTypeData[0])
  const [state, setState] = useStateMany({
    successAlert: false,
    waitingForResponse: false,
    userName: '',
    name: '',
    lastName: '',
    email: '',
    phone: '',
    address: '',
    documentNumber: '',
    professionalCard: '',
    error: false,
    errorMessage: '',
    errorTitle: '',
    ErrorElement: null,
  })

  const userNameRef = createRef<HTMLInputElement>()
  const emailRef = createRef<HTMLInputElement>()
  const phoneRef = createRef<HTMLInputElement>()
  const addressRef = createRef<HTMLInputElement>()
  const cityRef = createRef<HTMLInputElement>()
  const professionalCardRef = createRef<HTMLInputElement>()
  const documentNumberRef = createRef<HTMLInputElement>()
  const nameRef = createRef<HTMLInputElement>()
  const lastNameRef = createRef<HTMLInputElement>()
  const errorStyles = ["outline-3", "outline", "outline-red-400"]

  function updateUserName(event: React.ChangeEvent<HTMLInputElement>) {
    userNameRef.current?.classList.remove(...errorStyles)
    setState({ userName: event.target.value });
  }
  function updateEmail(event: React.ChangeEvent<HTMLInputElement>) {
    emailRef.current?.classList.remove(...errorStyles)
    setState({ email: event.target.value });
  }
  function updatePhone(event: React.ChangeEvent<HTMLInputElement>) {
    phoneRef.current?.classList.remove(...errorStyles)
    setState({ phone: event.target.value });
  }
  function updateName(event: React.ChangeEvent<HTMLInputElement>) {
    nameRef.current?.classList.remove(...errorStyles)
    setState({ name: event.target.value });
  }
  function updateLastName(event: React.ChangeEvent<HTMLInputElement>) {
    lastNameRef.current?.classList.remove(...errorStyles)
    setState({ lastName: event.target.value });
  }
  function updateAddress(event: React.ChangeEvent<HTMLInputElement>) {
    addressRef.current?.classList.remove(...errorStyles)
    setState({ address: event.target.value });
  }
  function updateCity() {
    cityRef.current?.classList.remove(...errorStyles)
  }
  function updateProfessionalCard(event: React.ChangeEvent<HTMLInputElement>) {
    professionalCardRef.current?.classList.remove(...errorStyles)
    setState({ professionalCard: event.target.value });
  }
  function updateDocumentNumber(event: React.ChangeEvent<HTMLInputElement>) {
    documentNumberRef.current?.classList.remove(...errorStyles)
    setState({ documentNumber: event.target.value });
  }

  function sendCreateOptometrist(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    event.preventDefault();
    if (state.userName && state.name && state.lastName && state.email) {
      let dataToSend = {
        API_KEY: process.env.REACT_APP_API_KEY,
        id_profile_creator: parseInt(user.id),
        username: state.userName,
        name: state.name,
        lastName: state.lastName,
        email: state.email,
        phone: state.phone,
        city: parseInt(city.id),
        address: state.address,
        professionalCard: state.professionalCard,
        documentNumber: state.documentNumber,
        documentType: parseInt(documentType.id),
      }
      try {
        setState({ waitingForResponse: true });
        API.post("/?insertOptometrist", {
          ...dataToSend
        }).then((response) => {
          if (response.status === 200 && response.data.success === 1) {
            // cambiar la variable 'reload' hace que se recargue la informacion de los perfiles
            setShowCreateProfile({ showCreateProfile: false, reload: !reload });
            resetProfileState()
            setTimeout(() => {
              setState({ successAlert: false });
            }, 7000);
          } else if (response.data['success'] === 10) {
            setState({ waitingForResponse: false, error: true, errorTitle: 'Ya existe un perfil asociado a ese correo electronico', errorMessage: 'Ya hay un perfil con esa direccion de correo electronico, puede intentar borrar la cuenta que lo tiene o actualizarla.' })
          } else if (response.data['success'] === 11) {
            setState({ waitingForResponse: false, error: true, errorTitle: 'Ya existe un perfil asociado a ese nombre de usuario', errorMessage: 'Ya hay un perfil con ese nombre de usuario, puede intentar borrar la cuenta que lo tiene o actualizarla.' })
          } else {
            setState({ waitingForResponse: false, error: true, errorTitle: 'Error al guardar en la informacion', errorMessage: 'Ocurrio un error al guardar la informacion en la base de datos, por favor verifique que la informacion ingresada sea la correcta e intente nuevamente' })
          }
        })
      } catch (error) {
        setState({ waitingForResponse: false, error: true, errorTitle: 'Error', errorMessage: error })
        console.error(error);
      }
    } else {
      throwEmptyDataError()
    }
  }
  function throwEmptyDataError() {
    let emptyFields = ''
    if (state.userName === '') {
      userNameRef.current?.classList.add(...errorStyles);
      emptyFields += " 'Nombre de usuario' ";
    }
    if (state.name === '') {
      nameRef.current?.classList.add(...errorStyles);
      emptyFields += " 'Nombre' ";
    }
    if (state.lastName === '') {
      lastNameRef.current?.classList.add(...errorStyles);
      emptyFields += " 'Apellido' ";
    }
    if (state.email === '') {
      emailRef.current?.classList.add(...errorStyles);
      emptyFields += " 'Correo electronico' ";
    }
    if (state.phone === '') {
      phoneRef.current?.classList.add(...errorStyles);
      emptyFields += " 'Numero de telefono' ";
    }
    if (state.level === '1') {
      if (state.address === '') {
        addressRef.current?.classList.add(...errorStyles);
        emptyFields += " 'Direccion' ";
      }
      if (city.id === 0) {
        cityRef.current?.classList.add(...errorStyles);
        emptyFields += " 'Ciudad' ";
      }
      if (state.professionalCard === '') {
        professionalCardRef.current?.classList.add(...errorStyles);
        emptyFields += " 'Tarjeta profesional' ";
      }
      if (state.documentNumber === '') {
        documentNumberRef.current?.classList.add(...errorStyles);
        emptyFields += " 'Numero de documento' ";
      }
    }
    setState({ error: true, errorTitle: 'Error: rellene todos los campos para enviar el guardar la informacion', errorMessage: `Los campos ${emptyFields} estan vacios o no son validos, por favor rellene los campos e intente de nuevo.`, ErrorElement: <p>Los campos <strong>{emptyFields}</strong> estan vacios o no son validos, por favor rellene los campos e intente de nuevo.</p> })
  }
  function resetProfileState() {
    setCity(citysData[0])
    setDocumentType(documentTypeData[0])
    setState({
      successAlert: false,
      waitingForResponse: false,
      userName: '',
      name: '',
      lastName: '',
      email: '',
      phone: '',
      level: '0',
      address: '',
      documentNumber: '',
      professionalCard: '',
      error: false,
      errorMessage: '',
      errorTitle: '',
      ErrorElement: null,
    })
  }
  if (showCreateProfile) {
    return (<>
      {state.error &&
        <ErrorAlert ErrorElement={state.ErrorElement}
          animate={{ x: 0, opacity: 1, zIndex: 50, left: "1rem", bottom: "1rem", position: "fixed" }}
          transition={{ duration: 0.3 }}
          initial={{ x: -50, opacity: 0.1, zIndex: 50, left: "1rem", bottom: "1rem", position: "fixed" }}
          message={state.errorMessage}
          title={state.errorTitle} />
      }
      {state.waitingForResponse &&
        <Loader
          animate={{ transform: "rotate(45deg) scale(0.8) translate(0px,0px)", opacity: 1, zIndex: 50, left: "2.5rem", bottom: "1.25rem", position: "fixed" }} transition={{ duration: 0.3 }} initial={{ transform: "rotate(45deg) scale(0.8) translate(-50px,50px)", opacity: 0.1, zIndex: 50, left: "2.5rem", bottom: "1.25rem", position: "fixed" }} />
      }
      {state.successAlert &&
        <motion.div initial={{ x: '-150%', opacity: 0.5 }} animate={{ x: '0%', opacity: 1 }} role="alert" className="rounded-xl border fixed  left-3 sm:left-10  bottom-10 z-50 bg-white border-gray-100 p-4 shadow-xl">
          <div className="flex items-start  gap-4">
            <span className="text-green-600">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                className="h-6 w-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                />
              </svg>
            </span>

            <div className="flex-1">
              <strong className="block font-medium text-gray-900">Cambios completados</strong>

              <p className="mt-1 text-sm text-gray-700">
                El nuevo perfil ha sido guardado.
              </p>
            </div>

            <button onClick={() => { setState({ successAlert: false }) }} className="text-gray-500 transition hover:text-gray-600">
              <span className="sr-only">Dismiss popup</span>

              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                className="h-6 w-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
          </div>
        </motion.div>
      }
      <Modal />
      <motion.section initial={{ y: 50, opacity: 0 }} animate={{ y: 0, opacity: 1 }} transition={{ duration: 0.5 }} className="fixed top-0 left-0 w-full h-full flex justify-center items-start z-40 overflow-y-auto">
        <div className="mx-auto max-w-screen-xl px-4 py-16 sm:px-6 lg:px-8">
          <div className="grid grid-cols-1 gap-x-16 gap-y-8 lg:grid-cols-1">
            {/* <div className="lg:col-span-2 lg:py-6">
              <div>
                <p className="text-white mt-4 inline-block">Una vez creados los optometras, podras crear pedidos a nombre de ellos facilmente.</p>
              </div>
            </div> */}
            <div className="lg:col-span-3 lg:p-12">
              <form className="rounded-lg bg-white p-8 shadow-lg space-y-4" autoComplete="nope">
                <div>
                  <label className="text-sm font-medium text-gray-700" htmlFor="username">Nombre de usuario</label>
                  <input ref={userNameRef} className="h-12 w-full rounded-lg border-gray-200 border-[1px] p-3 text-sm" type="text" id="username" value={state.userName} onChange={(event) => { updateUserName(event) }} />
                </div>
                <div className="grid grid-cols-1 gap-4 sm:grid-cols-2">
                  {/* Nombre */}
                  <div>
                    <label className="text-sm font-medium text-gray-700" htmlFor="name">Nombre</label>
                    <input ref={nameRef} className="h-12 w-full rounded-lg border-gray-200 border-[1px] p-3 text-sm" type="text" id="name" value={state.name} onChange={(event) => { updateName(event) }} />
                  </div>
                  {/* Apellido */}
                  <div>
                    <label className="text-sm font-medium text-gray-700" htmlFor="lastName">Apellido</label>
                    <input ref={lastNameRef} className="h-12 w-full rounded-lg border-gray-200 border-[1px] p-3 text-sm" type="text" id="lastName" value={state.lastName} onChange={(event) => { updateLastName(event) }} />
                  </div>
                </div>
                <div className="grid grid-cols-1 gap-4 sm:grid-cols-2">
                  <div>
                    <label className="text-sm font-medium text-gray-700" htmlFor="email">Correo Electronico</label>
                    <input ref={emailRef} className="h-12 w-full rounded-lg border-gray-200 border-[1px] p-3 text-sm" type="email" id="email" value={state.email} onChange={(event) => { updateEmail(event) }} />
                  </div>
                  <div>
                    <label className="text-sm font-medium text-gray-700" htmlFor="phone">Numero Telefono</label>
                    <input ref={phoneRef} className="h-12 w-full rounded-lg border-gray-200 border-[1px] p-3 text-sm" type="tel" id="phone" value={state.phone} onChange={(event) => { updatePhone(event) }} />
                  </div>
                </div>
                <div className="grid grid-cols-1 gap-4 sm:grid-cols-2">
                  {/* Ciudad */}
                  <div>
                    <span className="first-letter:capitalize text-sm font-medium text-gray-700">Ciudad</span>
                    <Listbox value={city} onChange={setCity}>
                      <div ref={cityRef} className="relative rounded-lg">
                        <Listbox.Button className="relative h-12 w-full mt-0 rounded-lg border-gray-200 border-[1px] p-3 text-sm text-left focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-sm">
                          <span className={`block truncate ${city.id === 0 && "text-gray-500"}`}>{city.name}</span>
                          <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                            <ChevronUpDownIcon
                              className="h-5 w-5 text-gray-400"
                              aria-hidden="true"
                            />
                          </span>
                        </Listbox.Button>
                        <Transition
                          as={Fragment}
                          leave="transition ease-in duration-100"
                          leaveFrom="opacity-100"
                          leaveTo="opacity-0"
                        >
                          <Listbox.Options className="z-40 absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                            {citysData.map((city: { name: string, id: number }, cityIdx: number) => (
                              <Listbox.Option
                                key={cityIdx}
                                onClick={((event) => { updateCity() })}
                                className={({ active }) =>
                                  `relative cursor-default select-none py-2 pl-10 pr-4 ${city.id === 0 && 'hidden'} ${active ? 'bg-blue-600 text-white' : 'text-gray-900'
                                  }`
                                }
                                value={city}
                              >
                                {({ selected }) => (
                                  <>
                                    <span
                                      className={`block truncate ${city.id === 0 && 'text-gray-500'} ${selected ? 'font-medium' : 'font-normal'
                                        }`}
                                    >
                                      {city.name}
                                    </span>
                                    {selected ? (
                                      <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-indigo-600">
                                        <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                      </span>
                                    ) : null}
                                  </>
                                )}
                              </Listbox.Option>
                            ))}
                          </Listbox.Options>
                        </Transition>
                      </div>
                    </Listbox>
                  </div>
                  {/* Direccion */}
                  <div>
                    <label className="text-sm font-medium text-gray-700" htmlFor="address">Direccion</label>
                    <input ref={addressRef} className="h-12 w-full rounded-lg border-gray-200 border-[1px] p-3 text-sm" type="text" id="address" value={state.address} onChange={(event) => { updateAddress(event) }} />
                  </div>
                </div>
                <div className="grid grid-cols-1 gap-4 sm:grid-cols-2">
                  {/* numero de documento */}
                  <div>
                    <span className="first-letter:capitalize text-sm font-medium text-gray-700">Numero de documento</span>
                    <div className="flex justify-center items-center border-gray-200 border-[1px] rounded-lg">
                      <Listbox value={documentType} onChange={setDocumentType}>
                        <div className="relative rounded-l-lg">
                          <Listbox.Button className="relative h-12 w-16 mt-0 rounded-l-lg p-3 text-sm text-left focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-sm">
                            <span className={`block truncate`}>{documentType.abbrev}</span>
                            <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                              <ChevronUpDownIcon
                                className="h-5 w-5 text-gray-400"
                                aria-hidden="true"
                              />
                            </span>
                          </Listbox.Button>
                          <Transition
                            as={Fragment}
                            leave="transition ease-in duration-100"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                          >
                            <Listbox.Options className="z-40 absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                              {documentTypeData.map((documentType: { name: string, id: number, abbrev: string }, documentIdx: number) => (
                                <Listbox.Option
                                  key={documentIdx}
                                  onClick={((event) => { })}
                                  className={({ active }) =>
                                    `relative cursor-default select-none py-2 pl-7 pr-1 ${active ? 'bg-blue-600 text-white' : 'text-gray-900'
                                    }`
                                  }
                                  value={documentType}
                                >
                                  {({ selected }) => (
                                    <>
                                      <span
                                        className={`block truncate ${selected ? 'font-medium' : 'font-normal'
                                          }`}
                                      >
                                        {documentType.abbrev}
                                      </span>
                                      {selected ? (
                                        <span className="absolute inset-y-0 left-0 flex items-center pl-1 text-indigo-600">
                                          <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                        </span>
                                      ) : null}
                                    </>
                                  )}
                                </Listbox.Option>
                              ))}
                            </Listbox.Options>
                          </Transition>
                        </div>
                      </Listbox>
                      <input ref={documentNumberRef} className="h-12 w-full rounded-r-lg border-gray-200 border-l-[1px] p-3 text-sm" type="text" id="documentNumber" value={state.documentNumber} onChange={(event) => { updateDocumentNumber(event) }} />
                    </div>
                  </div>
                  {/* Tarjeta profesional */}
                  <div>
                    <label className="text-sm font-medium text-gray-700" htmlFor="professionalCard">Tarjeta profesional</label>
                    <input ref={professionalCardRef} className="h-12 w-full rounded-lg border-gray-200 border-[1px] p-3 text-sm" type="text" id="professionalCard" value={state.professionalCard} onChange={(event) => { updateProfessionalCard(event) }} />
                  </div>
                </div>
                <div className="mt-4 flex justify-center items-center gap-5">
                  <button type="submit" onClick={(event) => { (sendCreateOptometrist(event)) }}
                    className="inline-block rounded bg-green-500 px-8 py-3 text-sm font-medium text-white transition hover:bg-transparent border-[1px] hover:border-green-500  hover:text-green-500 focus:outline-none focus:ring active:bg-green-400 active:text-white">
                    Crear Perfil
                  </button>
                  <button type="button" onClick={() => {
                    setShowCreateProfile({ showCreateProfile: false });
                    resetProfileState()
                  }}
                    className="inline-block rounded bg-red-500 px-8 py-3 text-sm font-medium text-white transition hover:bg-transparent border-[1px] hover:border-red-500  hover:text-red-500 focus:outline-none focus:ring active:bg-red-400 active:text-white">
                    Cerrar
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </motion.section>
    </>

    )
  } else {
    return (
      <></>
    )
  }
}
interface ProfilesListTypes {
  loading: boolean;
  data: [];
  loaded: boolean;
  user: userType;
  reload: boolean;
  citysData: any;
  documentTypeData: any;
  updateProfiles: (value: boolean | {}) => void;
}
const ProfilesList = ({ loading, data, loaded, user, reload, updateProfiles, citysData, documentTypeData }: ProfilesListTypes) => {
  const userNameRef = createRef<HTMLInputElement>()
  const emailRef = createRef<HTMLInputElement>()
  const phoneRef = createRef<HTMLInputElement>()
  const addressRef = createRef<HTMLInputElement>()
  const cityRef = createRef<HTMLInputElement>()
  const professionalCardRef = createRef<HTMLInputElement>()
  const documentNumberRef = createRef<HTMLInputElement>()
  const clientTypeRef = createRef<HTMLInputElement>()
  const nameRef = createRef<HTMLInputElement>()
  const lastNameRef = createRef<HTMLInputElement>()

  const [city, setCity] = useState(citysData[0])
  const [documentType, setDocumentType] = useState(documentTypeData[0])
  const [state, setState] = useStateMany({
    deleteStage: false,
    waitingForResponse: false,
    idToDelete: null,
    nameToDelete: null,
    emailToDelete: null,
    phoneToDelete: null,
    permissionsToDelete: null,
    successAlert: false,
    error: false,
    errorMessage: '',
    errorTitle: '',
    ErrorElement: null,
    updateStage: false,
    idToUpdate: null,
    userName: '',
    name: '',
    lastName: '',
    email: '',
    phone: '',
    address: '',
    professionalCard: '',
    level: '0',
    documentNumber: '',
    clientType: 0,

  });
  function sendDelete() {
    if (state.idToDelete !== null) {
      setState({
        waitingForResponse: true,
        error: false,
        errorMessage: '',
        errorTitle: ''
      });
      API.post('/?delete', {
        API_KEY: process.env.REACT_APP_API_KEY,
        id: state.idToDelete,
      }).then((response) => {
        if (response.status === 200 && response.data.success === 1) {
          setState({
            waitingForResponse: false,
            successAlert: true,
            deleteStage: false,
            idToDelete: null,
            nameToDelete: null,
            emailToDelete: null,
            phoneToDelete: null,
            permissionsToDelete: null,
            error: false,
            errorMessage: '',
            errorTitle: ''
          });
          updateProfiles({ reload: !reload })
          setTimeout(() => {
            setState({
              successAlert: false,
            });
          }, 7000);
        } else {
          setState({
            error: true,
            errorMessage: 'No tienes permisos para borrar',
            errorTitle: 'No se pudo borrar el perfil',
            waitingForResponse: false,
            successAlert: false,
            deleteStage: false,
            idToDelete: null,
            nameToDelete: null,
            emailToDelete: null,
            phoneToDelete: null,
            permissionsToDelete: null,
          })
        }
      })
    }
  }
  function updateUserName(event: React.ChangeEvent<HTMLInputElement>) {
    userNameRef.current?.classList.remove(...errorStyles)
    setState({ userName: event.target.value });
  }
  function updateEmail(event: React.ChangeEvent<HTMLInputElement>) {
    emailRef.current?.classList.remove(...errorStyles)
    setState({ email: event.target.value });
  }
  function updatePhone(event: React.ChangeEvent<HTMLInputElement>) {
    phoneRef.current?.classList.remove(...errorStyles)
    setState({ phone: event.target.value });
  }
  function updateName(event: React.ChangeEvent<HTMLInputElement>) {
    nameRef.current?.classList.remove(...errorStyles)
    setState({ name: event.target.value });
  }
  function updateLastName(event: React.ChangeEvent<HTMLInputElement>) {
    lastNameRef.current?.classList.remove(...errorStyles)
    setState({ lastName: event.target.value });
  }
  function updateAddress(event: React.ChangeEvent<HTMLInputElement>) {
    addressRef.current?.classList.remove(...errorStyles)
    setState({ address: event.target.value });
  }
  function updateCity() {
    cityRef.current?.classList.remove(...errorStyles)
  }
  function updateProfessionalCard(event: React.ChangeEvent<HTMLInputElement>) {
    professionalCardRef.current?.classList.remove(...errorStyles)
    setState({ professionalCard: event.target.value });
  }
  function updateDocumentNumber(event: React.ChangeEvent<HTMLInputElement>) {
    documentNumberRef.current?.classList.remove(...errorStyles)
    setState({ documentNumber: event.target.value });
  }

  function sendUpdateProfile(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    event.preventDefault();
    if (state.idToUpdate !== null) {
      if (state.userName && state.name && state.lastName && state.email) {
        let dataToSend = {}
        if (state.address && city.id !== 0 && state.documentNumber) {
          dataToSend = {
            API_KEY: process.env.REACT_APP_API_KEY,
            idSession: parseInt(state.idToUpdate),
            username: state.userName,
            name: state.name,
            lastName: state.lastName,
            email: state.email,
            phone: state.phone,
            permissions: 1,
            city: parseInt(city.id),
            address: state.address,
            professionalCard: state.professionalCard,
            documentNumber: state.documentNumber,
            clientPayMethod: 0,
            documentType: parseInt(documentType.id),
            customerType: 3,
          }
        } else {
          throwEmptyDataError()
        }

        setState({
          waitingForResponse: true,
          error: false,
          errorMessage: '',
          errorTitle: ''
        });
        API.post('/?update', {
          ...dataToSend
        }).then((response) => {
          setState({ waitingForResponse: false })
          if (response.status === 200 && response.data.success === 1) {
            setState({
              updateStage: false,
              idToUpdate: null,
              successAlert: true,
            });
            resetProfileState()
            setTimeout(() => {
              setState({ successAlert: false });
            }, 7000);
            updateProfiles({ reload: !reload })
            setTimeout(() => {
              setState({
                successAlert: false,
              });
            }, 7000);
          } else if (response.data['success'] === 10) {
            setState({ waitingForResponse: false, error: true, errorTitle: 'Ya existe un perfil asociado a ese correo electronico', errorMessage: 'Ya hay un perfil con esa direccion de correo electronico, puede intentar borrar la cuenta que lo tiene o actualizarla.' })
          } else if (response.data['success'] === 11) {
            setState({ waitingForResponse: false, error: true, errorTitle: 'Ya existe un perfil asociado a ese nombre de usuario', errorMessage: 'Ya hay un perfil con ese nombre de usuario, puede intentar borrar la cuenta que lo tiene o actualizarla.' })
          } else {
            setState({
              error: true,
              errorMessage: 'Ocurrio un error al proceder con la actualizacion',
              errorTitle: 'No se pudo actualizar el perfil',
              waitingForResponse: false,
              successAlert: false,
              updateStage: false,
            })
          }
        }).catch((error) => {
          console.log('Ha ocurrido un error', error)
          setState({
            error: true,
            errorMessage: 'Ocurrio un error al proceder con la actualizacion',
            errorTitle: 'No se pudo actualizar el perfil',
            waitingForResponse: false,
            successAlert: false,
            updateStage: false,
          })
        })
      } else {
        throwEmptyDataError()
      }
    }
  }

  function resetProfileState() {
    setCity(citysData[0])
    setDocumentType(documentTypeData[0])
    setState({
      successAlert: false,
      waitingForResponse: false,
      userName: '',
      name: '',
      lastName: '',
      email: '',
      phone: '',
      level: '0',
      address: '',
      clientType: 0,
      documentNumber: '',
      professionalCard: '',
      error: false,
      errorMessage: '',
      errorTitle: '',
      ErrorElement: null,
    })
  }
  function throwEmptyDataError() {
    let emptyFields = ''
    if (state.userName === '') {
      userNameRef.current?.classList.add(...errorStyles);
      emptyFields += " 'Nombre de usuario' ";
    }
    if (state.name === '') {
      nameRef.current?.classList.add(...errorStyles);
      emptyFields += " 'Nombre' ";
    }
    if (state.lastName === '') {
      lastNameRef.current?.classList.add(...errorStyles);
      emptyFields += " 'Apellido' ";
    }
    if (state.email === '') {
      emailRef.current?.classList.add(...errorStyles);
      emptyFields += " 'Correo electronico' ";
    }
    if (state.phone === '') {
      phoneRef.current?.classList.add(...errorStyles);
      emptyFields += " 'Numero de telefono' ";
    }
    if (state.level === '1') {
      if (state.address === '') {
        addressRef.current?.classList.add(...errorStyles);
        emptyFields += " 'Direccion' ";
      }
      if (city.id === 0) {
        cityRef.current?.classList.add(...errorStyles);
        emptyFields += " 'Ciudad' ";
      }
      if (state.professionalCard === '') {
        professionalCardRef.current?.classList.add(...errorStyles);
        emptyFields += " 'Tarjeta profesional' ";
      }
      if (state.documentNumber === '') {
        documentNumberRef.current?.classList.add(...errorStyles);
        emptyFields += " 'Numero de documento' ";
      }
    }
    setState({ error: true, errorTitle: 'Error: rellene todos los campos para enviar el guardar la informacion', errorMessage: `Los campos ${emptyFields} estan vacios o no son validos, por favor rellene los campos e intente de nuevo.`, ErrorElement: <p>Los campos <strong>{emptyFields}</strong> estan vacios o no son validos, por favor rellene los campos e intente de nuevo.</p> })
  }
  const errorStyles = ["outline-3", "outline", "outline-red-400"]

  function loadToUpdate(profile: any) {
    // console.log(profile, 'profail')
    // se cambia el estado con la informacion de el perfil a actualizar
    setState({
      updateStage: true,
      idToUpdate: profile.id,
      userName: profile.username,
      lastName: profile.last_name,
      name: profile.name,
      phone: profile.phone,
      level: profile.user_level,
      email: profile.email,
      successAlert: false,
    })
    if (parseInt(profile.user_level) === 1) {
      // busco los indices de las ciudades de los perfiles en los datos existentes traidos de la base de datos 
      let indexCity = 0
      if (profile.city) {
        indexCity = citysData.findIndex((el: any) => el.name === profile.city)
      }
      let indexDocumentType = 0
      if (profile.document_type) {
        indexDocumentType = documentTypeData.findIndex((el: any) => el.name === profile.document_type)
      }
      setState({
        updateStage: true,
        idToUpdate: profile.id,
        userName: profile.username,
        lastName: profile.last_name,
        name: profile.name,
        phone: profile.phone,
        level: profile.user_level,
        email: profile.email,
        successAlert: false,
        documentNumber: profile.document_number ? profile.document_number : '',
        address: profile.address ? profile.address : '',
        professionalCard: parseInt(profile.customer_type) === 2 ? '' : profile.professional_card ? profile.professional_card : '',
      })
      // guardo los datos si son diferentes de 0 
      setCity(citysData[indexCity])
      setDocumentType(documentTypeData[indexDocumentType])
    } else {


    }

  }
  return (
    <>
      {state.updateStage === true && state.successAlert === false &&
        <>
          <Modal />
          <motion.section initial={{ y: 50, opacity: 0 }} animate={{ y: 0, opacity: 1 }} transition={{ duration: 0.5 }} className="fixed top-0 left-0 w-full h-full flex justify-center items-start z-40 overflow-y-auto">
            {state.waitingForResponse === false && state.successAlert === false &&
              <div className="mx-auto max-w-screen-xl px-4 py-6 lg:py-16 sm:px-6 lg:px-8">
                <div className="grid grid-cols-1 gap-x-16 gap-y-8 lg:grid-cols-5">
                  <div className="lg:col-span-2 lg:py-6">
                    <p className="max-w-xl text-lg text-white font-medium">
                      Aquí podrás actualizar la informacion de los perfiles
                    </p>
                    <div>
                      <p className="text-white mt-4 inline-block">Antes de guardar los cambios por favor asegurese de que todos los campos esten completos y correctos.</p>
                    </div>
                  </div>
                  <div className="lg:col-span-3 lg:p-12">
                    <form className="rounded-lg bg-white p-8 shadow-lg space-y-4" autoComplete="nope">
                      <div>
                        <label className="text-sm font-medium text-gray-700" htmlFor="username">Nombre de usuario</label>
                        <input ref={userNameRef} className="h-12 w-full rounded-lg border-gray-200 border-[1px] p-3 text-sm" type="text" id="username" value={state.userName} onChange={(event) => { updateUserName(event) }} />
                      </div>
                      <div className="grid grid-cols-1 gap-4 sm:grid-cols-2">
                        {/* Nombre */}
                        <div>
                          <label className="text-sm font-medium text-gray-700" htmlFor="name">Nombre</label>
                          <input ref={nameRef} className="h-12 w-full rounded-lg border-gray-200 border-[1px] p-3 text-sm" type="text" id="name" value={state.name} onChange={(event) => { updateName(event) }} />
                        </div>
                        {/* Apellido */}
                        <div>
                          <label className="text-sm font-medium text-gray-700" htmlFor="lastName">Apellido</label>
                          <input ref={lastNameRef} className="h-12 w-full rounded-lg border-gray-200 border-[1px] p-3 text-sm" type="text" id="lastName" value={state.lastName} onChange={(event) => { updateLastName(event) }} />
                        </div>
                      </div>
                      <div className="grid grid-cols-1 gap-4 sm:grid-cols-2">
                        <div>
                          <label className="text-sm font-medium text-gray-700" htmlFor="email">Correo Electronico</label>
                          <input ref={emailRef} className="h-12 w-full rounded-lg border-gray-200 border-[1px] p-3 text-sm" type="email" id="email" value={state.email} onChange={(event) => { updateEmail(event) }} />
                        </div>
                        <div>
                          <label className="text-sm font-medium text-gray-700" htmlFor="phone">Numero Telefono</label>
                          <input ref={phoneRef} className="h-12 w-full rounded-lg border-gray-200 border-[1px] p-3 text-sm" type="tel" id="phone" value={state.phone} onChange={(event) => { updatePhone(event) }} />
                        </div>
                      </div>
                      <div className="grid grid-cols-1 gap-4 sm:grid-cols-2">
                        {/* Ciudad */}
                        <div>
                          <span className="first-letter:capitalize text-sm font-medium text-gray-700">Ciudad</span>
                          <Listbox value={city} onChange={setCity}>
                            <div ref={cityRef} className="relative rounded-lg">
                              <Listbox.Button className="relative h-12 w-full mt-0 rounded-lg border-gray-200 border-[1px] p-3 text-sm text-left focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-sm">
                                <span className={`block truncate ${city.id === 0 && "text-gray-500"}`}>{city.name}</span>
                                <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                                  <ChevronUpDownIcon
                                    className="h-5 w-5 text-gray-400"
                                    aria-hidden="true"
                                  />
                                </span>
                              </Listbox.Button>
                              <Transition
                                as={Fragment}
                                leave="transition ease-in duration-100"
                                leaveFrom="opacity-100"
                                leaveTo="opacity-0"
                              >
                                <Listbox.Options className="z-40 absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                  {citysData.map((city: { name: string, id: number }, cityIdx: number) => (
                                    <Listbox.Option
                                      key={cityIdx}
                                      onClick={((event) => { updateCity() })}
                                      className={({ active }) =>
                                        `relative cursor-default select-none py-2 pl-10 ${city.id === 0 && 'hidden'} pr-4 ${active ? 'bg-blue-600 text-white' : 'text-gray-900'
                                        }`
                                      }
                                      value={city}
                                    >
                                      {({ selected }) => (
                                        <>
                                          <span
                                            className={`block truncate ${city.id === 0 && 'text-gray-500'} ${selected ? 'font-medium' : 'font-normal'
                                              }`}
                                          >
                                            {city.name}
                                          </span>
                                          {selected ? (
                                            <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-indigo-600">
                                              <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                            </span>
                                          ) : null}
                                        </>
                                      )}
                                    </Listbox.Option>
                                  ))}
                                </Listbox.Options>
                              </Transition>
                            </div>
                          </Listbox>
                        </div>
                        {/* Direccion */}
                        <div>
                          <label className="text-sm font-medium text-gray-700" htmlFor="address">Direccion</label>
                          <input ref={addressRef} className="h-12 w-full rounded-lg border-gray-200 border-[1px] p-3 text-sm" type="text" id="address" value={state.address} onChange={(event) => { updateAddress(event) }} />
                        </div>
                      </div>
                      <div className="grid grid-cols-1 gap-4 sm:grid-cols-2">
                        {/* numero de documento */}
                        <div>
                          <span className="first-letter:capitalize text-sm font-medium text-gray-700">Numero de documento</span>
                          <div className="flex justify-center items-center border-gray-200 border-[1px] rounded-lg">
                            <Listbox value={documentType} onChange={setDocumentType}>
                              <div className="relative rounded-l-lg">
                                <Listbox.Button className="relative h-12 w-16 mt-0 rounded-l-lg p-3 text-sm text-left focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-sm">
                                  <span className={`block truncate`}>{documentType.abbrev}</span>
                                  <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                                    <ChevronUpDownIcon
                                      className="h-5 w-5 text-gray-400"
                                      aria-hidden="true"
                                    />
                                  </span>
                                </Listbox.Button>
                                <Transition
                                  as={Fragment}
                                  leave="transition ease-in duration-100"
                                  leaveFrom="opacity-100"
                                  leaveTo="opacity-0"
                                >
                                  <Listbox.Options className="z-40 absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                    {documentTypeData.map((documentType: { name: string, id: number, abbrev: string }, documentIdx: number) => (
                                      <Listbox.Option
                                        key={documentIdx}
                                        onClick={((event) => { })}
                                        className={({ active }) =>
                                          `relative cursor-default select-none py-2 pl-7 pr-1 ${active ? 'bg-blue-600 text-white' : 'text-gray-900'
                                          }`
                                        }
                                        value={documentType}
                                      >
                                        {({ selected }) => (
                                          <>
                                            <span
                                              className={`block truncate ${selected ? 'font-medium' : 'font-normal'
                                                }`}
                                            >
                                              {documentType.abbrev}
                                            </span>
                                            {selected ? (
                                              <span className="absolute inset-y-0 left-0 flex items-center pl-1 text-indigo-600">
                                                <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                              </span>
                                            ) : null}
                                          </>
                                        )}
                                      </Listbox.Option>
                                    ))}
                                  </Listbox.Options>
                                </Transition>
                              </div>
                            </Listbox>
                            <input ref={documentNumberRef} className="h-12 w-full rounded-r-lg border-gray-200 border-l-[1px] p-3 text-sm" type="text" id="documentNumber" value={state.documentNumber} onChange={(event) => { updateDocumentNumber(event) }} />
                          </div>
                        </div>
                        <div>
                          <label className="text-sm font-medium text-gray-700" htmlFor="professionalCard">Tarjeta profesional</label>
                          <input ref={professionalCardRef} className="h-12 w-full rounded-lg border-gray-200 border-[1px] p-3 text-sm" type="text" id="professionalCard" value={state.professionalCard} onChange={(event) => { updateProfessionalCard(event) }} />
                        </div>
                      </div>
                      <div className="mt-4 flex justify-center items-center gap-5">
                        <button type="submit" onClick={(event) => { sendUpdateProfile(event) }}
                          className="inline-block rounded bg-green-500 px-8 py-3 text-sm font-medium text-white transition hover:bg-transparent border-[1px] hover:border-green-500  hover:text-green-500 focus:outline-none focus:ring active:bg-green-400 active:text-white">
                          Actualizar
                        </button>
                        <button type="button" onClick={() => {
                          setState({
                            updateStage: false,
                            idToUpdate: null,
                          });
                          resetProfileState()
                        }}
                          className="inline-block rounded bg-red-500 px-8 py-3 text-sm font-medium text-white transition hover:bg-transparent border-[1px] hover:border-red-500  hover:text-red-500 focus:outline-none focus:ring active:bg-red-400 active:text-white">
                          Cerrar
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            }
            {state.waitingForResponse === true && state.successAlert === false &&
              <Loader />
            }
          </motion.section>
        </>
      }
      {state.deleteStage === true && state.idToDelete !== null &&
        <>
          <Modal />
          <motion.section initial={{ y: 50, opacity: 0 }} animate={{ y: 0, opacity: 1 }} transition={{ duration: 0.5 }} className="fixed top-0 left-0 w-full h-full flex justify-center items-center z-40">
            {state.waitingForResponse === false && state.successAlert === false &&
              <div className="rounded-lg bg-white p-8 shadow-2xl z-40">
                <p className="text-lg font-bold">¿Estas seguro de que quieres borrar este perfil?</p>
                <div className="mt-2">
                  <p className="text-sm lg:text-base text-gray-600">
                    Estas apunto de borrar el siguiente perfil:
                  </p>
                  <ul className="pl-2 mt-2 text-sm lg:text-base">
                    <li className="flex justify-start items-center gap-x-2 text-slate-800"><span className="font-bold first-letter:uppercase">Nombre de usuario:</span><span>{state.nameToDelete}</span></li>
                    <li className="flex justify-start items-center gap-x-2 text-slate-800"><span className="font-bold first-letter:uppercase">Correo electronico:</span><span>{state.emailToDelete}</span></li>
                    <li className="flex justify-start items-center gap-x-2 text-slate-800"><span className="font-bold first-letter:uppercase">Numero de telefono:</span><span>{state.phoneToDelete}</span></li>
                    <li className="flex justify-start items-center gap-x-2 text-slate-800"><span className="font-bold first-letter:uppercase">Permisos:</span><span>{state.permissionsToDelete}</span></li>
                  </ul>
                  <p className="mt-2 font-medium text-sm lg:text-base text-gray-600">
                    ¿Seguro de que quieres borrarlo?
                  </p>
                </div>
                <div className="mt-4 flex gap-2">
                  <button onClick={() => { sendDelete() }} className="inline-block rounded bg-red-500 px-4 py-2 text-sm font-medium text-white border-[1px] border-red-500 hover:text-red-500 hover:bg-transparent">
                    Si, estoy seguro
                  </button>
                  <button onClick={() => {
                    setState({
                      deleteStage: false,
                      idToDelete: null,
                      nameToDelete: null,
                      emailToDelete: null,
                      phoneToDelete: null,
                      permissionsToDelete: null,
                      error: false,
                      errorMessage: '',
                      errorTitle: ''
                    })
                  }} type="button" className="rounded bg-gray-100 px-4 py-2 text-sm font-medium text-gray-700">
                    No, volver
                  </button>
                </div>
              </div>
            }
            {state.waitingForResponse === true && state.successAlert === false &&
              <Loader />
            }
          </motion.section>
        </>
      }
      {state.error &&
        <ErrorAlert ErrorElement={state.ErrorElement}
          animate={{ x: 0, opacity: 1, zIndex: 50, left: "1rem", bottom: "1rem", position: "fixed" }}
          transition={{ duration: 0.3 }}
          initial={{ x: -50, opacity: 0.1, zIndex: 50, left: "1rem", bottom: "1rem", position: "fixed" }}
          message={state.errorMessage}
          title={state.errorTitle} />
      }
      {state.successAlert === true && state.waitingForResponse === false &&
        <motion.div initial={{ x: '-150%', opacity: 0.5 }} animate={{ x: '0%', opacity: 1 }} role="alert" className="rounded-xl border fixed left-3 sm:left-10 bottom-10 z-50 bg-white border-gray-100 p-4 shadow-xl">
          <div className="flex items-start  gap-4">
            <span className="text-green-600">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                className="h-6 w-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                />
              </svg>
            </span>

            <div className="flex-1">
              <strong className="block font-medium text-gray-900">Cambios completados</strong>

              <p className="mt-1 text-sm text-gray-700">
                Los cambios han sido guardados exitosamente!.
              </p>
            </div>
            <button onClick={() => { setState({ successAlert: false }) }} className="text-gray-500 transition hover:text-gray-600">
              <span className="sr-only">Dismiss popup</span>

              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                className="h-6 w-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
          </div>
        </motion.div>
      }
      <div className="overflow-x-auto mt-10">
        {data && loading === false && loaded === true
          &&
          <table className="min-w-full divide-y-2 divide-gray-200 text-sm">
            <thead>
              <tr>
                <th className="whitespace-nowrap px-4 py-2 text-left font-medium text-gray-900">
                  Id
                </th>
                <th className="whitespace-nowrap px-4 py-2 text-left font-medium text-gray-900">
                  Nombre
                </th>
                <th className="whitespace-nowrap px-4 py-2 text-left font-medium text-gray-900">
                  Apellido
                </th>
                <th className="whitespace-nowrap px-4 py-2 text-left font-medium text-gray-900">
                  Correo electronico
                </th>
                <th className="whitespace-nowrap px-4 py-2 text-left font-medium text-gray-900">
                  Numero de telefono
                </th>
                <th className="px-4 py-2 lg:sticky lg:right-0 lg:bg-gray-100" />
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-200">
              {data.map((el: any) => {
                return (
                  <tr key={el.id} className="relative lg:pr-32">
                    <td className="whitespace-nowrap px-4 py-2 text-gray-700">{el.id}</td>
                    <td className="whitespace-nowrap px-4 py-2 font-medium text-gray-700">{el.name}</td>
                    <td className="whitespace-nowrap px-4 py-2 font-medium text-gray-700">{el.last_name}</td>
                    <td className="whitespace-nowrap px-4 py-2 text-gray-700">{el.email}</td>
                    <td className={`whitespace-nowrap px-4 py-2 text-gray-700`}>{el.phone}</td>
                    <td className="whitespace-nowrap px-4 py-2 lg:sticky lg:right-0 lg:bg-gray-100 h-full">
                      <button onClick={() => {
                        loadToUpdate(el)
                      }} className="inline-block rounded bg-indigo-500 px-4 py-2 text-xs font-medium border-[1px] border-indigo-500 text-white hover:text-indigo-500 hover:bg-transparent">
                        Actualizar
                      </button>
                      <button onClick={() => {
                        setState({
                          idToDelete: el.id,
                          nameToDelete: el.name,
                          emailToDelete: el.email,
                          phoneToDelete: el.phone,
                          deleteStage: true,
                          successAlert: false,
                        })
                      }} className="ml-2 inline-block rounded bg-red-500 px-4 py-2 text-xs font-medium text-white border-[1px] border-red-500 hover:text-red-500 hover:bg-transparent">
                        Eliminar
                      </button>
                    </td>
                  </tr>
                )
              })
              }
            </tbody>
          </table>
        }
        {(data === null || data === undefined) && loading === false && loaded === true
          &&
          <div className="w-full flex justify-center items-center">
            <span className="text-2xl">Aun no hay datos :(</span>
          </div>}
      </div>
    </>
  )
}
import { motion } from "framer-motion";
import { Modal } from "../modal";
import { useStateMany } from "../customHooks/useStateMany";
import { useEffect } from "react";
import { API } from "../API";
import { Loader } from "../Loader";

const OrderStateHistory = ({ setVerPedidoState, verPedidoState }: any) => {
  const initialState = {
    data: [],
    reload: false,
    dataLoaded: false,
    waitingForResponse: false,
  }
  const [state, setState] = useStateMany({
    ...initialState
  })
  useEffect(() => {
    let dataToSend = {
      API_KEY: process.env.REACT_APP_API_KEY,
      id_order: parseInt(verPedidoState.orderId),
    }
    setState({ waitingForResponse: true, dataLoaded: false })
    API.post('/?searchStateHistory', {
      ...dataToSend
    }).then((response) => {
      setState({ waitingForResponse: false })
      if (response.status === 200) {
        let toSet = response.data.state_history ? response.data.state_history : []
        setState({
          data: [...toSet],
          dataLoaded: true
        })
      } else {
        console.log('Ocurrio un error:', response)
      }
    }).catch((error) => {
      console.log('Ha ocurrido un error con la respuesta del servidor', error)
    })
  }, [state.reload])
  return (
    <div className="z-50">
      <Modal />
      <motion.div initial={{ y: 50, opacity: 0 }} animate={{ y: 0, opacity: 1 }} transition={{ duration: 0.5 }} className="fixed top-0 left-0 w-full h-full flex justify-center items-center z-60">
        <div className="rounded-lg bg-white p-8 shadow-2xl max-w-lg w-full">
          <p className="text-lg font-bold">Historial del Estado</p>
          <div className={`mt-2 max-h-[460px] min-h-[200px] ${state.waitingForResponse || (state.waitingForResponse === false && state.dataLoaded === true && state.data.length === 0) ? 'items-center justify-center' : 'overflow-y-auto'} gap-y-2 flex flex-col`}>
            {state.waitingForResponse === true && state.dataLoaded === false &&
              <div className="-mb-7">
                <Loader />
              </div>
            }
            {state.waitingForResponse === false && state.dataLoaded === true &&
              state.data.length > 0 ?
              state.data.map((el: any) => {
                return (
                  <HistorialStateContainer item={el} key={el.id} />
                )
              })
              : <></>
            }
            {state.waitingForResponse === false && state.dataLoaded === true &&
              state.data.length === 0 &&
              <p className="text-gray-500 font-medium text-lg">No hay datos</p>
            }
            {/**/}
          </div>
          <div className="mt-4 flex gap-2">
            <button onClick={() => {
              setVerPedidoState({
                showOrderStateHistory: false
              })
            }} type="button" className="rounded border-2 w-full border-gray-300 bg-gray-100 px-4 py-2 text-sm font-medium text-gray-700">
              Volver
            </button>
          </div>
        </div>
        {/* {state.waitingForResponse === true && state.successAlert === false &&
                      <Loader />
                    } */}
      </motion.div>
    </div>
  )
}

const HistorialStateContainer = ({ item }: any) => {
  if (item.state === 'aceptado') {
    return (
      <div className="flex justify-between gap-x-4 items-center w-full rounded-lg p-2.5 border-green-500 border-2">
        <div className="flex items-center justify-center rounded-full max-w-[60px] max-h-[60px] w-full text-green-500">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1" stroke="currentColor" className="h-14 w-14">
            <path strokeLinecap="round" strokeLinejoin="round" d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
          </svg>
        </div>
        <div className="w-full flex flex-col justify-between gap-y-1">
          <div className="flex">
            <p className="text-sm font-medium first-letter:uppercase">{item.state} por <span className="font-normal">{item.email}</span></p>
          </div>
          <div className="text-sm">
            <span>{item.created_at}</span>
          </div>
        </div>
      </div>
    )
  } else if (item.state === 'rechazado') {
    return (
      <div className="flex justify-between gap-x-4 items-center w-full rounded-lg p-2.5 border-red-700 border-2">
        <div className="flex items-center justify-center rounded-full max-w-[60px] max-h-[60px] w-full text-red-700">

          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1" stroke="currentColor" className="h-14 w-14">
            <path strokeLinecap="round" strokeLinejoin="round" d="M8.25 9.75h4.875a2.625 2.625 0 010 5.25H12M8.25 9.75L10.5 7.5M8.25 9.75L10.5 12m9-7.243V21.75l-3.75-1.5-3.75 1.5-3.75-1.5-3.75 1.5V4.757c0-1.108.806-2.057 1.907-2.185a48.507 48.507 0 0111.186 0c1.1.128 1.907 1.077 1.907 2.185z" />
          </svg>
        </div>
        <div className="w-full flex flex-col justify-between gap-y-1">
          <div className="flex">
            <p className="text-sm font-medium first-letter:uppercase">{item.state} por <span className="font-normal">{item.email}</span></p>
          </div>
          <div className="text-sm">
            <span>{item.created_at}</span>
          </div>
        </div>
      </div>
    )
  } else if (item.state === 'completado') {

    return (
      <div className="flex justify-between gap-x-4 items-center w-full rounded-lg p-2.5 border-amber-700 border-2">
        <div className="flex items-center justify-center rounded-full max-w-[60px] max-h-[60px] w-full text-amber-700">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="h-14 w-14">
            <path d="M12.378 1.602a.75.75 0 00-.756 0L3 6.632l9 5.25 9-5.25-8.622-5.03zM21.75 7.93l-9 5.25v9l8.628-5.032a.75.75 0 00.372-.648V7.93zM11.25 22.18v-9l-9-5.25v8.57a.75.75 0 00.372.648l8.628 5.033z" />
          </svg>
        </div>
        <div className="w-full flex flex-col justify-between gap-y-1">
          <div className="flex">
            <p className="text-sm font-medium first-letter:uppercase">{item.state} por <span className="font-normal">{item.email}</span></p>
          </div>
          <div className="text-sm">
            <span>{item.created_at}</span>
          </div>
        </div>
      </div>
    )
  } else if (item.state === 'revision') {
    return (
      <div className="flex justify-between gap-x-4 items-center w-full rounded-lg p-2.5 border-slate-500 border-2">
        <div className="flex items-center justify-center rounded-full max-w-[60px] max-h-[60px] w-full text-slate-500">
          <svg className="h-14 w-14" viewBox="0 0 22 16" version="1.1">
            <g id="Icons" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
              <g id="Rounded" transform="translate(-747.000000, -2904.000000)">
                <g id="Image" transform="translate(100.000000, 2626.000000)">
                  <g id="-Round-/-Image-/-remove_red_eye" transform="translate(646.000000, 274.000000)">
                    <g className="fill-slate-500">
                      <polygon className="fill-transparent" id="Path" points="0 0 24 0 24 24 0 24" />
                      <path d="M12,4.5 C7,4.5 2.73,7.61 1,12 C2.73,16.39 7,19.5 12,19.5 C17,19.5 21.27,16.39 23,12 C21.27,7.61 17,4.5 12,4.5 Z M12,17 C9.24,17 7,14.76 7,12 C7,9.24 9.24,7 12,7 C14.76,7 17,9.24 17,12 C17,14.76 14.76,17 12,17 Z M12,9 C10.34,9 9,10.34 9,12 C9,13.66 10.34,15 12,15 C13.66,15 15,13.66 15,12 C15,10.34 13.66,9 12,9 Z" />
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </svg>
        </div>
        <div className="w-full flex flex-col justify-between gap-y-1">
          <div className="flex">
            <p className="text-sm font-medium first-letter:uppercase">{item.state} por <span className="font-normal">{item.email}</span></p>
          </div>
          <div className="text-sm">
            <span>{item.created_at}</span>
          </div>
        </div>
      </div>
    )
  } else if (item.state === 'entregado') {
    return (
      <div className="flex justify-between gap-x-4 items-center w-full rounded-lg p-2.5 border-indigo-500 border-2">
        <div className="flex items-center justify-center rounded-full max-w-[60px] max-h-[60px] w-full text-indigo-800">

          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="h-14 w-14">
            <path d="M11.47 1.72a.75.75 0 011.06 0l3 3a.75.75 0 01-1.06 1.06l-1.72-1.72V7.5h-1.5V4.06L9.53 5.78a.75.75 0 01-1.06-1.06l3-3zM11.25 7.5V15a.75.75 0 001.5 0V7.5h3.75a3 3 0 013 3v9a3 3 0 01-3 3h-9a3 3 0 01-3-3v-9a3 3 0 013-3h3.75z" />
          </svg>
        </div>
        <div className="w-full flex flex-col justify-between gap-y-1">
          <div className="flex">
            <p className="text-sm font-medium first-letter:uppercase">{item.state} por <span className="font-normal">{item.email}</span></p>
          </div>
          <div className="text-sm">
            <span>{item.created_at}</span>
          </div>
        </div>
      </div>
    )
  } else if (item.state === 'Anulado') {
    <span className="inline-flex items-center justify-center rounded-full bg-red-600 min-w-[115px] px-3 py-1 text-white outline outline-1 outline-red-400">

      <p className="whitespace-nowrap text-sm">Anulado</p>
    </span>
    return (
      <div className="flex justify-between gap-x-4 items-center w-full rounded-lg p-2.5 border-red-500 border-2">
        <div className="flex items-center justify-center rounded-full max-w-[60px] max-h-[60px] w-full text-red-800">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="h-14 w-14">
            <path fillRule="evenodd" d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25zm-1.72 6.97a.75.75 0 10-1.06 1.06L10.94 12l-1.72 1.72a.75.75 0 101.06 1.06L12 13.06l1.72 1.72a.75.75 0 101.06-1.06L13.06 12l1.72-1.72a.75.75 0 10-1.06-1.06L12 10.94l-1.72-1.72z" clipRule="evenodd" />
          </svg>
        </div>
        <div className="w-full flex flex-col justify-between gap-y-1">
          <div className="flex">
            <p className="text-sm font-medium first-letter:uppercase">{item.state} por <span className="font-normal">{item.email}</span></p>
          </div>
          <div className="text-sm">
            <span>{item.created_at}</span>
          </div>
        </div>
      </div>
    )
  } else {
    return (
      <></>
    )
  }
}

export { OrderStateHistory };
import { motion } from "framer-motion"
import { API } from "../API";
import { useStateMany } from "../customHooks/useStateMany"
import { ErrorAlert } from "../ErrorAlert"
import { Loader } from "../Loader";
const md5 = require('blueimp-md5');

interface UpdatePasswordTypes {
  user: {
    id: string,
    name: string,
    email: string,
    permissions: string,
    phone: string,
    status: number | null,
  }
  appSetState: any,
  appState: any,
}
function UpdatePassword({ user, appSetState, appState }: UpdatePasswordTypes) {
  const [state, setState] = useStateMany({
    stage: appState.currentOpen === 'security' ? 1 : 0,
    complete: false,
    password: '',
    repeatPassword: '',
    error: false,
    errorMessageText: '',
    errorMessageTitle: '',
    waitingForResponse: false,
  });
  function submitUpdatePassword(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    event.preventDefault();
    if (state.password === state.repeatPassword && state.password !== '' && state.repeatPassword !== '') {
      let dataToSend = {
        API_KEY: process.env.REACT_APP_API_KEY,
        idProfile: user.id,
        name: user.name,
        email: user.email,
        password: md5(state.password),
        status: 'Y',
      }
      try {
        setState({
          waitingForResponse: true,
          error: false,
          errorMessageText: '',
          errorMessageTitle: '',
        })
        API.post('?state', {
          ...dataToSend
        }).then((response) => {

          if (response.status === 200 && response.data.success === 1) {
            // TODO: tengo que hacer algunas validaciones y un loader

            // cuando se confirme que la clave ha sido guardada
            setState({ password: '', stage: 0, repeatPassword: '', complete: true,waitingForResponse:false })
            appSetState({
              user: {
                ...appState.user,
                status: 1
              }
            })
          } else {
            console.error("ocurrio un error")
            //! error
          }
        })
      } catch (error) {
        //! error
      }

    } else {
      setState({
        error: true,
        errorMessageText: 'Contraseñas invalidas',
        errorMessageTitle: 'Las contraseñas no coinciden o estan vacias, deben ser iguales para poder continuar'
      })
    }
  }

  function cancelUpdatePassword(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    setState({
      error: false,
      errorMessageText: '',
      errorMessageTitle: ''
    })
    event.preventDefault();
    let dataToSend = {
      API_KEY: process.env.REACT_APP_API_KEY,
      idProfile: user.id,
      status: 'N',
    }
    try {
      setState({
        waitingForResponse: true,
        error: false,
        errorMessageText: '',
        errorMessageTitle: '',
      })
      API.post('?state', {
        ...dataToSend
      }).then((response) => {
        if (response.status === 200 && response.data.success === 1) {
          // TODO: tengo que hacer algunas validaciones
          setState({ stage: 0, complete: true, waitingForResponse: false })
          appSetState({
            user: {
              ...appState.user,
              status: 1
            }
          })
        } else {
          //! error
        }
      })
    } catch (error) {
      //! error
    }

  }


  return (
    <main className="bg-gradient-to-r from-indigo-700 to-blue-500 min-h-screen flex justify-center items-center relative">
      {state.waitingForResponse === true &&
        <Loader
          animate={{ transform: "rotate(45deg) scale(0.8) translate(0px,0px)", opacity: 1, zIndex: 50, left: "2.5rem", bottom: "1.25rem", position: "fixed" }} transition={{ duration: 0.3 }} initial={{ transform: "rotate(45deg) scale(0.8) translate(-50px,50px)", opacity: 0.1, zIndex: 50, left: "2.5rem", bottom: "1.25rem", position: "fixed" }} />
      }
      <div className="w-full h-full flex justify-center items-center fixed top-0 left-0">
        {state.stage === 0 && state.complete === false &&
          <motion.div initial={{ y: 100, opacity: 0.2 }} transition={{ duration: 0.25 }} animate={{ y: 0, opacity: 1 }} className="w-full max-w-[450px] bg-white rounded shadow-xl p-2 sm:p-5 lg:p-10 text-slate-700 flex items-start justify-start flex-col gap-3 m-2">
            <h2 className="text-2xl font-medium">Bienvenido a la aplicacion de pedidos de keratos!</h2>
            <p>Cuando se te asigno esta cuenta se creo una contraseña inicial, ¿deseas cambiar esta contraseña?</p>
            <div className="flex justify-start items-center gap-4">
              <button onClick={() => { setState({ stage: 1 }) }}
                className="inline-block rounded bg-green-500 px-3 py-3 text-sm font-medium text-white transition hover:bg-transparent border-[1px] hover:border-green-500  hover:text-green-500 focus:outline-none focus:ring active:bg-green-400 active:text-white">
                Si, quiero cambiarla
              </button>
              <button onClick={(event) => { cancelUpdatePassword(event) }}
                className="inline-block rounded bg-red-500 px-3 py-3 text-sm font-medium text-white transition hover:bg-transparent border-[1px] hover:border-red-500  hover:text-red-500 focus:outline-none focus:ring active:bg-red-400 active:text-white">
                No, mantener la que tengo
              </button>
            </div>
          </motion.div>
        }
        {state.stage === 1 && state.complete === false &&
          <motion.div initial={{ y: 100, opacity: 0.2 }} transition={{ duration: 0.25 }} animate={{ y: 0, opacity: 1 }} className="w-full max-w-[450px] bg-white rounded shadow-xl p-2 sm:p-5 lg:p-10 text-slate-700 flex items-start justify-start flex-col gap-3 m-2">
            <h2 className="text-2xl font-medium">Ingresa la nueva contraseña</h2>
            <form className="w-full flex items-start justify-start gap-y-5 flex-col mt-6">
              <label className="text-sm font-medium text-gray-700 w-full">
                <span className="capitalize">contraseña</span>
                <input
                  type="password"
                  value={state.password}
                  onChange={(event) => { setState({ password: event.target.value }) }}
                  className="h-12 mt-2 w-full rounded-lg border-gray-200 border-[1px] p-3 text-sm"
                />
              </label>
              <label className="w-full text-sm font-medium text-gray-700">
                <span className="capitalize">confirmar contraseña</span>
                <input
                  type="password"
                  value={state.repeatPassword}
                  onChange={(event) => { setState({ repeatPassword: event.target.value }) }}
                  className="h-12 mt-2 w-full rounded-lg border-gray-200 border-[1px] p-3 text-sm"
                />
              </label>
              <div className="flex justify-start items-center gap-4 mt-6">
                <button onClick={(event) => { submitUpdatePassword(event) }}
                  className="inline-block rounded bg-green-500 px-8 py-3 text-sm font-medium text-white transition hover:bg-transparent border-[1px] hover:border-green-500  hover:text-green-500 focus:outline-none focus:ring active:bg-green-400 active:text-white">
                  Guardar
                </button>
                <button type="button" onClick={(event) => {
                  if(appState.currentOpen === 'security'){
                    cancelUpdatePassword(event)
                  }else{
                    setState({
                      stage: 0, complete: false, error: false,
                      errorMessageText: '',
                      errorMessageTitle: ''
                    })
                  }
                }}
                  className="inline-block rounded bg-red-500 px-8 py-3 text-sm font-medium text-white transition hover:bg-transparent border-[1px] hover:border-red-500  hover:text-red-500 focus:outline-none focus:ring active:bg-red-400 active:text-white">
                  Volver
                </button>
              </div>
            </form>
          </motion.div>
        }
        {state.error === true && state.errorMessageText && state.errorMessageTitle &&
          <ErrorAlert ErrorElement={state.ErrorElement}
            animate={{ x: 0, opacity: 1, zIndex: 50, left: "1rem", bottom: "1rem", position: "fixed" }}
            transition={{ duration: 0.3 }}
            initial={{ x: -50, opacity: 0.1, zIndex: 50, left: "1rem", bottom: "1rem", position: "fixed" }}
            message={state.errorMessageText}
            title={state.errorMessageTitle} />
        }
      </div>

    </main>
  )
}

export { UpdatePassword }
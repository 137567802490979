import React, { Fragment, useState } from 'react'
import { DownloadExcel } from "../DownloadExcel";
import { Listbox, Transition } from '@headlessui/react'
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid'
import { useStateMany } from '../customHooks/useStateMany';

function Reports({ user }: any) {
  const [dateInput, setDateInput] = useState(null);

  // Estados para manejar el select de tipos
  const [typeOrders, setTypeOrders] = useState([
    { id: 0, name: 'Seleccione un tipo' },
    { id: 4, name: 'todos' },
    { id: 1, name: 'pedido' },
    { id: 2, name: 'caja de prueba' },
    { id: 3, name: 'accesorio' },
  ])

  const [typeOrderSelected, setTypeOrderSelected] = useState(typeOrders[0]);

  // Estados para manejar el select de Estados

  const [stateOrders, setStateOrders] = useState([
    { id: 0, name: 'seleccione un estado' },
    { id: 7, name: 'todos' },
    { id: 1, name: 'revision' },
    { id: 2, name: 'aceptado' },
    { id: 3, name: 'completado' },
    { id: 4, name: 'entregado' },
    { id: 5, name: 'cancelado' },
    { id: 6, name: 'rechazado' },
  ])

  const [stateOrderSelected, setStateOrderSelected] = useState(stateOrders[0]);

  const handleChangeDate = (e: any) => {
    setState({fecha:e.target.value})
    setDateInput(e.target.value);
  };

  const [state, setState] = useStateMany({
    user:user,
    fecha: dateInput,
    tipo: typeOrderSelected.id,
    estado: stateOrderSelected.name,
    waitingForResponse: false,
  })

  return (
    <div className="max-w-full w-full">
      <div className="flex items-center justify-center flex-col h-full w-full">
        <h4 className="text-4xl font-bold mb-2 text-center text-slate-800">
          Reportes
        </h4>
        <p className="mb-10 text-gray-600 text-center max-w-[500px]">Aqui podras generar los reportes en formato excel que tendra todas las ordes en el estado, tipo y fecha deseadas.</p>
        <div className="flex justify-center items-center gap-4">
        </div>
      </div>
      <div className="mt-10">
        <h5 className='text-xl font-bold mb-2 text-left text-slate-800 border-b border-gray-300'>Excel</h5>
        <div className='flex flex-col justify-start items-start gap-y-5'>
          <div className='w-full flex flex-row gap-5 flex-wrap md:flex-nowrap'>
            {/* select de tipo de orden */}
            <div className='w-full max-w-[300px]'>
              <label className="text-sm font-medium text-gray-700">
                <span className="first-letter:capitalize">Tipo</span>
                <Listbox value={typeOrderSelected} onChange={setTypeOrderSelected}>
                  <div className="relative">
                    <Listbox.Button className="relative h-12 mt-2 w-full rounded-lg border-gray-200 bg-white border-[1px] p-3 text-sm text-left focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-sm">
                      <span className={`capitalize block truncate ${typeOrderSelected.id === 0 && "text-gray-500"}`}>{typeOrderSelected.name}</span>
                      <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                        <ChevronUpDownIcon
                          className="h-5 w-5 text-gray-400"
                          aria-hidden="true"
                        />
                      </span>
                    </Listbox.Button>
                    <Transition
                      as={Fragment}
                      leave="transition ease-in duration-100"
                      leaveFrom="opacity-100"
                      leaveTo="opacity-0"
                    >
                      <Listbox.Options className="z-40 absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                        {typeOrders.map((type: { name: string }, typeIdx: number) => (
                          <Listbox.Option
                            key={typeIdx}
                            className={({ active }) =>
                              `relative cursor-default select-none py-2 pl-10 pr-4 ${active ? 'bg-blue-600 text-white' : 'text-gray-900'
                              }`
                            }
                            value={type}
                          >
                            {({ selected }) => (
                              <>
                                <span
                                  className={`capitalize block truncate ${selected ? 'font-medium' : 'font-normal'
                                    }`}
                                >
                                  {type.name}
                                </span>
                                {selected ? (
                                  <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-indigo-600">
                                    <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                  </span>
                                ) : null}
                              </>
                            )}
                          </Listbox.Option>
                        ))}
                      </Listbox.Options>
                    </Transition>
                  </div>
                </Listbox>
              </label>
            </div>
            {/* select de estado de orden */}
            <div className='w-full max-w-[300px]'>
              <label className="text-sm font-medium text-gray-700">
                <span className="first-letter:capitalize">Estado</span>
                <Listbox value={stateOrderSelected} onChange={setStateOrderSelected}>
                  <div className="relative">
                    <Listbox.Button className="relative h-12 mt-2 w-full rounded-lg border-gray-200 bg-white border-[1px] p-3 text-sm text-left focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-sm">
                      <span className={`capitalize block truncate ${stateOrderSelected.id === 0 && "text-gray-500"}`}>{stateOrderSelected.name}</span>
                      <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                        <ChevronUpDownIcon
                          className="h-5 w-5 text-gray-400"
                          aria-hidden="true"
                        />
                      </span>
                    </Listbox.Button>
                    <Transition
                      as={Fragment}
                      leave="transition ease-in duration-100"
                      leaveFrom="opacity-100"
                      leaveTo="opacity-0"
                    >
                      <Listbox.Options className="z-40 absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                        {stateOrders.map((state: { name: string }, typeIdx: number) => (
                          <Listbox.Option
                            key={typeIdx}
                            className={({ active }) =>
                              `relative cursor-default select-none py-2 pl-10 pr-4 ${active ? 'bg-blue-600 text-white' : 'text-gray-900'
                              }`
                            }
                            value={state}
                          >
                            {({ selected }) => (
                              <>
                                <span
                                  className={`capitalize block truncate ${selected ? 'font-medium' : 'font-normal'
                                    }`}
                                >
                                  {state.name}
                                </span>
                                {selected ? (
                                  <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-indigo-600">
                                    <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                  </span>
                                ) : null}
                              </>
                            )}
                          </Listbox.Option>
                        ))}
                      </Listbox.Options>
                    </Transition>
                  </div>
                </Listbox>
              </label>
            </div>
          </div>
          <label className="text-sm font-medium text-gray-700">
            <span className="first-letter:capitalize">Fecha</span>
            <input onChange={(e) => { handleChangeDate(e) }} type="date" name="date" id="date" className={`relative h-12 mt-2 w-full rounded-lg border-gray-200 bg-white border-[1px] p-3 text-sm text-left focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-sm max-w-[300px] font-medium ${dateInput === null ? 'text-gray-500' : 'text-gray-700'}`} />
          </label>
          <DownloadExcel data={{
                user:user,
                fecha: dateInput,
                tipo: typeOrderSelected.id,
                estado: stateOrderSelected.name,
          }} />
        </div>
      </div>
    </div>
  )
}

export { Reports }

import React, { useRef } from 'react'
import { useStateMany } from '../customHooks/useStateMany'
import { motion } from "framer-motion"
import { Fragment, useEffect, useState } from 'react'
import { Listbox, Transition } from '@headlessui/react'
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid'
import { API } from "../API"
import { ErrorAlert } from "../ErrorAlert"
import { Loader } from "../Loader"
import { EyesInput } from '../MyOrders'
const md5 = require('blueimp-md5');
const UpdateOrder = ({ user, generalState, setGeneralState, setVerPedido, updateState, typeLens, lens, colorLens, testBoxesList, lensDesignList, accessoriesList, citysData }: any) => {
  // estado general del componente
  const [state, setState] = useStateMany({
    loading: false,
    dataLoaded: false,
    data: [],
    showLeftEyeForm: false,
    showRightEyeForm: false,
    createOrderStage: true,
    orderStep: 1,
    reload: false,
    waitingForResponse: false,
    errorMessage: false,
    errorMessageTitle: '',
    errorMessageText: '',
    successMessage: false,
    successMessageTitle: '',
    successMessageText: '',
  })

  // ! SECCION DE FORMULARIO DE CREAR PEDIDO
  // lista de tipos de lentes
  const [lensTypes, setLensTypes] = useState(typeLens)
  const [lensColors, setLensColors] = useState(colorLens)
  const [lensDesign, setLensDesign] = useState(lensDesignList)
  const [testBoxes, setTestBoxes] = useState(testBoxesList)
  const [accessories, setAccessories] = useState(accessoriesList)
  const [leftColorLens, setLeftColorLens] = useState(lensColors[0]);
  const [rightColorLens, setRightColorLens] = useState(lensColors[0]);

  // lista de referencias del ojo izquierdo
  const [lensReferences, setLensReferences] = useState(lens);
  const [colorsLength, setColorsLength] = useStateMany({
    left: 0,
    right: 0,
  });

  // Select de ciudad
  const [city, setCity] = useState(citysData[0])

  const eyesDiv = useRef<HTMLDivElement | null>(null);

  function calcColorLength(obj: any, from: string, reset: boolean = true) {
    if (from === 'left') {
      let left = 0;
      lensColors.forEach((el: any) => {
        if (el.id_lens === obj.id_lens) {
          left = left + 1;
        }
      })
      setColorsLength({ left: left })
      if (reset === true) {
        setLeftColorLens(lensColors[0]);
      }
    }
    if (from === 'right') {
      let right = 0;
      lensColors.forEach((el: any) => {
        if (el.id_lens === obj.id_lens) {
          right = right + 1;
        }
      })
      setColorsLength({ right: right })
      if (reset === true) {
        setRightColorLens(lensColors[0]);
      }
    }
  };
  // selector de referencia de lente izquierdo
  const [leftReferenceSelected, setLeftReferenceSelected] = useState(lensReferences[0]);
  // selector de referencia de lente derecho
  const [rightReferenceSelected, setRightReferenceSelected] = useState(lensReferences[0]);
  // selector de tipo de lente derecho
  const [rightLensTypeSelected, setRightLensTypesSelected] = useState(lensTypes[0]);
  // selector de tipo de lente izquierdo
  const [leftLensTypeSelected, setLeftLensTypesSelected] = useState(lensTypes[0]);
  // estado de los ojos
  const [testBoxSelected, setTestBoxSelected] = useState(testBoxes[0]);
  // selector de accesorios
  const [accessoriesSelected, setAccessoriesSelected] = useState(accessories[0]);
  // selector de design de lente izquierdo
  const [leftDesignSelected, setLeftDesignSelected] = useState(lensDesign[0]);
  // selector de design de lente derecho
  const [rightDesignSelected, setRightDesignSelected] = useState(lensDesign[0]);
  const leftInputs = leftReferenceSelected.id_input ? leftReferenceSelected.id_input.split(",") : [''];
  const rightInputs = rightReferenceSelected.id_input ? rightReferenceSelected.id_input.split(",") : [''];

  const [eyesState, setEyesState]: any = useStateMany({
    orderId: updateState.orderId,
    quienRealizaElPedido: updateState.quienRealizaElPedido,
    nombreDelPaciente: updateState.nombreDelPaciente,
    numeroDeDocumentoDelPaciente: updateState.numeroDeDocumentoDelPaciente,
    nombreDelDoctor: updateState.nombreDelDoctor,
    tarjetaProfesional: updateState.tarjetaProfesional,
    address: updateState.address,
    type_order: updateState.type_order,
    // ojo izquierdo
    // ! agregar estas dos nuevas propiedas al reset
    idCaja: updateState.idCaja,
    idAccesorio: updateState.idAccesorio,
    left_cantidad: isNaN(parseInt(updateState.left_cantidad)) || updateState.left_cantidad === "NaN" ? 1 : parseInt(updateState.left_cantidad),
    left_adicion: updateState.left_adicion,
    left_ZO: updateState.left_ZO,
    left_grados: updateState.left_grados,
    left_periferia1: updateState.left_periferia1,
    left_periferia2: updateState.left_periferia2,
    left_cInvertida: updateState.left_cInvertida,
    left_cAlineamiento: updateState.left_cAlineamiento,
    left_cApertura: updateState.left_cApertura,
    left_ZOcBase: updateState.left_ZOcBase,
    left_ZOcInvertida: updateState.left_ZOcInvertida,
    left_ZOcAlineamiento: updateState.left_ZOcAlineamiento,
    left_ZOcApertura: updateState.left_ZOcApertura,
    left_dZO: updateState.left_dZO,
    left_dSegundaCurva: updateState.left_dSegundaCurva,
    left_c_base: updateState.left_c_base,
    left_poder: updateState.left_poder,
    left_cilindro: updateState.left_cilindro,
    left_diametro: updateState.left_diametro,
    left_design: updateState.left_design,
    left_cpp: updateState.left_cpp,
    left_observaciones: updateState.left_observaciones,
    // ojo derecho
    //!nuevos inputs (recordar agregar su correspondiente reset)
    right_dZO: updateState.right_dZO,
    right_dSegundaCurva: updateState.right_dSegundaCurva,
    right_cantidad: isNaN(parseInt(updateState.right_cantidad)) || updateState.right_cantidad === "NaN" ? 1 : parseInt(updateState.right_cantidad),
    right_adicion: updateState.right_adicion,
    right_ZO: updateState.right_ZO,
    right_grados: updateState.right_grados,
    right_periferia1: updateState.right_periferia1,
    right_periferia2: updateState.right_periferia2,
    right_cInvertida: updateState.right_cInvertida,
    right_cAlineamiento: updateState.right_cAlineamiento,
    right_cApertura: updateState.right_cApertura,
    right_ZOcBase: updateState.right_ZOcBase,
    right_ZOcInvertida: updateState.right_ZOcInvertida,
    right_ZOcAlineamiento: updateState.right_ZOcAlineamiento,
    right_ZOcApertura: updateState.right_ZOcApertura,
    right_c_base: updateState.right_c_base,
    right_poder: updateState.right_poder,
    right_cilindro: updateState.right_cilindro,
    right_diametro: updateState.right_diametro,
    right_design: updateState.right_design,
    right_cpp: updateState.right_cpp,
    right_observaciones: updateState.right_observaciones,
  })
  function cancelOrder(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    event.preventDefault();
    setVerPedido({ updateOrderStage: false })
    setGeneralState({ reload: !generalState.reload })
    setLeftColorLens(lensColors[0])
    setRightColorLens(lensColors[0])
    setLeftReferenceSelected(lensReferences[0])
    setRightReferenceSelected(lensReferences[0])
    setRightLensTypesSelected(lensTypes[0])
    setLeftLensTypesSelected(lensTypes[0])
    setCity(citysData[0])
    setEyesState({
      orderId: 0,
      quienRealizaElPedido: '',
      nombreDelPaciente: '',
      numeroDeDocumentoDelPaciente: '',
      nombreDelDoctor: '',
      tarjetaProfesional: '',
      address: '',
      // ojo izquierdo
      // ! agregar estas dos nuevas propiedas al reset
      idCaja: 0,
      idAccesorio: 0,
      left_cantidad: 0,
      left_adicion: '',
      left_ZO: '',
      left_grados: '',
      left_periferia1: '',
      left_periferia2: '',
      left_cInvertida: '',
      left_cAlineamiento: '',
      left_cApertura: '',
      left_ZOcBase: '',
      left_ZOcInvertida: '',
      left_ZOcAlineamiento: '',
      left_ZOcApertura: '',
      left_dZO: '',
      left_dSegundaCurva: '',
      left_c_base: '',
      left_poder: '',
      left_cilindro: '',
      left_diametro: '',
      left_design: '',
      left_cpp: '',
      left_observaciones: '',
      // ojo derecho
      //!nuevos inputs (recordar agregar su correspondiente reset)
      right_dZO: '',
      right_dSegundaCurva: '',
      right_cantidad: 0,
      right_adicion: '',
      right_ZO: '',
      right_grados: '',
      right_periferia1: '',
      right_periferia2: '',
      right_cInvertida: '',
      right_cAlineamiento: '',
      right_cApertura: '',
      right_ZOcBase: '',
      right_ZOcInvertida: '',
      right_ZOcAlineamiento: '',
      right_ZOcApertura: '',
      right_c_base: '',
      right_poder: '',
      right_cilindro: '',
      right_diametro: '',
      right_design: '',
      right_cpp: '',
      right_observaciones: '',
    });
    setState({
      createOrderStage: false,
      showLeftEyeForm: false,
      showRightEyeForm: false,
      orderStep: 0,
      errorMessage: false,
      errorMessageTitle: '',
      errorMessageText: ''
    });
  }
  function resetInputs(lado: string) {
    if (lado === 'right') {
      setRightDesignSelected(lensDesign[0])
      setRightColorLens(lensColors[0])
      setEyesState({
        // ojo derecho
        right_dZO: '',
        right_dSegundaCurva: '',
        right_cantidad: 0,
        right_adicion: '',
        right_ZO: '',
        right_grados: '',
        right_periferia1: '',
        right_periferia2: '',
        right_cInvertida: '',
        right_cAlineamiento: '',
        right_cApertura: '',
        right_ZOcBase: '',
        right_ZOcInvertida: '',
        right_ZOcAlineamiento: '',
        right_ZOcApertura: '',
        right_c_base: '',
        right_poder: '',
        right_cilindro: '',
        right_diametro: '',
        right_design: '',
        right_cpp: '',
        right_observaciones: '',
      })
    }
    if (lado === 'left') {
      setLeftColorLens(lensColors[0])
      setLeftDesignSelected(lensDesign[0])
      setEyesState({
        // ojo izquierdo
        left_cantidad: 0,
        left_adicion: '',
        left_ZO: '',
        left_grados: '',
        left_periferia1: '',
        left_periferia2: '',
        left_cInvertida: '',
        left_cAlineamiento: '',
        left_cApertura: '',
        left_ZOcBase: '',
        left_ZOcInvertida: '',
        left_ZOcAlineamiento: '',
        left_ZOcApertura: '',
        left_c_base: '',
        left_poder: '',
        left_cilindro: '',
        left_diametro: '',
        left_cpp: '',
        left_observaciones: '',
      });
    }
  }
  function goToOrderStep2(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    event.preventDefault();
    if (state.orderStep === 1) {
      if (eyesState.quienRealizaElPedido !== '' && eyesState.nombreDelPaciente !== '' && eyesState.nombreDelDoctor !== '' && eyesState.tarjetaProfesional !== '') {
        setState({
          orderStep: 2, errorMessage: false, errorMessageTitle: '', errorMessageText: '', showLeftEyeForm: true,
          showRightEyeForm: false
        });
      } else {
        setState({ errorMessage: true, errorMessageTitle: 'Por favor llene todo los campos para continuar', errorMessageText: 'No se puede continuar con el proceso de creacion de un pedido sin los datos necesarios.' })
        setTimeout(() => {
          setState({ errorMessage: false, errorMessageTitle: '', errorMessageText: '' })
        }, 7000);
      }
    }
  }
  function validateLeftEye(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    event.preventDefault();
    // if (leftLensTypeSelected.name_typeLens !== 'indique el tipo de lente' && leftReferenceSelected.name_lens !== 'indique la referencia de el lente izquierdo') {
    //   eyesDiv.current?.scroll({ top: 0 });
    //   setState({ showLeftEyeForm: false, showRightEyeForm: true, errorMessage: false, errorMessageTitle: '', errorMessageText: '', })
    // } else {
    //   setState({ errorMessage: true, errorMessageTitle: 'Por favor llene todo los campos para continuar', errorMessageText: 'No se puede continuar con el proceso de creacion de un pedido sin los datos necesarios.' })
    // }
    eyesDiv.current?.scroll({ top: 0 });
    setState({ showLeftEyeForm: false, showRightEyeForm: true, errorMessage: false, errorMessageTitle: '', errorMessageText: '', })
    // event.preventDefault();
    // if (eyesState.left_c_base !== '' && eyesState.left_poder !== '' && eyesState.left_cilindro !== '' && eyesState.left_diametro !== '' && eyesState.left_design !== '' && eyesState.left_cpp !== '' && eyesState.left_observaciones !== '' && leftLensTypeSelected.name_typeLens !== 'indique el tipo de lente' && leftReferenceSelected.name_lens !== 'indique la referencia de el lente izquierdo') {
    //   eyesDiv.current?.scroll({ top: 0 });
    //   setState({ showLeftEyeForm: false, showRightEyeForm: true, errorMessage: false, errorMessageTitle: '', errorMessageText: '', })
    // } else {
    //   setState({ errorMessage: true, errorMessageTitle: 'Por favor llene todo los campos para continuar', errorMessageText: 'No se puede continuar con el proceso de creacion de un pedido sin los datos necesarios.' })
    // }
  }
  function goToOrderStep3(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    event.preventDefault();
    if (state.orderStep === 2) {
      setState({ orderStep: 3, errorMessage: false, errorMessageTitle: '', errorMessageText: '', showLeftEyeForm: false, showRightEyeForm: false, });
      // if (leftReferenceSelected.name_lens !== 'indique la referencia de el lente izquierdo' && rightReferenceSelected.name_lens !== 'indique la referencia de el lente derecho') {
      //   setState({ orderStep: 3, errorMessage: false, errorMessageTitle: '', errorMessageText: '', showLeftEyeForm: false, showRightEyeForm: false, });
      // } else {
      //   setState({ errorMessage: true, errorMessageTitle: 'Por favor llene todo los campos para continuar', errorMessageText: 'No se puede continuar con el proceso de creacion de un pedido sin los datos necesarios.' })
      // }
    }

    // event.preventDefault();
    // if (state.orderStep === 2) {
    //   if (eyesState.left_c_base !== '' && eyesState.left_poder !== '' && eyesState.left_cilindro !== '' && eyesState.left_diametro !== '' && eyesState.left_design !== '' && eyesState.left_cpp !== '' && eyesState.left_observaciones !== '' && eyesState.right_c_base !== '' && eyesState.right_poder !== '' && eyesState.right_cilindro !== '' && eyesState.right_diametro !== '' && eyesState.right_design !== '' && eyesState.right_cpp !== '' && eyesState.right_observaciones !== '' && leftLensTypeSelected.name_typeLens !== 'indique el tipo de lente' && rightLensTypeSelected.name_typeLens !== 'indique el tipo de lente' && leftReferenceSelected.name_lens !== 'indique la referencia de el lente izquierdo' && rightReferenceSelected.name_lens !== 'indique la referencia de el lente derecho') {
    //     setState({ orderStep: 3, errorMessage: false, errorMessageTitle: '', errorMessageText: '', showLeftEyeForm: false, showRightEyeForm: false, });
    //   } else {
    //     setState({ errorMessage: true, errorMessageTitle: 'Por favor llene todo los campos para continuar', errorMessageText: 'No se puede continuar con el proceso de creacion de un pedido sin los datos necesarios.' })
    //   }
    // }
  }
  function sendAccessories(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    event.preventDefault();
    if (parseInt(accessoriesSelected.id_acc) !== 0) {
      let dataToSend = {
        API_KEY: process.env.REACT_APP_API_KEY,
        idOrder: parseInt(eyesState.orderId),
        idSession: parseInt(user.id),
        creatorName: eyesState.quienRealizaElPedido,
        patientName: eyesState.nombreDelPaciente,
        patientDoc: eyesState.numeroDeDocumentoDelPaciente,
        optometristName: eyesState.nombreDelDoctor,
        professionalCard: eyesState.tarjetaProfesional,
        city: parseInt(city.id),
        address: eyesState.address,
        // otros 
        idBox: 0,
        idAcc: parseInt(accessoriesSelected.id_acc),
        // ojo derecho
        rTypeLens: 0,
        rLens: 0,
        rQty: parseInt(eyesState.left_cantidad),
        rZo: '',
        rAddition: '',
        rDegrees: '',
        rPerifery1: '',
        rPerifery2: '',
        rCinverted: '',
        rCAlignment: '',
        rCopening: '',
        rZoCbase: '',
        rZoCinverted: '',
        rZoCalignment: '',
        rZoCopening: '',

        rCbase: '',
        rPower: '',
        rCylinder: '',
        rDiameter: '',
        rDesign: 0,
        rCpp: '',
        rColor: 0,
        rObserv: '',
        // ojo izquierdo
        lTypeLens: 0,
        lLens: 0,
        lQty: parseInt(eyesState.left_cantidad),
        lZo: '',
        lAddition: '',
        lDegrees: '',
        lPerifery1: '',
        lPerifery2: '',
        lCinverted: '',
        lCAlignment: '',
        lCopening: '',
        lZoCbase: '',
        lZoCinverted: '',
        lZoCalignment: '',
        lZoCopening: '',

        lCbase: '',
        lPower: '',
        lCylinder: '',
        lDiameter: '',
        lDesign: '',
        lCpp: '',
        lColor: 0,
        lObserv: '',

        typeOrder: 3,
        statusOrder: 'revision',
      }
      setState({ waitingForResponse: true, errorMessage: false, errorMessageTitle: '', errorMessageText: '' })

      API.post('/?updateorders', {
        ...dataToSend,
        orderDataHistory: findDifferences(dataToSend),
      }).then((response) => {
        if (response.data.success === 1 && response.status === 200) {
          setVerPedido({ updateOrderStage: false })
          setAccessoriesSelected(accessories[0])
          setEyesState({
            quienRealizaElPedido: '',
            nombreDelPaciente: '',
            numeroDeDocumentoDelPaciente: '',
            nombreDelDoctor: '',
            tarjetaProfesional: '',
            observaciones: '',
            // ojo izquierdo
            left_observaciones: '',
            left_cantidad: 0,
            right_cantidad: 0,
            // ojo derecho
            cantidad: 0,
            right_observaciones: '',
            orderStep: 1,
          });
          // setState({
          //   waitingForResponse: false,
          //   successMessage: true,
          //   reload: !state.reload,
          //   successMessageTitle: 'Su accesorio se ha creado con exito!',
          //   successMessageText: 'Se ha creado su accesorio, espere pacientemente a que alguien de nuestro equipo la verifique y sea aceptada.',
          //   // openAccessoriesCreation: false,
          // })
          setGeneralState({
            reload: !generalState.reload,
            waitingForResponse: false,
            createOrderStage: false,
            orderStep: 0,
            successMessage: true,
            successMessageTitle: 'Su pedido se ha actualizado con exito!',
            successMessageText: 'Se ha actualizado su pedido, espere pacientemente a que alguien de nuestro equipo la verifique y sea aceptada.',
          })
          setTimeout(() => {
            setGeneralState({
              successMessage: false,
              successMessageTitle: '',
              successMessageText: '',
            })
          }, 7000)
        } else {
          setState({
            waitingForResponse: false,
            errorMessage: true,
            errorMessageTitle: 'Ocurrio un error al intentar guardar la pedido',
            errorMessageText: 'No se pudo guardar el pedido, intente de nuevo mas tarde',
          })
        }
      })
    }
  }
  function sendTestBox(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    event.preventDefault();
    if (parseInt(testBoxSelected.id_box) !== 0) {
      let dataToSend = {
        API_KEY: process.env.REACT_APP_API_KEY,
        idOrder: parseInt(eyesState.orderId),
        idSession: parseInt(user.id),
        creatorName: eyesState.quienRealizaElPedido,
        patientName: eyesState.nombreDelPaciente,
        patientDoc: eyesState.numeroDeDocumentoDelPaciente,
        optometristName: eyesState.nombreDelDoctor,
        professionalCard: eyesState.tarjetaProfesional,
        city: parseInt(city.id),
        address: eyesState.address,
        // otros 
        idBox: parseInt(testBoxSelected.id_box),
        idAcc: 0,
        // ojo derecho
        rTypeLens: 0,
        rLens: 0,
        rQty: parseInt(testBoxSelected.qty),
        rZo: '',
        rAddition: '',
        rDegrees: '',
        rPerifery1: '',
        rPerifery2: '',
        rCinverted: '',
        rCAlignment: '',
        rCopening: '',
        rZoCbase: '',
        rZoCinverted: '',
        rZoCalignment: '',
        rZoCopening: '',

        rCbase: '',
        rPower: '',
        rCylinder: '',
        rDiameter: '',
        rDesign: 0,
        rCpp: '',
        rColor: 0,
        rObserv: eyesState.left_observaciones,
        // ojo izquierdo
        lTypeLens: 0,
        lLens: 0,
        lQty: parseInt(testBoxSelected.qty),
        lZo: '',
        lAddition: '',
        lDegrees: '',
        lPerifery1: '',
        lPerifery2: '',
        lCinverted: '',
        lCAlignment: '',
        lCopening: '',
        lZoCbase: '',
        lZoCinverted: '',
        lZoCalignment: '',
        lZoCopening: '',

        lCbase: '',
        lPower: '',
        lCylinder: '',
        lDiameter: '',
        lDesign: '',
        lCpp: '',
        lColor: 0,
        lObserv: eyesState.left_observaciones,

        typeOrder: 2,
        statusOrder: 'revision',
      }
      setState({ waitingForResponse: true, errorMessage: false, errorMessageTitle: '', errorMessageText: '' })
      API.post('/?updateorders', {
        ...dataToSend,
        orderDataHistory: findDifferences(dataToSend),
      }).then((response) => {
        if (response.data.success === 1 && response.status === 200) {
          setTestBoxSelected(testBoxes[0])
          setEyesState({
            quienRealizaElPedido: '',
            nombreDelPaciente: '',
            numeroDeDocumentoDelPaciente: '',
            nombreDelDoctor: '',
            tarjetaProfesional: '',
            observaciones: '',
            // ojo izquierdo
            left_observaciones: '',
            // ojo derecho
            right_observaciones: '',
            orderStep: 1,
          });
          setVerPedido({ updateOrderStage: false })

          setGeneralState({
            reload: !generalState.reload,
            waitingForResponse: false,
            createOrderStage: false,
            orderStep: 0,
            successMessage: true,
            successMessageTitle: 'Su caja de prueba se ha actualizado con exito!',
            successMessageText: 'Se ha actualizado su caja de prueb, espere pacientemente a que alguien de nuestro equipo la verifique y sea aceptada.',
          })
          setTimeout(() => {
            setGeneralState({
              successMessage: false,
              successMessageTitle: '',
              successMessageText: '',
            })
          }, 7000)
        } else {
          setState({
            waitingForResponse: false,
            errorMessage: true,
            errorMessageTitle: 'Ocurrio un error al intentar guardar la pedido',
            errorMessageText: 'No se pudo guardar el pedido, intente de nuevo mas tarde',
          })
        }
      })
    }
  }
  function findDifferences(newData: any) {
    // busca la ciudad para comparar su ID con el que se va a enviar para verificar si hay cambios
    let findCity = citysData.find((el: any) => parseInt(el.name) === parseInt(updateState.city))

    let originalOrder: any = {
      API_KEY: process.env.REACT_APP_API_KEY,
      idOrder: parseInt(updateState.orderId),
      idSession: parseInt(user.id),
      creatorName: updateState.quienRealizaElPedido,
      patientName: updateState.nombreDelPaciente,
      patientDoc: updateState.numeroDeDocumentoDelPaciente,
      optometristName: updateState.nombreDelDoctor,
      professionalCard: updateState.tarjetaProfesional,
      address: updateState.address,
      city: findCity ? parseInt(findCity.id) : 0,
      // otros 
      idBox: parseInt(newData.typeOrder) === 2 ? parseInt(updateState.idCaja.id_box) : 0,
      idAcc: parseInt(newData.typeOrder) === 3 ? parseInt(updateState.idAccesorio.id_acc) : 0,
      rQty: parseInt(newData.typeOrder) === 2 ? parseInt(newData.rQty) : parseInt(updateState.left_cantidad),
      rObserv: parseInt(newData.typeOrder) === 2 ? updateState.left_observaciones : newData.lObserv,
      typeOrder: parseInt(newData.typeOrder) === 2 ? 2 : 3,
      statusOrder: 'revision',
    }
    if (parseInt(newData.typeOrder) === 1) {
      originalOrder = {
        creatorName: updateState.quienRealizaElPedido,
        patientName: updateState.nombreDelPaciente,
        patientDoc: updateState.numeroDeDocumentoDelPaciente,
        optometristName: updateState.nombreDelDoctor,
        professionalCard: updateState.tarjetaProfesional,
        address: updateState.address,
        city: findCity ? parseInt(findCity.id) : 0,
        // ojo derecho
        rTypeLens: parseInt(updateState?.right_typeLens?.id_typeLens),
        rLens: parseInt(updateState?.right_referenceLens?.id_lens),
        lQty: parseInt(updateState?.left_cantidad),
        rZo: updateState.right_ZO,
        rAddition: updateState.right_adicion,
        rDegrees: updateState.right_grados,
        rPerifery1: updateState.right_periferia1,
        rPerifery2: updateState.right_periferia2,
        rCinverted: updateState.right_cInvertida,
        rCAlignment: updateState.right_cAlineamiento,
        rCopening: updateState.right_cApertura,
        rZoCbase: updateState.right_ZOcBase,
        rZoCinverted: updateState.right_ZOcInvertida,
        rZoCalignment: updateState.right_ZOcAlineamiento,
        rZoCopening: updateState.right_ZOcApertura,
        rDzo: updateState.right_dZO,
        rDsecondturn: updateState.right_dSegundaCurva,
        rCbase: updateState.right_c_base,
        rPower: updateState.right_poder,
        rCylinder: updateState.right_cilindro,
        rDiameter: updateState.right_diametro,
        rDesign: parseInt(updateState?.right_design?.id_design),
        rCpp: updateState.right_cpp,
        rColor: parseInt(updateState?.right_color?.id_colorLens),
        rObserv: updateState.right_observaciones,
        // ojo izquierdo
        lTypeLens: parseInt(updateState?.left_typeLens?.id_typeLens),
        lLens: parseInt(updateState?.left_referenceLens?.id_lens),
        rQty: parseInt(updateState?.right_cantidad),
        lZo: updateState.left_ZO,
        lAddition: updateState.left_adicion,
        lDegrees: updateState.left_grados,
        lPerifery1: updateState.left_periferia1,
        lPerifery2: updateState.left_periferia2,
        lCinverted: updateState.left_cInvertida,
        lCAlignment: updateState.left_cAlineamiento,
        lCopening: updateState.left_cApertura,
        lZoCbase: updateState.left_ZOcBase,
        lZoCinverted: updateState.left_ZOcInvertida,
        lZoCalignment: updateState.left_ZOcAlineamiento,
        lZoCopening: updateState.left_ZOcApertura,
        lDzo: updateState.left_dZO,
        lDsecondturn: updateState.left_dSegundaCurva,
        lCbase: updateState.left_c_base,
        lPower: updateState.left_poder,
        lCylinder: updateState.left_cilindro,
        lDiameter: updateState.left_diametro,
        lDesign: parseInt(updateState?.left_design?.id_design),
        lCpp: updateState.left_cpp,
        lColor: parseInt(updateState?.left_color?.id_colorLens),
        lObserv: updateState.left_observaciones,
      }
    }

    const textReplace: any = {
      creatorName: 'Nombre de quien realiza el pedido',
      patientName: 'Nombre del paciente',
      patientDoc: 'Numero de documento del paciente',
      optometristName: 'Nombre del optometra u oftalmologo',
      professionalCard: 'Tarjeta profesional',
      city: 'Ciudad',
      address: 'Direccion',
      rTypeLens: 'Tipo de lente del ojo derecho',
      rLens: 'Referencia del ojo derecho',
      lQty: 'Cantidad del ojo izquierdo',
      rZo: 'Z.O del ojo derecho',
      idBox: 'Caja de prueba',
      idAcc: 'Accesorio',
      rAddition: 'Adicion del ojo derecho',
      rDegrees: 'Grados del ojo derecho',
      rPerifery1: 'Periferia 1 del ojo derecho',
      rPerifery2: 'Periferia 2 del ojo derecho',
      rCinverted: 'Curva Invertida del ojo derecho',
      rCAlignment: 'Curva de Alineamiento del ojo derecho',
      rCopening: 'Curva de Apertura del ojo derecho',
      rZoCbase: 'Z.O Curva Base del ojo derecho',
      rZoCinverted: 'Z.O Curva Invertida del ojo derecho',
      rZoCalignment: 'Z.O Curva de Alineamiento del ojo derecho',
      rZoCopening: 'Z.O Curva de Apertura del ojo derecho',
      rDzo: 'Diseno Z.O del ojo derecho',
      rDsecondturn: 'Diseno Segunda Curva del ojo derecho',
      rCbase: 'Curva Base del ojo derecho',
      rPower: 'Poder del ojo derecho',
      rCylinder: 'Cilindro del ojo derecho',
      rDiameter: 'Diametro del ojo derecho',
      rDesign: 'Diseno del ojo derecho',
      rCpp: 'Cpp del ojo derecho',
      rColor: 'Color del ojo derecho',
      rObserv: parseInt(newData.typeOrder) === 1 ? 'Observaciones del ojo derecho' : 'Observaciones',
      lTypeLens: 'Tipo de lente del ojo izquierdo',
      lLens: 'Referencia del ojo izquierdo',
      rQty: parseInt(newData.typeOrder) === 1 ? 'Cantidad del ojo derecho' : 'Cantidad',
      lZo: 'Z.O del ojo izquierdo',
      lAddition: 'Adicion del ojo izquierdo',
      lDegrees: 'Grados del ojo izquierdo',
      lPerifery1: 'Periferia 1 del ojo izquierdo',
      lPerifery2: 'Periferia 2 del ojo izquierdo',
      lCinverted: 'Curva Invertida del ojo izquierdo',
      lCAlignment: 'Curva de Alineamiento del ojo izquierdo',
      lCopening: 'Curva de Apertura del ojo izquierdo',
      lZoCbase: 'Z.O Curva Base del ojo izquierdo',
      lZoCinverted: 'Z.O Curva Invertida del ojo izquierdo',
      lZoCalignment: 'Z.O Curva de Alineamiento del ojo izquierdo',
      lZoCopening: 'Z.O Curva de Apertura del ojo izquierdo',
      lDzo: 'Diseno Z.O del ojo izquierdo',
      lDsecondturn: 'Diseno Segunda Curva del ojo izquierdo',
      lCbase: 'Curva Base del ojo izquierdo',
      lPower: 'Poder del ojo izquierdo',
      lCylinder: 'Cilindro del ojo izquierdo',
      lDiameter: 'Diametro del ojo izquierdo',
      lDesign: 'Diseno del ojo izquierdo',
      lCpp: 'Cpp del ojo izquierdo',
      lColor: 'Color del ojo izquierdo',
      lObserv: parseInt(newData.typeOrder) === 1 ? 'Observaciones del ojo izquierdo' : 'Observaciones',
    }
    let differences: string[] = [];
    Object.entries(originalOrder).forEach(([key, value]: any) => {
      if (key !== "API_KEY") {
        if (originalOrder[key] !== undefined) {
          if (newData[key] !== originalOrder[key]) {
            differences.push(`${textReplace[key]}`)
          }
        }
      }
    });
    return differences;

  }
  function sendUpdateOrder(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    event.preventDefault();
    let dataToSend = {
      API_KEY: process.env.REACT_APP_API_KEY,
      idOrder: parseInt(eyesState.orderId),
      // idOrder: eyesState.orderId,
      idSession: parseInt(user.id),
      creatorName: eyesState.quienRealizaElPedido,
      patientName: eyesState.nombreDelPaciente,
      patientDoc: eyesState.numeroDeDocumentoDelPaciente,
      optometristName: eyesState.nombreDelDoctor,
      professionalCard: eyesState.tarjetaProfesional,
      city: parseInt(city.id),
      address: eyesState.address,
      // otros 
      idBox: 0,
      idAcc: 0,
      // ojo derecho
      rTypeLens: parseInt(rightLensTypeSelected.id_typeLens),
      rLens: parseInt(rightReferenceSelected.id_lens),
      lQty: parseInt(eyesState.left_cantidad),
      rZo: eyesState.right_ZO,
      rAddition: eyesState.right_adicion,
      rDegrees: eyesState.right_grados,
      rPerifery1: eyesState.right_periferia1,
      rPerifery2: eyesState.right_periferia2,
      rCinverted: eyesState.right_cInvertida,
      rCAlignment: eyesState.right_cAlineamiento,
      rCopening: eyesState.right_cApertura,
      rZoCbase: eyesState.right_ZOcBase,
      rZoCinverted: eyesState.right_ZOcInvertida,
      rZoCalignment: eyesState.right_ZOcAlineamiento,
      rZoCopening: eyesState.right_ZOcApertura,
      rDzo: eyesState.right_dZO,
      rDsecondturn: eyesState.right_dSegundaCurva,
      rCbase: eyesState.right_c_base,
      rPower: eyesState.right_poder,
      rCylinder: eyesState.right_cilindro,
      rDiameter: eyesState.right_diametro,
      rDesign: parseInt(rightDesignSelected.id_design),
      rCpp: eyesState.right_cpp,
      rColor: parseInt(rightColorLens.id_colorLens),
      rObserv: eyesState.right_observaciones,
      // ojo izquierdo
      lTypeLens: parseInt(leftLensTypeSelected.id_typeLens),
      lLens: parseInt(leftReferenceSelected.id_lens),

      rQty: parseInt(eyesState.right_cantidad),
      lZo: eyesState.left_ZO,
      lAddition: eyesState.left_adicion,
      lDegrees: eyesState.left_grados,
      lPerifery1: eyesState.left_periferia1,
      lPerifery2: eyesState.left_periferia2,
      lCinverted: eyesState.left_cInvertida,
      lCAlignment: eyesState.left_cAlineamiento,
      lCopening: eyesState.left_cApertura,
      lZoCbase: eyesState.left_ZOcBase,
      lZoCinverted: eyesState.left_ZOcInvertida,
      lZoCalignment: eyesState.left_ZOcAlineamiento,
      lZoCopening: eyesState.left_ZOcApertura,
      lDzo: eyesState.left_dZO,
      lDsecondturn: eyesState.left_dSegundaCurva,
      lCbase: eyesState.left_c_base,
      lPower: eyesState.left_poder,
      lCylinder: eyesState.left_cilindro,
      lDiameter: eyesState.left_diametro,
      lDesign: parseInt(leftDesignSelected.id_design),
      lCpp: eyesState.left_cpp,
      lColor: parseInt(leftColorLens.id_colorLens),
      lObserv: eyesState.left_observaciones,
      typeOrder: 1,
      statusOrder: 'revision',
    }
    setGeneralState({ waitingForResponse: true, errorMessage: false, errorMessageTitle: '', errorMessageText: '' });
    // se envia la consulta
    API.post('/?updateorders', {
      ...dataToSend,
      orderDataHistory: findDifferences(dataToSend),
    }).then((response) => {
      if (response.data.success === 1 && response.status === 200) {
        setVerPedido({ updateOrderStage: false })
        setLeftColorLens(lensColors[0])
        setRightColorLens(lensColors[0])
        setLeftReferenceSelected(lensReferences[0])
        setRightReferenceSelected(lensReferences[0])
        setRightLensTypesSelected(lensTypes[0])
        setLeftLensTypesSelected(lensTypes[0])
        setEyesState({
          orderId: 0,
          quienRealizaElPedido: '',
          nombreDelPaciente: '',
          numeroDeDocumentoDelPaciente: '',
          nombreDelDoctor: '',
          tarjetaProfesional: '',
          // ojo izquierdo
          left_c_base: '',
          left_poder: '',
          left_cilindro: '',
          left_diametro: '',
          left_design: '',
          left_cpp: '',
          left_observaciones: '',
          // ojo derecho
          right_c_base: '',
          right_poder: '',
          right_cilindro: '',
          right_diametro: '',
          right_design: '',
          right_cpp: '',
          right_observaciones: '',
        });
        setGeneralState({
          reload: !generalState.reload,
          waitingForResponse: false,
          createOrderStage: false,
          orderStep: 0,
          successMessage: true,
          successMessageTitle: 'Su pedido se ha actualizado con exito!',
          successMessageText: 'Se ha actualizado su pedido, espere pacientemente a que alguien de nuestro equipo la verifique y sea aceptada.',
        })
        setTimeout(() => {
          setGeneralState({
            successMessage: false,
            successMessageTitle: '',
            successMessageText: '',
          })
        }, 7000)
      } else {
        setGeneralState({
          waitingForResponse: false,
          errorMessage: true,
          errorMessageTitle: 'Ocurrio un error al intentar actualizar el pedido',
          errorMessageText: 'No se pudo actualizar el pedido, intente de nuevo mas tarde',
        })
      }
    })
  }
  // se encarga de buscar y encontrar los valores en los selects de el pedido y ponerlos en los select actuales
  useEffect(() => {
    if (parseInt(eyesState.type_order) === 1) {
      let indexOfLeftLensType = 0
      if (updateState.left_typeLens) {
        if (updateState.left_typeLens.id_typeLens) {
          indexOfLeftLensType = lensTypes.findIndex((el: any) => parseInt(el.id_typeLens) === parseInt(updateState.left_typeLens.id_typeLens))
        }
      }

      let indexOfRightLensType = 0
      if (updateState.right_typeLens) {
        if (updateState.right_typeLens.id_typeLens) {
          indexOfRightLensType = lensTypes.findIndex((el: any) => parseInt(el.id_typeLens) === parseInt(updateState.right_typeLens.id_typeLens))
        }
      }

      let indexOfRightLens = 0
      if (updateState.right_referenceLens) {
        if (updateState.right_referenceLens.id_lens) {
          indexOfRightLens = lensReferences.findIndex((el: any) => parseInt(el.id_lens) === parseInt(updateState.right_referenceLens.id_lens))
        }
      }
      let indexOfLeftLens = 0
      if (updateState.left_referenceLens) {
        if (updateState.left_referenceLens.id_lens) {
          indexOfLeftLens = lensReferences.findIndex((el: any) => parseInt(el.id_lens) === parseInt(updateState.left_referenceLens.id_lens))
        }
      }

      // let indexOfLeftLens = lensReferences.findIndex((el: any) => parseInt(el.id_lens) === parseInt(updateState.left_referenceLens.id_lens))
      let leftInputs = lensReferences[indexOfLeftLens].id_input ? lensReferences[indexOfLeftLens].id_input.split(",") : [""];
      let rightInputs = lensReferences[indexOfRightLens].id_input ? lensReferences[indexOfRightLens].id_input.split(",") : [""];
      if (leftInputs.includes('2')) {
        let indexOfLeftDesign = 0
        if (updateState.left_design) {
          if (updateState.left_design.id_design) {
            indexOfLeftDesign = lensDesign.findIndex((el: any) => parseInt(el.id_design) === parseInt(updateState.left_design.id_design))
          }
        }
        setLeftDesignSelected(lensDesign[indexOfLeftDesign]);
      }
      if (rightInputs.includes('2')) {
        let indexOfRightDesign = 0
        if (updateState.right_design) {
          if (updateState.right_design.id_design) {
            indexOfRightDesign = lensDesign.findIndex((el: any) => parseInt(el.id_design) === parseInt(updateState.right_design.id_design))

          }
        }
        setRightDesignSelected(lensDesign[indexOfRightDesign]);
      }
      if (leftInputs.includes('1')) {
        let indexOfLeftLensColor = 0
        if (updateState.left_color) {
          if (updateState.left_color.id_colorLens) {
            indexOfLeftLensColor = lensColors.findIndex((el: any) => parseInt(el.id_colorLens) === parseInt(updateState.left_color.id_colorLens))
          }
        }
        setLeftColorLens(lensColors[indexOfLeftLensColor]);
      }
      if (rightInputs.includes('1')) {
        let indexOfRightLensColor = 0
        if (updateState.right_color) {
          if (updateState.right_color.id_colorLens) {
            indexOfRightLensColor = lensColors.findIndex((el: any) => parseInt(el.id_colorLens) === parseInt(updateState.right_color.id_colorLens))
          }
        }
        setRightColorLens(lensColors[indexOfRightLensColor]);

      }

      setLeftReferenceSelected(lensReferences[indexOfLeftLens]);
      setRightReferenceSelected(lensReferences[indexOfRightLens]);

      setRightLensTypesSelected(lensTypes[indexOfRightLensType]);
      setLeftLensTypesSelected(lensTypes[indexOfLeftLensType]);
      calcColorLength(lensReferences[indexOfLeftLens], 'left', false);
      calcColorLength(lensReferences[indexOfRightLens], 'right', false);
    } else if (parseInt(eyesState.type_order) === 2) {
      let indexOfTestBox = testBoxes.findIndex((el: any) => parseInt(el.id_box) === parseInt(updateState.idCaja.id_box))
      setTestBoxSelected(testBoxes[indexOfTestBox])
    } else if (parseInt(eyesState.type_order) === 3) {
      let indexOfAccessories = accessories.findIndex((el: any) => parseInt(el.id_acc) === parseInt(updateState.idAccesorio.id_acc))
      setAccessoriesSelected(accessories[indexOfAccessories]);
    }
    let indexOfCity = 0
    if (updateState.city) {
      indexOfCity = citysData.findIndex((el: any) => el.name === updateState.city)
    }
    setCity(citysData[indexOfCity])
  }, [])

  return (
    <>
      {state.errorMessage && state.waitingForResponse === false &&
        <ErrorAlert ErrorElement={state.ErrorElement}
          animate={{ x: 0, opacity: 1, zIndex: 50, left: "1rem", bottom: "1rem", position: "fixed" }}
          transition={{ duration: 0.3 }}
          initial={{ x: -50, opacity: 0.1, zIndex: 50, left: "1rem", bottom: "1rem", position: "fixed" }}
          message={state.errorMessageText}
          title={state.errorMessageTitle} />
      }
      {/* mensaje de completado */}
      {state.successMessage === true && state.waitingForResponse === false &&
        <motion.div initial={{ x: '-150%', opacity: 0.5 }} animate={{ x: '0%', opacity: 1 }} role="alert" className="rounded-xl border fixed left-3 sm:left-10 bottom-10 z-40 bg-white border-gray-100 p-4 shadow-xl">
          <div className="flex items-start  gap-4 max-w-[450px]">
            <span className="text-green-600">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                className="h-6 w-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                />
              </svg>
            </span>

            <div className="flex-1">
              <strong className="block font-medium text-gray-900">{state.successMessageTitle}</strong>

              <p className="mt-1 text-sm text-gray-700">
                {state.successMessageText}
              </p>
            </div>

            <button onClick={() => { setState({ successMessage: false, successMessageTitle: '', successMessageText: '' }) }} className="text-gray-500 transition hover:text-gray-600">
              <span className="sr-only">Dismiss popup</span>

              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                className="h-6 w-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
          </div>
        </motion.div>
      }
      {/* modal con formulario de creacion de pedido */}
      {state.createOrderStage === true && state.orderStep !== 0 &&
        <>
          <motion.form initial={{ y: 50, opacity: 0 }} animate={{ y: 0, opacity: 1 }} transition={{ duration: 0.5 }} className="fixed top-0 left-0 w-full h-full flex justify-center items-center z-40">
            {state.orderStep === 1 && state.waitingForResponse === false &&
              <div className="bg-white p-4 md:p-10 z-40 rounded-lg grid grid-cols-1 md:grid-cols-2 gap-x-4 gap-y-3 max-h-[90vh] w-full max-w-[1000px] overflow-y-auto">
                {/* Quien realiza el pedido */}
                <div className="col-span-full">
                  <p className="font-medium mt-5 text-slate-600">Quien realiza el pedido?</p>
                </div>
                <div className="col-span-full md:col-span-2">
                  <label className=" text-sm font-medium text-gray-700">
                    <span className="capitalize">Nombre</span>
                    <input
                      type="text"
                      value={eyesState.quienRealizaElPedido}
                      onChange={(event) => { setEyesState({ quienRealizaElPedido: event.target.value }) }}
                      className="h-12 mt-2 w-full rounded-lg border-gray-200 border-[1px] p-3 text-sm"
                    />
                  </label>
                </div>
                {/* Datos del paciente */}
                <div className="md:col-span-full">
                  <p className="font-medium mt-5 text-slate-600">Datos de el paciente</p>
                </div>
                <div className="">
                  <label className="text-sm font-medium text-gray-700">
                    <span className="capitalize">Nombre</span>
                    <input
                      type="text"
                      value={eyesState.nombreDelPaciente}
                      onChange={(event) => { setEyesState({ nombreDelPaciente: event.target.value }) }}
                      className="h-12 mt-2 w-full rounded-lg border-gray-200 border-[1px] p-3 text-sm"
                    />
                  </label>
                </div>
                <div className="">
                  <label className="text-sm font-medium text-gray-700">
                    <span className="capitalize">Numero de documento</span>
                    <input
                      type="text"
                      value={eyesState.numeroDeDocumentoDelPaciente}
                      onChange={(event) => { setEyesState({ numeroDeDocumentoDelPaciente: event.target.value }) }}
                      className="h-12 mt-2 w-full rounded-lg border-gray-200 border-[1px] p-3 text-sm"
                    />
                  </label>
                </div>
                <div className="col-span-full">
                  <p className="font-medium mt-5 text-slate-600">Datos del optómetra u oftalmólogo</p>
                </div>
                <div className="">
                  <label className=" text-sm font-medium text-gray-700">
                    <span className="capitalize">Nombre</span>
                    <input
                      type="text"
                      disabled={true}
                      value={eyesState.nombreDelDoctor}
                      onChange={(event) => { setEyesState({ nombreDelDoctor: event.target.value }) }}
                      className="h-12 mt-2 w-full rounded-lg border-gray-200 border-[1px] p-3 text-sm"
                    />
                  </label>
                </div>
                <div className="">
                  <label className=" text-sm font-medium text-gray-700">
                    <span className="capitalize">Tarjeta profesional</span>
                    <input
                      type="text"
                      disabled={true}
                      value={eyesState.tarjetaProfesional}
                      onChange={(event) => { setEyesState({ tarjetaProfesional: event.target.value }) }}
                      className="h-12 mt-2 w-full rounded-lg border-gray-200 border-[1px] p-3 text-sm"
                    />
                  </label>
                </div>
                {/* Ciudad */}
                <div>
                  <span className="first-letter:capitalize text-sm font-medium text-gray-700">Ciudad</span>
                  <Listbox value={city} onChange={setCity}>
                    <div className="relative rounded-lg">
                      <Listbox.Button className="relative h-12 w-full mt-2 rounded-lg border-gray-200 border-[1px] p-3 text-sm text-left focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-sm">
                        <span className={`block truncate ${city.id === 0 && "text-gray-500"}`}>{city.name}</span>
                        <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                          <ChevronUpDownIcon
                            className="h-5 w-5 text-gray-400"
                            aria-hidden="true"
                          />
                        </span>
                      </Listbox.Button>
                      <Transition
                        as={Fragment}
                        leave="transition ease-in duration-100"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                      >
                        <Listbox.Options className="z-40 fixed max-w-sm mt-1 w-full max-h-60 overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                          {citysData.map((city: { name: string, id: number }, cityIdx: number) => (
                            <Listbox.Option
                              key={cityIdx}
                              onClick={((event) => { })}
                              className={({ active }) =>
                                `relative cursor-default select-none py-2 pl-10 pr-4 ${city.id === 0 && 'hidden'} ${active ? 'bg-blue-600 text-white' : 'text-gray-900'
                                }`
                              }
                              value={city}
                            >
                              {({ selected }) => (
                                <>
                                  <span
                                    className={`block truncate ${city.id === 0 && 'text-gray-500'} ${selected ? 'font-medium' : 'font-normal'
                                      }`}
                                  >
                                    {city.name}
                                  </span>
                                  {selected ? (
                                    <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-indigo-600">
                                      <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                    </span>
                                  ) : null}
                                </>
                              )}
                            </Listbox.Option>
                          ))}
                        </Listbox.Options>
                      </Transition>
                    </div>
                  </Listbox>
                </div>
                {/* Direccion */}
                <label className=" text-sm font-medium text-gray-700">
                  <span className="capitalize">Dirección</span>
                  <input
                    type="text"
                    value={eyesState.address}
                    onChange={(event) => { setEyesState({ address: event.target.value }) }}
                    className="h-12 mt-2 w-full rounded-lg border-gray-200 border-[1px] p-3 text-sm"
                  />
                </label>
                <div className="col-span-full flex items-center justify-end mt-5 gap-2">
                  <button type="button" onClick={(event) => {
                    cancelOrder(event);
                  }} className="group flex items-center justify-center gap-2 rounded-lg border border-red-500 bg-red-500 px-3 py-2 transition-colors hover:bg-transparent focus:outline-none focus:ring">
                    <span className="capitalize text-sm font-medium text-white transition-colors group-hover:text-red-500 group-active:text-red-500">
                      cancelar
                    </span>
                    <span className="flex-shrink-0 rounded-full border border-current bg-white p-2 text-red-500 group-active:text-red-500">
                      <svg className="h-3 w-3" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M18 6L6 18" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                        <path d="M6 6L18 18" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                      </svg>
                    </span>
                  </button>
                  <button onClick={(event) => { goToOrderStep2(event) }} className="group flex items-center justify-center gap-2 rounded-lg border border-green-500 bg-green-500 px-3 py-2 transition-colors hover:bg-transparent focus:outline-none focus:ring">
                    <span className="capitalize text-sm font-medium text-white transition-colors group-hover:text-green-500 group-active:text-green-500">
                      siguiente
                    </span>
                    <span className="flex-shrink-0 rounded-full border border-current bg-white p-2 text-green-500 group-active:text-green-500">
                      <svg className="h-3 w-3" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path className="fill-green-500" strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M17 8l4 4m0 0l-4 4m4-4H3" />
                      </svg>
                    </span>
                  </button>
                </div>
              </div>
            }
            {state.orderStep === 2 && state.waitingForResponse === false &&
              <div ref={eyesDiv} className="bg-white p-4 md:p-10 z-40 rounded-lg grid grid-cols-1 md:grid-cols-1 mx-5 gap-x-6 gap-y-3 max-h-[95vh] w-full max-w-[800px] overflow-y-auto">
                {parseInt(eyesState.type_order) === 2 &&
                  <div className="flex flex-col gap-y-3">
                    <div className="">
                      <p className="font-medium mt-5 text-slate-600 text-center border-b-[1px] pb-3 border-slate-200">Caja de prueba</p>
                    </div>
                    <div>
                      <span className="first-letter:capitalize">Nombre</span>
                      <Listbox value={testBoxSelected} onChange={setTestBoxSelected}>
                        <div className="relative">
                          <Listbox.Button className="relative h-12 mt-2 w-full rounded-lg border-gray-200 border-[1px] p-3 text-sm text-left focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-sm">
                            <span className={`block truncate ${testBoxSelected.id_box === '0' && "text-gray-500"}`}>{testBoxSelected.box}</span>
                            <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                              <ChevronUpDownIcon
                                className="h-5 w-5 text-gray-400"
                                aria-hidden="true"
                              />
                            </span>
                          </Listbox.Button>
                          <Transition
                            as={Fragment}
                            leave="transition ease-in duration-100"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                          >
                            <Listbox.Options className="z-40 absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                              {testBoxes.map((item: { box: string }, boxIdx: number) => (
                                <Listbox.Option
                                  key={boxIdx}
                                  className={({ active }) =>
                                    `relative cursor-default select-none py-2 pl-10 pr-4 ${active ? 'bg-blue-600 text-white' : 'text-gray-900'
                                    }`
                                  }
                                  value={item}
                                >
                                  {({ selected }) => (
                                    <>
                                      <span
                                        className={`block truncate ${selected ? 'font-medium' : 'font-normal'
                                          }`}
                                      >
                                        {item.box}
                                      </span>
                                      {selected ? (
                                        <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-indigo-600">
                                          <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                        </span>
                                      ) : null}
                                    </>
                                  )}
                                </Listbox.Option>
                              ))}
                            </Listbox.Options>
                          </Transition>
                        </div>
                      </Listbox>
                    </div>
                    <p>{testBoxSelected.qty > 0 ? `Cantidad: ${testBoxSelected.qty}` : ''}</p>
                    <EyesInput title='observaciones' type="textarea" value={eyesState.left_observaciones} onWrite={(event) => { setEyesState({ left_observaciones: event.target.value }) }} />
                    <div className="col-span-full flex items-center justify-end mt-5 gap-2">
                      <button type="button" onClick={() => {
                        setState({ orderStep: 1 })
                      }} className="group flex items-center justify-center gap-2 rounded-lg border border-gray-400 bg-gray-400 px-3 py-2 transition-colors hover:bg-transparent focus:outline-none focus:ring">
                        <span className="capitalize text-sm font-medium text-white transition-colors group-hover:text-gray-400 group-active:text-gray-400">
                          volver
                        </span>
                        <span className="flex-shrink-0 rounded-full border border-current bg-white p-2 text-gray-400 group-active:text-gray-400">
                          <svg className="h-3 w-3 rotate-180" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path className="fill-green-500" strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M17 8l4 4m0 0l-4 4m4-4H3" />
                          </svg>
                        </span>
                      </button>
                      <button onClick={(event) => { sendTestBox(event) }} className="group flex items-center justify-center gap-2 rounded-lg border border-green-500 bg-green-500 px-3 py-2 transition-colors hover:bg-transparent focus:outline-none focus:ring">
                        <span className="capitalize text-sm font-medium text-white transition-colors group-hover:text-green-500 group-active:text-green-500">
                          confirmar
                        </span>
                        <span className="flex-shrink-0 rounded-full border border-current bg-white p-2 text-green-500 group-active:text-green-500">
                          <svg className="h-3 w-3" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path className="fill-green-500" strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M17 8l4 4m0 0l-4 4m4-4H3" />
                          </svg>
                        </span>
                      </button>
                    </div>
                  </div>
                }
                {parseInt(eyesState.type_order) === 3 &&
                  <div className="flex flex-col gap-y-3">
                    <div className="">
                      <p className="font-medium mt-5 text-slate-600 text-center border-b-[1px] pb-3 border-slate-200">Accesorios</p>
                    </div>
                    <div>
                      <span className="first-letter:capitalize">Nombre</span>
                      <Listbox value={accessoriesSelected} onChange={setAccessoriesSelected}>
                        <div className="relative">
                          <Listbox.Button className="relative h-12 mt-2 w-full rounded-lg border-gray-200 border-[1px] p-3 text-sm text-left focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-sm">
                            <span className={`block truncate ${accessoriesSelected.id_acc === '0' && "text-gray-500"}`}>{accessoriesSelected.acc}</span>
                            <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                              <ChevronUpDownIcon
                                className="h-5 w-5 text-gray-400"
                                aria-hidden="true"
                              />
                            </span>
                          </Listbox.Button>
                          <Transition
                            as={Fragment}
                            leave="transition ease-in duration-100"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                          >
                            <Listbox.Options className="z-40 absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                              {accessories.map((item: { acc: string }, accIdx: number) => (
                                <Listbox.Option
                                  key={accIdx}
                                  className={({ active }) =>
                                    `relative cursor-default select-none py-2 pl-10 pr-4 ${active ? 'bg-blue-600 text-white' : 'text-gray-900'
                                    }`
                                  }
                                  value={item}
                                >
                                  {({ selected }) => (
                                    <>
                                      <span
                                        className={`block truncate ${selected ? 'font-medium' : 'font-normal'
                                          }`}
                                      >
                                        {item.acc}
                                      </span>
                                      {selected ? (
                                        <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-indigo-600">
                                          <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                        </span>
                                      ) : null}
                                    </>
                                  )}
                                </Listbox.Option>
                              ))}
                            </Listbox.Options>
                          </Transition>
                        </div>
                      </Listbox>
                    </div>
                    <EyesInput title='cantidad' value={eyesState.left_cantidad} onWrite={(event) => { setEyesState({ left_cantidad: event.target.value }) }} />
                    <div className="col-span-full flex items-center justify-end mt-5 gap-2">
                      <button type="button" onClick={() => {
                        setState({ orderStep: 1 })
                      }} className="group flex items-center justify-center gap-2 rounded-lg border border-gray-400 bg-gray-400 px-3 py-2 transition-colors hover:bg-transparent focus:outline-none focus:ring">
                        <span className="capitalize text-sm font-medium text-white transition-colors group-hover:text-gray-400 group-active:text-gray-400">
                          volver
                        </span>
                        <span className="flex-shrink-0 rounded-full border border-current bg-white p-2 text-gray-400 group-active:text-gray-400">
                          <svg className="h-3 w-3 rotate-180" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path className="fill-green-500" strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M17 8l4 4m0 0l-4 4m4-4H3" />
                          </svg>
                        </span>
                      </button>
                      <button onClick={(event) => { sendAccessories(event) }} className="group flex items-center justify-center gap-2 rounded-lg border border-green-500 bg-green-500 px-3 py-2 transition-colors hover:bg-transparent focus:outline-none focus:ring">
                        <span className="capitalize text-sm font-medium text-white transition-colors group-hover:text-green-500 group-active:text-green-500">
                          confirmar
                        </span>
                        <span className="flex-shrink-0 rounded-full border border-current bg-white p-2 text-green-500 group-active:text-green-500">
                          <svg className="h-3 w-3" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path className="fill-green-500" strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M17 8l4 4m0 0l-4 4m4-4H3" />
                          </svg>
                        </span>
                      </button>
                    </div>
                  </div>
                }
                {state.showLeftEyeForm == true && parseInt(eyesState.type_order) === 1 &&
                  <>
                    <div className="flex flex-col gap-y-3">
                      <div className="">
                        <p className="font-medium mt-5 text-slate-600 text-center border-b-[1px] pb-3 border-slate-200">Ojo izquierdo</p>
                      </div>
                      <div>
                        <span className="first-letter:capitalize">Tipo de lente</span>
                        <Listbox value={leftLensTypeSelected} onChange={setLeftLensTypesSelected}>
                          <div className="relative">
                            <Listbox.Button className="relative h-12 mt-2 w-full rounded-lg border-gray-200 border-[1px] p-3 text-sm text-left focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-sm">
                              <span className={`block truncate ${leftLensTypeSelected.id_typeLens === '0' && "text-gray-500"}`}>{leftLensTypeSelected.name_typeLens}</span>
                              <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                                <ChevronUpDownIcon
                                  className="h-5 w-5 text-gray-400"
                                  aria-hidden="true"
                                />
                              </span>
                            </Listbox.Button>
                            <Transition
                              as={Fragment}
                              leave="transition ease-in duration-100"
                              leaveFrom="opacity-100"
                              leaveTo="opacity-0"
                            >
                              <Listbox.Options className="z-40 absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                {lensTypes.map((lens: { name_typeLens: string }, lensTypesIdx: number) => (
                                  <Listbox.Option
                                    key={lensTypesIdx}
                                    onClick={() => { setLeftReferenceSelected(lensReferences[0]); setLeftColorLens(lensColors[0]) }}
                                    className={({ active }) =>
                                      `relative cursor-default select-none py-2 pl-10 pr-4 ${active ? 'bg-blue-600 text-white' : 'text-gray-900'
                                      }`
                                    }
                                    value={lens}
                                  >
                                    {({ selected }) => (
                                      <>
                                        <span
                                          className={`block truncate ${selected ? 'font-medium' : 'font-normal'
                                            }`}
                                        >
                                          {lens.name_typeLens}
                                        </span>
                                        {selected ? (
                                          <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-indigo-600">
                                            <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                          </span>
                                        ) : null}
                                      </>
                                    )}
                                  </Listbox.Option>
                                ))}
                              </Listbox.Options>
                            </Transition>
                          </div>
                        </Listbox>
                      </div>
                      <div>
                        <span className="first-letter:capitalize">Referencia</span>
                        <Listbox value={leftReferenceSelected} onChange={setLeftReferenceSelected}>
                          <div className="relative">
                            <Listbox.Button className="relative h-12 mt-2 w-full rounded-lg border-gray-200 border-[1px] p-3 text-sm text-left focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-sm">
                              <span className={`block truncate ${leftReferenceSelected.id_typeLens === '0' && "text-gray-500"}`}>{leftReferenceSelected.name_lens}</span>
                              <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                                <ChevronUpDownIcon
                                  className="h-5 w-5 text-gray-400"
                                  aria-hidden="true"
                                />
                              </span>
                            </Listbox.Button>
                            <Transition
                              as={Fragment}
                              leave="transition ease-in duration-100"
                              leaveFrom="opacity-100"
                              leaveTo="opacity-0"
                            >
                              <Listbox.Options className="z-40 absolute mt-1 max-h-60 w-full overflow-y-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                {lensReferences.map((lens: { id_lens: string, name_lens: string, id_typeLens: string }, lensIdx: number) => {
                                  if (lens.id_typeLens === leftLensTypeSelected.id_typeLens) {
                                    return (
                                      <Listbox.Option
                                        key={lensIdx}
                                        onClick={() => { calcColorLength(lens, 'left'); resetInputs('left'); }}
                                        className={({ active }) =>
                                          `relative cursor-default select-none py-2 pl-10 pr-4 ${active ? 'bg-blue-600 text-white' : 'text-gray-900'
                                          }`
                                        }
                                        value={lens}
                                      >
                                        {({ selected }) => (
                                          <>
                                            <span
                                              className={`block truncate ${selected ? 'font-medium' : 'font-normal'
                                                }`}
                                            >
                                              {lens.name_lens}
                                            </span>
                                            {selected ? (
                                              <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-indigo-600">
                                                <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                              </span>
                                            ) : null}
                                          </>
                                        )}
                                      </Listbox.Option>
                                    )
                                  } else {
                                    return null;
                                  }
                                })}
                              </Listbox.Options>
                            </Transition>
                          </div>
                        </Listbox>
                      </div>
                      {leftInputs.includes('1') &&
                        <div>
                          <span className="first-letter:capitalize">Color</span>
                          <Listbox value={leftColorLens} onChange={setLeftColorLens}>
                            <div className="relative">
                              <Listbox.Button className="relative h-12 mt-2 w-full rounded-lg border-gray-200 border-[1px] p-3 text-sm text-left focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-sm">
                                <span className={`block truncate ${leftColorLens.id_colorLens === '0' && "text-gray-500"}`}>{colorsLength.left === 0 ? 'No hay colores disponibles' : leftColorLens.name_colorLens}</span>
                                <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                                  <ChevronUpDownIcon
                                    className="h-5 w-5 text-gray-400"
                                    aria-hidden="true"
                                  />
                                </span>
                              </Listbox.Button>
                              <Transition
                                as={Fragment}
                                leave="transition ease-in duration-100"
                                leaveFrom="opacity-100"
                                leaveTo="opacity-0"
                              >
                                <Listbox.Options className="z-40 absolute mt-1 max-h-60 w-full overflow-y-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                  {lensColors.map((lens: { id_colorLens: string, name_colorLens: string, id_lens: string }, lensIdx: number) => {
                                    if (lens.id_lens === leftReferenceSelected.id_lens) {
                                      return (
                                        <Listbox.Option
                                          key={lensIdx}
                                          className={({ active }) =>
                                            `relative cursor-default select-none py-2 pl-10 pr-4 ${active ? 'bg-blue-600 text-white' : 'text-gray-900'
                                            }`
                                          }
                                          value={lens}
                                        >
                                          {({ selected }) => (
                                            <>
                                              <span
                                                className={`block truncate ${selected ? 'font-medium' : 'font-normal'
                                                  }`}
                                              >
                                                {lens.name_colorLens}
                                              </span>
                                              {selected ? (
                                                <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-indigo-600">
                                                  <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                                </span>
                                              ) : null}
                                            </>
                                          )}
                                        </Listbox.Option>
                                      )
                                    } else {
                                      return null;
                                    }
                                  })}
                                </Listbox.Options>
                              </Transition>
                            </div>
                          </Listbox>
                        </div>
                      }
                      {leftInputs.includes('2') &&
                        <div>
                          <span className="first-letter:capitalize">Diseño</span>
                          <Listbox value={leftDesignSelected} onChange={setLeftDesignSelected}>
                            <div className="relative">
                              <Listbox.Button className="relative h-12 mt-2 w-full rounded-lg border-gray-200 border-[1px] p-3 text-sm text-left focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-sm">
                                <span className={`block truncate ${leftDesignSelected.id_design === '0' && "text-gray-500"}`}>{leftDesignSelected.design_name}</span>
                                <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                                  <ChevronUpDownIcon
                                    className="h-5 w-5 text-gray-400"
                                    aria-hidden="true"
                                  />
                                </span>
                              </Listbox.Button>
                              <Transition
                                as={Fragment}
                                leave="transition ease-in duration-100"
                                leaveFrom="opacity-100"
                                leaveTo="opacity-0"
                              >
                                <Listbox.Options className="z-40 absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                  {lensDesign.map((lens: { design_name: string }, lensDesignIdx: number) => (
                                    <Listbox.Option
                                      key={lensDesignIdx}
                                      onClick={() => { setLeftDesignSelected(lensDesign[0]) }}
                                      className={({ active }) =>
                                        `relative cursor-default select-none py-2 pl-10 pr-4 ${active ? 'bg-blue-600 text-white' : 'text-gray-900'
                                        }`
                                      }
                                      value={lens}
                                    >
                                      {({ selected }) => (
                                        <>
                                          <span
                                            className={`block truncate ${selected ? 'font-medium' : 'font-normal'
                                              }`}
                                          >
                                            {lens.design_name}
                                          </span>
                                          {selected ? (
                                            <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-indigo-600">
                                              <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                            </span>
                                          ) : null}
                                        </>
                                      )}
                                    </Listbox.Option>
                                  ))}
                                </Listbox.Options>
                              </Transition>
                            </div>
                          </Listbox>
                        </div>
                      }
                      {parseInt(leftDesignSelected.id_design) === 3 &&
                        <EyesInput title='Diseño Z.O' value={eyesState.left_dZO} onWrite={(event) => { setEyesState({ left_dZO: event.target.value }) }} />
                      }
                      {parseInt(leftDesignSelected.id_design) === 3 || parseInt(leftDesignSelected.id_design) === 4 ?
                        <EyesInput title='Diseño Segunda Curva' value={eyesState.left_dSegundaCurva} onWrite={(event) => { setEyesState({ left_dSegundaCurva: event.target.value }) }} />
                        : <></>
                      }
                      {leftInputs.includes('3') &&
                        <EyesInput title='cantidad' value={eyesState.left_cantidad} onWrite={(event) => { setEyesState({ left_cantidad: event.target.value }) }} />
                      }
                      {leftInputs.includes('4') &&
                        <EyesInput title='c.base' value={eyesState.left_c_base} onWrite={(event) => { setEyesState({ left_c_base: event.target.value }) }} />
                      }

                      {leftInputs.includes('5') &&
                        <EyesInput title='poder' value={eyesState.left_poder} onWrite={(event) => { setEyesState({ left_poder: event.target.value }) }} />
                      }
                      {leftInputs.includes('6') &&
                        <EyesInput title='diametro' value={eyesState.left_diametro} onWrite={(event) => { setEyesState({ left_diametro: event.target.value }) }} />
                      }
                      {leftInputs.includes('7') &&
                        <EyesInput title='Z.O' value={eyesState.left_ZO} onWrite={(event) => { setEyesState({ left_ZO: event.target.value }) }} />
                      }
                      {leftInputs.includes('8') &&
                        <EyesInput title='Adicion' value={eyesState.left_adicion} onWrite={(event) => { setEyesState({ left_adicion: event.target.value }) }} />
                      }
                      {leftInputs.includes('9') &&
                        <EyesInput title='cilindro' value={eyesState.left_cilindro} onWrite={(event) => { setEyesState({ left_cilindro: event.target.value }) }} />
                      }
                      {leftInputs.includes('10') &&
                        <EyesInput title='grados' value={eyesState.left_grados} onWrite={(event) => { setEyesState({ left_grados: event.target.value }) }} />
                      }
                      {leftInputs.includes('11') &&
                        <EyesInput title='Periferia 1' value={eyesState.left_periferia1} onWrite={(event) => { setEyesState({ left_periferia1: event.target.value }) }} />
                      }
                      {leftInputs.includes('12') &&
                        <EyesInput title='Periferia 2' value={eyesState.left_periferia2} onWrite={(event) => { setEyesState({ left_periferia2: event.target.value }) }} />
                      }
                      {leftInputs.includes('13') &&
                        <EyesInput title='cpp' value={eyesState.left_cpp} onWrite={(event) => { setEyesState({ left_cpp: event.target.value }) }} />
                      }
                      {leftInputs.includes('14') &&
                        <EyesInput title='observaciones' type="textarea" value={eyesState.left_observaciones} onWrite={(event) => { setEyesState({ left_observaciones: event.target.value }) }} />
                      }
                      {leftInputs.includes('15') &&
                        <EyesInput title='Curva Invertida' value={eyesState.left_cInvertida} onWrite={(event) => { setEyesState({ left_cInvertida: event.target.value }) }} />
                      }
                      {leftInputs.includes('16') &&
                        <EyesInput title='Curva de Alineamiento' value={eyesState.left_cAlineamiento} onWrite={(event) => { setEyesState({ left_cAlineamiento: event.target.value }) }} />
                      }
                      {leftInputs.includes('17') &&
                        <EyesInput title='Curva de Apertura' value={eyesState.left_cApertura} onWrite={(event) => { setEyesState({ left_cApertura: event.target.value }) }} />
                      }
                      {leftInputs.includes('18') &&
                        <EyesInput title='Z.O Curva Base' value={eyesState.left_ZOcBase} onWrite={(event) => { setEyesState({ left_ZOcBase: event.target.value }) }} />
                      }
                      {leftInputs.includes('19') &&
                        <EyesInput title='Z.O Curva Invertida' value={eyesState.left_ZOcInvertida} onWrite={(event) => { setEyesState({ left_ZOcInvertida: event.target.value }) }} />
                      }
                      {leftInputs.includes('20') &&
                        <EyesInput title='Z.O Curva de Alineamiento' value={eyesState.left_ZOcAlineamiento} onWrite={(event) => { setEyesState({ left_ZOcAlineamiento: event.target.value }) }} />
                      }
                      {leftInputs.includes('21') &&
                        <EyesInput title='Z.O Curva de Apertura' value={eyesState.left_ZOcApertura} onWrite={(event) => { setEyesState({ left_ZOcApertura: event.target.value }) }} />
                      }
                    </div>
                    <div className="col-span-full flex items-center justify-end mt-5 gap-2">
                      <button type="button" onClick={() => { setState({ orderStep: 1, showLeftEyeForm: false, showRightEyeForm: false, }) }} className="group flex items-center justify-center gap-2 rounded-lg border border-gray-400 bg-gray-400 px-3 py-2 transition-colors hover:bg-transparent focus:outline-none focus:ring">
                        <span className="capitalize text-sm font-medium text-white transition-colors group-hover:text-gray-400 group-active:text-gray-400">
                          volver
                        </span>
                        <span className="flex-shrink-0 rounded-full border border-current bg-white p-2 text-gray-400 group-active:text-gray-400">
                          <svg className="h-3 w-3 rotate-180" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path className="fill-green-500" strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M17 8l4 4m0 0l-4 4m4-4H3" />
                          </svg>
                        </span>
                      </button>
                      <button onClick={(event) => { validateLeftEye(event) }} className="group flex items-center justify-center gap-2 rounded-lg border border-green-500 bg-green-500 px-3 py-2 transition-colors hover:bg-transparent focus:outline-none focus:ring">
                        <span className="capitalize text-sm font-medium text-white transition-colors group-hover:text-green-500 group-active:text-green-500">
                          siguiente
                        </span>
                        <span className="flex-shrink-0 rounded-full border border-current bg-white p-2 text-green-500 group-active:text-green-500">
                          <svg className="h-3 w-3" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path className="fill-green-500" strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M17 8l4 4m0 0l-4 4m4-4H3" />
                          </svg>
                        </span>
                      </button>
                    </div>
                  </>
                }
                {state.showRightEyeForm == true && parseInt(eyesState.type_order) === 1 &&
                  <>
                    <div className="flex flex-col gap-y-3">
                      <div className="">
                        <p className="font-medium mt-5 text-slate-600 text-center border-b-[1px] pb-3 border-slate-200">Ojo derecho</p>
                      </div>
                      <div>
                        <span className="first-letter:capitalize">Tipo de lente</span>
                        <Listbox value={rightLensTypeSelected} onChange={setRightLensTypesSelected}>
                          <div className="relative">
                            <Listbox.Button className="relative h-12 mt-2 w-full rounded-lg border-gray-200 border-[1px] p-3 text-sm text-left focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-sm">
                              <span className={`block truncate ${rightLensTypeSelected.id_typeLens === '0' && "text-gray-500"}`}>{rightLensTypeSelected.name_typeLens}</span>
                              <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                                <ChevronUpDownIcon
                                  className="h-5 w-5 text-gray-400"
                                  aria-hidden="true"
                                />
                              </span>
                            </Listbox.Button>
                            <Transition
                              as={Fragment}
                              leave="transition ease-in duration-100"
                              leaveFrom="opacity-100"
                              leaveTo="opacity-0"
                            >
                              <Listbox.Options className="z-40 absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                {lensTypes.map((lens: { name_typeLens: string }, lensTypesIdx: number) => (
                                  <Listbox.Option
                                    onClick={() => { setRightReferenceSelected(lensReferences[0]); setRightColorLens(lensColors[0]) }}
                                    key={lensTypesIdx}
                                    className={({ active }) =>
                                      `relative cursor-default select-none py-2 pl-10 pr-4 ${active ? 'bg-blue-600 text-white' : 'text-gray-900'
                                      }`
                                    }
                                    value={lens}
                                  >
                                    {({ selected }) => (
                                      <>
                                        <span
                                          className={`block truncate ${selected ? 'font-medium' : 'font-normal'
                                            }`}
                                        >
                                          {lens.name_typeLens}
                                        </span>
                                        {selected ? (
                                          <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-indigo-600">
                                            <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                          </span>
                                        ) : null}
                                      </>
                                    )}
                                  </Listbox.Option>
                                ))}
                              </Listbox.Options>
                            </Transition>
                          </div>
                        </Listbox>
                      </div>
                      <div>
                        <span className="first-letter:capitalize">Referencia</span>
                        <Listbox value={rightReferenceSelected} onChange={setRightReferenceSelected}>
                          <div className="relative">
                            <Listbox.Button className="relative h-12 mt-2 w-full rounded-lg border-gray-200 border-[1px] p-3 text-sm text-left focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-sm">
                              <span className={`block truncate ${rightReferenceSelected.id_typeLens === '0' && "text-gray-500"}`}>{rightReferenceSelected.name_lens}</span>
                              <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                                <ChevronUpDownIcon
                                  className="h-5 w-5 text-gray-400"
                                  aria-hidden="true"
                                />
                              </span>
                            </Listbox.Button>
                            <Transition
                              as={Fragment}
                              leave="transition ease-in duration-100"
                              leaveFrom="opacity-100"
                              leaveTo="opacity-0"
                            >
                              <Listbox.Options className="z-40 absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                {lensReferences.map((lens: { id_lens: string, name_lens: string, id_typeLens: string }, lensIdx: number) => {
                                  if (lens.id_typeLens === rightLensTypeSelected.id_typeLens) {
                                    return (
                                      <Listbox.Option
                                        key={lensIdx}
                                        onClick={() => { calcColorLength(lens, 'right'); resetInputs('right'); }}
                                        className={({ active }) =>
                                          `relative cursor-default select-none py-2 pl-10 pr-4 ${active ? 'bg-blue-600 text-white' : 'text-gray-900'
                                          }`
                                        }
                                        value={lens}
                                      >
                                        {({ selected }) => (
                                          <>
                                            <span
                                              className={`block truncate ${selected ? 'font-medium' : 'font-normal'
                                                }`}
                                            >
                                              {lens.name_lens}
                                            </span>
                                            {selected ? (
                                              <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-indigo-600">
                                                <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                              </span>
                                            ) : null}
                                          </>
                                        )}
                                      </Listbox.Option>
                                    )
                                  }
                                })}
                              </Listbox.Options>
                            </Transition>
                          </div>
                        </Listbox>
                      </div>
                      {rightInputs.includes('1') &&
                        <div>
                          <span className="first-letter:capitalize">Color</span>
                          <Listbox value={rightColorLens} onChange={setRightColorLens}>
                            <div className="relative">
                              <Listbox.Button className="relative h-12 mt-2 w-full rounded-lg border-gray-200 border-[1px] p-3 text-sm text-left focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-sm">
                                <span className={`block truncate ${rightColorLens.id_colorLens === '0' && "text-gray-500"}`}>{colorsLength.right === 0 ? 'No hay colores disponibles' : rightColorLens.name_colorLens}</span>
                                <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                                  <ChevronUpDownIcon
                                    className="h-5 w-5 text-gray-400"
                                    aria-hidden="true"
                                  />
                                </span>
                              </Listbox.Button>
                              <Transition
                                as={Fragment}
                                leave="transition ease-in duration-100"
                                leaveFrom="opacity-100"
                                leaveTo="opacity-0"
                              >
                                <Listbox.Options className="z-40 absolute mt-1 max-h-60 w-full overflow-y-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                  {lensColors.map((lens: { id_colorLens: string, name_colorLens: string, id_lens: string }, lensIdx: number) => {
                                    if (lens.id_lens === rightReferenceSelected.id_lens) {
                                      return (
                                        <Listbox.Option
                                          key={lensIdx}
                                          className={({ active }) =>
                                            `relative cursor-default select-none py-2 pl-10 pr-4 ${active ? 'bg-blue-600 text-white' : 'text-gray-900'
                                            }`
                                          }
                                          value={lens}
                                        >
                                          {({ selected }) => (
                                            <>
                                              <span
                                                className={`block truncate ${selected ? 'font-medium' : 'font-normal'
                                                  }`}
                                              >
                                                {lens.name_colorLens}
                                              </span>
                                              {selected ? (
                                                <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-indigo-600">
                                                  <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                                </span>
                                              ) : null}
                                            </>
                                          )}
                                        </Listbox.Option>
                                      )
                                    } else {
                                      return null;
                                    }
                                  })}
                                </Listbox.Options>
                              </Transition>
                            </div>
                          </Listbox>
                        </div>
                      }
                      {rightInputs.includes('2') &&
                        // <EyesInput title='diseño' value={eyesState.right_design} onWrite={(event) => { setEyesState({ right_design: event.target.value }) }} />
                        <div>
                          <span className="first-letter:capitalize">Diseño</span>
                          <Listbox value={rightDesignSelected} onChange={setRightDesignSelected}>
                            <div className="relative">
                              <Listbox.Button className="relative h-12 mt-2 w-full rounded-lg border-gray-200 border-[1px] p-3 text-sm text-left focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-sm">
                                <span className={`block truncate ${rightDesignSelected.id_design === '0' && "text-gray-500"}`}>{rightDesignSelected.design_name}</span>
                                <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                                  <ChevronUpDownIcon
                                    className="h-5 w-5 text-gray-400"
                                    aria-hidden="true"
                                  />
                                </span>
                              </Listbox.Button>
                              <Transition
                                as={Fragment}
                                leave="transition ease-in duration-100"
                                leaveFrom="opacity-100"
                                leaveTo="opacity-0"
                              >
                                <Listbox.Options className="z-40 absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                  {lensDesign.map((lens: { design_name: string }, lensDesignIdx: number) => (
                                    <Listbox.Option
                                      key={lensDesignIdx}
                                      onClick={() => { setRightDesignSelected(lensDesign[0]) }}
                                      className={({ active }) =>
                                        `relative cursor-default select-none py-2 pl-10 pr-4 ${active ? 'bg-blue-600 text-white' : 'text-gray-900'
                                        }`
                                      }
                                      value={lens}
                                    >
                                      {({ selected }) => (
                                        <>
                                          <span
                                            className={`block truncate ${selected ? 'font-medium' : 'font-normal'
                                              }`}
                                          >
                                            {lens.design_name}
                                          </span>
                                          {selected ? (
                                            <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-indigo-600">
                                              <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                            </span>
                                          ) : null}
                                        </>
                                      )}
                                    </Listbox.Option>
                                  ))}
                                </Listbox.Options>
                              </Transition>
                            </div>
                          </Listbox>
                        </div>
                      }
                      {parseInt(rightDesignSelected.id_design) === 3 &&
                        <EyesInput title='Diseño Z.O' value={eyesState.right_dZO} onWrite={(event) => { setEyesState({ right_dZO: event.target.value }) }} />
                      }
                      {parseInt(rightDesignSelected.id_design) === 3 || parseInt(rightDesignSelected.id_design) === 4 ?
                        <EyesInput title='Diseño Segunda Curva' value={eyesState.right_dSegundaCurva} onWrite={(event) => { setEyesState({ right_dSegundaCurva: event.target.value }) }} />
                        : <></>
                      }
                      {rightInputs.includes('3') &&
                        <EyesInput title='cantidad' value={eyesState.right_cantidad} onWrite={(event) => { setEyesState({ right_cantidad: event.target.value }) }} />
                      }
                      {rightInputs.includes('4') &&
                        <EyesInput title='c.base' value={eyesState.right_c_base} onWrite={(event) => { setEyesState({ right_c_base: event.target.value }) }} />
                      }
                      {rightInputs.includes('5') &&
                        <EyesInput title='poder' value={eyesState.right_poder} onWrite={(event) => { setEyesState({ right_poder: event.target.value }) }} />
                      }
                      {rightInputs.includes('6') &&
                        <EyesInput title='diametro' value={eyesState.right_diametro} onWrite={(event) => { setEyesState({ right_diametro: event.target.value }) }} />

                      }
                      {rightInputs.includes('7') &&
                        <EyesInput title='Z.O' value={eyesState.right_ZO} onWrite={(event) => { setEyesState({ right_ZO: event.target.value }) }} />
                      }
                      {rightInputs.includes('8') &&
                        <EyesInput title='Adicion' value={eyesState.right_adicion} onWrite={(event) => { setEyesState({ right_adicion: event.target.value }) }} />
                      }
                      {rightInputs.includes('9') &&
                        <EyesInput title='cilindro' value={eyesState.right_cilindro} onWrite={(event) => { setEyesState({ right_cilindro: event.target.value }) }} />
                      }
                      {rightInputs.includes('10') &&
                        <EyesInput title='grados' value={eyesState.right_grados} onWrite={(event) => { setEyesState({ right_grados: event.target.value }) }} />
                      }
                      {rightInputs.includes('11') &&
                        <EyesInput title='Periferia 1' value={eyesState.right_periferia1} onWrite={(event) => { setEyesState({ right_periferia1: event.target.value }) }} />
                      }
                      {rightInputs.includes('12') &&
                        <EyesInput title='Periferia 2' value={eyesState.right_periferia2} onWrite={(event) => { setEyesState({ right_periferia2: event.target.value }) }} />
                      }
                      {/* ewe */}
                      {rightInputs.includes('13') &&
                        <EyesInput title='cpp' value={eyesState.right_cpp} onWrite={(event) => { setEyesState({ right_cpp: event.target.value }) }} />
                      }
                      {rightInputs.includes('14') &&
                        <EyesInput title='observaciones' type="textarea" value={eyesState.right_observaciones} onWrite={(event) => { setEyesState({ right_observaciones: event.target.value }) }} />
                      }
                      {rightInputs.includes('15') &&
                        <EyesInput title='Curva Invertida' value={eyesState.right_cInvertida} onWrite={(event) => { setEyesState({ right_cInvertida: event.target.value }) }} />
                      }
                      {rightInputs.includes('16') &&
                        <EyesInput title='Curva de Alineamiento' value={eyesState.right_cAlineamiento} onWrite={(event) => { setEyesState({ right_cAlineamiento: event.target.value }) }} />
                      }
                      {rightInputs.includes('17') &&
                        <EyesInput title='Curva de Apertura' value={eyesState.right_cApertura} onWrite={(event) => { setEyesState({ right_cApertura: event.target.value }) }} />
                      }

                      {rightInputs.includes('18') &&
                        <EyesInput title='Z.O Curva Base' value={eyesState.right_ZOcBase} onWrite={(event) => { setEyesState({ right_ZOcBase: event.target.value }) }} />
                      }
                      {rightInputs.includes('19') &&
                        <EyesInput title='Z.O Curva Invertida' value={eyesState.right_ZOcInvertida} onWrite={(event) => { setEyesState({ right_ZOcInvertida: event.target.value }) }} />
                      }
                      {rightInputs.includes('20') &&
                        <EyesInput title='Z.O Curva de Alineamiento' value={eyesState.right_ZOcAlineamiento} onWrite={(event) => { setEyesState({ right_ZOcAlineamiento: event.target.value }) }} />
                      }
                      {rightInputs.includes('21') &&
                        <EyesInput title='Z.O Curva de Apertura' value={eyesState.right_ZOcApertura} onWrite={(event) => { setEyesState({ right_ZOcApertura: event.target.value }) }} />
                      }
                    </div>
                    <div className="col-span-full flex items-center justify-end mt-5 gap-2">
                      <button type="button" onClick={() => { setState({ showLeftEyeForm: true, showRightEyeForm: false, }) }} className="group flex items-center justify-center gap-2 rounded-lg border border-gray-400 bg-gray-400 px-3 py-2 transition-colors hover:bg-transparent focus:outline-none focus:ring">
                        <span className="capitalize text-sm font-medium text-white transition-colors group-hover:text-gray-400 group-active:text-gray-400">
                          volver
                        </span>
                        <span className="flex-shrink-0 rounded-full border border-current bg-white p-2 text-gray-400 group-active:text-gray-400">
                          <svg className="h-3 w-3 rotate-180" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path className="fill-green-500" strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M17 8l4 4m0 0l-4 4m4-4H3" />
                          </svg>
                        </span>
                      </button>
                      <button onClick={(event) => { goToOrderStep3(event) }} className="group flex items-center justify-center gap-2 rounded-lg border border-green-500 bg-green-500 px-3 py-2 transition-colors hover:bg-transparent focus:outline-none focus:ring">
                        <span className="capitalize text-sm font-medium text-white transition-colors group-hover:text-green-500 group-active:text-green-500">
                          siguiente
                        </span>
                        <span className="flex-shrink-0 rounded-full border border-current bg-white p-2 text-green-500 group-active:text-green-500">
                          <svg className="h-3 w-3" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path className="fill-green-500" strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M17 8l4 4m0 0l-4 4m4-4H3" />
                          </svg>
                        </span>
                      </button>
                    </div>
                  </>
                }
              </div>
            }
            {state.orderStep === 3 && state.waitingForResponse === false &&
              <div className="bg-white p-4 md:p-10 z-40 rounded-lg grid max-w-[1000px] w-full grid-cols-1 md:grid-cols-4 gap-x-4 gap-y-3 max-h-[90vh] overflow-y-auto">
                <div className="flex flex-col gap-y-3 md:col-start-1 md:col-end-6 border-b-[1px] pb-3 border-slate-100">
                  <div className="breal-all">
                    <p className="text-xl mb-2 text-slate-500 font-black">Verifica que los datos de el pedido esten correctos</p>
                    <p className="text-slate-600">Presiona el boton de <strong>Confirmar</strong> para guadar los nuevos cambios de el pedido</p>
                  </div>
                </div>
                <div className="flex flex-col gap-y-3 md:col-start-1 md:col-end-6 border-b-[1px] pb-3 border-slate-200">
                  <div className="break-words">
                    <strong className="mr-2">Nombre de quien realiza el pedido:</strong><span>{eyesState.quienRealizaElPedido}</span>
                  </div>
                  <div className="break-words">
                    <strong className="mr-2">Nombre del paciente:</strong><span>{eyesState.nombreDelPaciente}</span>
                  </div>
                  <div className="break-words">
                    <strong className="mr-2">Numero de documento del paciente:</strong><span>{eyesState.numeroDeDocumentoDelPaciente}</span>
                  </div>
                  <div className="break-words">
                    <strong className="mr-2">Nombre del optometra u oftalmólogo:</strong><span>{eyesState.nombreDelDoctor}</span>
                  </div>
                  <div className="break-words">
                    <strong className="mr-2">Tarjeta profesional:</strong><span>{eyesState.tarjetaProfesional}</span>
                  </div>
                </div>
                <div className="flex flex-col md:col-start-1 md:col-end-3 gap-y-3">
                  <div>
                    <p className="text-slate-600 border-b-[1px] pb-3 border-slate-200 text-center">Ojo izquierdo</p>
                  </div>
                  <div className="break-words">
                    <strong className="mr-2">Tipo de lente:</strong><span>{leftLensTypeSelected.name_typeLens}</span>
                  </div>
                  <div className="break-words">
                    <strong className="mr-2">Referencia:</strong><span>{leftReferenceSelected.name_lens}</span>
                  </div>
                  {leftInputs.includes('1') &&
                    <div className="break-words">
                      <strong className="mr-2">Color:</strong><span>{leftColorLens.name_colorLens === 'Indique el color del lente' ? 'N/A' : leftColorLens.name_colorLens}</span>
                    </div>
                  }
                  {leftInputs.includes('2') &&
                    <div className="break-words">
                      <strong className="mr-2">Diseño:</strong><span>{leftDesignSelected.id_design === '0' ? 'N/A' : leftDesignSelected.design_name}</span>
                    </div>
                  }
                  {leftInputs.includes('2') && parseInt(leftDesignSelected.id_design) === 3 &&
                    <>
                      <div className="break-words">
                        <strong className="mr-2">Z.O de Diseño:</strong><span>{eyesState.left_dZO}</span>
                      </div>
                      <div className="break-words">
                        <strong className="mr-2">Segunda Curva de Diseño:</strong><span>{eyesState.left_dSegundaCurva}</span>
                      </div>
                    </>
                  }
                  {leftInputs.includes('2') && parseInt(leftDesignSelected.id_design) === 4 &&
                    <div className="break-words">
                      <strong className="mr-2">Segunda Curva de Diseño:</strong><span>{eyesState.left_dSegundaCurva}</span>
                    </div>
                  }
                  {leftInputs.includes('3') &&
                    <div className="break-words">
                      <strong className="mr-2">Cantidad:</strong><span>{eyesState.left_cantidad}</span>
                    </div>
                  }
                  {leftInputs.includes('4') &&
                    <div className="break-words">
                      <strong className="mr-2">C.base:</strong><span>{eyesState.left_c_base}</span>
                    </div>
                  }
                  {leftInputs.includes('5') &&
                    <div className="break-words">
                      <strong className="mr-2">poder:</strong><span>{eyesState.left_poder}</span>
                    </div>
                  }
                  {leftInputs.includes('6') &&
                    <div className="break-words">
                      <strong className="mr-2">diametro:</strong><span>{eyesState.left_diametro}</span>
                    </div>
                  }
                  {leftInputs.includes('7') &&
                    <div className="break-words">
                      <strong className="mr-2">Z.O:</strong><span>{eyesState.left_ZO}</span>
                    </div>
                  }
                  {leftInputs.includes('8') &&
                    <div className="break-words">
                      <strong className="mr-2">Adicion:</strong><span>{eyesState.left_adicion}</span>
                    </div>
                  }
                  {leftInputs.includes('9') &&
                    <div className="break-words">
                      <strong className="mr-2">cilindro:</strong><span>{eyesState.left_cilindro}</span>
                    </div>
                  }

                  {leftInputs.includes('10') &&
                    <div className="break-words">
                      <strong className="mr-2">Grados:</strong><span>{eyesState.left_grados}</span>
                    </div>
                  }

                  {leftInputs.includes('11') &&
                    <div className="break-words">
                      <strong className="mr-2">Periferia 1:</strong><span>{eyesState.left_periferia1}</span>
                    </div>
                  }

                  {leftInputs.includes('12') &&
                    <div className="break-words">
                      <strong className="mr-2">Periferia 2:</strong><span>{eyesState.left_periferia2}</span>
                    </div>
                  }

                  {leftInputs.includes('13') &&
                    <div className="break-words">
                      <strong className="mr-2">cpp:</strong><span>{eyesState.left_cpp}</span>
                    </div>
                  }

                  {leftInputs.includes('14') &&
                    <div className="break-words">
                      <strong className="mr-2">Observaciones:</strong><span>{eyesState.left_observaciones}</span>
                    </div>
                  }

                  {leftInputs.includes('15') &&
                    <div className="break-words">
                      <strong className="mr-2">Curva Invertida:</strong><span>{eyesState.left_cInvertida}</span>
                    </div>
                  }

                  {leftInputs.includes('16') &&
                    <div className="break-words">
                      <strong className="mr-2">Curva de Alineamiento:</strong><span>{eyesState.left_cAlineamiento}</span>
                    </div>
                  }

                  {leftInputs.includes('17') &&
                    <div className="break-words">
                      <strong className="mr-2">Curva de Apertura:</strong><span>{eyesState.left_cApertura}</span>
                    </div>
                  }

                  {leftInputs.includes('18') &&
                    <div className="break-words">
                      <strong className="mr-2">Z.O Curva de Base:</strong><span>{eyesState.left_ZOcBase}</span>
                    </div>
                  }

                  {leftInputs.includes('19') &&
                    <div className="break-words">
                      <strong className="mr-2">Z.O Curva Invertidas:</strong><span>{eyesState.left_ZOcInvertida}</span>
                    </div>
                  }

                  {leftInputs.includes('20') &&
                    <div className="break-words">
                      <strong className="mr-2">Z.O Curva de Alineamiento:</strong><span>{eyesState.left_ZOcAlineamiento}</span>
                    </div>
                  }
                  {leftInputs.includes('21') &&
                    <div className="break-words">
                      <strong className="mr-2">Z.O Curva de Apertura:</strong><span>{eyesState.left_ZOcApertura}</span>
                    </div>
                  }
                </div>
                <div className="flex flex-col gap-y-3 md:col-start-3 md:col-end-6">
                  <div>
                    <p className="text-slate-600 border-b-[1px] pb-3 border-slate-200 text-center">Ojo derecho</p>
                  </div>
                  <div className="break-words">
                    <strong className="mr-2">Tipo de lente:</strong><span>{rightLensTypeSelected.name_typeLens}</span>
                  </div>
                  <div className="break-words">
                    <strong className="mr-2">Referencia:</strong><span>{rightReferenceSelected.name_lens}</span>
                  </div>
                  {rightInputs.includes('1') &&
                    <div className="break-words">
                      <strong className="mr-2">Color:</strong><span>{rightColorLens.name_colorLens === 'Indique el color del lente' ? 'N/A' : rightColorLens.name_colorLens}</span>
                    </div>
                  }
                  {rightInputs.includes('2') &&
                    <div className="break-words">
                      <strong className="mr-2">Diseño:</strong><span>{rightDesignSelected.id_design === '0' ? 'N/A' : rightDesignSelected.design_name}</span>
                    </div>
                  }
                  {rightInputs.includes('2') && parseInt(rightDesignSelected.id_design) === 3 &&
                    <>
                      <div className="break-words">
                        <strong className="mr-2">Z.O de Diseño:</strong><span>{eyesState.right_dZO}</span>
                      </div>
                      <div className="break-words">
                        <strong className="mr-2">Segunda Curva de Diseño:</strong><span>{eyesState.right_dSegundaCurva}</span>
                      </div>
                    </>
                  }
                  {rightInputs.includes('2') && parseInt(rightDesignSelected.id_design) === 4 &&
                    <div className="break-words">
                      <strong className="mr-2">Segunda Curva de Diseño:</strong><span>{eyesState.right_dSegundaCurva}</span>
                    </div>
                  }
                  {rightInputs.includes('3') &&
                    <div className="break-words">
                      <strong className="mr-2">Cantidad:</strong><span>{eyesState.right_cantidad}</span>
                    </div>
                  }
                  {rightInputs.includes('4') &&
                    <div className="break-words">
                      <strong className="mr-2">C.base:</strong><span>{eyesState.right_c_base}</span>
                    </div>
                  }
                  {rightInputs.includes('5') &&
                    <div className="break-words">
                      <strong className="mr-2">poder:</strong><span>{eyesState.right_poder}</span>
                    </div>
                  }
                  {rightInputs.includes('6') &&
                    <div className="break-words">
                      <strong className="mr-2">diametro:</strong><span>{eyesState.right_diametro}</span>
                    </div>
                  }
                  {rightInputs.includes('7') &&
                    <div className="break-words">
                      <strong className="mr-2">Z.O:</strong><span>{eyesState.right_ZO}</span>
                    </div>
                  }
                  {rightInputs.includes('8') &&
                    <div className="break-words">
                      <strong className="mr-2">Adicion:</strong><span>{eyesState.right_adicion}</span>
                    </div>
                  }
                  {rightInputs.includes('9') &&
                    <div className="break-words">
                      <strong className="mr-2">cilindro:</strong><span>{eyesState.right_cilindro}</span>
                    </div>
                  }

                  {rightInputs.includes('10') &&
                    <div className="break-words">
                      <strong className="mr-2">Grados:</strong><span>{eyesState.right_grados}</span>
                    </div>
                  }

                  {rightInputs.includes('11') &&
                    <div className="break-words">
                      <strong className="mr-2">Periferia 1:</strong><span>{eyesState.right_periferia1}</span>
                    </div>
                  }

                  {rightInputs.includes('12') &&
                    <div className="break-words">
                      <strong className="mr-2">Periferia 2:</strong><span>{eyesState.right_periferia2}</span>
                    </div>
                  }

                  {rightInputs.includes('13') &&
                    <div className="break-words">
                      <strong className="mr-2">cpp:</strong><span>{eyesState.right_cpp}</span>
                    </div>
                  }

                  {rightInputs.includes('14') &&
                    <div className="break-words">
                      <strong className="mr-2">Observaciones:</strong><span>{eyesState.right_observaciones}</span>
                    </div>
                  }

                  {rightInputs.includes('15') &&
                    <div className="break-words">
                      <strong className="mr-2">Curva Invertida:</strong><span>{eyesState.right_cInvertida}</span>
                    </div>
                  }

                  {rightInputs.includes('16') &&
                    <div className="break-words">
                      <strong className="mr-2">Curva de Alineamiento:</strong><span>{eyesState.right_cAlineamiento}</span>
                    </div>
                  }

                  {rightInputs.includes('17') &&
                    <div className="break-words">
                      <strong className="mr-2">Curva de Apertura:</strong><span>{eyesState.right_cApertura}</span>
                    </div>
                  }

                  {rightInputs.includes('18') &&
                    <div className="break-words">
                      <strong className="mr-2">Z.O Curva de Base:</strong><span>{eyesState.right_ZOcBase}</span>
                    </div>
                  }

                  {rightInputs.includes('19') &&
                    <div className="break-words">
                      <strong className="mr-2">Z.O Curva Invertidas:</strong><span>{eyesState.right_ZOcInvertida}</span>
                    </div>
                  }

                  {rightInputs.includes('20') &&
                    <div className="break-words">
                      <strong className="mr-2">Z.O Curva de Alineamiento:</strong><span>{eyesState.right_ZOcAlineamiento}</span>
                    </div>
                  }
                  {rightInputs.includes('21') &&
                    <div className="break-words">
                      <strong className="mr-2">Z.O Curva de Apertura:</strong><span>{eyesState.right_ZOcApertura}</span>
                    </div>
                  }
                </div>
                <div className="col-span-full flex items-center justify-end mt-5 gap-2">
                  <button type="button" onClick={() => { setState({ orderStep: 2, showLeftEyeForm: false, showRightEyeForm: true, }) }} className="group flex items-center justify-center gap-2 rounded-lg border border-gray-400 bg-gray-400 px-3 py-2 transition-colors hover:bg-transparent focus:outline-none focus:ring">
                    <span className="capitalize text-sm font-medium text-white transition-colors group-hover:text-gray-400 group-active:text-gray-400">
                      volver
                    </span>
                    <span className="flex-shrink-0 rounded-full border border-current bg-white p-2 text-gray-400 group-active:text-gray-400">
                      <svg className="h-3 w-3 rotate-180" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path className="fill-green-500" strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M17 8l4 4m0 0l-4 4m4-4H3" />
                      </svg>
                    </span>
                  </button>
                  <button onClick={(event) => { sendUpdateOrder(event) }} className="group flex items-center justify-center gap-2 rounded-lg border border-green-500 bg-green-500 px-3 py-2 transition-colors hover:bg-transparent focus:outline-none focus:ring">
                    <span className="capitalize text-sm font-medium text-white transition-colors group-hover:text-green-500 group-active:text-green-500">
                      confirmar
                    </span>
                    <span className="flex-shrink-0 rounded-full border border-current bg-white p-2 text-green-500 group-active:text-green-500">
                      <svg className="h-3 w-3" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path className="fill-green-500" strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M17 8l4 4m0 0l-4 4m4-4H3" />
                      </svg>
                    </span>
                  </button>
                </div>
              </div>
            }
            {state.waitingForResponse &&
              <Loader />
            }
          </motion.form>
        </>
      }
    </>
  )
}

export { UpdateOrder };
interface ContainerTypes{
  children:JSX.Element | JSX.Element[];
  className?:string;
}
export function Container({children,className}:ContainerTypes,){
  return(
    <main className={`${className}`}>
      {children}
    </main>
  )
}
const removeAccents = (str: string) => {
  return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
}

const removeAccentsFromObject = (obj: any) => {
  Object.entries(obj).forEach(([key, value]) => {
    if (typeof (value) === 'string' && value && key !== "API_KEY") {
      obj[key] = removeAccents(value)
    }
  });
  return obj
}

export { removeAccents, removeAccentsFromObject }


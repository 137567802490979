import './Loader.scss'
import {motion} from 'framer-motion'
interface LoaderTypes {
  className?: string;
  initial?:{};
  transition?:{};
  animate?:{};
}
export function Loader({ className="",initial="",transition="",animate="" }: LoaderTypes) {
  return (
    <div className='w-full h-full flex justify-center items-center'>
      <motion.div initial={initial} transition={transition} animate={animate} className={`loader ${className}`}>
        <div className="loader-square"></div>
        <div className="loader-square"></div>
        <div className="loader-square"></div>
        <div className="loader-square"></div>
        <div className="loader-square"></div>
        <div className="loader-square"></div>
        <div className="loader-square"></div>
      </motion.div>
    </div>
  )
}

import { useStateMany } from "../customHooks/useStateMany"

const PopUp = ({ message, children,className }: { message: string, children: JSX.Element | JSX.Element[],className?:string }) => {
  const [state, setState] = useStateMany({
    show: false,
    opacity: false,
  })
  const showPopUp = () => {
    setState({ show: true })
    setTimeout(() => {
      setState({ opacity: true })
    }, 200);
  }
  const hiddenPopUp = () => {
    setState({ opacity: false })
    setTimeout(() => {
      setState({ show: false })
    }, 200);
  }
  return (
    <div className={`relative ${className}`} onMouseEnter={() => { showPopUp() }} onMouseLeave={() => { hiddenPopUp() }}>
      {children}
      <div>
        <div style={{ "width": "0", "height": "0", "borderLeft": "7px solid transparent", "borderRight": "7px solid transparent", "top": "calc(100% + 5px)" }} className={`absolute left-[50%] border-blue-500 border-b-[7px] translate-x-[-50%] transition-opacity duration-100 ${state.show ? 'flex' : 'hidden'} ${state.opacity ? 'opacity-100' : 'opacity-0'}`} />
        <div style={{ "top": "calc(100% + 12px)" }} className={`absolute shadow- rounded-lg max-w-[300px] w-max transition-opacity duration-100 px-3 py-2 bg-blue-500 right-0 ${state.show ? 'flex' : 'hidden'} ${state.opacity ? 'opacity-100' : 'opacity-0'}`}>
          <p className="text-white text-sm">
            {message}
          </p>
        </div>
      </div>
    </div>
  )
}

export { PopUp }
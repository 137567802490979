
import { motion } from "framer-motion"
import { useStateMany } from "../customHooks/useStateMany";
import { Modal } from "../modal"
import { Listbox, Transition } from '@headlessui/react'
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid'
import { API } from "../API"
import { Fragment, useState } from 'react'
import { Loader } from "../Loader";
import { ErrorAlert } from "../ErrorAlert";
type userType = {
  id: any;
  name: string;
  email: string;
  permissions: string;
  phone: string;
  city: string;
  address: string;
  customerType: string;
  documentNumber: string;
  professionalCard: string;
  documentType: string;
}
interface ProfilesTypes {
  user: userType;
  updateUser: (value: object) => void;
}
function Security({ user, updateUser }: ProfilesTypes) {
  const [state, setState] = useStateMany({
    editEmail: false,
    showPinInput: false,
    pin: '',
    email: '',
    waitingForResponse: false,
    successAlert: false,
    errorAlert: false,
    errorMessage: '',
    errorTitle: ''
  })

  function sendEmail() {
    setState({
      errorAlert: false,
      errorTitle: '',
      errorMessage: '',
    })
    let dataToSend = {
      API_KEY: process.env.REACT_APP_API_KEY,
      id: parseInt(user.id),
      email: '',
    }
    if (state.email) {
      if (state.email.includes("@")) {
        dataToSend = {
          API_KEY: process.env.REACT_APP_API_KEY,
          id: parseInt(user.id),
          email: state.email,
        }
      } else {
        setState({
          errorAlert: true,
          errorTitle: 'Error: el correo no es valido',
          errorMessage: 'El correo electronico ingresado no es valido, por favor rectifique e intente nuevamente',
        })
        setTimeout(() => {
          setState({
            errorAlert: false,
            errorTitle: '',
            errorMessage: '',
          })
        }, 7000)
        return '';
      }
    } else {
      setState({
        errorAlert: true,
        errorTitle: 'Error: no se permiten campos vacios',
        errorMessage: 'No se permiten campos vacios, por favor rellene los campos para continuar',
      })
      setTimeout(() => {
        setState({
          errorAlert: false,
          errorTitle: '',
          errorMessage: '',
        })
      }, 7000)
      return '';
    }
    setState({ waitingForResponse: true })
    // setTimeout(() => {
    //   setState({ waitingForResponse: false, showPinInput: true })
    //   setTimeout(() => {
    //     setState({ successAlert: false })
    //   }, 3000)
    // }, 5000)
    // console.log(dataToSend, 'informacion enviada')
    API.post('/?updateEmail', {
      ...dataToSend
    }).then((response) => {
      setState({ waitingForResponse: false })
      // console.log(response, 'respuesta de updateEmail')
      if (response.data.success === 1 && response.status === 200) {
        setState({ showPinInput: true })
        updateUser({
          // login: true,
          // user: {
          //   // ...response.data.user,
          //   ...user,
          //   name: response.data.user.name,
          //   phone: response.data.user.phone,
          //   address: response.data.user.address,
          //   city: response.data.user.city,
          //   customerType: response.data.user.customerType,
          //   documentNumber: response.data.user.documentNumber,
          //   professionalCard: response.data.user.professionalCard,
          //   documentType: response.data.user.documentType,
          // }
        })
      } else {
        // error
        setState({
          errorAlert: true,
          errorTitle: 'Error: hubo un error al intentar guardar la informacion',
          errorMessage: 'El servidor respondio de forma inesperada',
        })
        setTimeout(() => {
          setState({
            errorAlert: false,
            errorTitle: '',
            errorMessage: '',
          })
        }, 7000)
      }
    }).catch(function (error) {
      setState({ waitingForResponse: false })
      console.log(error)
      setState({
        errorAlert: true,
        errorTitle: 'Error: No se pudo establecer conexion con el servidor',
        errorMessage: 'error:' + error,
      })
      setTimeout(() => {
        setState({
          errorAlert: false,
          errorTitle: '',
          errorMessage: '',
        })
      }, 7000)
    })
  }

  function validatePin() {
    setState({
      errorAlert: false,
      errorTitle: '',
      errorMessage: '',
    })
    let dataToSend = {
      API_KEY: process.env.REACT_APP_API_KEY,
      id: parseInt(user.id),
      pin: '',
    }
    if (state.pin) {
      if (state.pin.length >= 6) {
        dataToSend = {
          API_KEY: process.env.REACT_APP_API_KEY,
          id: parseInt(user.id),
          pin: state.pin,
        }
      } else {
        setState({
          errorAlert: true,
          errorTitle: 'Error: el PIN no es valido',
          errorMessage: 'La cantidad de caracteres de el PIN debe ser de 6 caracteres',
        })
        setTimeout(() => {
          setState({
            errorAlert: false,
            errorTitle: '',
            errorMessage: '',
          })
        }, 7000)
        return '';
      }
    } else {
      setState({
        errorAlert: true,
        errorTitle: 'Error: no se permiten campos vacios',
        errorMessage: 'No se permiten campos vacios, por favor rellene los campos para continuar',
      })
      setTimeout(() => {
        setState({
          errorAlert: false,
          errorTitle: '',
          errorMessage: '',
        })
      }, 7000)
      return '';
    }
    setState({ waitingForResponse: true })
    API.post('/?confirmEmail', {
      ...dataToSend
    }).then((response) => {
      setState({ waitingForResponse: false })
      // console.log(response, 'respuesta de confirmEmail')
      if (response.data.success === 1 && response.status === 200) {
        setState({ waitingForResponse: false, successAlert: true, showPinInput: false, editEmail: false })
        updateProfile(state.email)
      } else {
        // error
        setState({
          errorAlert: true,
          errorTitle: 'Error: hubo un error al intentar guardar la informacion',
          errorMessage: 'El servidor respondio de forma inesperada',
        })
        setTimeout(() => {
          setState({
            errorAlert: false,
            errorTitle: '',
            errorMessage: '',
          })
        }, 7000)
      }
    }).catch(function (error) {
      setState({ waitingForResponse: false })
      console.log(error)
      setState({
        errorAlert: true,
        errorTitle: 'Error: No se pudo establecer conexion con el servidor',
        errorMessage: 'error:' + error,
      })
      setTimeout(() => {
        setState({
          errorAlert: false,
          errorTitle: '',
          errorMessage: '',
        })
      }, 7000)
    })
  }
  function updateProfile(newEmail: string) {
    let dataToSend = {
      API_KEY: process.env.REACT_APP_API_KEY,
      id: parseInt(user.id),
      email: newEmail,
    }
    API.post('/?getProfile', {
      ...dataToSend
    }).then((response) => {
      // setState({ waitingForResponse: false })

      // console.log(response, 'respuesta de getProfile')
      if (response.data && response.status === 200) {
        updateUser({
          login: true,
          user: {
            // ...response.data.user,
            ...user,
            name: response.data.user.name,
            email: response.data.user.email,
            phone: response.data.user.phone,
            address: response.data.user.address,
            city: response.data.user.city,
            customerType: response.data.user.customerType,
            documentNumber: response.data.user.documentNumber,
            professionalCard: response.data.user.professionalCard,
            documentType: response.data.user.documentType,
          }
        })
      } else {
        // error
        // console.log("Ha ocurrido un error con la respuesta:", response)
        setState({ waitingForResponse: false })
      }
    }).catch(function (error) {
      console.log(error)
      setState({ waitingForResponse: false })
    })
  }
  return (
    <>
      {state.waitingForResponse &&
        <div className="w-full fixed h-full z-80 left-0 top-0">
          <Loader
            animate={{ transform: "rotate(45deg) scale(0.8) translate(0px,0px)", opacity: 1, zIndex: 50, left: "2.5rem", bottom: "1.25rem", position: "fixed" }} transition={{ duration: 0.3 }} initial={{ transform: "rotate(45deg) scale(0.8) translate(-50px,50px)", opacity: 0.1, zIndex: 50, left: "2.5rem", bottom: "1.25rem", position: "fixed" }} />
        </div>
      }
      {state.successAlert &&
        <motion.div initial={{ x: '-150%', opacity: 0.5 }} animate={{ x: '0%', opacity: 1 }} role="alert" className="rounded-xl border fixed  left-3 sm:left-10  bottom-10 z-50 bg-white border-gray-100 p-4 shadow-xl">
          <div className="flex items-start  gap-4">
            <span className="text-green-600">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                className="h-6 w-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                />
              </svg>
            </span>
            <div className="flex-1">
              <strong className="block font-medium text-gray-900">Cambios completados</strong>
              <p className="mt-1 text-sm text-gray-700">
                El correo electronico de tu perfil se ha actualizado con exito.
              </p>
            </div>
            <button onClick={() => { setState({ successAlert: false }) }} className="text-gray-500 transition hover:text-gray-600">
              <span className="sr-only">Dismiss popup</span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                className="h-6 w-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
          </div>
        </motion.div>
      }
      {state.errorAlert &&
        <ErrorAlert ErrorElement={state.ErrorElement}
          animate={{ x: 0, opacity: 1, zIndex: 50, left: "1rem", bottom: "1rem", position: "fixed" }}
          transition={{ duration: 0.3 }}
          initial={{ x: -50, opacity: 0.1, zIndex: 50, left: "1rem", bottom: "1rem", position: "fixed" }}
          message={state.errorMessage}
          title={state.errorTitle} />
      }
      <div className="flex relative sm:flex-col md:flex-row w-full gap-x-5 items-start justify-center">
        <div className="py-8 px-8 gap-10 flex flex-col w-full max-w-[700px]">
          {/* email section */}
          <div className="flex gap-2 py-8 px-8 bg-white rounded-lg items-start flex-col justify-start">
            <p className="text-sm font-medium text-gray-700">Correo Electronico</p>
            {/* Separador */}
            <div className="border border-b-[0px] border-gray-200 w-full" />

            <div className="flex justify-between items-center w-full">
              {state.editEmail
                ? <>
                  {state.showPinInput
                    ?
                    <input value={state.pin} className="h-10 w-40 rounded-lg text-gray-700 border-gray-200 border-[1px] p-3 text-sm" type="text" placeholder="Pin de confirmacion" onChange={(event) => { setState({ pin: event.target.value }) }} />
                    :
                    <input value={state.email} className="h-10 w-60 rounded-lg text-gray-700 border-gray-200 border-[1px] p-3 text-sm" type="text" placeholder="Nuevo Correo Electronico" onChange={(event) => { setState({ email: event.target.value }) }} />
                  }
                  {/* submit button */}
                  {/* <button onClick={() => { }} className="group flex items-center justify-center gap-2 rounded-lg border border-green-500 bg-green-500 h-10 px-1 transition-colors hover:bg-transparent focus:outline-none focus:ring">
                    <span className="flex-shrink-0 rounded-full border border-current bg-white p-2 text-green-500 group-active:text-green-500">
                      <svg className="h-3 w-3" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 12.75l6 6 9-13.5" />
                      </svg>
                    </span>
                  </button> */}
                  {/* cancel update button */}
                  {/* <button onClick={() => { setState({ editEmail: false }); }} className="group flex items-center justify-center gap-2 rounded-lg border border-red-500 bg-red-500 h-10 px-1 transition-colors hover:bg-transparent focus:outline-none focus:ring">
                    <span className="flex-shrink-0 rounded-full border border-current bg-white p-2 text-red-500 group-active:text-red-500">
                      <svg className="h-3 w-3" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M18 6L6 18" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                        <path d="M6 6L18 18" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                      </svg>
                    </span>
                  </button> */}
                  <div className="flex gap-2">
                    {state.showPinInput
                      ? <button onClick={(event) => { validatePin() }} className="group flex items-center justify-center gap-2 rounded-lg border border-green-500 bg-green-500 px-2 py-2 h-10 transition-colors hover:bg-transparent focus:outline-none focus:ring">
                        <span className="capitalize text-sm font-medium text-white transition-colors group-hover:text-green-500 group-active:text-green-500">
                          Validar PIN
                        </span>
                        <span className="flex-shrink-0 rounded-full border border-current bg-white p-2 text-green-500 group-active:text-green-500">
                          <svg className="h-3 w-3" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path className="fill-green-500" strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M17 8l4 4m0 0l-4 4m4-4H3" />
                          </svg>
                        </span>
                      </button>
                      : <button onClick={(event) => { sendEmail() }} className="group flex items-center justify-center gap-2 rounded-lg border border-green-500 bg-green-500 px-2 py-2 h-10 transition-colors hover:bg-transparent focus:outline-none focus:ring">
                        <span className="capitalize text-sm font-medium text-white transition-colors group-hover:text-green-500 group-active:text-green-500">
                          Confirmar
                        </span>
                        <span className="flex-shrink-0 rounded-full border border-current bg-white p-2 text-green-500 group-active:text-green-500">
                          <svg className="h-3 w-3" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path className="fill-green-500" strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M17 8l4 4m0 0l-4 4m4-4H3" />
                          </svg>
                        </span>
                      </button>
                    }
                    <button type="button" onClick={(event) => { setState({ editEmail: false, showPinInput: false, email: '', pin: '' }); }} className="group flex items-center justify-center gap-2 rounded-lg border border-red-500 bg-red-500 px-2 py-2 h-10 transition-colors hover:bg-transparent focus:outline-none focus:ring">
                      <span className="capitalize text-sm font-medium text-white transition-colors group-hover:text-red-500 group-active:text-red-500">
                        cancelar
                      </span>
                      <span className="flex-shrink-0 rounded-full border border-current bg-white p-2 text-red-500 group-active:text-red-500">
                        <svg className="h-3 w-3" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M18 6L6 18" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                          <path d="M6 6L18 18" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>
                      </span>
                    </button>
                  </div>
                </>
                : <>
                  <p className="text-sm text-gray-600">{user.email}</p>
                  <button onClick={() => { setState({ editEmail: true }) }} className="inline-block rounded bg-blue-500 px-4 py-2 h-10 text-xs font-medium border-[1px] border-blue-500 text-white hover:text-blue-500 hover:bg-transparent">Editar</button>
                </>
              }
            </div>
            {state.showPinInput &&
              <p className="text-sm text-gray-600 mt-4">Se ha enviado un correo electrónico a <span className="italic">'{state.email}'</span> con el PIN de verificacion, por favor ingrese el PIN para validar el nuevo correo electrónico</p>
            }
          </div>
          {/* password section */}
          <div className="flex gap-2 py-8 px-8 bg-white rounded-lg items-start flex-col justify-start">
            <p className="text-sm font-medium text-gray-700">Contraseña</p>
            <div className="border border-b-[0px] border-gray-200 w-full" />
            <div className="flex justify-between items-center w-full">
              {state.editName
                ? <>
                  <input className="h-10 w-48 rounded-lg text-gray-700 border-gray-200 border-[1px] p-3 text-sm" type="text" id="username" onChange={(event) => { }} />
                  {/* submit button */}
                  <button onClick={() => { }} className="group flex items-center justify-center gap-2 rounded-lg border border-green-500 bg-green-500 h-10 px-1 transition-colors hover:bg-transparent focus:outline-none focus:ring">
                    <span className="flex-shrink-0 rounded-full border border-current bg-white p-2 text-green-500 group-active:text-green-500">
                      <svg className="h-3 w-3" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 12.75l6 6 9-13.5" />
                      </svg>
                    </span>
                  </button>
                  {/* cancel update button */}
                  <button onClick={() => { setState({ editName: false }); }} className="group flex items-center justify-center gap-2 rounded-lg border border-red-500 bg-red-500 h-10 px-1 transition-colors hover:bg-transparent focus:outline-none focus:ring">
                    <span className="flex-shrink-0 rounded-full border border-current bg-white p-2 text-red-500 group-active:text-red-500">
                      <svg className="h-3 w-3" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M18 6L6 18" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                        <path d="M6 6L18 18" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                      </svg>
                    </span>
                  </button>
                </>
                : <>
                  <p className="text-sm text-gray-600 font-bold">************</p>
                  <button onClick={() => {
                    updateUser({
                      login: true,
                      user: {
                        ...user,
                        status: 0
                      }
                    })
                  }} className="inline-block rounded bg-blue-500 h-10 px-4 py-2 text-xs font-medium border-[1px] border-blue-500 text-white hover:text-blue-500 hover:bg-transparent">Editar</button>
                </>
              }
            </div>
          </div>
        </div>
      </div>

    </>
  )
}

export { Security };
import {motion} from 'framer-motion';
interface ErrorAlertTypes {
  className?: string;
  initial?:{};
  transition?:{};
  animate?:{};
  title?:string;
  message?:string;
  ErrorElement?:any
}

export function ErrorAlert({ErrorElement=null,animate='',transition='',initial='',className='',message="A ocurrido un error inesperado, por favor reporte este error al equipo de desarrollo, muchas gracias.",title="Algo anda mal."}:ErrorAlertTypes) {
  return (
    <motion.div initial={initial} transition={transition} animate={animate} className={`shadow-xl rounded border-l-4 border-red-500 bg-red-50 p-4  sm:max-w-[450px] max-w-[90%] w-full ${className}`} role="alert">
      <strong className="block font-medium text-red-800">{title}</strong>
      <div className="mt-2 text-sm text-red-700">
        {ErrorElement !== null ? ErrorElement : message}
      </div>
    </motion.div>
  )
}

import { useEffect } from 'react';
import { useStateMany } from './customHooks/useStateMany';
import { Login } from './Login';
import { Dashboard } from './Dashboard';
import { SideNav } from './SideNav';
import { Interface } from './Interface/';
import { General } from "./General";
import { Profiles } from "./Profiles";
import { MyOrders } from "./MyOrders";
import { Orders } from './Orders';
import { UpdatePassword } from './UpdatePassword';
import { Reports } from './Reports';
import { Details } from './Details';
import { Security } from './Security'
import { Notification } from './Notifications';
import { Optometrists } from './Optometrists';
function App() {
  // TODO: pasar la alerta de "SUCCESS" a un componente individual y reutilizable
  const [state, setState] = useStateMany({
    login: false,
    user: {
      id: '',
      username: '',
      name: '',
      lastName: '',
      email: '',
      permissions: '',
      phone: '',
      status: null,
      address: '',
      city: '',
      customerType: '',
      documentNumber: '',
      professionalCard: '',
      documentType: '',
      userType: '',
      documentAbbrev: '',
    },
    currentOpen: 'general',
    browserRoute: window.location.href.includes("http://localhost:3000") ? 'development' : 'production'
  })
  useEffect(() => {
  }, [])
  return (
    <div className="App">
      {!state.login &&
        <Login appName='Keratos' setState={setState} state={state} />
      }
      {state.login === true && state.user.permissions && state.user.status === 1 &&
        <Dashboard>
          <SideNav state={state} setState={setState} />
          <Interface>
            <div className='flex w-full items-center justify-end h-5'>
              <Notification user={state.user} />
            </div>
            {state.currentOpen === 'general' && <General />}
            {state.currentOpen === 'profiles' && <Profiles user={state.user} />}
            {state.currentOpen === 'optometrists' && <Optometrists user={state.user} />}
            {state.currentOpen === 'misPedidos' && <MyOrders user={state.user} />}
            {state.currentOpen === 'pedidos' && <Orders user={state.user} />}
            {state.currentOpen === 'reportes' && <Reports user={state.user} />}
            {state.currentOpen === 'details' && <Details user={state.user} updateUser={setState} />}
            {state.currentOpen === 'security' && <Security user={state.user} updateUser={setState} />}
          </Interface>
        </Dashboard>
      }
      {state.login === true && state.user.status === 0 &&
        <UpdatePassword user={state.user} appSetState={setState} appState={state} />
      }
    </div>
  );
}

export default App;

import { motion } from "framer-motion";
import { useStateArrActive } from "../customHooks/useStateArr";

export function General() {
  const [cardData, setCardData] = useStateArrActive([
    {
      id: 1,
      category: "Pizza",
      title: "5 Food Apps Delivering the Best of Your City",
      pointOfInterest: 80,
      backgroundColor: "#814A0E",
      active: false
    },
    {
      id: 2,
      category: "How to",
      title: "Arrange Your Apple Devices for the Gram",
      pointOfInterest: 120,
      backgroundColor: "#959684",
      active: false
    },
    {
      id: 3,
      category: "Pedal Power",
      title: "Map Apps for the Superior Mode of Transport",
      pointOfInterest: 260,
      backgroundColor: "#5DBCD2",
      active: false
    },
    {
      id: 4,
      category: "Holidays",
      title: "Our Pick of Apps to Help You Escape From Apps",
      pointOfInterest: 200,
      backgroundColor: "#8F986D",
      active: false
    },
    {
      id: 5,
      category: "Photography",
      title: "The Latest Ultra-Specific Photography Editing Apps",
      pointOfInterest: 150,
      backgroundColor: "#FA6779",
      active: false
    },
    {
      id: 6,
      category: "They're all the same",
      title: "100 Cupcake Apps for the Cupcake Connoisseur",
      pointOfInterest: 60,
      backgroundColor: "#282F49",
      active: false
    },
    {
      id: 8,
      category: "Holidays",
      title: "Seriously the Only Escape is the Stratosphere",
      pointOfInterest: 260,
      backgroundColor: "#CC555B",
      active: false
    },
    {
      id: 7,
      category: "Cats",
      title: "Yes, They Are Sociopaths",
      pointOfInterest: 200,
      backgroundColor: "#AC7441",
      active: false,
    },
  ])
  const variants: any = {
    open: { left: '50%', top: '50%', position: 'fixed', zIndex: '30' },
    closed: { position: 'static' },
  }
  return (
    // <div className="w-full bg-red-200 flex justify-center items-start mb-auto">
    //   <ul className="bg-blue-100 mb-auto grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-5">
    // {cardData.map((card:any)=>(
    //   <motion.li 
    //   key={card.id} 
    //   className={`bg-red-400 min-h-[200px] rounded w-full max-w-[400px] bg-transparent relative`}
    //   > 
    //     <motion.div className={`${card.active ? 'w-full h-full z-[-10] absolute opacity-0' : 'opacity-0 absolute left-0 top-0 w-full h-full z-10 bg-slate-200'}`} onClick={()=>{setCardData(card.id,true);console.log('qie,ierd')}}/>
    //     <motion.div className={`${card.active ? 'transition-[background-color] duration-1000 opacity-70 fixed bg-black left-0 top-0 w-screen h-screen z-20' : 'w-[0px] h-[0px] z-[-1] opacity-0 fixed'}`} onClick={()=>{setCardData(card.id,false)}}>
    //     </motion.div>
    //     <motion.div animate={card.active ? "open" : "closed"} variants={variants} className={`relative transition-all duration-300 h-full ${card.active ? 'top-[50%] left-[50%] fixed bg-red-500 w-full h-full max-w-[700px] max-h-[90vh] z-30 translate-x-[-50%] translate-y-[-50%]' : 'bg-red-300 static z-0'}`}>
    //       <motion.h1>{card.category}</motion.h1>
    //       <motion.p>{card.title}</motion.p>
    //       <motion.p>{card.id}</motion.p>
    //     </motion.div>
    //   </motion.li>
    // ))}
    // </ul>
    // </div>
    <div className="flex items-center justify-center flex-col h-full w-full">
      <h4 className="text-4xl font-bold mb-2 text-center text-blue-500">
        Bienvenido!
      </h4>
      <p className="mb-10 text-gray-600 text-center max-w-[500px]">En el menu de <strong>Pedidos</strong> podras elegir entre una gran variedad de tipos de lentes, lentes y colores de lentes que necesites, al crear un pedido podras estar al dia con su estado actual.</p>
      <p className="mb-10 text-gray-600 text-center max-w-[500px]">En el menu de <strong>Cuenta</strong> podras personalizar la informacion de tu cuenta de la forma en que quieras.</p>
    </div>
  )
}

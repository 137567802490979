import { useStateMany } from "../customHooks/useStateMany"
import { Listbox, Transition } from '@headlessui/react'
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid'
import { Fragment, useEffect, useState } from 'react'
import './styles.css'
import { PopUp } from "../PopUp"

const OrderFilter = ({ filteredState, setFilteredState }: any) => {

  const ordersTypes = [
    { name: 'Todos', id: 0, color: '', bgColor: '', textColor: '', filter: ['1', '2', '3'] },
    { name: 'Pedido', id: 1, bgColor: 'bg-sky-200', textColor: 'text-sky-800', filter: ['1'] },
    { name: 'Caja', id: 2, bgColor: 'bg-lime-300', textColor: 'text-lime-700', filter: ['2'] },
    { name: 'Accesorio', id: 1, bgColor: 'bg-orange-200', textColor: 'text-orange-700', filter: ['3'] },
  ]
  const stateType = [
    { name: 'Todos', id: 0, color: '', bgColor: '', textColor: '', filter: ['aceptado', 'revision', 'cancelado', 'completado', 'entregado', 'rechazado'] },
    { name: 'Aceptado', id: 1, bgColor: 'bg-emerald-200', textColor: 'text-emerald-800', filter: ['aceptado'] },
    { name: 'Completado', id: 2, bgColor: 'bg-amber-200', textColor: 'text-amber-800', filter: ['completado'] },
    { name: 'Revision', id: 3, bgColor: 'bg-gray-300', textColor: 'text-slate-700', filter: ['revision'] },
    { name: 'Entregado', id: 4, bgColor: 'bg-indigo-200', textColor: 'text-indigo-800', filter: ['entregado'] },
    { name: 'Rechazado', id: 5, bgColor: 'bg-red-100', textColor: 'text-red-700', filter: ['rechazado'] },
    { name: 'Anulado', id: 6, bgColor: 'bg-red-600', textColor: 'text-black', filter: ['cancelado'] },
  ]
  const typesToSortByDate = [
    { id: 0, name: 'Ninguno' },
    { id: 1, name: 'Fecha especifica' },
    { id: 2, name: 'Descendente' },
    { id: 3, name: 'Ascendente' },
  ]

  const [orderDate, setOrderDate] = useState(typesToSortByDate[0])
  const [orderType, setOrderType] = useState(ordersTypes[0])
  const [orderState, setOrderState] = useState(stateType[0])
  const [dateInput, setDateInput] = useState(null);
  const initialState = {
    optometristName: '',
    patientName: '',
    id: '',
    update: false,
  }
  const [state, setState] = useStateMany({
    ...initialState
  })
  const resetToInitialState = () => {
    setOrderDate(typesToSortByDate[0])
    setOrderType(ordersTypes[0])
    setOrderState(stateType[0])
    setDateInput(null);
    setState({ ...initialState })
  }
  const handleChangeDate = (e: any) => {
    setDateInput(e.target.value);
    setState({ update: !state.update })
  };

  // cada vez que se ejecuta filtra las ordenes segun las opciones en la variable de estado filteredData
  useEffect(() => {
    const cloneArray = (items: any) =>
      items.map((item: any) =>
        Array.isArray(item)
          ? cloneArray(item)
          : item
      )

    let orders = cloneArray(filteredState.data)

    function sortDates(a: any, b: any) {
      var dateA = new Date(a.created_order).getTime();
      var dateB = new Date(b.created_order).getTime();
      // para organizarlos de mas reciente arriba a mas antiguo abajo
      if (orderDate.name === 'Descendente') { return dateB > dateA ? 1 : -1; }
      // para organizarlos de mas antiguos arriba y los mas nuevos abajo
      if (orderDate.name === 'Ascendente') { return dateA > dateB ? 1 : -1; }
    }
    if (orderDate.id !== 1) {
      orders.sort(sortDates)
    }
    if (orderDate.id === 0) {
      orders = cloneArray(filteredState.data)
    }
    let newOrders = []

    for (let i = 0; i < orders.length; i++) {
      const order = orders[i]
      if (orderDate.id === 1) {
        if (orderType.filter.includes(order.type_order) && orderState.filter.includes(order.state_order) && order.optometrist_name.toLowerCase().includes(state.optometristName.toLowerCase()) && order.id_order.includes(state.id) && order.created_at.includes(dateInput) && order.patient_name.toLowerCase().includes(state.patientName.toLowerCase())) (

          newOrders.push(order)
        )
      } else {
        if (orderType.filter.includes(order.type_order) && orderState.filter.includes(order.state_order) && order.optometrist_name.toLowerCase().includes(state.optometristName.toLowerCase()) && order.id_order.includes(state.id) && order.patient_name.toLowerCase().includes(state.patientName.toLowerCase())) (

          newOrders.push(order)
        )
      }
    }
    setFilteredState({ filteredData: newOrders })
  }, [state.update])
  return (
    <thead>
      <tr className="relative z-[2]">
        {/* Input para filtrar por ID de pedido */}
        <th className="whitespace-nowrap px-4 py-2 text-left font-medium text-gray-900">

          <input type="number" value={state.id} onChange={(event) => { setState({ id: event.target.value }); setState({ update: !state.update }) }} className={`h-10 min-w-[70px] max-w-[70px] rounded-lg bg-transparent text-gray-700 border-gray-200 border-[1px] px-3 text-sm text-left focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white focus:bg-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-sm`} />
        </th>
        {/* Input para filtrar por el nombre del optometrista */}
        <th className="whitespace-nowrap px-4 py-2 text-left font-medium text-gray-900">

          <input type="text" value={state.optometristName} onChange={(event) => { setState({ optometristName: event.target.value }); setState({ update: !state.update }) }} className={`h-10 min-w-[90px] max-w-[170px] rounded-lg bg-transparent text-gray-700 border-gray-200 border-[1px] pl-3 text-sm text-left focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white focus:bg-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-sm`} />
        </th>
        {/* Input para filtrar por el nombre del paciente */}
        <th className="whitespace-nowrap px-4 py-2 text-left font-medium text-gray-900">
          <input type="text" value={state.patientName} onChange={(event) => { setState({ patientName: event.target.value }); setState({ update: !state.update }) }} className={`h-10 min-w-[90px] max-w-[170px] rounded-lg bg-transparent text-gray-700 border-gray-200 border-[1px] pl-3 text-sm text-left focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white focus:bg-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-sm`} />
        </th>
        {/* Input para filtrar por el fecha del pedido */}
        <th className="whitespace-nowrap px-4 py-2 text-left font-medium text-gray-900">
          {orderDate.id === 1 ?
            <div className="flex justify-center items-center">
              <input onChange={(e) => { handleChangeDate(e) }} type="date" name="date" id="date" className={`relative h-10 w-[130px] rounded-l-lg text-gray-700 border-gray-200 border-[1px] pl-3 pr-1 text-sm text-left focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-sm ${dateInput === null ? 'text-gray-500' : 'text-gray-700'}`} />
              <button onClick={() => { setOrderDate(typesToSortByDate[0]); setState({ update: !state.update }) }} className="group rounded-r-lg flex items-center justify-center gap-2 border border-red-500 bg-red-500 h-10 w-10 px-1 transition-colors hover:bg-transparent focus:outline-none focus:ring">
                <span className="flex-shrink-0 rounded-full border border-current bg-white p-2 text-red-500 group-active:text-red-500">
                  <svg className="h-3 w-3" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M18 6L6 18" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M6 6L18 18" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                  </svg>
                </span>
              </button>
            </div>
            :
            <Listbox value={orderDate} onChange={setOrderDate}>
              <div className="relative rounded-lg">
                <Listbox.Button className={`relative h-10 min-w-[170px] max-w-[170px] rounded-lg text-gray-700 border-gray-200 border-[1px] pl-3 text-sm text-left focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-sm`}>
                  <span className={`block truncate ${orderDate.id === 0 && 'text-gray-500'}`}>{orderDate.name}</span>
                  <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                    <ChevronUpDownIcon
                      className="h-5 w-5 text-gray-400"
                      aria-hidden="true"
                    />
                  </span>
                </Listbox.Button>
                <Transition
                  as={Fragment}
                  leave="transition ease-in duration-100"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <Listbox.Options className="z-40 absolute mt-1 max-h-60 min-w-[150px] w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                    {typesToSortByDate.map((element: { name: string, id: number }, elementIdx: number) => (
                      <Listbox.Option
                        key={elementIdx}
                        onClick={((event) => {
                          if (element.id !== 1) {
                            setState({ update: !state.update })
                          }
                        })}
                        className={({ active }) =>
                          `relative cursor-default select-none py-2 pl-3 pr-4 ${active ? 'bg-blue-600 text-white' : 'text-gray-900'
                          }`
                        }
                        value={element}
                      >
                        {({ selected }) => (
                          <>
                            <span
                              className={`block truncate ${element.id === 0 && 'text-gray-500'} ${selected ? 'font-medium' : 'font-normal'
                                }`}
                            >
                              {element.name}
                            </span>
                            {selected ? (
                              <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-indigo-600">
                                <CheckIcon className="h-5 w-5" aria-hidden="true" />
                              </span>
                            ) : null}
                          </>
                        )}
                      </Listbox.Option>
                    ))}
                  </Listbox.Options>
                </Transition>
              </div>
            </Listbox>
          }

        </th>
        {/* Input para filtrar por estado de pedido */}
        <th className="whitespace-nowrap px-4 py-2 text-left font-medium text-gray-900">
          <Listbox value={orderState} onChange={setOrderState}>
            <div className="relative rounded-lg">
              <Listbox.Button className={`relative h-10 min-w-[130px] ${orderState.bgColor ? orderState.bgColor : ''} rounded-lg text-gray-700 border-gray-200 border-[1px] pl-3 text-sm text-left focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-sm`}>
                <span className={`block truncate ${orderState.textColor ? orderState.textColor : "text-gray-500"}`}>{orderState.name}</span>
                <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                  <ChevronUpDownIcon
                    className="h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                </span>
              </Listbox.Button>
              <Transition
                as={Fragment}
                leave="transition ease-in duration-100"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Listbox.Options className="z-40 absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                  {stateType.map((orderState: { name: string, id: number, bgColor: string, textColor: string }, orderIdx: number) => (
                    <Listbox.Option
                      key={orderIdx}
                      onClick={((event) => {
                        // filterData(orderState)
                        setState({ update: !state.update })
                      })}
                      className={({ active }) =>
                        `relative cursor-default select-none py-2 pl-3 pr-4 ${active ? 'bg-blue-600 text-white' : 'text-gray-900'
                        }`
                      }
                      value={orderState}
                    >
                      {({ selected }) => (
                        <>
                          <span
                            className={`block truncate ${orderState.textColor ? orderState.textColor : 'text-gray-500'} ${selected ? 'font-medium' : 'font-normal'
                              }`}
                          >
                            {orderState.name}
                          </span>
                          {selected ? (
                            <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-indigo-600">
                              <CheckIcon className="h-5 w-5" aria-hidden="true" />
                            </span>
                          ) : null}
                        </>
                      )}
                    </Listbox.Option>
                  ))}
                </Listbox.Options>
              </Transition>
            </div>
          </Listbox>
        </th>
        {/* Input para filtrar por Tipo de pedido */}
        <th className="whitespace-nowrap px-4 py-2 text-left font-medium text-gray-900">
          <Listbox value={orderType} onChange={setOrderType}>
            <div className="relative rounded-lg">
              <Listbox.Button className={`relative h-10 min-w-[120px] ${orderType.bgColor ? orderType.bgColor : ''} rounded-lg text-gray-700 border-gray-200 border-[1px] pl-3 text-sm text-left focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-sm`}>
                <span className={`block truncate ${orderType.textColor ? orderType.textColor : "text-gray-500"}`}>{orderType.name}</span>
                <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                  <ChevronUpDownIcon
                    className="h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                </span>
              </Listbox.Button>
              <Transition
                as={Fragment}
                leave="transition ease-in duration-100"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Listbox.Options className="z-40 absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                  {ordersTypes.map((type: { name: string, id: number, bgColor: string, textColor: string }, orderIdx: number) => (
                    <Listbox.Option
                      key={orderIdx}
                      onClick={((event) => {
                        // filterData(type)
                        setState({ update: !state.update })
                      })}
                      className={({ active }) =>
                        `relative cursor-default select-none py-2 pl-3 pr-4 ${active ? 'bg-blue-600 text-white' : 'text-gray-900'
                        }`
                      }
                      value={type}
                    >
                      {({ selected }) => (
                        <>
                          <span
                            className={`block truncate ${type.textColor ? type.textColor : 'text-gray-500'} ${selected ? 'font-medium' : 'font-normal'
                              }`}
                          >
                            {type.name}
                          </span>
                          {selected ? (
                            <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-indigo-600">
                              <CheckIcon className="h-5 w-5" aria-hidden="true" />
                            </span>
                          ) : null}
                        </>
                      )}
                    </Listbox.Option>
                  ))}
                </Listbox.Options>
              </Transition>
            </div>
          </Listbox>
        </th>
        <th className="px-4 py-2 lg:sticky lg:right-0 lg:bg-gray-100 min-w-[130px]">
          <PopUp message="Presione para reiniciar los filtros." className="w-min font-normal">
            <button type="button" onClick={(event) => {
              resetToInitialState()
            }} className="group flex items-center justify-center gap-2 h-10 w-10 rounded-lg border border-gray-500 bg-gray-500 px-3 py-2 transition-colors hover:bg-transparent focus:outline-none focus:ring">
              <span className="flex-shrink-0 rounded-full border border-current bg-white p-2 text-gray-500 group-active:text-gray-500">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="h-3 w-3" >
                  <path strokeLinecap="round" strokeLinejoin="round" d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99" />
                </svg>
              </span>
            </button>
          </PopUp>
        </th>
      </tr>
    </thead>
  )
}





export { OrderFilter };
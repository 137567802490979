import { motion } from "framer-motion";
import { Modal } from "../modal";
import { useStateMany } from "../customHooks/useStateMany";
import { useEffect } from "react";
import { API } from "../API";
import { Loader } from "../Loader";

const OrderDataHistory = ({ setVerPedidoState, verPedidoState }: any) => {
  const initialState = {
    data: [],
    reload: false,
    dataLoaded: false,
    waitingForResponse: false,
    showItem: false,
    item: {},
  }
  const [state, setState] = useStateMany({
    ...initialState
  })
  useEffect(() => {
    let dataToSend = {
      API_KEY: process.env.REACT_APP_API_KEY,
      id_order: parseInt(verPedidoState.orderId),
    }
    setState({ waitingForResponse: true, dataLoaded: false })
    API.post('/?searchDataHistory', {
      ...dataToSend
    }).then((response) => {
      // console.log(response, 'response data')
      setState({ waitingForResponse: false })
      if (response.status === 200) {
        let toSet = response.data.data_history ? response.data.data_history : []
        setState({
          data: [...toSet],
          dataLoaded: true
        })
      } else {
        console.log('Ocurrio un error:', response)
      }
    }).catch((error) => {
      console.log('Ha ocurrido un error con la respuesta del servidor', error)
    })
  }, [state.reload])
  return (
    <div className="z-50">
      <Modal />
      <motion.div initial={{ y: 50, opacity: 0 }} animate={{ y: 0, opacity: 1 }} transition={{ duration: 0.5 }} className="fixed top-0 left-0 w-full h-full flex justify-center items-center z-60">
        {/* muestra el listado de pedidos */}
        {state.showItem === false
          ?
          <div className="rounded-lg bg-white p-8 shadow-2xl max-w-lg w-full">
            <p className="text-lg font-bold">Historial de cambios</p>
            <div className={`mt-2 max-h-[460px] min-h-[200px] ${state.waitingForResponse || (state.waitingForResponse === false && state.dataLoaded === true && state.data.length === 0) ? 'items-center justify-center' : 'overflow-y-auto'} gap-y-2 flex flex-col`}>
              {state.waitingForResponse === true && state.dataLoaded === false &&
                <div className="-mb-7">
                  <Loader />
                </div>
              }
              {state.waitingForResponse === false && state.dataLoaded === true &&
                state.data.length > 0 ?
                state.data.map((el: any) => {
                  return (
                    <HistorialDataContainer item={el} key={el.id} state={state} setState={setState} />
                  )
                })
                : <></>
              }
              {state.waitingForResponse === false && state.dataLoaded === true &&
                state.data.length === 0 &&
                <p className="text-gray-500 font-medium text-lg">No hay datos</p>
              }
            </div>
            <div className="mt-4 flex gap-2">
              <button onClick={() => {
                setVerPedidoState({
                  showOrderDataHistory: false
                })
              }} type="button" className="rounded border-2 w-full border-gray-300 bg-gray-100 px-4 py-2 text-sm font-medium text-gray-700">
                Volver
              </button>
            </div>
          </div>
          :
          <div className="rounded-lg bg-white p-8 shadow-2xl max-w-lg w-full flex flex-col gap-y-2">
            <p>Cambios realizador por: <strong>{state.item.email}</strong></p>
            <p>Fecha de los cambios: <strong>{state.item.created_at}</strong></p>
            <p>Cambios realizados en:</p>
            <div>
              <ul>
                {state.item.data ?
                  state.item.data.map((el: string[]) => {
                    return (
                      <li key={`${el}`} className="pl-2 text-gray-800 flex items-center gap-y-"><strong><div className="w-1.5 h-1 rounded-full mr-2 bg-gray-700"></div></strong> {el}</li>
                    )
                  })
                  : ''}
              </ul>
            </div>
            <button type="button" className="rounded border-2 border-gray-300 bg-gray-100 px-4 py-2 text-sm font-medium text-gray-700" onClick={() => { setState({ showItem: false, item: {} }) }}>
              Volver
            </button>
          </div>
        }
        {/* Muestra la informacion de seleccionada en la lista */}
      </motion.div>
    </div>
  )
}
const HistorialDataContainer = ({ item, state, setState }: any) => {
  return (
    <div className="flex justify-between gap-x-4 items-center w-full rounded-lg p-2.5 border-gray-700 border-2">
      <div className="flex items-center justify-center rounded-full max-w-[60px] max-h-[60px] w-full text-gray-700">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.2} stroke="currentColor" className="w-12 h-12">
          <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m0 12.75h7.5m-7.5 3H12M10.5 2.25H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z" />
        </svg>
      </div>
      <div className="w-full flex flex-col justify-between gap-y-1">
        <div className="flex">
          <p className="text-sm font-medium first-letter:uppercase">Modificado por<span className="text-sm font-normal text-gray-700"> {item.email}</span></p>
        </div>
        <div className="text-sm">
          <span>{item.created_at}</span>
        </div>
      </div>
      <div>
        <button onClick={() => {
          setState({
            showItem: true, item: {
              ...item,
              data: JSON.parse(item.data)
            }
          })
        }} type="button" className="border-2 border-gray-700 font-medium p-4 rounded-lg hover:bg-gray-700 hover:text-white">Ver</button>
      </div>
    </div>
  )
}

export { OrderDataHistory };
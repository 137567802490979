import { Fragment, useEffect, useState } from 'react'
import { Combobox, Transition } from '@headlessui/react'
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid'
import { useStateMany } from '../customHooks/useStateMany'
import { API } from '../API'
import { userType } from '../typos'

function OrderDataLoader({ user, eyesState, setEyesState, setCity, citysData }: {
  user: userType,
  eyesState: any,
  setEyesState: any,
  setCity?: any,
  citysData?: any,
}) {
  const [state, setState] = useStateMany({
    optometrists: [
      { id: 0, name: 'Seleccione un optometra' },
    ],
    reload: false,
    loading: false,
    loaded: false,
  })

  useEffect(() => {
    try {
      let dataToSend = {
        API_KEY: process.env.REACT_APP_API_KEY,
        id_profile_creator: parseInt(user.id),
      }
      setState({ loading: true });

      API.post('/?optometrists', {
        ...dataToSend
      }).then((response) => {
        if (response.status === 200) {
          let toSet = response.data.optometrists ? response.data.optometrists : [];
          let optometrists = [
            { id: 0, name: 'Seleccione un optometra'  },
            ...toSet,
          ]
          setState({ optometrists: optometrists, loading: false, loaded: true });
        } else {
          setState({ loading: false, loaded: true, error: true, errorTitle: 'Error al consultar la informacion de los usuarios', errorMessage: 'No se pudo obtener los datos de los usuarios, por favor comunique este error al equipo de desarrollo' })
        }
      }).catch((error) => {
        console.log("Ha ocurrido un error", error)
        setState({ loading: false, loaded: true, error: true, errorTitle: 'Error al consultar la informacion de los usuarios', errorMessage: 'No se pudo obtener los datos de los usuarios, por favor comunique este error al equipo de desarrollo' })
      });
    } catch (error) {
      console.log(error)
    }
  }, [state.reload])

  const [selected, setSelected] = useState(state.optometrists[0])
  const [query, setQuery] = useState('')

  function loadValues(item: any) {
    console.log(item, 'item')
    setEyesState({ 
      nombreDelDoctor: `${item.name ? item.name : ''} ${item.last_name ? item.last_name : ''}`,
      tarjetaProfesional: item.professional_card ? item.professional_card : '',
      address: item.address ? item.address : '' ,
      id_client:parseInt(item.id),
    })
    if (item.city !== "" && item.city) {
      let findCity = citysData.findIndex((el: { id: number, name: string }) => el.name === item.city)
      setCity(citysData[findCity])
    }else{
      setCity(citysData[0])
    }
  }
  // detecta algun cambio en la variable selected
  useEffect(() => {
    loadValues(selected)
  }, [selected])
  
  const filteredPeople =
    query === ''
      ? state.optometrists
      : state.optometrists.filter((person: any) =>
        person.name
          .toLowerCase()
          .replace(/\s+/g, '')
          .includes(query.toLowerCase().replace(/\s+/g, ''))
      )
  return (
    <div className="">
      <Combobox value={selected} onChange={setSelected}>
        <div className="relative">
          <div className="relative h-12 mt-2 w-full rounded-lg border-gray-200 border-[1px] text-sm cursor-default overflow-hidden bg-white text-left focus:outline-none sm:text-sm">
            <Combobox.Input
              className={`w-full h-12 border-none py-2 pl-3 pr-10 text-sm ${selected.id === 0 ? 'text-gray-500' : 'text-gray-700'} focus:outline-none`}
              displayValue={(person: { name: string,last_name: string }) => `${person.name} ${person.last_name ? person.last_name : ''}`}
              onChange={(event) => { setQuery(event.target.value) }}
            />
            <Combobox.Button className="absolute inset-y-0 right-0 flex items-center pl-4 pr-2">
              <ChevronUpDownIcon
                className="h-5 w-5 text-gray-400"
                aria-hidden="true"
              />
            </Combobox.Button>
          </div>
          <Transition
            as={Fragment}
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
            afterLeave={() => setQuery('')}
          >
            <Combobox.Options className="fixed z-40 max-w-[450px] mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
              {filteredPeople.length === 0 && query !== '' ? (
                <div className="relative cursor-default select-none py-2 px-4 text-gray-700">
                  Sin coincidencias
                </div>
              ) : (
                state.optometrists && state.optometrists.length > 1 && state.loaded === true && state.loading === false ?
                filteredPeople.map((person: any) => {
                  if (person) {
                    return (
                      <Combobox.Option
                        key={person.id}
                        className={({ active }) =>
                          `relative cursor-default select-none py-2 pl-10 pr-4 ${person.id === 0 && 'hidden'} ${active ? 'bg-blue-600 text-white' : 'text-gray-900'
                          }`
                        }
                        value={person}
                      >
                        {({ selected, active }) => (
                          <>
                            <span
                              className={`block truncate ${selected ? 'font-medium' : 'font-normal'
                                }`}
                            >
                              {`${person.name} ${person.last_name ? person.last_name : ''}`}
                            </span>
                            {selected ? (
                              <span
                                className={`absolute inset-y-0 left-0 flex items-center pl-3 ${active ? 'text-white' : 'text-blue-600'
                                  }`}
                              >
                                <CheckIcon className="h-5 w-5" aria-hidden="true" />
                              </span>
                            ) : null}
                          </>
                        )}
                      </Combobox.Option>
                    )
                  }
                })
                : <div>
                  <p>No tienes optometras registrados</p>
                </div>
              )}
            </Combobox.Options>
          </Transition>
        </div>
      </Combobox>
    </div>
  )
}

export { OrderDataLoader }